import { FrameLeftRu } from './FrameLeftRu';
import * as React from 'react';

interface Size {
  width: number;
  height: number;
}

export const FrameRu = ({ height, width }: Size) => {
  return (
    <g>
      <rect style={{ fill: 'none', stroke: '#060606', strokeWidth: 0.05 }} x="0" y="0" width={width} height={height} />
      <rect
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 0.5 }}
        width={width - 25}
        height={height - 10}
        x="20"
        y="5"
      />
      <g transform={`translate(0, ${height - 155})`}>
        <FrameLeftRu />
      </g>
    </g>
  );
};
