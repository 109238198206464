import { NgGraceModel } from './NgGraceModel';
import { CanvasEngineOptions, SelectionBoxLayerFactory } from '@projectstorm/react-canvas-core';
import { NodeDirectory } from './directory/NodeDirectory';
import { DefaultLinkLayerFactory } from './insides/layer/DefaultLinkLayerFactory';
import { DirectoryPortFactory } from './directory/DirectoryPortFactory';
import { BusPortFactory } from './bus/BusPortFactory';
import { ConnectivityPortFactory } from './connectivity/ConnectivityPortFactory';
import { DefaultState } from './states/DefaultState';
import { ModelObserver, NgGraceModelObserver } from './NgGraceModelObserver';
import { CommonFunctionalEngine } from './CommonFunctionalEngine';

export class NgGraceEngine extends CommonFunctionalEngine {
  constructor(
    directory: NodeDirectory,
    observer: ModelObserver = new NgGraceModelObserver(),
    options: CanvasEngineOptions = {}
  ) {
    super({ ...options }, observer);

    this.layerFactories.registerFactory(new DefaultLinkLayerFactory());
    this.layerFactories.registerFactory(new SelectionBoxLayerFactory());

    [new DirectoryPortFactory(directory), new BusPortFactory(), new ConnectivityPortFactory()].forEach((factory) =>
      this.portFactories.registerFactory(factory as any)
    );

    this.stateMachine.pushState(new DefaultState());
  }

  buildNewModel(): NgGraceModel {
    return new NgGraceModel();
  }
}
