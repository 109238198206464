import { EditablePath } from '../Path';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';
import { SmartLinkModel } from '../../SmartLinkModel';

export class ExtendingEditablePath implements EditablePath {
  private readonly link: SmartLinkModel;
  private readonly origin: EditablePath;

  constructor(link: SmartLinkModel, origin: EditablePath) {
    this.link = link;
    this.origin = origin;
  }

  moveSegment(startPoint: SmartLinkPointModel, displacement: Point): void {
    const link = this.link;
    const existingPoints = link.getPoints();
    const endPoint = existingPoints[existingPoints.indexOf(startPoint) + 1];
    const sourceConnector = existingPoints[1];
    const targetConnector = existingPoints[existingPoints.length - 2];

    if (sourceConnector.getID() === startPoint.getID()) {
      const sourceConnectorIndex = 1;
      link.addPoint(
        new SmartLinkPointModel({
          position: sourceConnector.getPosition().clone(),
          link: link,
        }),
        sourceConnectorIndex
      );
    }

    if (targetConnector.getID() === endPoint.getID()) {
      const targetConnectorIndex = link.getPoints().length - 1;
      link.addPoint(
        new SmartLinkPointModel({
          position: targetConnector.getPosition().clone(),
          link: link,
        }),
        targetConnectorIndex
      );
    }

    this.origin.moveSegment(startPoint, displacement);
  }
}
