import styled from 'styled-components';
import { Styled as StyledInputField } from '../../widgets/InputField.styled';

const OpexForm = styled.form`
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px 16px;
  align-items: center;

  ${StyledInputField.InputField} {
    width: auto;
    margin: 0;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.dark};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

export const Styled = {
  OpexForm,
  Name,
  Description,
};
