import React, { useCallback } from 'react';
import { Styled as S } from './InputRadioGroup.styled';
import { InputRadio } from './InputRadio';

export type OptionType<T extends string> = {
  id: T;
  name: string;
  disabled?: boolean;
};

type InputRadioGroupProps<T extends string> = {
  name: string;
  label?: string;
  value?: T;
  hideBorder?: boolean;
  options: OptionType<T>[];

  onChange(selected: T): void;
};

export const InputRadioGroup = <T extends string>({
  name,
  label,
  value,
  hideBorder,
  options,
  onChange,
}: InputRadioGroupProps<T>) => {
  const handleChange = useCallback(
    (newOption: T) => {
      newOption !== value && onChange(newOption);
    },
    [onChange, value]
  );

  return (
    <S.InputRadioGroup empty={false} hideBorder={hideBorder}>
      <S.InputContainer>
        {options.map((o) => (
          <InputRadio<T>
            key={o.id}
            value={o.id}
            label={o.name}
            disabled={o.disabled}
            name={name}
            selected={value}
            onChange={handleChange}
          />
        ))}
      </S.InputContainer>
      <S.InputLabel>{label}</S.InputLabel>
    </S.InputRadioGroup>
  );
};
