import React, { FormEvent, useCallback, useState } from 'react';
import { useOverlay } from '../../context/OverlayContext';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Api } from '../../../api/Api';
import { Styled as S } from '../AuthForm.styled';
import Logo from '../../../assets/logo.svg';
import { InputField } from '../../widgets/InputField';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';

interface PasswordRecoverySettingsProps {
  onRecoveryRequest: () => void;
}

export const PasswordRecoverySettings: React.FC<PasswordRecoverySettingsProps> = ({ onRecoveryRequest }) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  const handleEmailChange = useCallback((email: string) => {
    setError(undefined);
    setEmail(email);
  }, []);

  const handleRecovery = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      overlay.show();
      Api.createPasswordRecoveryLink({ email }, { omitInterceptorErrorModal: true })
        .then(() => {
          onRecoveryRequest();
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    },
    [email, onRecoveryRequest, overlay]
  );

  return (
    <S.AuthForm>
      <S.Main onSubmit={handleRecovery}>
        <S.LeftPanel>
          <S.Title>Password recovery</S.Title>
          <S.Description>Enter the email you're using for your account.</S.Description>
        </S.LeftPanel>
        <S.RightPanel>
          <S.Logo src={Logo} alt="ngGrace Logo" />
          <div>Enter the email you're using for your account</div>
          <S.Form>
            <InputField label={'Work email'} value={email} onChange={handleEmailChange} />
          </S.Form>
          {error && <Feedback error text={error} />}
          <S.FooterOnlyButtons>
            <S.Buttons>
              <Btn type="button" secondary onClick={redirectToLoginPage}>
                Cancel
              </Btn>
              <Btn type="submit" disabled={loading}>
                {!loading ? 'Reset password' : 'Processing'}
              </Btn>
            </S.Buttons>
          </S.FooterOnlyButtons>
        </S.RightPanel>
      </S.Main>
    </S.AuthForm>
  );
};
