import {
  AbstractDisplacementState,
  AbstractDisplacementStateEvent,
  Action,
  ActionEvent,
  InputType,
  State,
} from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { DiagramEngine } from '../insides/engine/DiagramEngine';
import { BusPortModel } from '../bus/BusPortModel';

export class MoveBusPortState extends AbstractDisplacementState<DiagramEngine> {
  private port?: BusPortModel;
  private initialOffset?: number;

  constructor() {
    super({ name: 'move-bus-port' });

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          this.port = this.engine.getMouseElement(event.event as MouseEvent) as BusPortModel;
          this.initialOffset = this.port.getBusOffset();
          this.engine.getModel().clearSelection();
        },
      })
    );
  }

  fireMouseMoved(event: AbstractDisplacementStateEvent) {
    const mousePoint = this.engine.getRelativeMousePoint(event.event);
    this.engine.getCanvas().style.cursor = this.getCursor();
    this.port!.move(mousePoint);
    this.engine.repaintCanvas();
  }

  deactivated(next: State) {
    this.engine.getCanvas().style.removeProperty('cursor');
    if (this.initialOffset !== this.port?.getBusOffset()) {
      this.port?.getParent().fireEvent({}, 'payloadChanged');
    }
    super.deactivated(next);
  }

  private getCursor = (): string => {
    const horizontal = !this.port!.getParent().getRotation().isAxisSwapped();
    return horizontal ? 'ew-resize' : 'ns-resize';
  };
}
