import React, { FormEvent, useCallback, useState } from 'react';
import { Styled as S } from './../AuthForm.styled';
import Logo from '../../../assets/logo.svg';
import { InputField } from '../../widgets/InputField';
import { Btn } from '../../widgets/Btn.styled';
import { CompanyRegisterDto } from '../../../api/nggrace-back';
import { useOverlay } from '../../context/OverlayContext';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Api } from '../../../api/Api';
import { Feedback } from '../../widgets/Feedback';

interface CompanyRegisterSettingsProps {
  onRegisterRequest: () => void;
}

export const CompanyRegisterSettings: React.FC<CompanyRegisterSettingsProps> = ({ onRegisterRequest }) => {
  const [company, setCompany] = useState<CompanyRegisterDto>({ name: '', administratorEmail: '' });
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  const handleNameChange = useCallback((name: string) => {
    setError(undefined);
    setCompany((company) => ({ ...company, name }));
  }, []);

  const handleAdministratorEmailChange = useCallback((administratorEmail: string) => {
    setError(undefined);
    setCompany((company) => ({ ...company, administratorEmail }));
  }, []);

  const handleRegister = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      overlay.show();
      Api.registerCompany(company, { omitInterceptorErrorModal: true })
        .then(() => {
          onRegisterRequest();
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    },
    [company, onRegisterRequest, overlay]
  );

  return (
    <S.AuthForm>
      <S.Main onSubmit={handleRegister}>
        <S.LeftPanel>
          <S.Title>Registration request</S.Title>
          <S.Description>Please use the registration form to register your company.</S.Description>
        </S.LeftPanel>
        <S.RightPanel>
          <S.Logo src={Logo} alt="ngGrace Logo" />
          <div>Use your contact details to register</div>
          <S.Form>
            <InputField label={'Company name'} value={company.name} onChange={handleNameChange} />
            <InputField
              label={'Work email'}
              value={company.administratorEmail}
              onChange={handleAdministratorEmailChange}
            />
          </S.Form>
          {error && <Feedback error text={error} />}
          <S.Footer>
            <S.ExtraInfo>
              By making a registration request, you agree to the <a href='https://nggrace.com/terms-of-use-rus'>Term of use</a> and <a href='https://nggrace.com/privacy-policy-rus'>Privacy policy</a>.
            </S.ExtraInfo>
            <S.Buttons>
              <Btn type="button" secondary onClick={redirectToLoginPage}>
                Cancel
              </Btn>
              <Btn type="submit" disabled={loading}>
                {!loading ? 'Register' : 'Processing'}
              </Btn>
            </S.Buttons>
          </S.Footer>
        </S.RightPanel>
      </S.Main>
    </S.AuthForm>
  );
};
