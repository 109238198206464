/* tslint:disable */
/* eslint-disable */

export interface HttpClient<O> {
  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: O;
  }): RestResponse<R>;
}

export class RestApplicationClient<O> {
  constructor(protected httpClient: HttpClient<O>) {}

  /**
   * HTTP POST /auth/login
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.authenticate
   */
  authenticate(queryParams: { username: string; password: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`auth/login`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /auth/logout
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.logout
   */
  logout(options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`auth/logout`, options: options });
  }

  /**
   * HTTP POST /auth/user
   * Java method: com.softaria.grace.ng.controller.AuthenticationController.getCurrentUser
   */
  getCurrentUser(options?: O): RestResponse<UserDto | undefined> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`auth/user`, options: options });
  }

  /**
   * HTTP POST /companies
   * Java method: com.softaria.grace.ng.controller.CompanyController.getCompanies
   */
  getCompanies(
    companySortDto: CompanySortDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<CompanyDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`companies`,
      queryParams: queryParams,
      data: companySortDto,
      options: options,
    });
  }

  /**
   * HTTP GET /companies/preferences
   * Java method: com.softaria.grace.ng.controller.CompanyController.getAllCompanies
   */
  getAllCompanies(options?: O): RestResponse<CompanyBaseDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`companies/preferences`, options: options });
  }

  /**
   * HTTP POST /company
   * Java method: com.softaria.grace.ng.controller.CompanyController.createCompany
   */
  createCompany(companyUpdateDto: CompanyUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company`,
      data: companyUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /company/register
   * Java method: com.softaria.grace.ng.controller.CompanyController.registerCompany
   */
  registerCompany(companyRegisterDto: CompanyRegisterDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company/register`,
      data: companyRegisterDto,
      options: options,
    });
  }

  /**
   * HTTP DELETE /company/{companyId}
   * Java method: com.softaria.grace.ng.controller.CompanyController.deleteCompany
   */
  deleteCompany(companyId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`company/${companyId}`, options: options });
  }

  /**
   * HTTP POST /company/{companyId}
   * Java method: com.softaria.grace.ng.controller.CompanyController.updateCompany
   */
  updateCompany(companyId: number, companyUpdateDto: CompanyUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`company/${companyId}`,
      data: companyUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /export/form/{exportFormId}
   * Java method: com.softaria.grace.ng.controller.ExportController.updateExportForm
   */
  updateExportForm(exportFormId: number, exportFormDto: ExportFormDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`export/form/${exportFormId}`,
      data: exportFormDto,
      options: options,
    });
  }

  /**
   * HTTP GET /export/project/{projectId}/type/{type}
   * Java method: com.softaria.grace.ng.controller.ExportController.getExportForm
   */
  getExportForm(projectId: number, type: ExportFormType, options?: O): RestResponse<ExportFormDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`export/project/${projectId}/type/${type}`,
      options: options,
    });
  }

  /**
   * HTTP POST /password_recovery
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.createPasswordRecoveryLink
   */
  createPasswordRecoveryLink(queryParams: { email: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`password_recovery`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /password_recovery/create/{passwordRecoveryToken}
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.resetPassword
   */
  resetPassword(passwordRecoveryToken: string, queryParams: { password: string }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`password_recovery/create/${passwordRecoveryToken}`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /password_recovery/{passwordRecoveryToken}
   * Java method: com.softaria.grace.ng.controller.PasswordRecoveryController.getPasswordRecoveryLinkStatus
   */
  getPasswordRecoveryLinkStatus(passwordRecoveryToken: string, options?: O): RestResponse<PasswordRecoveryLinkStatus> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`password_recovery/${passwordRecoveryToken}`,
      options: options,
    });
  }

  /**
   * HTTP POST /project
   * Java method: com.softaria.grace.ng.controller.ProjectController.createProject
   */
  createProject(options?: O): RestResponse<number> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`project`, options: options });
  }

  /**
   * HTTP POST /project
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProjects
   */
  getProjects(
    projectSortingDto: ProjectSortingDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<ProjectViewDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project`,
      queryParams: queryParams,
      data: projectSortingDto,
      options: options,
    });
  }

  /**
   * HTTP GET /project/customer-companies
   * Java method: com.softaria.grace.ng.controller.ProjectController.getCustomerCompanies
   */
  getCustomerCompanies(options?: O): RestResponse<string[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/customer-companies`, options: options });
  }

  /**
   * HTTP DELETE /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.deleteProject
   */
  deleteProject(projectId: number, options?: O): RestResponse<void> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`project/${projectId}`, options: options });
  }

  /**
   * HTTP GET /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProject
   */
  getProject(projectId: number, options?: O): RestResponse<ProjectDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/${projectId}`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}
   * Java method: com.softaria.grace.ng.controller.ProjectController.updateProject
   */
  updateProject(projectId: number, projectUpdateDto: ProjectUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}`,
      data: projectUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/copy
   * Java method: com.softaria.grace.ng.controller.ProjectController.cloneProject
   */
  cloneProject(projectId: number, options?: O): RestResponse<ProjectViewDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`project/${projectId}/copy`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.exportProject
   */
  exportProject(projectId: number, queryParams?: { files?: File[] }, options?: O): RestResponse<StreamingResponseBody> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/export`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /project/{projectId}/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.getProjectExport
   */
  getProjectExport(projectId: number, options?: O): RestResponse<ProjectExportDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`project/${projectId}/export`, options: options });
  }

  /**
   * HTTP POST /project/{projectId}/stage/export
   * Java method: com.softaria.grace.ng.controller.ProjectController.createExport
   */
  createExport(projectId: number, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/export`,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/lan
   * Java method: com.softaria.grace.ng.controller.ProjectController.createLan
   */
  createLan(projectId: number, lan: LanCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/lan`,
      data: lan,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/scd
   * Java method: com.softaria.grace.ng.controller.ProjectController.createScd
   */
  createScd(projectId: number, scd: ScdCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/scd`,
      data: scd,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/ssd
   * Java method: com.softaria.grace.ng.controller.ProjectController.createSsd
   */
  createSsd(projectId: number, ssd: SsdCreationDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/ssd`,
      data: ssd,
      options: options,
    });
  }

  /**
   * HTTP POST /project/{projectId}/stage/tco
   * Java method: com.softaria.grace.ng.controller.ProjectController.createTco
   */
  createTco(projectId: number, opexForm: OpexFormDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`project/${projectId}/stage/tco`,
      data: opexForm,
      options: options,
    });
  }

  /**
   * HTTP GET /stage
   * Java method: com.softaria.grace.ng.controller.StageController.getStage
   */
  getStage(queryParams: { projectId: number; stageType: StageType }, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/clear
   * Java method: com.softaria.grace.ng.controller.StageController.clearStage
   */
  clearStage(queryParams: { projectId: number; stageType: StageType }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage/clear`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/export-model
   * Java method: com.softaria.grace.ng.controller.StageController.getStageExternalModel
   */
  getStageExternalModel(
    queryParams: { projectId: number; stageType: StageType },
    options?: O
  ): RestResponse<string | undefined> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`stage/export-model`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/import-model
   * Java method: com.softaria.grace.ng.controller.StageController.updateStageFromExternalModel
   */
  updateStageFromExternalModel(
    json: string,
    queryParams: { projectId: number; stageType: StageType },
    options?: O
  ): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/import-model`,
      queryParams: queryParams,
      data: json,
      options: options,
    });
  }

  /**
   * HTTP POST /stage/{stageId}
   * Java method: com.softaria.grace.ng.controller.StageController.updateStage
   */
  updateStage(stageId: number, diagram: ValueDto, options?: O): RestResponse<StageBaseDto> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`stage/${stageId}`,
      data: diagram,
      options: options,
    });
  }

  /**
   * HTTP GET /stage/{stageId}/diagram
   * Java method: com.softaria.grace.ng.controller.StageController.getStageDiagram
   */
  getStageDiagram(stageId: number, options?: O): RestResponse<DiagramDto> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`stage/${stageId}/diagram`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/export-scl
   * Java method: com.softaria.grace.ng.controller.StageController.exportScl
   */
  exportScl(stageId: number, options?: O): RestResponse<ValueDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/export-scl`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/finish
   * Java method: com.softaria.grace.ng.controller.StageController.finishStage
   */
  finishStage(stageId: number, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/finish`, options: options });
  }

  /**
   * HTTP DELETE /stage/{stageId}/rollback
   * Java method: com.softaria.grace.ng.controller.StageController.rollbackToStage
   */
  rollbackToStage(stageId: number, options?: O): RestResponse<StageDto> {
    return this.httpClient.request({ method: 'DELETE', url: uriEncoding`stage/${stageId}/rollback`, options: options });
  }

  /**
   * HTTP POST /stage/{stageId}/validate
   * Java method: com.softaria.grace.ng.controller.StageController.validateStage
   */
  validateStage(stageId: number, options?: O): RestResponse<StageValidationDto> {
    return this.httpClient.request({ method: 'POST', url: uriEncoding`stage/${stageId}/validate`, options: options });
  }

  /**
   * HTTP DELETE /user
   * Java method: com.softaria.grace.ng.controller.UserController.deleteUser
   */
  deleteUser(queryParams: { userId: number }, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`user`,
      queryParams: queryParams,
      options: options,
    });
  }

  /**
   * HTTP POST /user/update/{userId}
   * Java method: com.softaria.grace.ng.controller.UserController.updateUser
   */
  updateUser(userId: number, userUpdateDto: UserUpdateDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user/update/${userId}`,
      data: userUpdateDto,
      options: options,
    });
  }

  /**
   * HTTP POST /user_invite
   * Java method: com.softaria.grace.ng.controller.UserInviteController.inviteUser
   */
  inviteUser(userInviteDto: UserInviteDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user_invite`,
      data: userInviteDto,
      options: options,
    });
  }

  /**
   * HTTP GET /user_invite/check/{userInviteToken}
   * Java method: com.softaria.grace.ng.controller.UserInviteController.isUserInvited
   */
  isUserInvited(userInviteToken: string, options?: O): RestResponse<boolean> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`user_invite/check/${userInviteToken}`,
      options: options,
    });
  }

  /**
   * HTTP POST /user_invite/register/{userInviteToken}
   * Java method: com.softaria.grace.ng.controller.UserInviteController.registerUserByInvite
   */
  registerUserByInvite(userInviteToken: string, userRegisterDto: UserRegisterDto, options?: O): RestResponse<void> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`user_invite/register/${userInviteToken}`,
      data: userRegisterDto,
      options: options,
    });
  }

  /**
   * HTTP POST /users
   * Java method: com.softaria.grace.ng.controller.UserController.getUsers
   */
  getUsers(
    userRequestDto: UserRequestDto,
    queryParams?: { page?: number; size?: number; sort?: string },
    options?: O
  ): RestResponse<Page<UserDto>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`users`,
      queryParams: queryParams,
      data: userRequestDto,
      options: options,
    });
  }

  /**
   * HTTP GET /users/preferences
   * Java method: com.softaria.grace.ng.controller.UserController.getAllUsers
   */
  getAllUsers(options?: O): RestResponse<UserBaseDto[]> {
    return this.httpClient.request({ method: 'GET', url: uriEncoding`users/preferences`, options: options });
  }
}

export interface Annotation {}

export interface AutoCloseable {}

export interface BaseStream<T, S> extends AutoCloseable {
  parallel?: boolean;
}

export interface Company extends Annotation {}

export interface CompanyBaseDto {
  id: number;
  name: string;
}

export interface CompanyDto {
  createdAt: number;
  creator: UserBaseDto;
  deleted: boolean;
  disabled: boolean;
  id: number;
  lastActivityAt: number;
  name: string;
}

export interface CompanyRegisterDto {
  administratorEmail: string;
  name: string;
}

export interface CompanySortDto {
  direction: Direction;
  property: CompanySortProperty;
}

export interface CompanyUpdateDto {
  disabled?: boolean;
  name?: string;
}

export interface CompanyValidator extends ConstraintValidator<Company, CompanyBaseDto> {}

export interface ConstraintValidator<A, T> {}

export interface DiagramDto {
  id: number;
  json?: string;
  type: StageType;
}

export interface ExportFormDto {
  chiefProjectEngineer?: string;
  code?: string;
  company?: string;
  companySecondLine?: string;
  contractNumber?: string;
  contractorDocumentNumber?: string;
  createdAt: number;
  description?: string;
  developedBy?: string;
  formats: ExportFormatType[];
  id: number;
  logo?: string;
  logoFilename?: string;
  revision?: string;
  schemaName?: string;
  schemaNameSecondLine?: string;
  stage?: DocStageType;
  status?: string;
  title?: string;
  titleSecondLine?: string;
  type: ExportFormType;
  verifiedBy?: string;
}

export interface ImportFileValidator extends ConstraintValidator<ValidImportFile, ProjectCreateDto> {}

export interface Iterable<T> {}

export interface LanCreationDto {
  stationLevel?: string;
  switchVendor?: string;
}

export interface MessageDto {
  en: string;
  ru: string;
}

export interface OpexFormDto {
  billingPeriodInYears?: number;
  discountFactor?: number;
  inflationFactor?: number;
  numberOfTechnicalService?: number;
  personPerHourCost?: number;
  personPerHourCostFactor?: number;
  serviceFactor?: number;
}

export interface Order extends Serializable {
  ascending?: boolean;
  descending?: boolean;
  direction?: Direction;
  ignoreCase?: boolean;
  nullHandling?: NullHandling;
  property?: string;
}

export interface Page<T> extends Slice<T> {
  totalElements?: number;
  totalPages?: number;
}

export interface Pageable {
  offset?: number;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  sort?: Sort;
  unpaged?: boolean;
}

export interface PasswordRecoveryDto {
  email: string;
  uuid: string;
}

export interface ProjectCreateDto {
  creationType?: ProjectCreationType;
  customerName?: string;
  frequency?: Frequency;
  importFile?: File;
  name?: string;
  stationName?: string;
  styleSelection?: ProjectStyleSelection;
}

export interface ProjectDto {
  activeStage: StageBaseDto;
  creationType: ProjectCreationType;
  customerCompany?: string;
  frequency: Frequency;
  id: number;
  iedVendor?: string;
  muVendor?: string;
  name: string;
  pacsArch?: string;
  setOfRules?: string;
  stationLevel?: string;
  stationName?: string;
  styleSelection: ProjectStyleSelection;
  switchVendor?: string;
  uuid: string;
}

export interface ProjectExportDto {
  activeStage: StageBaseDto;
  customerCompany?: string;
  exportForms: ExportFormDto[];
  id: number;
  name: string;
  stages: StageExportDto[];
  stationName?: string;
  styleSelection: ProjectStyleSelection;
}

export interface ProjectSortingDto {
  authorId?: number;
  companyId?: number;
  customerCompany?: string;
  direction: Direction;
  property: ProjectSortProperty;
}

export interface ProjectUpdateDto {
  customerName?: string;
  name?: string;
  stationName?: string;
}

export interface ProjectViewDto {
  createdAt: number;
  creator: UserBaseDto;
  customerCompany?: string;
  editedAt: number;
  editor: UserBaseDto;
  id: number;
  name: string;
  stage: StageViewDto;
}

export interface ReportConfigDto {
  projectName: string;
  styleSelection: ProjectStyleSelection;
}

export interface ScdCreationDto {
  iedVendor?: string;
  muVendor?: string;
  pacsArch?: string;
}

export interface Serializable {}

export interface Slice<T> extends Streamable<T> {
  content?: T[];
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  sort?: Sort;
}

export interface Sort extends Streamable<Order>, Serializable {
  sorted?: boolean;
  unsorted?: boolean;
}

export interface SsdCreationDto {
  setOfRules?: string;
}

export interface StageBaseDto {
  finished: boolean;
  finishedAt?: number;
  finishedBy?: UserBaseDto;
  id: number;
  raw: boolean;
  type: StageType;
  valid: boolean;
}

export interface StageDto {
  baseInfo: StageBaseDto;
  json?: string;
  opexForm?: OpexFormDto;
  project: ProjectDto;
}

export interface StageExportDto {
  id: number;
  type: StageType;
}

export interface StageValidationDto {
  message?: MessageDto;
  stage: StageDto;
  success: boolean;
}

export interface StageViewDto {
  finishedAt?: number;
  id: number;
  type: StageType;
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
  empty?: boolean;
}

export interface StreamingResponseBody {}

export interface Supplier<T> {}

export interface UserBaseDto {
  company: CompanyBaseDto;
  id: number;
  name: string;
}

export interface UserDto {
  company: CompanyBaseDto;
  createdAt: number;
  deleted: boolean;
  disabled: boolean;
  email: string;
  id: number;
  name: string;
  role: UserRole;
}

export interface UserInviteDto {
  company?: CompanyBaseDto;
  email: string;
  role: UserRole;
  uuid?: string;
}

export interface UserRegisterDto {
  name: string;
  password: string;
}

export interface UserRequestDto {
  companyName?: string;
  sortDirection: Direction;
  sortProperty: UserSortProperty;
}

export interface UserUpdateDto {
  company: CompanyBaseDto;
  disabled?: boolean;
  email: string;
  name: string;
  role: UserRole;
}

export interface ValidImportFile extends Annotation {}

export interface ValueDto {
  value: string;
}

export type CompanySortProperty = 'COMPANY_NAME' | 'CREATED_AT' | 'LAST_ACTIVITY_AT' | 'CREATOR_NAME' | 'STATUS';

export type Direction = 'ASC' | 'DESC';

export type DocStageType = 'PROJECT_DOC' | 'WORKING_DOC' | 'EXECUTIVE_DOC';

export type ExportFormType = 'SLD' | 'SSD' | 'SCD' | 'LAN' | 'GENERAL' | 'CABLE' | 'TCO';

export type ExportFormatType = 'SVG' | 'PDF' | 'SCL' | 'XLS';

export type Frequency = 'FIFTY' | 'SIXTY';

export type NullHandling = 'NATIVE' | 'NULLS_FIRST' | 'NULLS_LAST';

export type PasswordRecoveryLinkStatus = 'VALID' | 'EXPIRED' | 'NON_EXISTENT';

export type ProjectCreationType = 'MANUAL' | 'IMPORT';

export type ProjectSortProperty =
  | 'ID'
  | 'PROJECT_NAME'
  | 'CREATOR_NAME'
  | 'CREATED_AT'
  | 'EDITOR_NAME'
  | 'EDITED_AT'
  | 'CUSTOMER_COMPANY'
  | 'COMPANY_NAME'
  | 'STAGE_TYPE'
  | 'STAGE_FINISHED_AT';

export type ProjectStyleSelection = 'RU' | 'EU';

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type StageType = 'SLD' | 'SSD' | 'SCD' | 'LAN' | 'TCO' | 'EXPORT';

export type UserRole = 'ROLE_SYSTEM_ADMIN' | 'ROLE_COMPANY_ADMIN' | 'ROLE_COMPANY_USER';

export type UserSortProperty = 'NAME' | 'EMAIL' | 'CREATED_AT' | 'STATUS';

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
  let result = '';
  for (let i = 0; i < substitutions.length; i++) {
    result += template[i];
    result += encodeURIComponent(substitutions[i]);
  }
  result += template[template.length - 1];
  return result;
}

// Added by 'AxiosClientExtension' extension

import axios, * as Axios from 'axios';

declare module 'axios' {
  export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
    data: R;
  }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {
  constructor(private axios: Axios.AxiosInstance) {}

  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: Axios.AxiosRequestConfig;
  }): RestResponse<R> {
    function assign(target: any, source?: any) {
      if (source != undefined) {
        for (const key in source) {
          if (source.hasOwnProperty(key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    }

    const config: Axios.AxiosRequestConfig = {};
    config.method = requestConfig.method as typeof config.method; // `string` in axios 0.16.0, `Method` in axios 0.19.0
    config.url = requestConfig.url;
    config.params = requestConfig.queryParams;
    config.data = requestConfig.data;
    assign(config, requestConfig.options);
    const copyFn = requestConfig.copyFn;

    const axiosResponse = this.axios.request(config);
    return axiosResponse.then((axiosResponse) => {
      if (copyFn && axiosResponse.data) {
        (axiosResponse as any).originalData = axiosResponse.data;
        axiosResponse.data = copyFn(axiosResponse.data);
      }
      return axiosResponse;
    });
  }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {
  constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
    axiosInstance.defaults.baseURL = baseURL;
    super(new AxiosHttpClient(axiosInstance));
  }
}
