export interface Removable {
  remove(): void;

  canRemove(): boolean;
}

export const checkIsRemovable = (objectToCheck: object): objectToCheck is Removable => {
  const possibleRemovable = objectToCheck as Removable;
  return typeof possibleRemovable['remove'] === 'function' && typeof possibleRemovable['canRemove'] === 'function';
};
