import styled, { css } from 'styled-components';
import { Styled as StyledInputField } from './InputField.styled';
import { Styled as StyledInputRadio } from './InputRadio.styled';

const InputRadioGroup = styled(StyledInputField.InputField)<{
  empty?: boolean;
  hideBorder?: boolean;
}>`
  display: flex;
  align-items: center;

  ${StyledInputRadio.InputRadioLabel} {
    padding: 0 24px 0 12px;
  }

  ${StyledInputRadio.InputRadioCheck} {
    margin: 0;
  }

  ${(props) =>
    props.hideBorder &&
    css`
      ${StyledInputField.InputContainer} {
        border: 1px solid transparent;
      }
    `}
`;

export const Styled = {
  InputRadioGroup,
  InputContainer: StyledInputField.InputContainer,
  InputLabel: StyledInputField.InputLabel,
};
