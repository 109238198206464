import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Icon } from '../widgets/Icon';
import styled from 'styled-components';
import { EngineContext } from './EngineContext';

const Zoom = styled.div`
  height: ${(props) => props.theme.editor.footerHeight};
  width: 100%;
  background-color: ${(props) => props.theme.colors.solitude};
  color: ${(props) => props.theme.colors.dark};
  z-index: 100;

  border: 1px solid ${(props) => props.theme.colors.grey};
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Scale = styled.span`
  border: 1px solid ${(props) => props.theme.colors.grey};
  padding: 2px 8px;
  margin: 0 10px;
  font-size: 14px;
`;

const ZOOM_DELTA = 20;

export const ZoomWidget: React.FC = () => {
  const engine = useContext(EngineContext);
  const [scale, setScale] = useState<number>();

  useEffect(() => {
    setScale(Math.floor(engine.getModel().getZoomLevel()));
    return engine.onZoom((event) => setScale(event.zoom));
  }, [engine]);

  const zoomIn = useCallback(() => engine.zoom(ZOOM_DELTA), [engine]);
  const zoomOut = useCallback(() => engine.zoom(-ZOOM_DELTA), [engine]);

  return (
    <Zoom>
      <Icon name={'minus'} onClick={zoomOut} />
      <Scale>{scale}%</Scale>
      <Icon name={'plus'} onClick={zoomIn} />
    </Zoom>
  );
};
