import styled, { css } from 'styled-components';

const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  width: 100%;
`;

const Tab = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 0;
  margin: 0 33px -1px 0;
  background: none;
  border: none;
  cursor: pointer;
  border-bottom: 4px solid transparent;

  ${(props) =>
    props.active &&
    css`
      border-bottom: 4px solid ${props.theme.colors.blue};
    `};
`;
export const Styled = {
  Tabs,
  Tab,
};
