import { AbstractReactFactory, BaseModel } from '@projectstorm/react-canvas-core';
import { Rack } from './Rack';
import { LanEngine } from '../LanEngine';
import { GenerateModelEvent } from '../../scd/controller/ControllerFactory';
import { DefaultHasName } from '../building/HasName';
import { HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { CompositeRackModel, CompositeRackModelType } from './CompositeRackModel';
import { DefaultHasChildren } from '../../placeholder/HasChildren';
import { RpaNetworkZoneTypes } from '../zone/ZoneModel';
import { ZoneLayerModel } from '../zone/layer/ZoneLayerModel';
import { CompositeRackWidget } from './CompositeRackWidget';

export class CompositeRackFactory extends AbstractReactFactory<Rack & BaseModel, LanEngine> {
  constructor() {
    super(CompositeRackModelType);
  }

  generateReactWidget(event: { model: CompositeRackModel }): JSX.Element {
    return <CompositeRackWidget rack={event.model} key={event.model.getID()} />;
  }

  generateModel(event: GenerateModelEvent<CompositeRackModel>) {
    return new CompositeRackModel(
      (eventDelegate) => new DefaultHasChildren(eventDelegate as any),
      new DefaultHasName(event.initialConfig.name),
      (eventDelegate) => new HasRelativeParent(eventDelegate)
    );
  }

  createNewLanModel(name: string, zoneLayer: ZoneLayerModel) {
    const zones = Object.values(zoneLayer.getModels()).filter(
      (zone) => RpaNetworkZoneTypes.indexOf(zone.getZoneType()) !== -1
    );
    return new CompositeRackModel(
      (eventDelegate) => new DefaultHasChildren(eventDelegate as any),
      new DefaultHasName(name),
      (eventDelegate) => new HasRelativeParent(eventDelegate),
      zones,
      this.engine
    );
  }
}
