import { BaseModel, BaseObserver, Toolkit } from '@projectstorm/react-canvas-core';
import { DefaultHasChildren, HasChildren } from '../../placeholder/HasChildren';
import { SingleRackModel } from '../rack/SingleRackModel';
import { HasRect } from '../../placeholder/HasRect';
import { AddedPoint, BasePoint } from '../../geometry/Point';
import { Rectangle } from '@projectstorm/geometry';
import { Factory } from '../../../../utils/factory';
import { HasPosition } from '../../placeholder/HasPosition';
import { ModelCoordinateEquivalentSet } from './layer/ModelCoordinateEquivalentSet';
import { Node, NodeType } from '../../auto-layout/node/Node';
import { SmartRotationHour } from '../../geometry/RotationHour';
import { Link } from '../../auto-layout/Link';
import { DefaultGraph, Graph } from '../../auto-layout/graph/Graph';
import { Coordinate, DefaultCoordinate, OppositeCoordinate } from '../../geometry/Coordinate';
import { NormalizedNode } from '../../auto-layout/node/NormalizedNode';
import { TranslatedNode } from '../../auto-layout/node/TranslatedNode';
import { onceForLoop } from '../../FunctionDecorators';
import { DefaultWaypointGraph, WaypointGraph, WaypointGraphContainer } from '../waypoint/WaypointGraph';

export enum ZoneType {
  WORKSTATION = 'WORKSTATION',
  SCADA = 'SCADA',
  MIDDLE = 'MIDDLE',
  LOW_NETWORK = 'LOW_NETWORK',
  LOW_GENERAL = 'LOW_GENERAL',
  RPA_PRIMARY_NETWORK_STATION = 'RPA_PRIMARY_NETWORK_STATION',
  RPA_BACKUP_NETWORK_STATION = 'RPA_BACKUP_NETWORK_STATION',
  RPA_PRIMARY_NETWORK_PROCESS = 'RPA_PRIMARY_NETWORK_PROCESS',
  RPA_BACKUP_NETWORK_PROCESS = 'RPA_BACKUP_NETWORK_PROCESS',
  RPA_PROTECTION = 'RPA_PROTECTION',
}

export const RpaNetworkZoneTypes: ZoneType[] = [
  ZoneType.RPA_PRIMARY_NETWORK_PROCESS,
  ZoneType.RPA_PRIMARY_NETWORK_STATION,
  ZoneType.RPA_BACKUP_NETWORK_PROCESS,
  ZoneType.RPA_BACKUP_NETWORK_STATION,
];

export const ZoneModelType = 'zone';
export type ZoneHasRectFactory = Factory<
  { eventDelegate: BaseObserver; hasChildren: HasChildren<SingleRackModel> },
  HasRect
>;
export type ZoneModelHasChildrenFactory = Factory<
  { origin: HasChildren<SingleRackModel>; hasPosition: HasPosition; eventDelegate: BaseObserver },
  HasChildren<SingleRackModel>
>;

export class ZoneModel
  extends BaseModel
  implements HasChildren<SingleRackModel>, HasRect, WaypointGraphContainer<string> {
  private readonly hasChildren: HasChildren<SingleRackModel>;
  private readonly hasRect: HasRect;
  private readonly zoneType: ZoneType;
  private columnSets: ModelCoordinateEquivalentSet[] = [];
  private waypointGraph: WaypointGraph<string> | undefined;

  constructor(zoneType: ZoneType, hasRectFactory: ZoneHasRectFactory, hasChildrenFactory: ZoneModelHasChildrenFactory) {
    super({ id: zoneType, type: ZoneModelType });
    this.zoneType = zoneType;

    const originalHasChildren = new DefaultHasChildren<SingleRackModel>(this as any);
    this.hasRect = hasRectFactory({ eventDelegate: this, hasChildren: originalHasChildren });
    this.hasChildren = hasChildrenFactory({
      hasPosition: this.hasRect,
      origin: originalHasChildren,
      eventDelegate: this,
    });

    const fireWaypointsChanged = onceForLoop(() => {
      this.updateLinkWaypointGraph();
      this.fireEvent({}, 'waypointsChanged');
    });

    this.registerListener({
      columnSetsChanged: (event) => {
        this.columnSets = (event as any).sets || [];
        fireWaypointsChanged();
      },
      positionChanged: fireWaypointsChanged,
      sizeChanged: fireWaypointsChanged,
    });
  }

  addChild(childToAdd: SingleRackModel, index?: number): void {
    this.hasChildren.addChild(childToAdd, index);
  }

  getChildren(): SingleRackModel[] {
    return this.hasChildren.getChildren();
  }

  getPosition(): BasePoint {
    return this.hasRect.getPosition();
  }

  getRect(): Rectangle | undefined {
    return this.hasRect.getRect();
  }

  getSize(): BasePoint {
    return this.hasRect.getSize();
  }

  setPosition(newValue: BasePoint): void {
    return this.hasRect.setPosition(newValue);
  }

  getZoneType(): ZoneType {
    return this.zoneType;
  }

  getOuterWaypoints() {
    const topNodes: Node[] = [];
    const bottomNodes: Node[] = [];
    const links: Link[] = [];
    const rect = this.hasRect.getRect()!;
    const top = rect.getTopLeft().y;
    const bottom = rect.getBottomLeft().y;
    const offset = 25;

    topNodes.push(new WaypointNode(new AddedPoint(new BasePoint(rect.getTopLeft()), new BasePoint(offset, 0))));
    bottomNodes.push(new WaypointNode(new AddedPoint(new BasePoint(rect.getBottomLeft()), new BasePoint(offset, 0))));
    links.push(new SimpleLink(topNodes[0], bottomNodes[0]));

    this.columnSets.forEach((column) => {
      const maxX = column.getMaxPosition();
      const topNode = new WaypointNode(new BasePoint(maxX - offset, top));
      const bottomNode = new WaypointNode(new BasePoint(maxX - offset, bottom));
      topNodes.push(topNode);
      bottomNodes.push(bottomNode);
      links.push(new SimpleLink(topNodes[topNodes.length - 1], topNode));
      links.push(new SimpleLink(bottomNodes[topNodes.length - 1], bottomNode));
      links.push(new SimpleLink(topNode, bottomNode));
    });
    return new DefaultGraph([...topNodes, ...bottomNodes], links);
  }

  getLinkWaypointGraph() {
    return this.waypointGraph!;
  }

  private updateLinkWaypointGraph() {
    this.waypointGraph = new DefaultWaypointGraph(
      this.columnSets
        .map((column) => {
          const offset = 25;
          const right = column.getMaxPosition() - offset;
          return column
            .getModels()
            .map(
              (model): Graph => {
                const rect = model.getRect()!;
                const left = rect.getTopLeft().x - offset;
                const top = rect.getTopLeft().y - offset;
                const bottom = rect.getBottomLeft().y + offset;
                const leftTop = new WaypointNode(new BasePoint(left, top));
                const rightTop = new WaypointNode(new BasePoint(right, top));
                const leftBottom = new WaypointNode(new BasePoint(left, bottom));
                const rightBottom = new WaypointNode(new BasePoint(right, bottom));
                return new DefaultGraph(
                  [leftTop, rightTop, leftBottom, rightBottom],
                  [
                    new SimpleLink(leftTop, rightTop),
                    new SimpleLink(rightTop, rightBottom),
                    new SimpleLink(rightBottom, leftBottom),
                    new SimpleLink(leftBottom, leftTop),
                  ]
                );
              }
            )
            .reduce((result: Graph, current: Graph): Graph => {
              return new CoordinateEdgeMergingGraph(result, current, new DefaultCoordinate('y'));
            }, new DefaultGraph([], []));
        })
        .reduce((result: Graph, current: Graph) => {
          return new CoordinateEdgeMergingGraph(result, current, new DefaultCoordinate('x'));
        }, new DefaultGraph([], [])),
      (node) => node.getID(),
      this
    );
  }
}

export class WaypointNode implements Node {
  private readonly position: BasePoint;
  private readonly id: string;

  constructor(position: BasePoint) {
    this.id = Toolkit.UID();
    this.position = position;
  }

  getID(): string {
    return this.id;
  }

  getRect(): Rectangle {
    return new Rectangle(this.position, 0, 0);
  }

  getHour(): SmartRotationHour {
    throw new Error('waypoint node has no hour');
  }

  getType(): NodeType {
    throw new Error('waypoint node has no nodeType');
  }
}

export class SimpleLink implements Link {
  private readonly source: Node;
  private readonly target: Node;

  constructor(source: Node, target: Node) {
    this.source = source;
    this.target = target;
  }

  getSourceNode(): Node {
    return this.source;
  }

  getTargetNode(): Node {
    return this.target;
  }
}

export class CoordinateEdgeMergingGraph implements Graph {
  private first: Graph;
  private second: Graph;
  private coordinate: Coordinate;

  constructor(first: Graph, second: Graph, coordinate: Coordinate) {
    this.first = first;
    this.second = second;
    this.coordinate = coordinate;
  }

  getLinks(): Link[] {
    const coord = new OppositeCoordinate(this.coordinate).getName();
    const mergedNodes = this.getMergedNodes();
    const sortedNodes = mergedNodes.sort((a, b) => a.getRect().getTopLeft()[coord] - b.getRect().getTopLeft()[coord]);

    const links: Link[] = [...this.first.getLinks(), ...this.second.getLinks()].filter(
      (link) =>
        !(
          mergedNodes.find((node) => node.getID() === link.getSourceNode().getID()) &&
          mergedNodes.find((node) => node.getID() === link.getTargetNode().getID())
        )
    );

    for (let i = 1; i < sortedNodes.length; i++) {
      const source = sortedNodes[i - 1];
      const target = sortedNodes[i];
      const existing = links.find((link) => {
        const nodeIds = [link.getSourceNode().getID(), link.getTargetNode().getID()];
        return nodeIds.includes(source.getID()) && nodeIds.includes(target.getID());
      });
      if (!existing) {
        links.push(new SimpleLink(source, target));
      }
    }
    return links;
  }

  getNodes(): Node[] {
    const mergedNodes = this.getMergedNodes();
    return [
      ...[...this.first.getNodes(), ...this.second.getNodes()].filter(
        (node) => !mergedNodes.find((merged) => merged.getID() === node.getID())
      ),
      ...mergedNodes,
    ];
  }

  getMergedNodes() {
    const first = this.first.getNodes();
    const second = this.second.getNodes();
    const coord = this.coordinate.getName();
    const maxFirst = Math.max(...first.map((node) => node.getRect().getTopLeft()[coord]));
    const minSecond = Math.min(...second.map((node) => node.getRect().getTopLeft()[coord]));
    const middle = maxFirst + (minSecond - maxFirst) / 2;
    return [
      ...first.filter((point) => point.getRect().getTopLeft()[coord] === maxFirst),
      ...second.filter((point) => point.getRect().getTopLeft()[coord] === minSecond),
    ].map((node) => {
      const position = new BasePoint(node.getRect().getTopLeft());
      position[coord] = middle;
      return new TranslatedNode(new NormalizedNode(node), position);
    });
  }

  getRect(): Rectangle {
    throw new Error();
  }
}
