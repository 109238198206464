import { Point } from '@projectstorm/geometry';
import {
  RotatableNodeModel,
  RotatableNodeModelGenerics,
  RotatableNodeModelOptions,
  RotatableNodeModelPayload,
} from '../generics/RotatableNodeModel';
import { ResizeEdge, ResizeStrategy } from '../generics/ResizableNodeModel';
import { ConnectivityPortModel } from './ConnectivityPortModel';
import { PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { RotationHour } from '../geometry/RotationHour';

export interface ConnectivityNodeModelPayload extends RotatableNodeModelPayload {}

export interface ConnectivityNodeModelOptions extends RotatableNodeModelOptions {
  payload: ConnectivityNodeModelPayload;
}

export interface ConnectivityNodeModelGenerics extends RotatableNodeModelGenerics {
  PAYLOAD: ConnectivityNodeModelPayload;
  OPTIONS: ConnectivityNodeModelOptions;
}

export const ConnectivityNodeSize = 5;

export class ConnectivityNodeModel extends RotatableNodeModel<ConnectivityNodeModelGenerics> {
  constructor() {
    super({
      type: 'connectivity',
      payload: {
        hour: RotationHour.ZERO,
      },
      defaultSize: new Point(ConnectivityNodeSize, ConnectivityNodeSize),
      resizers: [],
    });
    this.addPort(new ConnectivityPortModel(PortModelAlignment.TOP));
    this.addPort(new ConnectivityPortModel(PortModelAlignment.RIGHT));
    this.addPort(new ConnectivityPortModel(PortModelAlignment.LEFT));
    this.addPort(new ConnectivityPortModel(PortModelAlignment.BOTTOM));
  }

  setCenter(centerPosition: Point) {
    this.setPosition(centerPosition.x - ConnectivityNodeSize / 2, centerPosition.y - ConnectivityNodeSize / 2);
  }

  getResizeStrategy(): ResizeStrategy {
    return new (class implements ResizeStrategy {
      resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
        return [];
      }
    })();
  }

  canRotate(): boolean {
    return false;
  }
}
