import React, { useCallback, useState } from 'react';
import { Page } from '../../Page';
import { Styled as S } from '../TablePage.styled';
import { Header } from '../../widgets/Header.styled';
import { BtnIcon } from '../../widgets/Btn.styled';
import { Icon } from '../../widgets/Icon';
import { CompanyList } from './CompanyList';
import { NotificationProvider } from '../../context/NotificationContext';

export const CompaniesPage: React.FC = () => {
  const [companyCreationVisibility, setCompanyCreationVisibility] = useState(false);

  const showCompanyCreation = useCallback(() => {
    setCompanyCreationVisibility(true);
  }, []);

  const hideCompanyCreation = useCallback(() => {
    setCompanyCreationVisibility(false);
  }, []);

  return (
    <NotificationProvider>
      <Page active={'companies'}>
        <S.Content>
          <S.Header>
            <Header>Companies</Header>
            <BtnIcon onClick={showCompanyCreation}>
              <Icon name={'plus'} /> New
            </BtnIcon>
          </S.Header>
          <CompanyList
            companyCreationVisibility={companyCreationVisibility}
            hideCompanyCreation={hideCompanyCreation}
          />
        </S.Content>
      </Page>
    </NotificationProvider>
  );
};
