import styled from 'styled-components';
import { Btn } from '../widgets/Btn.styled';
import { Styled as StyledIcon } from '../widgets/Icon.styled';
import { Link as _Link } from 'react-router-dom';

const Form = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  width: 980px;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.white};
  padding: 66px;
  border-radius: 16px;
  text-align: center;
  position: relative;
`;

const CheckMark = styled.div`
  position: absolute;
  top: -64px;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
  background: rgba(75, 156, 137, 0.5);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 1px solid rgba(18, 77, 84, 0.4);
  color: ${(props) => props.theme.colors.white};
  ${StyledIcon.Icon} {
    width: 60px;
    height: 54px;
  }
`;

const Title = styled.div`
  width: 330px;
  color: ${(props) => props.theme.colors.blue};
  font-size: 32px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.secondary};
  margin-bottom: 25px;
`;

const Info = styled.div`
  width: 580px;
  margin-bottom: 25px;
`;

const Button = styled(Btn)`
  height: 40px;
  width: 180px;
`;

const Link = styled(_Link)`
  font-size: inherit;
  color: ${(props) => props.theme.colors.blue};
`;

export const Styled = {
  Form,
  Main,
  CheckMark,
  Title,
  Info,
  Button,
  Link,
};
