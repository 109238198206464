import styled from 'styled-components';

const Rack = styled.div.attrs<{ left: number; top: number }>(({ left, top }) => ({
  style: {
    left,
    top,
  },
}))<{ left: number; top: number }>`
  position: absolute;
`;

export const Styled = {
  Rack,
};
