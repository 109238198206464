import { HasSize } from '../HasSize';
import { BasePoint } from '../../geometry/Point';
import { HasChildren } from '../HasChildren';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { PlaceholderWidgetSizes, PlaceholderBrickSizes } from '../../ssd/logic-device/PlaceholderWidget.styled';
import { HasPosition } from '../HasPosition';

export class PlaceholderHasSize<T extends BaseObserver & HasSize & HasPosition> implements HasSize {
  private hasChildren: HasChildren<T>;
  private constantMetrics: typeof PlaceholderWidgetSizes & typeof PlaceholderBrickSizes;

  constructor(hasChildren: HasChildren<T>) {
    this.hasChildren = hasChildren;
    this.constantMetrics = { ...PlaceholderWidgetSizes, ...PlaceholderBrickSizes };
  }

  getSize(): BasePoint {
    const heightConstantPart =
      this.constantMetrics.primaryHeaderRect.getHeight() +
      this.constantMetrics.leftHeaderRect.getHeight() +
      4 * this.constantMetrics.borderWidth;

    const placeholderWidth = this.constantMetrics.rightHeaderRect.getRightMiddle().x + this.constantMetrics.borderWidth;
    return new BasePoint(placeholderWidth, this.calculateContentHeightAndUpdateChildren() + heightConstantPart);
  }

  private calculateContentHeightAndUpdateChildren() {
    const children = this.hasChildren.getChildren();
    const placeholderWidth =
      this.constantMetrics.rightHeaderRect.getRightMiddle().x - this.constantMetrics.borderWidth * 2;
    const childrenRowWidth = placeholderWidth - this.constantMetrics.contentHorizontalPadding * 2;
    let rowCount = 1;
    let currentRowWidth = 0;
    const childrenStartHeight =
      this.constantMetrics.leftHeaderRect.getBottomMiddle().y + this.constantMetrics.contentVerticalPadding;

    const rowHeight = this.constantMetrics.placeholderBrickHeight + this.constantMetrics.brickMargin;

    if (children.length) {
      children.forEach((child) => {
        const childWidth = child.getSize().x;
        if (currentRowWidth + childWidth > childrenRowWidth) {
          rowCount++;
          currentRowWidth = 0;
        }

        child.setPosition(
          new BasePoint(
            currentRowWidth + this.constantMetrics.contentHorizontalPadding,
            childrenStartHeight + rowHeight * (rowCount - 1)
          )
        );
        currentRowWidth += childWidth + this.constantMetrics.brickMargin;
      });
    }

    return (
      this.constantMetrics.contentVerticalPadding * 2 +
      rowCount * this.constantMetrics.placeholderBrickHeight +
      (rowCount - 1) * this.constantMetrics.brickMargin
    );
  }
}
