import React from 'react';
import { Styled as S } from './Accordion.styled';

type ListItemType = {
  name: string;
  value: string | number;
};

type AccordionProps = {
  title: string;
  subtitle: string;
  expanded: boolean;
  list?: ListItemType[];
  onClick(): void;
};

export const Accordion: React.FC<AccordionProps> = ({ title, subtitle, expanded, list, children, onClick }) => {
  return (
    <S.Accordion>
      <S.Button expanded={expanded} onClick={onClick}>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.Button>
      {expanded && list && (
        <S.List>
          {list.map((item) => (
            <S.ListItem key={item.name}>
              <S.ListItemName>{item.name}</S.ListItemName>
              <S.ListItemValue>{item.value}</S.ListItemValue>
            </S.ListItem>
          ))}
        </S.List>
      )}
      {expanded && children && <S.Content>{children}</S.Content>}
    </S.Accordion>
  );
};
