import { ConnectivityNodeFactory } from '../../../connectivity/ConnectivityNodeFactory';
import { ConnectivityNodeWithPlaceholdersModel } from '../../../connectivity/ConnectivityNodeWithPlaceholdersModel';
import { ControllerPlaceholder } from '../../controller/ControllerPlaceholder';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';
import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import { StageType } from '../../../../../api/nggrace-back';

export class ScdConnectivityNodeFactory extends ConnectivityNodeFactory {
  private stage: StageType = 'SCD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new ConnectivityNodeWithPlaceholdersModel<ControllerPlaceholder>(
      (node) => new DefaultNodeWithPlaceholders<ControllerPlaceholder>(node, node, this.stage, event.initialConfig.SCD)
    );
  }
}
