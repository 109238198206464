import { ModelObserver, registerLinkListener, registerNodeListener } from '../NgGraceModelObserver';
import { ScdModel } from './ScdModel';
import { debounced } from '../FunctionDecorators';
import { BaseModel } from '@projectstorm/react-canvas-core';
import { DirectoryLogicDeviceModel } from '../ssd/logic-device/LogicDeviceModel';
import { ControllerModel } from './controller/ControllerModel';

export class ScdModelObserver implements ModelObserver {
  onChange(model: ScdModel, listener: () => void): () => void {
    const debouncedListener = debounced(listener, 200);
    const handlers: { deregister: () => any }[] = [];

    registerNodeListener(
      model,
      {
        payloadChanged: debouncedListener,
      },
      handlers
    );

    registerLinkListener(
      model,
      {
        positionChanged: debouncedListener,
      },
      handlers
    );

    handlers.push(
      model.getControllerLayer().registerListener({
        controllerAdded: debouncedListener,
        controllerRemoved: debouncedListener,
      })
    );

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  }

  onSelectionChange(model: ScdModel, listener: () => void): () => void {
    const handlers: { deregister: () => any }[] = [];
    const selectionListener = { selectionChanged: listener };

    registerNodeListener(model, selectionListener, handlers);

    const addEntityListener = (entity: BaseModel, additional?: object) => {
      handlers.push(entity.registerListener({ ...selectionListener, ...additional }));
    };

    const addControllerListener = (controller: ControllerModel) => {
      addEntityListener(controller, {
        childrenChanged: ({ child, created }: { child: DirectoryLogicDeviceModel; created: boolean }) =>
          created && addEntityListener(child),
      });
      controller.getChildren().map((child) => addEntityListener(child));
    };

    model.getControllerLayer().registerListener({
      controllerAdded: ({ controller }: { controller: ControllerModel }) => addControllerListener(controller),
    } as any);

    Object.values(model.getControllerLayer().getModels()).forEach(addControllerListener);

    return () => {
      handlers.forEach((handler) => handler.deregister());
    };
  }
}
