import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CompanyBaseDto,
  Direction,
  ProjectSortingDto,
  ProjectSortProperty,
  ProjectViewDto,
  UserBaseDto,
} from '../../../api/nggrace-back';
import { formatMomentShort } from '../../../utils/date-utils';
import { convertStageTypeShort } from '../../../utils/project-utils';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Styled as S } from './ProjectList.styled';
import { Icon } from '../../widgets/Icon';
import { List } from '../../widgets/List.styled';
import { Api } from '../../../api/Api';
import { InputSelect } from '../../widgets/InputSelect';
import { SortCell } from '../../widgets/SortCell';
import InfiniteScroll from 'react-infinite-scroll-component';
import { theme } from '../../../theme';
import ReactModal from 'react-modal';
import { Confirmation } from '../../widgets/Confirmation';
import { useUser } from '../login/UserContext';
import { hasCompaniesAccess } from '../../../utils/role-utils';
import { useNotifications } from '../../context/NotificationContext';

interface ProjectRowProps {
  project: ProjectViewDto;
  remove: (projectId: number) => void;
  userAccessCompanies: boolean;
}

interface ProjectListState {
  projects: ProjectViewDto[];
  hasMore: boolean;
  page: number;
  size: number;
  sortProperty: ProjectSortProperty;
  sortDirection: Direction;
  editorId?: number;
  companyId?: number;
  customerCompany?: string;
  editors: UserBaseDto[];
  companies: CompanyBaseDto[];
  customerCompanies: string[];
}

export const ProjectList: React.FC = () => {
  const [state, setState] = useState<ProjectListState>({
    projects: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'CREATED_AT',
    sortDirection: 'DESC',
    editors: [],
    companies: [],
    customerCompanies: [],
  });
  const user = useUser()!.user!;
  const userAccessCompanies = useMemo(() => hasCompaniesAccess(user), [user]);
  const notifications = useNotifications();

  useEffect(() => {
    Api.getCustomerCompanies().then((response) =>
      setState((state) => ({ ...state, customerCompanies: response.data }))
    );
    Api.getAllUsers().then((response) => setState((state) => ({ ...state, editors: response.data })));
    if (userAccessCompanies) {
      Api.getAllCompanies().then((response) => setState((state) => ({ ...state, companies: response.data })));
    }
  }, [userAccessCompanies]);

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: ProjectSortingDto = {
        property: state.sortProperty,
        direction: state.sortDirection,
        authorId: state.editorId,
        companyId: state.companyId,
        customerCompany: state.customerCompany,
      };
      Api.getProjects(sort, { page, size }).then((response) => {
        setState((state) => ({
          ...state,
          projects: reload ? response.data.content || [] : state.projects.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
      });
    },
    [state.companyId, state.customerCompany, state.editorId, state.sortDirection, state.sortProperty]
  );

  const reload = useCallback(() => {
    setState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, state.size, true);
  }, [fetch, state.size]);

  useEffect(reload, [reload]);

  const direction = (property: ProjectSortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback((property: ProjectSortProperty, direction: Direction) => {
    setState((state) => {
      return { ...state, sortProperty: property, sortDirection: direction };
    });
  }, []);

  const handleEditorChange = useCallback((editorId: number) => {
    setState((state) => {
      return { ...state, editorId };
    });
  }, []);

  const handleCompanyChange = useCallback((companyId: number) => {
    setState((state) => {
      return { ...state, companyId };
    });
  }, []);

  const handleCustomerCompanyChange = useCallback((customerCompany: string) => {
    setState((state) => {
      return { ...state, customerCompany };
    });
  }, []);

  const handleDelete = useCallback(
    (projectId) => {
      Api.deleteProject(projectId)
        .then(() => {
          fetch(state.projects.length - 1, 1);
          notifications.notifySuccess('Project deleted.');
        })
        .then(() => setState((state) => ({ ...state, projects: state.projects.filter((it) => it.id !== projectId) })));
    },
    [fetch, notifications, state.projects.length]
  );

  const handleNext = useCallback(() => {
    fetch(state.page + 1, state.size);
    setState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, state.page, state.size]);

  return (
    <S.ProjectList>
      <InfiniteScroll
        dataLength={state.projects.length}
        next={handleNext}
        hasMore={state.hasMore}
        scrollableTarget="scrollableTarget"
        loader={null}
      >
        <S.Grid id="scrollableTarget" showCompany={userAccessCompanies}>
          <List.Row>
            <SortCell<ProjectSortProperty>
              type={'PROJECT_NAME'}
              direction={direction('PROJECT_NAME')}
              onSort={handleSort}
            >
              Title
            </SortCell>
            <SortCell<ProjectSortProperty> type={'CREATED_AT'} direction={direction('CREATED_AT')} onSort={handleSort}>
              Created
            </SortCell>
            <List.FilterCell>
              <InputSelect
                label={'Author'}
                hideLabel
                hideBorder
                filter
                selected={state.editorId}
                options={state.editors}
                onChange={handleEditorChange}
              />
            </List.FilterCell>
            {userAccessCompanies && (
              <List.FilterCell>
                <InputSelect
                  filter
                  label={'Company'}
                  hideLabel
                  hideBorder
                  selected={state.companyId}
                  options={state.companies}
                  onChange={handleCompanyChange}
                />
              </List.FilterCell>
            )}
            <SortCell<ProjectSortProperty> type={'EDITED_AT'} direction={direction('EDITED_AT')} onSort={handleSort}>
              Edited
            </SortCell>
            <SortCell<ProjectSortProperty>
              type={'EDITOR_NAME'}
              direction={direction('EDITOR_NAME')}
              onSort={handleSort}
            >
              Editor
            </SortCell>
            <List.FilterCell>
              <InputSelect
                filter
                label={'Customer company'}
                hideLabel
                hideBorder
                selected={state.customerCompany}
                options={state.customerCompanies.map((it) => ({ id: it, name: it }))}
                onChange={handleCustomerCompanyChange}
              />
            </List.FilterCell>
            <SortCell<ProjectSortProperty> type={'STAGE_TYPE'} direction={direction('STAGE_TYPE')} onSort={handleSort}>
              Stage
            </SortCell>
            <SortCell<ProjectSortProperty>
              type={'STAGE_FINISHED_AT'}
              direction={direction('STAGE_FINISHED_AT')}
              onSort={handleSort}
            >
              Approved
            </SortCell>
            <List.HeaderCell center>Copy</List.HeaderCell>
            <List.HeaderCell center>Delete</List.HeaderCell>
          </List.Row>

          {state.projects.map((project) => (
            <ProjectRow
              key={project.id}
              project={project}
              remove={handleDelete}
              userAccessCompanies={userAccessCompanies}
            />
          ))}
        </S.Grid>
      </InfiniteScroll>
    </S.ProjectList>
  );
};

const ProjectRow: React.FC<ProjectRowProps> = ({ project, remove, userAccessCompanies }) => {
  const { redirectToStagePage } = useRoutes();
  const [confirmVisibility, setConfirmVisibility] = useState(false);

  const showConfirmationModal = useCallback((event) => {
    event.stopPropagation();
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const handleClick = useCallback(() => {
    redirectToStagePage(project.id, project.stage.type);
  }, [redirectToStagePage, project]);

  const handleCopy = useCallback(
    (event) => {
      event.stopPropagation();
      Api.cloneProject(project.id)
        .then((response) => response.data)
        .then((cloneProject) => redirectToStagePage(cloneProject.id, cloneProject.stage.type));
    },
    [project.id, redirectToStagePage]
  );

  return (
    <>
      <List.Row key={project.id} onClick={handleClick}>
        <List.CellWithLongContent data-testid={'projectNameCell'} title={project.name}>
          {project.name}
        </List.CellWithLongContent>
        <List.Cell data-testid={'creationDateCell'}>{formatMomentShort(project.createdAt)}</List.Cell>
        <List.CellWithLongContent data-testid={'creatorCell'} title={project.creator.name}>
          {project.creator.name}
        </List.CellWithLongContent>
        {userAccessCompanies && (
          <List.CellWithLongContent title={project.creator.company.name}>
            {project.creator.company.name}
          </List.CellWithLongContent>
        )}
        <List.Cell data-testid={'editingDateCell'}>{formatMomentShort(project.editedAt)}</List.Cell>
        <List.CellWithLongContent data-testid={'editorCell'} title={project.editor.name}>
          {project.editor.name}
        </List.CellWithLongContent>
        <List.CellWithLongContent data-testid={'companyCell'} title={project.customerCompany}>
          {project.customerCompany}
        </List.CellWithLongContent>
        <List.Cell data-testid={'stageCell'}>{convertStageTypeShort(project.stage.type)}</List.Cell>
        <List.Cell data-testid={'finishingDateCell'}>
          {project.stage.finishedAt ? formatMomentShort(project.stage.finishedAt) : '-'}
        </List.Cell>
        <List.Cell center>
          <List.Button onClick={handleCopy}>
            <Icon name={'copy'} />
          </List.Button>
        </List.Cell>
        <List.Cell center>
          <List.Button onClick={showConfirmationModal}>
            <Icon name={'trash'} />
          </List.Button>
        </List.Cell>
      </List.Row>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Remove project'}
          text={`Are you sure you want to remove ${project.name}?`}
          onClose={hideConfirmationModal}
          onConfirm={() => remove(project.id)}
        />
      </ReactModal>
    </>
  );
};
