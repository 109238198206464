import { Placeholder, PlaceholderBaseChild, PlaceholderBaseRelativeModel } from './Placeholder';
import { Factory } from '../../../utils/factory';
import { BaseModel } from '@projectstorm/react-canvas-core';

export interface PlaceholderSet<
  PLACEHOLDER extends Placeholder<CHILD, RELATIVE> & BaseModel,
  CHILD extends PlaceholderBaseChild,
  RELATIVE extends PlaceholderBaseRelativeModel
> {
  getPlaceholders(): PLACEHOLDER[];

  addChild(child: CHILD, index?: number): void;
}

export class DefaultPlaceholderSet<
  PLACEHOLDER extends Placeholder<CHILD, RELATIVE> & BaseModel,
  CHILD extends PlaceholderBaseChild,
  RELATIVE extends PlaceholderBaseRelativeModel
> implements PlaceholderSet<PLACEHOLDER, CHILD, RELATIVE> {
  private readonly predicate: (placeholder: PLACEHOLDER, child: CHILD) => boolean;
  private readonly placeholders: PLACEHOLDER[] = [];
  private readonly placeholderFactory: Factory<CHILD, PLACEHOLDER>;

  constructor(
    predicate: (placeholder: Placeholder<CHILD, RELATIVE>, child: CHILD) => boolean,
    placeholderFactory: Factory<CHILD, PLACEHOLDER>
  ) {
    this.predicate = predicate;
    this.placeholderFactory = placeholderFactory;
  }

  addChild(child: CHILD, index?: number): void {
    const existing = this.placeholders.find((placeholder) => this.predicate(placeholder, child));
    if (existing) {
      return existing.addChild(child, index);
    }
    const created = this.placeholderFactory(child);
    this.placeholders.push(created);
    created.addChild(child, index);

    const deregister = created.registerListener({
      entityRemoved: () => {
        deregister();
        const index = this.placeholders.indexOf(created);
        if (index !== -1) {
          this.placeholders.splice(index, 1);
        }
      },
    }).deregister;
  }

  getPlaceholders(): PLACEHOLDER[] {
    return [...this.placeholders];
  }
}
