import { BaseModel, BaseModelGenerics, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { CodedDirectoryEntry, ControllerDirectoryEntry } from '../../directory/PropertiesDirectory';
import { DirectoryLogicDeviceModel } from '../../ssd/logic-device/LogicDeviceModel';
import { ControllerLayerModel } from '../layer/controller/ControllerLayerModel';
import { Placeholder } from '../../placeholder/Placeholder';
import { HasChildren } from '../../placeholder/HasChildren';
import { Factory } from '../../../../utils/factory';
import { DefaultPlaceholderSet, PlaceholderSet } from '../../placeholder/PlaceholderSet';
import { ControllerPlaceholder } from './ControllerPlaceholder';
import { ScdNodeModel } from '../ScdModel';

export const ControllerModelType = 'controller';

export type ControllerType = 'IED' | 'MU';

export interface ControllerModelGenerics extends BaseModelGenerics {
  PARENT: ControllerLayerModel;
}

export class ControllerModel
  extends BaseModel<ControllerModelGenerics>
  implements HasChildren<DirectoryLogicDeviceModel<ScdNodeModel>> {
  private readonly directoryEntry: CodedDirectoryEntry;
  private readonly projectName: string;
  private readonly placeholderSet: PlaceholderSet<
    ControllerPlaceholder,
    DirectoryLogicDeviceModel<ScdNodeModel>,
    ScdNodeModel
  >;

  constructor(directoryEntry: ControllerDirectoryEntry, projectName: string) {
    super({ type: ControllerModelType });
    this.directoryEntry = directoryEntry;
    this.projectName = projectName || '';
    this.placeholderSet = new DefaultPlaceholderSet(canAddChildToPlaceholder, ControllerPlaceholderFactory(this));
  }

  getChildren(): DirectoryLogicDeviceModel<ScdNodeModel>[] {
    return this.placeholderSet.getPlaceholders().flatMap((placeholder) => placeholder.getChildren());
  }

  addChild(childToAdd: DirectoryLogicDeviceModel<ScdNodeModel>, index?: number): void {
    this.placeholderSet.addChild(childToAdd, index);
  }

  getPlaceholders(): ControllerPlaceholder[] {
    return this.placeholderSet.getPlaceholders();
  }

  getDirectoryId(): string {
    return this.directoryEntry.id;
  }

  getCodeName(): string {
    return this.directoryEntry.code;
  }

  getName(): string {
    return this.directoryEntry.name.en;
  }

  getProjectName(): string {
    return this.projectName;
  }

  isLocked(): boolean {
    return super.isLocked() || this.getChildren().length > 0;
  }

  serialize() {
    return {
      ...super.serialize(),
      directoryId: this.getDirectoryId(),
      deviceIds: this.getChildren().map((child) => child.getID()),
      projectName: this.projectName,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.data.deviceIds.forEach((deviceId) =>
      event.getModel<DirectoryLogicDeviceModel<ScdNodeModel>>(deviceId).then((device) => this.addChild(device))
    );
  }

  getSelectionEntities(): Array<BaseModel> {
    return [...super.getSelectionEntities(), ...this.placeholderSet.getPlaceholders()];
  }
}

const canAddChildToPlaceholder = (
  placeholder: Placeholder<DirectoryLogicDeviceModel<ScdNodeModel>, ScdNodeModel>,
  child: DirectoryLogicDeviceModel<ScdNodeModel>
) => {
  const placeholderRelativeModel = placeholder.getRelativeModel();
  const childRelativeModel = child.getRelativeModel();

  return !!(
    placeholderRelativeModel &&
    childRelativeModel &&
    placeholderRelativeModel.getID() === childRelativeModel.getID()
  );
};

const ControllerPlaceholderFactory = (
  controller: ControllerModel
): Factory<DirectoryLogicDeviceModel<ScdNodeModel>, ControllerPlaceholder> => {
  return (child: DirectoryLogicDeviceModel<ScdNodeModel>) =>
    new ControllerPlaceholder(controller, (child.getRelativeModel() as unknown) as ScdNodeModel);
};
