import React, { ReactNode, useCallback, useMemo } from 'react';
import { Library, LibraryAccordion } from '../../widgets/library/Library';
import { useDirectory } from '../../hooks/useDirectory';
import { CodedDirectoryEntry } from '../../editor/directory/PropertiesDirectory';
import { Brick } from '../../widgets/Brick.styled';
import { ProjectDto } from '../../../api/nggrace-back';
import { theme } from '../../../theme';
import { TreeDraggableType } from '../../widgets/tree/state/TreeState';
import { useEntryColor } from '../../hooks/useNodeColor';
import Tippy from '@tippyjs/react';
import { TreeDraggable } from '../../widgets/tree/TreeDraggable';
import { SsdStructureTreeDndFormatDragId } from './tree/SsdStructureTree';

type SsdLibraryItemProps = {
  type: TreeDraggableType;
  entry: CodedDirectoryEntry;
  nameWidget: ReactNode;
  color?: string;
};

interface SsdLibraryProps {
  project: ProjectDto;
}

export const SsdLibrary: React.FC<SsdLibraryProps> = ({ project }) => {
  const { logicDeviceDirectory, logicNodeDirectory } = useDirectory();
  const logicDeviceItems = useMemo(() => {
    return logicDeviceDirectory.getAll().filter((entry) => entry.setOfRules.includes(project.setOfRules!));
  }, [logicDeviceDirectory, project.setOfRules]);
  const logicNodeItems = useMemo(() => {
    return logicNodeDirectory.getAll().filter((entry) => entry.setOfRules.includes(project.setOfRules!));
  }, [logicNodeDirectory, project.setOfRules]);

  const logicDeviceWidgetFactory = useCallback(
    (entry: CodedDirectoryEntry, nameWidget: ReactNode) => (
      <SsdLibraryItem
        type={TreeDraggableType.LogicDevice}
        entry={entry}
        color={theme.colors.blue}
        nameWidget={nameWidget}
      />
    ),
    []
  );

  const logicNodeWidgetFactory = useCallback(
    (entry: CodedDirectoryEntry, nameWidget: ReactNode) => (
      <SsdLibraryItem type={TreeDraggableType.LogicNode} entry={entry} nameWidget={nameWidget} />
    ),
    []
  );

  const handleFilter = useCallback((filter: string, item: CodedDirectoryEntry) => {
    const lowerCasedFilter = filter.toLowerCase();
    return (
      item.name.en.toLowerCase().includes(lowerCasedFilter) ||
      item.name.ru.toLowerCase().includes(lowerCasedFilter) ||
      item.code.toLowerCase().includes(lowerCasedFilter)
    );
  }, []);

  return (
    <LibraryAccordion>
      <Library<CodedDirectoryEntry>
        header="Logical Device Library"
        items={logicDeviceItems}
        onFilter={handleFilter}
        widgetFactory={logicDeviceWidgetFactory}
      />
      <Library<CodedDirectoryEntry>
        header="Logical Node Library"
        items={logicNodeItems}
        onFilter={handleFilter}
        widgetFactory={logicNodeWidgetFactory}
      />
    </LibraryAccordion>
  );
};

const SsdLibraryItem: React.FC<SsdLibraryItemProps> = ({ type, entry, nameWidget, color }) => {
  const dragElementCallback = useCallback(() => undefined, []);
  const payload = useMemo(() => ({ type, directoryId: entry.id }), [entry.id, type]);
  const brickColor = useEntryColor(entry, color);

  return (
    <TreeDraggable
      getDragElement={dragElementCallback}
      formatId={SsdStructureTreeDndFormatDragId[type]!}
      payload={payload}
    >
      <Tippy content={entry.code} delay={[800, 0]}>
        <Brick color={brickColor}>{entry.code}</Brick>
      </Tippy>
      {nameWidget}
    </TreeDraggable>
  );
};
