import React, { useCallback } from 'react';
import { SelectableTreeNode } from './state/TreeState';

export interface SelectableTreeNodeWidgetProps {
  node: SelectableTreeNode;
}

export const SelectableTreeNodeWidget: React.FC<SelectableTreeNodeWidgetProps> = ({ node, children }) => {
  const toggleSelected = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      if (node.canSelect()) {
        node.setSelected(!node.isSelected());
      }
    },
    [node]
  );

  return <div onClick={toggleSelected}>{children}</div>;
};
