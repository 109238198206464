import styled, { css } from 'styled-components';
import { Icon } from './widgets/Icon.styled';
import { Link } from 'react-router-dom';

const Sidebar = styled.div<{ hidden?: boolean }>`
  position: fixed;
  transition: left 0.5s;
  left: 0;
  z-index: 99;
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: stretch;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 15px 4px rgba(63, 63, 63, 0.4);
  border-radius: 0 10px;

  ${(props) =>
    props.hidden &&
    css`
      left: -120px;
    `};
`;

const SidebarLink = styled(Link)<{ $active?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.$active ? props.theme.colors.blue : props.theme.colors.black)};
  height: 120px;
  width: 120px;
  border-bottom: 1px solid #d9d9d9;

  &:hover {
    background-color: ${(props) => props.theme.colors.solitude};
    opacity: 0.5;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `};

  ${(props) =>
    props.$active &&
    css`
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 4px;
        background: ${(props) => props.theme.colors.blue};
        border-radius: 50px;

        margin: 10% 0;
        height: 80%;
      }
    `};

  ${Icon} {
    width: 21px;
    height: 21px;
    margin: 14px;
    color: ${(props) => (props.$active ? props.theme.colors.blue : props.theme.colors.black)};
  }
`;

export const Styled = {
  Sidebar,
  SidebarLink,
};
