import * as React from 'react';
import { ExportLanPort } from './ExportLanPort';
import { LanNodeModel } from '../../../lan/node/LanNodeModel';
import { ExportLanNodeLabel } from './ExportLanNodeLabel';
import { LanPortModel } from '../../../lan/node/port/LanPortModel';
import { LanStrokeWidth } from '../../../lan/node/LanNodeWidget';
import { DirectoryNodeSvgCache } from './ExportNode';
import { isNetworkNodeModel } from '../../../NgGraceModel';
import { getNetworkNodeImageOffset } from '../../../lan/node/network/NetworkNodeWidget';

interface ExportLanNodeProps {
  node: LanNodeModel;
  svgCache: DirectoryNodeSvgCache;
}

export const ExportLanNode: React.FC<ExportLanNodeProps> = ({ node, svgCache }) => {
  const imageOffset = isNetworkNodeModel(node) ? getNetworkNodeImageOffset(node) : undefined;

  return (
    <g transform={`translate(${node.getPositionToRender().x},${node.getPositionToRender().y})`}>
      <ExportLanNodeLabel node={node} size={'m'} />
      <rect
        x={LanStrokeWidth / 2}
        y={LanStrokeWidth / 2}
        fill="none"
        stroke={'black'}
        strokeWidth={LanStrokeWidth}
        height={node.getRotatedSize().y - LanStrokeWidth}
        width={node.getRotatedSize().x - LanStrokeWidth}
      />
      {imageOffset && (
        <g
          transform={`translate(${imageOffset!.left} ${imageOffset!.top})`}
          dangerouslySetInnerHTML={{ __html: svgCache[node.getDirectoryEntryId()] }}
        />
      )}
      {Object.values(node.getPorts()).map((port) => (
        <ExportLanPort key={port.getID()} port={port as LanPortModel} />
      ))}
    </g>
  );
};
