import { HasChildrenListener } from '../HasChildren';
import { HasSize, HasSizedChildrenListener, HasSizeListener } from '../HasSize';
import { BasePoint } from '../../geometry/Point';
import { Listenable } from '../Listenable';
import { HasRelativeModel } from '../HasRelativeModel';

export class PlaceholderCachingHasSize implements HasSize {
  private origin: HasSize;
  private cachedValue?: BasePoint;

  constructor(
    origin: HasSize,
    eventDelegate: Listenable<HasChildrenListener<any> & HasSizeListener>,
    hasRelativeModel: HasRelativeModel<Listenable<HasSizedChildrenListener>>,
    size?: BasePoint
  ) {
    this.origin = origin;
    this.cachedValue = size;

    const registerListener = () =>
      eventDelegate.registerListener({
        childrenChanged: () => {
          const newSize = this.origin.getSize();
          if (!this.cachedValue || !this.cachedValue.equals(newSize)) {
            this.cachedValue = newSize;
            eventDelegate.fireEvent({ newSize }, 'sizeChanged');
            hasRelativeModel.getRelativeModel()?.fireEvent(undefined, 'childSizeChanged');
          }
        },
      });

    if (!this.cachedValue) {
      registerListener();
    } else {
      setTimeout(registerListener);
    }
  }

  getSize(): BasePoint {
    if (!this.cachedValue) {
      this.cachedValue = this.origin.getSize();
    }

    return this.cachedValue;
  }
}
