import React from 'react';
import { SwitchNodeModel } from './SwitchNodeModel';
import { LanEngine } from '../../LanEngine';
import { LanNodeWidget } from '../LanNodeWidget';
import { Styled as S } from '../network/NetworkNodeWidget.styled';

export interface SwitchNodeWidgetProps {
  node: SwitchNodeModel;
  engine: LanEngine;
}

const borderWidth = 1;
export const LanNodeBorderWidth = borderWidth;

export const SwitchNodeWidget: React.FC<SwitchNodeWidgetProps> = React.memo(({ node, engine }) => {
  return (
    <>
      <S.Label height={node.getSize().y} width={node.getSize().x} yCenter={true}>
        <div>{node.getProjectName()}</div>
        <div>{node.getName()}</div>
      </S.Label>
      <LanNodeWidget node={node} engine={engine} />
    </>
  );
});
