import { ExtendableTreeNode, TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import {
  DefaultHasExtendableParentModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { LanNodeModel } from '../../../editor/lan/node/LanNodeModel';
import { LanEngine } from '../../../editor/lan/LanEngine';
import { Factory } from '../../../../utils/factory';

export const LanStructureTreeLanNodeFactory = (
  engine: LanEngine
): Factory<LanStructureTreeLanNodeProps, TreeNodeState> => ({ model, parent }): TreeNodeState =>
  LanStructureTreeLanNode(engine, parent, model);

export interface LanStructureTreeLanNodeProps {
  model: LanNodeModel;
  parent: ExtendableTreeNode;
}

const LanStructureTreeLanNode = (
  engine: LanEngine,
  parent: ExtendableTreeNode,
  lanNode: LanNodeModel
): TreeNodeState => {
  const model = LanStructureTreeLanNodeModel(lanNode);

  return {
    ...model,
    ...NotExtendableModel(),
    ...DefaultHasExtendableParentModel(model, parent),
    ...SelectableTreeBaseModel(lanNode, engine),
  };
};

const LanStructureTreeLanNodeModel = (lanNode: LanNodeModel): TreeNodeModel => {
  return {
    getName: () => lanNode.getName(),
    getKey: () => lanNode.getID(),
    onChildrenChanged: () => () => {},
    getChildren: () => [],
  };
};
