import { AbstractReactFactory, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { SmartLinkModel } from '../../link/smart/SmartLinkModel';
import { DiagramEngine } from '../../insides/engine/DiagramEngine';
import { LanLinkModel, LanLinkType } from './LanLinkModel';
import { DefaultLinkModel } from '@projectstorm/react-diagrams';
import { SmartLinkWidget } from '../../link/smart/SmartLinkWidget';

export interface GenerateSmartLinkModelEvent extends GenerateModelEvent {
  initialConfig: ReturnType<SmartLinkModel['serialize']>;
}

export class LanLinkFactory extends AbstractReactFactory<DefaultLinkModel, DiagramEngine> {
  constructor() {
    super(LanLinkType);
  }

  generateReactWidget(event: any): JSX.Element {
    return <SmartLinkWidget engine={this.engine} link={event.model} />;
  }

  generateModel(event: GenerateSmartLinkModelEvent): DefaultLinkModel {
    return new LanLinkModel();
  }
}
