import { ModelCommand } from './ModelCommand';
import { NgGraceModel } from '../../NgGraceModel';
import { ConnectivityNodeModel } from '../../connectivity/ConnectivityNodeModel';
import { JoinedSmartLinkModel } from '../../link/smart/JoinedSmartLinkModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { SmartLinkModel } from '../../link/smart/SmartLinkModel';

export class ConnectivityNodeDeletionCommand implements ModelCommand {
  private model: NgGraceModel;
  private node: ConnectivityNodeModel;

  constructor(model: NgGraceModel, node: ConnectivityNodeModel) {
    this.model = model;
    this.node = node;
  }

  execute() {
    const connectedPorts = Object.values(this.node.getPorts()).filter((port) => Object.values(port.getLinks()).length);
    if (connectedPorts.length > 2) {
      return;
    }

    if (connectedPorts.length < 2) {
      return this.node.remove();
    }

    const linkA = Object.values(connectedPorts[0].getLinks())[0];
    const linkB = Object.values(connectedPorts[1].getLinks())[0];

    const sourceLink = linkA.getTargetPort().getID() === connectedPorts[0].getID() ? linkA : linkB;
    const targetLink = linkA.getTargetPort().getID() === connectedPorts[0].getID() ? linkB : linkA;
    const sourcePort = sourceLink.getSourcePort();

    const needToReverseTargetLink = !connectedPorts.some((port) => port.getID() === targetLink.getSourcePort().getID());
    const targetPort = needToReverseTargetLink ? targetLink.getSourcePort() : targetLink.getTargetPort();

    this.node.remove();

    if (sourcePort.getParent().getID() === targetPort.getParent().getID()) {
      return;
    }
    const resultLink = new JoinedSmartLinkModel(
      this.node.getCenter(),
      sourcePort as ConnectablePortModel,
      targetPort as ConnectablePortModel,
      sourceLink as SmartLinkModel,
      targetLink as SmartLinkModel
    );

    this.model.addLink(resultLink);
  }
}
