import * as React from 'react';
import { NgGraceNodeWidget } from '../node/NgGraceNodeWidget';
import { RotatableNodeModel } from '../../generics/RotatableNodeModel';
import { NgGraceNodeLayerModel } from './NgGraceNodeLayerModel';
import { DiagramEngine } from '../engine/DiagramEngine';

export interface NodeLayerWidgetProps {
  layer: NgGraceNodeLayerModel;
  engine: DiagramEngine;
}

export const NgGraceNodeLayerWidget: React.FC<NodeLayerWidgetProps> = ({ layer, engine }) => {
  return (
    <>
      {Object.values(layer.getNodes()).map((node: RotatableNodeModel) => {
        return <NgGraceNodeWidget key={node.getID()} diagramEngine={engine} node={node} />;
      })}
    </>
  );
};
