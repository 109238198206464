import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { LanEngine } from '../../LanEngine';
import { SwitchNodeModel, SwitchNodeType } from './SwitchNodeModel';
import { SwitchNodeWidget } from './SwitchNodeWidget';
import { Directory } from '../../../directory/Directory';
import { SwitchDirectoryEntry } from '../../../directory/PropertiesDirectory';
import { GenerateModelEvent } from '../../../scd/controller/ControllerFactory';
import { RawSwitchNodeModel } from './RawSwitchNodeModel';

export class SwitchNodeFactory extends AbstractReactFactory<SwitchNodeModel, LanEngine> {
  private switchDirectory: Directory<SwitchDirectoryEntry>;

  constructor(switchDirectory: Directory<SwitchDirectoryEntry>) {
    super(SwitchNodeType);
    this.switchDirectory = switchDirectory;
  }

  generateReactWidget(event: { model: SwitchNodeModel }): JSX.Element {
    const model = event.model;
    return <SwitchNodeWidget node={model} engine={this.engine} />;
  }

  generateModel(event: GenerateModelEvent<SwitchNodeModel>) {
    return new RawSwitchNodeModel(this.switchDirectory.getEntry(event.initialConfig.directoryId));
  }
}
