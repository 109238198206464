import { DefaultLayer, ExportLayer } from './content/ExportLayer';
import { ExportNodeLayer } from './content/node/ExportNodeLayer';
import { ExportLinkLayer } from './content/link/ExportLinkLayer';
import { SsdModel } from '../ssd/SsdModel';
import { ExportLogicDeviceLayer } from './content/placeholder/ExportLogicDeviceLayer';
import { getFrame } from './frame/Frame';
import { FrameTable } from './frame/table/FrameTable';
import { renderToStaticMarkup } from 'react-dom/server';
import * as React from 'react';
import { ExportFormDto } from '../../../api/nggrace-back';
import { NodeDirectory } from '../directory/NodeDirectory';
import { NgGraceModel } from '../NgGraceModel';
import { NetworkDeviceDirectoryEntry, PropertiesDirectory } from '../directory/PropertiesDirectory';
import { ScdModel } from '../scd/ScdModel';
import { ExportControllerLayer } from './content/placeholder/ExportControllerLayer';
import { GTRS_RtpB } from './GTRS_RtpB';
import { IProjectExport } from './types';
import { LanModel } from '../lan/LanModel';
import { ExportRackLayer } from './content/placeholder/ExportRackLayer';

interface ExportSvgProps {
  project: IProjectExport;
  directory: NodeDirectory;
  networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>;
  model: NgGraceModel;
  dto: ExportFormDto;
}

const contentScale = 0.4;

export const exportSvg = async ({ project, directory, networkDeviceDirectory, model, dto }: ExportSvgProps) => {
  let layers: ExportLayer;

  if (model instanceof LanModel) {
    layers = new ExportNodeLayer(
      new ExportLinkLayer(
        new ExportRackLayer(new DefaultLayer(), Object.values(model.getRackLayer().getModels())),
        model.getLinks()
      ),
      directory,
      networkDeviceDirectory,
      model.getNodes()
    );
  } else {
    layers = new ExportNodeLayer(
      new ExportLinkLayer(new DefaultLayer(), model.getLinks()),
      directory,
      networkDeviceDirectory,
      model.getNodes()
    );
    if (model instanceof ScdModel) {
      layers = new ExportControllerLayer(
        layers,
        Object.values(model.getControllerLayer().getModels()).flatMap((controllerModel) =>
          controllerModel.getPlaceholders()
        )
      );
    } else if (model instanceof SsdModel) {
      layers = new ExportLogicDeviceLayer(layers, Object.values(model.getLogicDeviceLayer().getModels()));
    }
  }

  await layers.build();

  const frame = getFrame(
    project.styleSelection,
    layers.boundary.width * contentScale,
    layers.boundary.height * contentScale
  );

  const jsx = (
    <svg
      width={frame.size.width + 'mm'}
      height={frame.size.height + 'mm'}
      viewBox={`0 0 ${frame.size.width} ${frame.size.height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
              @font-face {
                font-family: GOSTRUS;
                src: url(data:font/truetype;charset=utf-8;base64,${GTRS_RtpB}) format('truetype');
              }
            `,
          }}
        />
      </defs>

      <g>{frame.svg}</g>

      <g
        transform={`translate(${frame.position.x + (frame.maxSize.width - layers.boundary.width * contentScale) / 2},${
          frame.position.y + (frame.maxSize.height - layers.boundary.height * contentScale) / 2
        }) scale(${contentScale})`}
      >
        <g transform={`translate(${-layers.boundary.xMin},${-layers.boundary.yMin})`}>{layers.element}</g>
      </g>

      <FrameTable project={project} dto={dto} frame={frame} />
    </svg>
  );
  return renderToStaticMarkup(jsx);
};
