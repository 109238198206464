import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { useRoutes } from './hooks/useRoutes';
import { setupInterceptor } from '../api/Api';
import { LoginPage } from '../components/pages/login/LoginPage';
import { UserRegisterPage } from '../components/pages/user-register/UserRegisterPage';
import { CompaniesPage } from '../components/pages/company/CompaniesPage';
import { ProjectsPage } from '../components/pages/project/ProjectsPage';
import { LandingPage } from '../components/pages/LandingPage';
import { CompanyRegisterPage } from '../components/pages/company-register/CompanyRegisterPage';
import { UsersPage } from '../components/pages/user/UsersPage';
import { SldStagePage } from '../components/pages/sld/SldStagePage';
import { TcoPage } from '../components/pages/tco/TcoPage';
import { ExportPage } from '../components/pages/export/ExportPage';
import { MessageModal } from '../components/widgets/MessageModal';
import { SsdStagePage } from '../components/pages/ssd/SsdStagePage';
import { ScdStagePage } from '../components/pages/scd/ScdStagePage';
import { LanStagePage } from '../components/pages/lan/LanStagePage';
import { useUser } from '../components/pages/login/UserContext';
import { PasswordRecoveryPage } from '../components/pages/password-recovery/PasswordRecoveryPage';
import { PasswordResetPage } from '../components/pages/password-recovery/PasswordResetPage';
import { LogoutPage } from '../components/pages/LogoutPage';

export const AppRoutes: React.FC = () => {
  const {
    projectsPath,
    sldStagePath,
    ssdStagePath,
    scdStagePath,
    lanStagePath,
    tcoPath,
    exportPath,
    companiesPath,
    usersPath,
    resetPasswordPath,
    passwordRecoveryPath,
    landingPath,
    loginPath,
    logoutPath,
    registerCompanyPath,
    registerUserPath,
    getDefaultPath,
    redirectToRootPath,
  } = useRoutes();

  const [interceptorsAdded, setInterceptorsAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user, loading: userLoading, resetUser } = useUser()!;

  const hideMessage = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  const resetContexts = useCallback(() => {
    resetUser();
  }, [resetUser]);

  useEffect(() => {
    setInterceptorsAdded(true);
    return setupInterceptor(redirectToRootPath, resetContexts, setErrorMessage);
  }, [redirectToRootPath, resetContexts]);

  if (userLoading) {
    return <></>;
  }

  return (
    <>
      {interceptorsAdded && (
        <Switch>
          <Route path={loginPath} component={LoginPage} />
          <Route path={logoutPath} component={LogoutPage} />
          <Route path={passwordRecoveryPath} component={PasswordRecoveryPage} />
          <Route path={resetPasswordPath} component={PasswordResetPage} />
          <Route path={registerCompanyPath} component={CompanyRegisterPage} />
          <Route path={registerUserPath} component={UserRegisterPage} />
          <Route path={landingPath} component={LandingPage} />
          <PrivateRoute path={projectsPath} component={ProjectsPage} />
          <PrivateRoute path={sldStagePath} component={SldStagePage} />
          <PrivateRoute path={ssdStagePath} component={SsdStagePage} />
          <PrivateRoute path={scdStagePath} component={ScdStagePage} />
          <PrivateRoute path={lanStagePath} component={LanStagePage} />
          <PrivateRoute path={tcoPath} component={TcoPage} />
          <PrivateRoute path={exportPath} component={ExportPage} />
          <PrivateRoute path={companiesPath} component={CompaniesPage} />
          <PrivateRoute path={usersPath} component={UsersPage} />
          <Route>
            <Redirect to={getDefaultPath(user)} />
          </Route>
        </Switch>
      )}
      <MessageModal error header="We are sorry" isOpen={!!errorMessage} onRequestClose={hideMessage}>
        {errorMessage}
      </MessageModal>
    </>
  );
};
