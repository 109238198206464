import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import { DirectoryLogicNodeModel } from '../../../editor/ssd/logic-device/LogicNodeModel';
import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { SsdStructureTreeLogicNodeProps } from './SsdStructureTreeLogicNode';
import { LogicDeviceDirectoryEntry, LogicNodeDirectoryEntry } from '../../../editor/directory/PropertiesDirectory';
import {
  DefaultHasExtendableParentModel,
  NavigatingSelectableTreeModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { LogicDeviceLayerModel } from '../../../editor/ssd/layer/logic-device/LogicDeviceLayerModel';
import { Directory } from '../../../editor/directory/Directory';
import { Factory } from '../../../../utils/factory';
import { SsdStructureTreeNodeModelType } from './SsdStructureTreeNode';
import { SsdEngine } from '../../../editor/ssd/SsdEngine';
import { DefaultPlaceholder } from '../../../editor/placeholder/Placeholder';
import { HasRelativeParent } from '../../../editor/placeholder/HasRelativeModel';

export const SsdStructureTreeLogicDeviceFactory = (
  engine: SsdEngine,
  logicDeviceLayer: LogicDeviceLayerModel,
  logicNodeDirectory: Directory<LogicNodeDirectoryEntry>,
  logicNodeFactory: Factory<SsdStructureTreeLogicNodeProps, TreeNodeState>
): Factory<SsdStructureTreeLogicDeviceProps, TreeNodeState> => ({ model, parent }) =>
  SsdStructureTreeLogicDevice(model, parent, engine, logicDeviceLayer, logicNodeDirectory, logicNodeFactory);

export interface SsdStructureTreeLogicDeviceModelProps {
  devicePayload: TreeDraggablePayload;
  parent: SsdStructureTreeNodeModelType;
  deviceToAddAfter?: TreeNodeModel;
}

export const SsdStructureTreeLogicDeviceModelFactory = (
  logicDeviceDirectory: Directory<LogicDeviceDirectoryEntry>,
  logicDeviceLayer: LogicDeviceLayerModel,
  logicNodeDirectory: Directory<LogicNodeDirectoryEntry>
): Factory<SsdStructureTreeLogicDeviceModelProps, DirectoryLogicDeviceModel> => ({
  devicePayload,
  parent,
  deviceToAddAfter,
}) => {
  let device: DirectoryLogicDeviceModel;

  if (!devicePayload.modelId) {
    const entry = logicDeviceDirectory.getEntry(devicePayload.directoryId!);
    const logicNodes = entry.logicNodeIds.map((nodeId) => new DirectoryLogicNodeModel(logicNodeDirectory, nodeId));

    device = new DirectoryLogicDeviceModel(
      entry,
      logicNodes,
      (device) => new DefaultPlaceholder(device, device as any, (origin) => new HasRelativeParent(origin)) //FIXME: USE FACTORY FROM OUTSIDE
    );
  } else {
    device = logicDeviceLayer.getModels()[devicePayload.modelId];
  }

  device.remove();
  const indexToAdd = deviceToAddAfter
    ? parent.getChildren().findIndex((prevDevice) => prevDevice.getID() === deviceToAddAfter.getKey()) + 1
    : 0;

  device.setRelativeModel(parent, indexToAdd);
  logicDeviceLayer.addModel(device);
  return device;
};

export interface SsdStructureTreeLogicDeviceProps {
  model: DirectoryLogicDeviceModel;
  parent: ExtendableTreeNode;
}

const SsdStructureTreeLogicDevice = (
  device: DirectoryLogicDeviceModel,
  parent: ExtendableTreeNode,
  engine: SsdEngine,
  logicDeviceLayer: LogicDeviceLayerModel,
  logicNodeDirectory: Directory<LogicNodeDirectoryEntry>,
  logicNodeFactory: Factory<SsdStructureTreeLogicNodeProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableSsdStructureTreeLogicDeviceModel(device, logicDeviceLayer, logicNodeDirectory);
  const logicNodeMapping = (node: DirectoryLogicNodeModel) => logicNodeFactory({ logicNode: node, parent: extendable });
  const model = SsdStructureTreeLogicDeviceModel(device, logicNodeMapping);

  const selectable = NavigatingSelectableTreeModel(
    SelectableTreeBaseModel(device, engine),
    device.getRelativeModel()!.getID(),
    engine
  );
  return {
    ...model,
    ...extendable,
    ...DefaultHasExtendableParentModel(model, parent),
    ...selectable,
  };
};

const SsdStructureTreeLogicDeviceModel = (
  device: DirectoryLogicDeviceModel,
  logicNodeMapping: (node: DirectoryLogicNodeModel) => TreeNodeState
): TreeNodeModel => ({
  getName: () => device.getCodeName(),
  getKey: () => device.getID(),
  onChildrenChanged: (cb) =>
    device.registerListener({
      childrenChanged: (event: { child: DirectoryLogicNodeModel; created: boolean }) =>
        cb(logicNodeMapping(event.child), event.created),
    } as any).deregister,
  getChildren: () => device.getChildren().map(logicNodeMapping),
});

const ExtendableSsdStructureTreeLogicDeviceModel = (
  device: DirectoryLogicDeviceModel,
  logicDeviceLayer: LogicDeviceLayerModel,
  logicNodeDirectory: Directory<LogicNodeDirectoryEntry>
): ExtendableTreeNode => ({
  canAddChild: ({ type }): boolean => type === TreeDraggableType.LogicNode,
  addChild: (childPayload, childToAddAfter) => {
    const node = childPayload.modelId
      ? Object.values(logicDeviceLayer.getModels())
          .flatMap((device) => device.getChildren())
          .find((node) => node.getID() === childPayload.modelId)!
      : new DirectoryLogicNodeModel(logicNodeDirectory, childPayload.directoryId!);

    node.remove();
    const indexToAdd = childToAddAfter
      ? device.getChildren().findIndex((child) => child.getID() === childToAddAfter.getKey()) + 1
      : 0;
    device.addChild(node, indexToAdd);
  },
});
