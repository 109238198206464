import React, { useCallback, useMemo, useState } from 'react';
import { Styled as S } from '../../Modal.styled';
import { ScdCreationDto, StageDto } from '../../../api/nggrace-back';
import { InputSelect } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Confirmation } from '../../widgets/Confirmation';
import { useNotifications } from '../../context/NotificationContext';

type ScdSettingsProps = {
  stage: StageDto;
  onReloadStage?: () => void;
  onClose(): void;
};

export const ScdSettings: React.FC<ScdSettingsProps> = ({ stage, onReloadStage, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<ScdCreationDto>({ ...stage.project });
  const { pacsArchDirectory, controllerVendorDirectory, controllerDirectory } = useDirectory();
  const { redirectToStagePage } = useRoutes();
  const update = useMemo(() => stage.baseInfo.type === 'SCD', [stage]);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const notifications = useNotifications();

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const pacsArchOptions = useMemo(() => {
    return pacsArchDirectory.getAll().map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [pacsArchDirectory]);

  const controllers = useMemo(() => controllerDirectory.getAll(), [controllerDirectory]);

  const iedVendorOptions = useMemo(() => {
    return controllerVendorDirectory
      .getAll()
      .filter((vendor) => controllers.find((controller) => controller.vendorId === vendor.id && controller.ied))
      .map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [controllers, controllerVendorDirectory]);

  const muVendorOptions = useMemo(() => {
    return controllerVendorDirectory
      .getAll()
      .filter((vendor) => controllers.find((controller) => controller.vendorId === vendor.id && controller.mu))
      .map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [controllers, controllerVendorDirectory]);

  const handlePacsArchChange = useCallback((pacsArch: string) => {
    setState((state) => ({ ...state, pacsArch }));
  }, []);

  const handleIedVendorChange = useCallback((iedVendor: string) => {
    setState((state) => ({ ...state, iedVendor }));
  }, []);

  const handleMuVendorChange = useCallback((muVendor: string) => {
    setState((state) => ({ ...state, muVendor }));
  }, []);

  const handleSynthesisScd = useCallback(() => {
    setLoading(true);
    Api.createScd(stage.project.id, state, { omitInterceptorErrorModal: true })
      .then(() => {
        if (update) {
          onReloadStage!!();
          setLoading(false);
          onClose();
          notifications.notifySuccess('SCD settings updated.');
        } else {
          redirectToStagePage(stage.project.id, 'SCD');
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  }, [stage.project.id, state, update, onReloadStage, onClose, notifications, redirectToStagePage]);

  const handleUpdateScd = useCallback(() => {
    hideConfirmationModal();
    handleSynthesisScd();
  }, [handleSynthesisScd, hideConfirmationModal]);

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <>
      <ReactModal isOpen onRequestClose={handleClose} style={theme.modal}>
        <S.Modal>
          <S.Header>SCD Settings</S.Header>
          <S.Main>
            <S.LeftPanel>
              <S.Form>
                <InputSelect
                  label="PACS Architecture"
                  selected={state.pacsArch}
                  options={pacsArchOptions}
                  onChange={handlePacsArchChange}
                />
                <InputSelect
                  label="IED Vendor"
                  selected={state.iedVendor}
                  options={iedVendorOptions}
                  onChange={handleIedVendorChange}
                />
                <InputSelect
                  label="MU Vendor"
                  selected={state.muVendor}
                  options={muVendorOptions}
                  onChange={handleMuVendorChange}
                />
              </S.Form>
            </S.LeftPanel>
            <S.RightPanel>
              <S.Description>
                <p>You must select the parameters for SCD PACS system synthesis.</p>
                <p>The PACS architecture defines the variations of possible designed systems.</p>
                <p>
                  You must select your preferred system hardware vendors from the list of possible vendors. IPACS is
                  selected by default.
                </p>
              </S.Description>
              {error && <Feedback error text={error} />}
            </S.RightPanel>
          </S.Main>
          <S.Footer>
            <Btn secondary onClick={handleClose}>
              Cancel
            </Btn>
            <S.BtnPrimary onClick={update ? showConfirmationModal : handleSynthesisScd} disabled={loading}>
              {!loading ? 'Synthesis' : 'Processing'}
            </S.BtnPrimary>
          </S.Footer>
        </S.Modal>
      </ReactModal>{' '}
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Are you sure?'}
          text={`All changes made to diagram would be lost`}
          onClose={hideConfirmationModal}
          onConfirm={handleUpdateScd}
        />
      </ReactModal>
    </>
  );
};
