import { BrickTextMetrics, TextMetrics } from '../../../../utils/TextMetrics';
import { HasSize } from '../HasSize';
import { BasePoint } from '../../geometry/Point';
import { PlaceholderBrickSizes } from '../../ssd/logic-device/PlaceholderWidget.styled';

export class BrickHasSize implements HasSize {
  private named: { getCodeName(): string };
  private textMetrics: TextMetrics;
  private constantMetrics: typeof PlaceholderBrickSizes;

  constructor(named: { getCodeName(): string }) {
    this.named = named;
    this.textMetrics = BrickTextMetrics;
    this.constantMetrics = PlaceholderBrickSizes;
  }

  getSize(): BasePoint {
    const textWidth = this.textMetrics.getWidth(this.named.getCodeName());
    return new BasePoint(
      textWidth + this.constantMetrics.placeholderBrickHorizontalPadding * 2,
      this.constantMetrics.placeholderBrickHeight
    );
  }
}
