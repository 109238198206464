import { getDistance, LanLinkGraph } from './LanLinkGraph';
import { SmartLinkPointModel } from '../../../point/SmartLinkPointModel';
import { SingleRackModel } from '../../rack/SingleRackModel';
import { DefaultConnectingPath } from '../../../link/smart/path/connecting/DefaultConnectingPath';
import { PortEndedPath } from '../../../link/smart/path/default/PortEndedPath';
import { NormalizingAngledPath } from '../../../link/smart/path/default/NormalizingAngledPath';
import { AngledPath } from '../../../link/smart/path/default/AngledPath';
import { Direction } from '../../../geometry/Direction';
import { RestrictedDirectionPoint } from '../../../geometry/Point';
import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { Node } from '../../../auto-layout/node/Node';
import { Point } from '@projectstorm/geometry';
import { LanLinkModel } from '../LanLinkModel';
import { LanNodeModel } from '../../node/LanNodeModel';

export class LanLinkRackGraph implements LanLinkGraph {
  private readonly rack: SingleRackModel;
  private readonly link: LanLinkModel;
  private readonly deregister: () => any;

  constructor(rack: SingleRackModel, link: LanLinkModel, onChange: () => void) {
    this.link = link;
    this.rack = rack;
    this.deregister = rack.registerListener({ waypointsChanged: onChange } as any).deregister;
  }

  detach(): void {
    this.deregister();
  }

  getId(): string {
    return this.rack.getID();
  }

  needDetach(): boolean {
    const sourceNode = this.link.getSourcePort().getParent() as LanNodeModel;
    const targetNode = this.link.getTargetPort().getParent() as LanNodeModel;
    return (
      sourceNode.getRelativeZone()?.getID() !== targetNode.getRelativeZone()?.getID() ||
      !this.rack.getChildren().find((child) => child.getID() === sourceNode.getID()) ||
      !this.rack.getChildren().find((child) => child.getID() === targetNode.getID())
    );
  }

  getPoints() {
    const graph = this.rack.getLinkWaypointGraph();
    if (!graph) {
      return [];
    }
    const sourcePort = this.link.getSourcePort();
    const targetPort = this.link.getTargetPort();

    const nodes = graph.getNodes();
    const sourceWaypoint = this.getWaypointForFrom(sourcePort, targetPort, nodes);
    const targetWaypoint = this.getWaypointForFrom(targetPort, sourcePort, nodes);

    let path =
      sourceWaypoint.getID() !== targetWaypoint.getID()
        ? graph.find(sourceWaypoint.getID(), targetWaypoint.getID()).map((node) => node.data)
        : [sourceWaypoint];

    if (path[0].getID() !== sourceWaypoint.getID()) {
      path.reverse();
    }

    const sourceEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), sourcePort),
      this.link
    );

    const targetEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), targetPort),
      this.link
    );

    const intermediatePointsRestrictedDirections =
      this.rack.getGrowCoordinate().getName() === 'y'
        ? [Direction.LEFT, Direction.RIGHT]
        : [Direction.TOP, Direction.BOTTOM];

    return [
      ...sourceEndedPath.connect(
        new RestrictedDirectionPoint(sourcePort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP]),
        new RestrictedDirectionPoint(path[0].getRect().getTopLeft(), intermediatePointsRestrictedDirections)
      ),
      ...path
        .map(
          (node) =>
            new SmartLinkPointModel({
              position: node.getRect().getTopLeft(),
              link: this.link,
            })
        )
        .slice(1, -1),
      ...targetEndedPath.connect(
        new RestrictedDirectionPoint(
          path[path.length - 1].getRect().getTopLeft(),
          intermediatePointsRestrictedDirections
        ),
        new RestrictedDirectionPoint(targetPort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP])
      ),
    ];
  }

  getColor(): string {
    return 'black';
  }

  private getWaypointForFrom(from: ConnectablePortModel, to: ConnectablePortModel, waypoints: Node[]) {
    const waypointMapping: {
      accept: (from: ConnectablePortModel, to: ConnectablePortModel) => boolean;
      getClosest: (from: ConnectablePortModel, to: ConnectablePortModel) => Node;
    }[] = [
      {
        accept: () => true,
        getClosest: (from) => this.getClosestWaypoint(from.getConnector(), waypoints),
      },
    ];

    return waypointMapping.find((mapping) => mapping.accept(from, to))!.getClosest(from, to);
  }

  private getClosestWaypoint(point: Point, waypoints: Node[]) {
    let minDistance = Number.MAX_VALUE;
    let currentId: string | undefined = undefined;

    waypoints.forEach((waypoint) => {
      const position = waypoint.getRect().getTopLeft();
      const distance = getDistance(point, position);
      if (minDistance > distance) {
        minDistance = distance;
        currentId = waypoint.getID();
      }
    });

    return waypoints.find((waypoint) => waypoint.getID() === currentId)!;
  }

  getConnectorLength(): number {
    return 65;
  }
}
