import { ConnectingSmartLinkFactory } from '../link/smart/ConnectingSmartLinkFactory';
import { LanModel } from './LanModel';
import { CommonFunctionalEngine } from '../CommonFunctionalEngine';
import { LanNodeLayerFactory } from './node/layer/LanNodeLayerFactory';
import { AbstractModelFactory, FactoryBank } from '@projectstorm/react-canvas-core';
import { BuildingFactory } from './building/BuildingFactory';
import { SwitchNodeFactory } from './node/switch/SwitchNodeFactory';
import { SingleRackFactory } from './rack/SingleRackFactory';
import { ZoneFactory } from './zone/ZoneFactory';
import { BuildingLayerFactory } from './building/layer/BuildingLayerFactory';
import { RackLayerFactory } from './rack/layer/RackLayerFactory';
import { ZoneLayerFactory } from './zone/layer/ZoneLayerFactory';
import { CompositeRackFactory } from './rack/CompositeRackFactory';
import {
  ControllerDirectoryEntry,
  NetworkDeviceDirectoryEntry,
  PropertiesDirectoryEntry,
  SwitchDirectoryEntry,
} from '../directory/PropertiesDirectory';
import { Directory } from '../directory/Directory';
import { NetworkNodeFactory } from './node/network/NetworkNodeFactory';
import { NetworkPortFactory } from './node/network/NetworkPortFactory';
import { SwitchPortFactory } from './node/switch/SwitchPortFactory';
import { SingleRackModelType } from './rack/SingleRackModel';
import { CompositeRackModelType } from './rack/CompositeRackModel';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { LanDefaultState } from './state/LanDefaultState';
import { LanLinkLayerFactory } from './link/layer/LanLinkLayerFactory';
import { LanModelObserver } from './LanModelObserver';
import { LanControllerNodeFactory } from './node/controller/LanControllerNodeFactory';
import { LanControllerPortFactory } from './node/controller/LanControllerPortFactory';
import { LanLinkFactory } from './link/LanLinkFactory';
import { checkIsRemovable } from './Removable';

export class LanEngine extends CommonFunctionalEngine {
  protected readonly buildingFactories: FactoryBank<BuildingFactory> = new FactoryBank();
  protected readonly rackFactories: FactoryBank<SingleRackFactory | CompositeRackFactory> = new FactoryBank();
  protected readonly zoneFactories: FactoryBank<ZoneFactory> = new FactoryBank();
  private readonly notifyError: (message: string, title?: string) => void;

  constructor(
    switchDirectory: Directory<SwitchDirectoryEntry>,
    networkDeviceTypeDirectory: Directory<PropertiesDirectoryEntry>,
    networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>,
    controllerDirectory: Directory<ControllerDirectoryEntry>,
    notifyError: (message: string, title?: string) => void
  ) {
    super({}, new LanModelObserver());
    this.notifyError = notifyError;
    [new ConnectingSmartLinkFactory(), new LanLinkFactory()].forEach((factory) =>
      this.linkFactories.registerFactory(factory)
    );

    const setup = (bank: FactoryBank) => {
      bank.registerListener({
        factoryAdded: (event: { factory: AbstractModelFactory }) => {
          event.factory.setDiagramEngine((this as unknown) as DiagramEngine);
        },
      } as any);
    };

    setup(this.buildingFactories as any);
    setup(this.rackFactories as any);
    setup(this.zoneFactories as any);

    [
      new BuildingLayerFactory(),
      new LanNodeLayerFactory(),
      new RackLayerFactory(),
      new ZoneLayerFactory(),
      new LanLinkLayerFactory(),
    ].map((layer) => this.layerFactories.registerFactory(layer));

    [
      new SwitchNodeFactory(switchDirectory),
      new NetworkNodeFactory(networkDeviceTypeDirectory, networkDeviceDirectory),
      new LanControllerNodeFactory(controllerDirectory),
    ].forEach((factory) => this.nodeFactories.registerFactory(factory));

    this.buildingFactories.registerFactory(new BuildingFactory());
    [new SingleRackFactory(), new CompositeRackFactory()].forEach((factory) =>
      this.rackFactories.registerFactory(factory)
    );

    [
      new NetworkPortFactory(networkDeviceDirectory),
      new SwitchPortFactory(switchDirectory),
      new LanControllerPortFactory(controllerDirectory),
    ].forEach((factory) => this.portFactories.registerFactory(factory));

    this.stateMachine.pushState(new LanDefaultState(this.notifyError));
  }

  getBuildingFactories() {
    return this.buildingFactories;
  }

  getZoneFactories() {
    return this.zoneFactories;
  }

  getRackFactories() {
    return this.rackFactories;
  }

  buildNewModel() {
    return new LanModel();
  }

  getSingleRackFactory(): SingleRackFactory {
    return this.rackFactories.getFactory<SingleRackFactory>(SingleRackModelType);
  }

  getCompositeRackFactory(): CompositeRackFactory {
    return this.rackFactories.getFactory<CompositeRackFactory>(CompositeRackModelType);
  }

  async deleteSelected() {
    const removeForbidden = this.getModel()
      .getSelectedEntities()
      .some((model) => checkIsRemovable(model) && !model.canRemove());

    if (removeForbidden) {
      return this.notifyError('Can not remove element containing Controller Node', 'Invalid Remove');
    }

    await super.deleteSelected();
  }

  zoomToFitNodes(margin: number = 200) {
    super.zoomToFitNodes(margin);
  }
}
