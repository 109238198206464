import { PropertiesDirectoryName } from '../../../editor/directory/PropertiesDirectory';

export enum TreeDraggableType {
  LogicDevice = 'LogicDevice',
  LogicNode = 'LogicNode',
  Controller = 'Controller',
  Building = 'Building',
  Room = 'Room',
  Rack = 'Rack',
  LanNode = 'LanNode',
}

export interface TreeDraggablePayload {
  type: TreeDraggableType;
  directoryId?: string;
  directory?: PropertiesDirectoryName;
  modelId?: string;
}

export interface TreeNodeModel {
  getName(): string;

  getKey(): string;

  getChildren(): TreeNodeState[];

  onChildrenChanged(cb: (child: TreeNodeState, added: boolean) => void): () => void;
}

export interface ExtendableTreeNode {
  canAddChild(payload: TreeDraggablePayload): boolean;

  addChild(payload: TreeDraggablePayload, childToAddAfter?: TreeNodeModel): void;
}

export interface HasExtendableParent {
  canAddNeighbour(payload: TreeDraggablePayload): boolean;

  addNeighbour(payload: TreeDraggablePayload): void;
}

export interface SelectableTreeNode {
  canSelect(): boolean;

  isSelected(): boolean;

  setSelected(selected: boolean): void;

  onSelectionChanged(cb: () => void): () => void;
}

export interface TreeNodeState extends TreeNodeModel, ExtendableTreeNode, SelectableTreeNode, HasExtendableParent {}
