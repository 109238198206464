import { HasSize } from '../../placeholder/HasSize';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasChildren } from '../../placeholder/HasChildren';
import { BasePoint } from '../../geometry/Point';

import { Polygon } from '@projectstorm/geometry';
import { SingleRackModel } from '../rack/SingleRackModel';
import { onceForLoop } from '../../FunctionDecorators';

export const ZonePadding = 50;

export class ZoneHasSize implements HasSize {
  private hasChildren: HasChildren<SingleRackModel>;
  private defaultSize: BasePoint;

  constructor(eventDelegate: BaseObserver, hasChildren: HasChildren<SingleRackModel>, defaultSize: BasePoint) {
    this.hasChildren = hasChildren;
    this.defaultSize = defaultSize;
    eventDelegate.registerListener({
      childrenChanged: onceForLoop(() => eventDelegate.fireEvent({}, 'sizeChanged')),
    });
  }

  getSize(): BasePoint {
    const childrenRect = Polygon.boundingBoxFromPolygons(
      this.hasChildren.getChildren().map((child) => child.getRect()!)
    );

    const padding = ZonePadding * 2;
    return new BasePoint(childrenRect.getWidth() + padding, childrenRect.getHeight() + padding);
  }
}
