import WindingTypeDirectory from '../../assets/directory/WindingTypeDirectory.json';
import VoltageLevelDirectory from '../../assets/directory/VoltageLevelDirectory.json';
import AccuracyClassDirectory from '../../assets/directory/AccuracyClassDirectory.json';
import RatedCurrentDirectory from '../../assets/directory/RatedCurrentDirectory.json';
import PowerFlowDirectory from '../../assets/directory/PowerFlowDirectory.json';
import LogicNodeDirectory from '../../assets/directory/LogicNodeDirectory.json';
import LogicDeviceDirectory from '../../assets/directory/LogicDeviceDirectory.json';
import ControllerDirectory from '../../assets/directory/ControllerDirectory.json';
import SetOfRulesDirectory from '../../assets/directory/SetOfRulesDirectory.json';
import ControllerVendorDirectory from '../../assets/directory/ControllerVendorDirectory.json';
import PACSArchDirectory from '../../assets/directory/PACSArchDirectory.json';
import StationLevelDirectory from '../../assets/directory/StationLevelDirectory.json';
import SwitchVendorDirectory from '../../assets/directory/SwitchVendorDirectory.json';
import SwitchDirectory from '../../assets/directory/SwitchDirectory.json';
import NetworkDeviceVendorDirectory from '../../assets/directory/NetworkDeviceVendorDirectory.json';
import NetworkDeviceTypeDirectory from '../../assets/directory/NetworkDeviceTypeDirectory.json';
import NetworkDeviceDirectory from '../../assets/directory/NetworkDeviceDirectory.json';
import {
  ControllerDirectoryEntry,
  LogicDeviceDirectoryEntry,
  LogicNodeDirectoryEntry,
  NetworkDeviceDirectoryEntry,
  NetworkDeviceTypeDirectoryEntry,
  PropertiesDirectory,
  PropertiesDirectoryEntry,
  PropertiesDirectoryName,
  SetOfRulesDirectoryEntry,
  StationLevelDirectoryEntry,
  SwitchDirectoryEntry,
  VoltageLevelDirectoryEntry,
} from '../editor/directory/PropertiesDirectory';
import { ProjectStyleSelection } from '../../api/nggrace-back';
import DeviceDirectoryEntries from '../../assets/directory/DeviceDirectory.json';
import { getStyleValue, LocalizedValue } from '../../utils/language-util';
import { Direction } from '../editor/geometry/Direction';
import { PortModelAlignment } from '@projectstorm/react-diagrams';
import {
  FieldRecord,
  NodeDirectory,
  NodeDirectoryEntry,
  NodeEntryType,
  PortRecord,
  RawNodeDirectoryEntry,
  RawPortRecord,
} from '../editor/directory/NodeDirectory';

class RawNodeDirectoryWrapper implements NodeDirectory {
  private readonly nodes: Map<string, NodeDirectoryEntry>;

  constructor(entries: RawNodeDirectoryEntry[], style: ProjectStyleSelection) {
    this.nodes = new Map();
    entries.forEach((device) => {
      this.nodes.set(device.id, new RawNodeDirectoryEntryWrapper(device, style));
    });
  }

  getAll(): NodeDirectoryEntry[] {
    return Array.from(this.nodes.values());
  }

  getEntry(id: string): NodeDirectoryEntry {
    const result = this.nodes.get(id);
    if (!result) {
      throw new Error('unknown node id: ' + id);
    }

    return result;
  }
}

class MockNodeDirectoryWrapper extends RawNodeDirectoryWrapper {
  constructor(style: ProjectStyleSelection) {
    super(DeviceDirectoryEntries as RawNodeDirectoryEntry[], style);
  }
}

class RawNodeDirectoryEntryWrapper implements NodeDirectoryEntry {
  private readonly raw: RawNodeDirectoryEntry;
  private readonly style: ProjectStyleSelection;
  private readonly wrappedPorts: PortRecord[];

  constructor(raw: RawNodeDirectoryEntry, style: ProjectStyleSelection) {
    this.raw = raw;
    this.style = style;
    this.wrappedPorts = raw.ports.map((portRecord) => new RawPortRecordWrapper(portRecord, this.style));
  }

  get deviceType(): string {
    return this.raw.deviceType;
  }

  get fields(): FieldRecord[] {
    return this.raw.fields;
  }

  get height(): number {
    return getStyleValue(this.style, this.raw.height);
  }

  get id(): string {
    return this.raw.id;
  }

  get labelDirection(): Direction {
    return getStyleValue(this.style, this.raw.labelDirection);
  }

  get name(): LocalizedValue<string> {
    return this.raw.name;
  }

  get ports(): PortRecord[] {
    return this.wrappedPorts;
  }

  get svg(): string {
    return getStyleValue(this.style, this.raw.svg);
  }

  get width(): number {
    return getStyleValue(this.style, this.raw.width);
  }

  get transformer(): boolean {
    return this.raw.transformer || false;
  }

  get type(): NodeEntryType {
    return NodeEntryType.DIRECTORY;
  }
}

export class RawPortRecordWrapper implements PortRecord {
  private readonly raw: RawPortRecord;
  private readonly style: ProjectStyleSelection;

  constructor(raw: RawPortRecord, style: ProjectStyleSelection) {
    this.raw = raw;
    this.style = style;
  }

  get alignment(): PortModelAlignment {
    return getStyleValue(this.style, this.raw.alignment);
  }

  get name(): string {
    return this.raw.name;
  }

  get fields(): FieldRecord[] {
    return this.raw.fields;
  }

  get x(): number {
    return getStyleValue(this.style, this.raw.x);
  }

  get y(): number {
    return getStyleValue(this.style, this.raw.y);
  }
}

class MockPropertiesDirectory<T extends PropertiesDirectoryEntry> implements PropertiesDirectory<T> {
  private readonly properties: T[];

  constructor(properties: T[]) {
    this.properties = properties;
  }

  getAll(): T[] {
    return this.properties;
  }

  getEntry(directoryId: string): T {
    return this.properties.find((entry) => entry.id === directoryId)!;
  }
}

const windingTypeDirectory = new MockPropertiesDirectory(WindingTypeDirectory);
const voltageLevelDirectory = new MockPropertiesDirectory(VoltageLevelDirectory as VoltageLevelDirectoryEntry[]);
const accuracyClassDirectory = new MockPropertiesDirectory(AccuracyClassDirectory);
const ratedCurrentDirectory = new MockPropertiesDirectory(RatedCurrentDirectory);
const powerFlowDirectory = new MockPropertiesDirectory(PowerFlowDirectory);
const logicNodeDirectory = new MockPropertiesDirectory(LogicNodeDirectory as LogicNodeDirectoryEntry[]);
const logicDeviceDirectory = new MockPropertiesDirectory(LogicDeviceDirectory as LogicDeviceDirectoryEntry[]);
const controllerDirectory = new MockPropertiesDirectory<ControllerDirectoryEntry>(
  ControllerDirectory as ControllerDirectoryEntry[]
);
const setOfRulesDirectory = new MockPropertiesDirectory<SetOfRulesDirectoryEntry>(
  SetOfRulesDirectory as SetOfRulesDirectoryEntry[]
);
const pacsArchDirectory = new MockPropertiesDirectory(PACSArchDirectory);
const controllerVendorDirectory = new MockPropertiesDirectory(ControllerVendorDirectory);
const stationLevelDirectory = new MockPropertiesDirectory<StationLevelDirectoryEntry>(
  StationLevelDirectory as StationLevelDirectoryEntry[]
);
const switchVendorDirectory = new MockPropertiesDirectory(SwitchVendorDirectory);
const switchDirectory = new MockPropertiesDirectory<SwitchDirectoryEntry>(SwitchDirectory as SwitchDirectoryEntry[]);
const networkDeviceTypeDirectory = new MockPropertiesDirectory<NetworkDeviceTypeDirectoryEntry>(
  NetworkDeviceTypeDirectory
);
const networkDeviceVendorDirectory = new MockPropertiesDirectory(NetworkDeviceVendorDirectory);
const networkDeviceDirectory = new MockPropertiesDirectory<NetworkDeviceDirectoryEntry>(
  NetworkDeviceDirectory as NetworkDeviceDirectoryEntry[]
);

const ruNodeDirectory = new MockNodeDirectoryWrapper('RU');
const euNodeDirectory = new MockNodeDirectoryWrapper('EU');

const getDirectory = (directoryName: PropertiesDirectoryName): PropertiesDirectory<PropertiesDirectoryEntry> => {
  switch (directoryName) {
    case 'WindingTypeDirectory': {
      return windingTypeDirectory;
    }
    case 'VoltageLevelDirectory': {
      return voltageLevelDirectory;
    }
    case 'AccuracyClassDirectory': {
      return accuracyClassDirectory;
    }
    case 'RatedCurrentDirectory': {
      return ratedCurrentDirectory;
    }
    case 'PowerFlowDirectory': {
      return powerFlowDirectory;
    }
    case 'LogicNodeDirectory': {
      return logicNodeDirectory;
    }
    case 'LogicDeviceDirectory': {
      return logicDeviceDirectory;
    }
    case 'ControllerDirectory': {
      return controllerDirectory;
    }
    case 'SetOfRulesDirectory': {
      return setOfRulesDirectory;
    }
    case 'ControllerVendorDirectory': {
      return controllerVendorDirectory;
    }
    case 'PACSArchDirectory': {
      return pacsArchDirectory;
    }
    case 'StationLevelDirectory': {
      return stationLevelDirectory;
    }
    case 'SwitchVendorDirectory': {
      return switchVendorDirectory;
    }
    case 'SwitchDirectory': {
      return switchDirectory;
    }
    case 'NetworkDeviceTypeDirectory': {
      return networkDeviceTypeDirectory;
    }
    case 'NetworkDeviceVendorDirectory': {
      return networkDeviceVendorDirectory;
    }
    case 'NetworkDeviceDirectory': {
      return networkDeviceDirectory;
    }
  }
  throw new Error(`Unsupported directory: ${directoryName}`);
};

const getNodeDirectory = (style: ProjectStyleSelection) => {
  return style === 'RU' ? ruNodeDirectory : euNodeDirectory;
};

export const useDirectory = () => {
  return {
    windingTypeDirectory,
    voltageLevelDirectory,
    accuracyClassDirectory,
    ratedCurrentDirectory,
    powerFlowDirectory,
    logicNodeDirectory,
    logicDeviceDirectory,
    controllerDirectory,
    setOfRulesDirectory,
    pacsArchDirectory,
    controllerVendorDirectory,
    stationLevelDirectory,
    switchVendorDirectory,
    switchDirectory,
    networkDeviceVendorDirectory,
    networkDeviceTypeDirectory,
    networkDeviceDirectory,
    getDirectory,
    getNodeDirectory,
  };
};
