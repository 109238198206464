import { DefaultPortDependantPointsCount, Path } from '../Path';
import { Point } from '@projectstorm/geometry';
import { NormalizingAngledPath } from './NormalizingAngledPath';
import { AngledPath, PreferredSide } from './AngledPath';
import { BasePoint, RestrictedDirectionPoint } from '../../../../geometry/Point';

export class SidedAngledPath implements Path {
  private readonly oldPoints: Point[];
  private readonly verticalOrientedOrigin: Path;
  private readonly horizontalOrientedOrigin: NormalizingAngledPath;

  constructor(oldPoints: Point[]) {
    this.oldPoints = oldPoints;
    this.verticalOrientedOrigin = new NormalizingAngledPath(new AngledPath(PreferredSide.TopLeft));
    this.horizontalOrientedOrigin = new NormalizingAngledPath(new AngledPath());
  }

  getPoints(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): BasePoint[] {
    const defaultResult = this.horizontalOrientedOrigin.getPoints(start, end);

    if (
      this.oldPoints.length !== DefaultPortDependantPointsCount ||
      defaultResult.length !== DefaultPortDependantPointsCount
    ) {
      return defaultResult;
    }
    const additionalResult = this.verticalOrientedOrigin.getPoints(start, end);
    if (additionalResult.length !== defaultResult.length) {
      return defaultResult;
    }

    const floatingPoint = this.oldPoints[this.oldPoints.length - 1];
    const resultFloatingPointIndex = 1;
    return this.getRangeBetweenPoints(additionalResult[resultFloatingPointIndex], floatingPoint) <
      this.getRangeBetweenPoints(defaultResult[resultFloatingPointIndex], floatingPoint)
      ? additionalResult
      : defaultResult;
  }

  private getRangeBetweenPoints(a: Point, b: Point) {
    return Math.sqrt(Math.pow(Math.abs(a.x - b.x), 2) + Math.pow(Math.abs(a.y - b.y), 2));
  }
}
