import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { ControllerDirectoryEntry, PropertiesDirectory } from '../../directory/PropertiesDirectory';
import { ControllerModel, ControllerModelType } from './ControllerModel';
import { ScdEngine } from '../ScdEngine';

export type GenerateModelEvent<T extends { serialize(): {} }> = { initialConfig: ReturnType<T['serialize']> };

export class ControllerFactory extends AbstractReactFactory<ControllerModel, ScdEngine> {
  private controllerDirectory: PropertiesDirectory<ControllerDirectoryEntry>;

  constructor(directory: PropertiesDirectory<ControllerDirectoryEntry>) {
    super(ControllerModelType);
    this.controllerDirectory = directory;
  }

  generateModel(event: GenerateModelEvent<ControllerModel>): ControllerModel {
    const data = event.initialConfig;
    return new ControllerModel(this.controllerDirectory.getEntry(data.directoryId), data.projectName);
  }

  generateReactWidget(event: GenerateWidgetEvent<ControllerModel>): JSX.Element {
    return <></>;
  }
}
