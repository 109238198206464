import styled from 'styled-components';
import { Styled as StyledToolbar } from '../../toolbar/Toolbar.styled';
import SimpleBar from 'simplebar-react';

const ExportPage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${StyledToolbar.Toolbar} {
    box-shadow: none;
  }
`;

const Scroll = styled(SimpleBar)`
  display: block;
  width: 100%;
  height: ${(props) => `calc(100vh - 2*${props.theme.editor.headerHeight} )`};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Content = styled.div`
  display: flex;
  align-items: stretch;
  gap: 24px;
  padding: 48px 75px 40px 95px;
`;

const Cards = styled.div`
  flex: 1 1 570px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 19px;
`;

export const Styled = {
  ExportPage,
  Scroll,
  Content,
  Cards,
};
