import { Graph, LinkSet, NodeSet } from '../../auto-layout/graph/Graph';
import Path from 'ngraph.path';
import { Node } from '../../auto-layout/node/Node';
import { BaseObserver, ListenerHandle } from '@projectstorm/react-canvas-core';
import { Link } from '../../auto-layout/Link';
import { Factory } from '../../../../utils/factory';
import createGraph, { NodeId } from 'ngraph.graph';
import { getDistance } from '../link/graph/LanLinkGraph';

export interface WaypointGraphContainer<NODE_TYPE> {
  getLinkWaypointGraph(): WaypointGraph<NODE_TYPE>;
}

export interface WaypointGraph<NODE_TYPE> extends NodeSet, LinkSet, Path.PathFinder<Node> {
  getNodeType(node: Node): NODE_TYPE;

  registerListener(onChange: () => void): ListenerHandle;
}

export class DefaultWaypointGraph<NODE_TYPE> implements WaypointGraph<NODE_TYPE> {
  private readonly origin: Graph;
  private readonly nodeTypeFactory: Factory<Node, NODE_TYPE>;
  private readonly eventDelegate: BaseObserver;
  private readonly path: Path.PathFinder<Node>;

  constructor(origin: Graph, nodeTypeFactory: Factory<Node, NODE_TYPE>, eventDelegate: BaseObserver) {
    this.origin = origin;
    this.nodeTypeFactory = nodeTypeFactory;
    this.eventDelegate = eventDelegate;
    const nodes = origin.getNodes();
    const links = origin.getLinks();
    const pathGraph = createGraph<Node, Link>({ multigraph: true });
    nodes.forEach((node) => pathGraph.addNode(node.getID(), node));
    links.forEach((link) => pathGraph.addLink(link.getSourceNode().getID(), link.getTargetNode().getID(), link));

    this.path = Path.aStar(pathGraph, {
      distance: (a, b) => getDistance(a.data.getRect().getTopLeft(), b.data.getRect().getTopLeft()),
      heuristic: (a, b) => getDistance(a.data.getRect().getTopLeft(), b.data.getRect().getTopLeft()),
    });
  }

  find(from: NodeId, to: NodeId) {
    return this.path.find(from, to);
  }

  getLinks(): Link[] {
    return this.origin.getLinks();
  }

  getNodeType(node: Node): NODE_TYPE {
    return this.nodeTypeFactory(node);
  }

  getNodes(): Node[] {
    return this.origin.getNodes();
  }

  registerListener(onChange: () => void): ListenerHandle {
    return this.eventDelegate.registerListener({ waypointsChanged: onChange } as any);
  }
}
