import { NgGraceModel } from '../NgGraceModel';
import { DiagramModelGenerics } from '@projectstorm/react-diagrams';
import { BaseModel, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { BuildingLayerModel } from './building/layer/BuildingLayerModel';
import { RackLayerModel } from './rack/layer/RackLayerModel';
import { ZoneLayerModel } from './zone/layer/ZoneLayerModel';
import { LanNodeLayerModel } from './node/layer/LanNodeLayerModel';
import { LanLinkLayerModel } from './link/layer/LanLinkLayerModel';
import { NgGraceEngine } from '../NgGraceEngine';

export class LanModel extends NgGraceModel {
  private buildingLayer: BuildingLayerModel;
  private rackLayer: RackLayerModel;
  private zoneLayer: ZoneLayerModel;
  private restLayers: ReturnType<LanModel['serialize']>['layers'] = [];

  constructor(options?: DiagramModelGenerics['OPTIONS']) {
    super(options);
    this.layers = [];
    this.zoneLayer = new ZoneLayerModel();
    this.rackLayer = new RackLayerModel();
    this.buildingLayer = new BuildingLayerModel();
    this.addLayer(this.zoneLayer);
    this.addLayer(new LanLinkLayerModel());
    this.addLayer(new LanNodeLayerModel());
    this.addLayer(this.buildingLayer);
    this.addLayer(this.rackLayer);
  }

  deserialize(event: DeserializeEvent<this>) {
    const acceptableLayerTypes = this.layers.map((layer) => layer.getType());
    this.restLayers.push(...event.data.layers.filter((layer) => acceptableLayerTypes.indexOf(layer.type) === -1));
    event.data.layers = event.data.layers.filter((layer) => acceptableLayerTypes.indexOf(layer.type) !== -1);
    super.deserialize(event);

    const deserializedZoneLayer = this.getLayers().find(
      (layer): layer is ZoneLayerModel => layer instanceof ZoneLayerModel
    );

    if (deserializedZoneLayer) {
      this.zoneLayer = deserializedZoneLayer;
    } else {
      this.zoneLayer = new ZoneLayerModel();
      this.addLayer(this.zoneLayer);
    }
    this.zoneLayer.registerModels(event);

    const deserializedBuildingLayer = this.getLayers().find(
      (layer): layer is BuildingLayerModel => layer instanceof BuildingLayerModel
    );

    if (deserializedBuildingLayer) {
      this.buildingLayer = deserializedBuildingLayer;
    } else {
      this.buildingLayer = new BuildingLayerModel();
      this.addLayer(this.buildingLayer);
    }

    const deserializedRacksLayer = this.getLayers().find(
      (layer): layer is RackLayerModel => layer instanceof RackLayerModel
    );

    if (deserializedRacksLayer) {
      this.rackLayer = deserializedRacksLayer;
    } else {
      this.rackLayer = new RackLayerModel();
      this.addLayer(this.rackLayer);
    }

    const deserializedNodeLayer = this.getLayers().find(
      (layer): layer is LanNodeLayerModel => layer instanceof LanNodeLayerModel
    );

    if (!deserializedNodeLayer) {
      this.addLayer(new LanNodeLayerModel());
    }

    const deserializedLinkLayer = this.getLayers().find(
      (layer): layer is LanLinkLayerModel => layer instanceof LanLinkLayerModel
    );

    if (!deserializedLinkLayer) {
      this.addLayer(new LanLinkLayerModel());
    }

    const linkLayer = this.getActiveLinkLayer();
    this.removeLayer(linkLayer);
    this.addLayer(linkLayer);
    const nodeLayer = this.getActiveNodeLayer();
    this.removeLayer(nodeLayer);
    this.addLayer(nodeLayer);
  }

  generateModel() {
    return new LanModel();
  }

  serialize() {
    const result = super.serialize();
    result.layers.push(...this.restLayers);
    return result;
  }

  getBuildingLayer() {
    return this.buildingLayer;
  }

  getZoneLayer() {
    return this.zoneLayer;
  }

  getRackLayer() {
    return this.rackLayer;
  }

  async deserializeModel(data: ReturnType<this['serialize']>, engine: NgGraceEngine) {
    const models: {
      [id: string]: BaseModel;
    } = {};
    const promises: {
      [id: string]: Promise<BaseModel>;
    } = {};
    const resolvers: {
      [id: string]: (model: BaseModel) => any;
    } = {};

    const event: DeserializeEvent = {
      data: this.fillDefaultsData(data),
      engine: engine,
      registerModel: (model: BaseModel) => {
        models[model.getID()!] = model;
        if (resolvers[model.getID()!]) {
          resolvers[model.getID()!](model);
        }
      },
      getModel<T extends BaseModel>(id: string): Promise<T> {
        if (models[id]) {
          return Promise.resolve(models[id]) as Promise<T>;
        }
        if (!promises[id]) {
          promises[id] = new Promise((resolve) => {
            resolvers[id] = resolve;
          });
        }
        return promises[id] as Promise<T>;
      },
    };
    this.deserialize(event);

    return Promise.all(Object.values(promises)).then(() => Promise.resolve());
  }
}
