import { Graph } from './Graph';
import { Link } from '../Link';
import { Node } from '../node/Node';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { TranslatedNode } from '../node/TranslatedNode';
import { BasePoint } from '../../geometry/Point';
import { Mapping } from '../mapping/Mapping';

export class ForestGraph implements Graph {
  private roots: Graph;
  private rootWithChildren: Graph;
  private treeMapping: Mapping<{ root: Node; rootWithChildren: Node[] }, Graph>;

  constructor(
    roots: Graph,
    rootWithChildren: Graph,
    treeMapping: Mapping<{ root: Node; rootWithChildren: Node[] }, Graph>
  ) {
    this.roots = roots;
    this.rootWithChildren = rootWithChildren;
    this.treeMapping = treeMapping;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const roots = this.roots.getNodes();
    let xTreeOffset = 0;
    const xOffsetBetweenNodes = 75;
    return roots
      .map((neighbour) => this.treeMapping.map({ root: neighbour, rootWithChildren: roots }))
      .flatMap((tree) => {
        const result = tree.getNodes().map((node) => new TranslatedNode(node, new BasePoint(xTreeOffset, 0)));
        xTreeOffset += tree.getRect().getWidth() + xOffsetBetweenNodes;
        return result;
      });
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}
