import { HasSize } from '../../placeholder/HasSize';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasChildren } from '../../placeholder/HasChildren';
import { BasePoint } from '../../geometry/Point';

import { Polygon, Rectangle } from '@projectstorm/geometry';
import { LanNodeModel } from '../node/LanNodeModel';
import { HasRelativeModel } from '../../placeholder/HasRelativeModel';
import { onceForLoop } from '../../FunctionDecorators';

export const TopRackPadding = 30;
export const RackPadding = 80;

export class RackHasSize implements HasSize {
  private readonly hasChildren: HasChildren<LanNodeModel>;
  private readonly startOffsetX: number;

  constructor(
    eventDelegate: BaseObserver,
    hasChildren: HasChildren<LanNodeModel>,
    hasRelativeModel: HasRelativeModel<BaseObserver>,
    startOffsetX: number
  ) {
    this.hasChildren = hasChildren;
    this.startOffsetX = startOffsetX;
    const childrenListener = () => {
      eventDelegate.fireEvent({}, 'sizeChanged');
      hasRelativeModel.getRelativeModel()?.fireEvent({}, 'childrenChanged');
    };

    eventDelegate.registerListener({
      childrenChanged: onceForLoop(() => {
        //onRemove we have to wait for the children to update their positions(sync)
        //and the only way to ensure that is to run in a next task
        Promise.resolve().then(childrenListener);
      }),
    });
  }

  getSize(): BasePoint {
    const children = this.hasChildren.getChildren();
    if (!children.length && this.startOffsetX) {
      return new BasePoint(0, 0);
    }
    const childrenRect = Polygon.boundingBoxFromPolygons(
      children.map((child) => {
        const position = child.getRotatedPosition();
        const size = child.getRotatedSize();
        return new Rectangle(position, size.x, size.y);
      })
    );

    const horizontalPadding = RackPadding * 2 + this.startOffsetX;
    const verticalPadding = RackPadding + TopRackPadding;
    return new BasePoint(childrenRect.getWidth() + horizontalPadding, childrenRect.getHeight() + verticalPadding);
  }
}
