import * as React from 'react';
import { ConnectivityNodeModel, ConnectivityNodeSize } from '../../../connectivity/ConnectivityNodeModel';

interface ExportConnectivityNodeProps {
  node: ConnectivityNodeModel;
}

export const ExportConnectivityNode: React.FC<ExportConnectivityNodeProps> = ({ node }) => {
  const radius = ConnectivityNodeSize / 2;
  const position = node.getPositionToRender();
  return (
    <>
      <circle cx={position.x + radius} cy={position.y + radius} r={radius} fill={'black'} />
    </>
  );
};
