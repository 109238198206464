import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import { DirectoryNodeFactory } from '../../../directory/DirectoryNodeFactory';
import { ControllerPlaceholder } from '../../controller/ControllerPlaceholder';
import { DirectoryNodeWithPlaceholdersModel } from '../../../directory/DirectoryNodeWithPlaceholdersModel';
import { StageType } from '../../../../../api/nggrace-back';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';

export class ScdDirectoryNodeFactory extends DirectoryNodeFactory {
  private stage: StageType = 'SCD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new DirectoryNodeWithPlaceholdersModel<ControllerPlaceholder>(
      this.directory.getEntry(event.initialConfig.payload.directoryEntryId),
      (node) => new DefaultNodeWithPlaceholders<ControllerPlaceholder>(node, node, this.stage, event.initialConfig.SCD)
    );
  }
}
