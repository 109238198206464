import { Node, NodeType } from './Node';
import { SmartRotationHour } from '../../geometry/RotationHour';
import { Rectangle } from '@projectstorm/geometry';

export class CachingRectNode implements Node {
  private origin: Node;
  private cachedRect?: Rectangle;

  constructor(origin: Node) {
    this.origin = origin;
  }

  getHour(): SmartRotationHour {
    return this.origin.getHour();
  }

  getID(): string {
    return this.origin.getID();
  }

  getRect(): Rectangle {
    return this.cachedRect ? this.cachedRect : (this.cachedRect = this.origin.getRect());
  }

  getType(): NodeType {
    return this.origin.getType();
  }
}
