import {
  LayerModelGenerics,
  LayerModel,
  FactoryBank,
  DeserializeEvent,
  Toolkit,
} from '@projectstorm/react-canvas-core';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory';
import { LanModel } from '../../LanModel';
import { LanEngine } from '../../LanEngine';
import { RackModel } from '../Rack';
import { LayerListener } from '../../building/layer/BuildingLayerModel';

export const RackLayerModelType = 'rack-layer';

export interface RackLayerModelGenerics extends LayerModelGenerics {
  PARENT: LanModel;
  ENGINE: LanEngine;
  CHILDREN: RackModel;
  LISTENER: LayerListener;
}

export class RackLayerModel extends LayerModel<RackLayerModelGenerics> {
  constructor(options: RackLayerModelGenerics['OPTIONS'] = {}) {
    super({ ...options, type: RackLayerModelType, transformed: true });
    this.models = {};
    this.repaintEnabled = true;
  }

  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions, id: event.data.id || Toolkit.UID() };
  }

  getChildModelFactoryBank(engine: RackLayerModelGenerics['ENGINE']) {
    return (engine.getRackFactories() as unknown) as FactoryBank<AbstractModelFactory>; // typing shit again
  }

  addModel(model: RackLayerModelGenerics['CHILDREN']) {
    super.addModel(model);
    model.registerListener({
      entityRemoved: () => {
        this.removeModel(model);
        this.fireEvent({ model }, 'modelRemoved');
      },
    });

    this.fireEvent({ model }, 'modelAdded');
  }
}
