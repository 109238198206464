import React, { useCallback } from 'react';
import { Styled as S } from './ExportCard.styled';
import { Icon } from '../../widgets/Icon';
import { ExportFormType } from '../../../api/nggrace-back';

type ExportCardProps = {
  title: string;
  type: ExportFormType;
  activeType?: ExportFormType;
  subtitle?: string;
  completed?: boolean;
  disabled?: boolean;

  onClick(type: ExportFormType): void;
};

const types: ExportFormType[] = ['SLD', 'SSD', 'SCD', 'LAN', 'GENERAL', 'CABLE', 'TCO'];

export const ExportCard: React.FC<ExportCardProps> = ({
  title,
  type,
  subtitle,
  activeType,
  completed,
  disabled,
  onClick,
}) => {
  const handleClick = useCallback(() => onClick && onClick(type), [onClick, type]);
  const active = activeType === type;
  return (
    <S.ExportCard disabled={disabled} active={active} onClick={handleClick}>
      <S.StageIndicator active={active}>{types.indexOf(type) + 1}</S.StageIndicator>
      <S.Content>
        <S.Title>{title}</S.Title>
        {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      </S.Content>
      <S.ExportIndicator active={active} completed={!disabled && completed}>
        <Icon name={'checkmark'} />
      </S.ExportIndicator>
    </S.ExportCard>
  );
};
