import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { LogicDeviceLayerModel, LogicDeviceLayerModelType } from './LogicDeviceLayerModel';
import { SsdEngine } from '../../SsdEngine';
import { LogicDeviceLayerWidget } from './LogicDeviceLayerWidget';

export class LogicDeviceLayerFactory extends AbstractReactFactory<LogicDeviceLayerModel, SsdEngine> {
  constructor() {
    super(LogicDeviceLayerModelType);
  }

  generateModel(event: GenerateModelEvent): LogicDeviceLayerModel {
    return new LogicDeviceLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<LogicDeviceLayerModel>): JSX.Element {
    return <LogicDeviceLayerWidget layer={event.model} engine={this.engine} />;
  }
}
