import { Graph } from './Graph';
import { Link } from '../Link';
import { Node } from '../node/Node';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { TranslatedNode } from '../node/TranslatedNode';
import { BasePoint } from '../../geometry/Point';
import { Mapping, PathMapping } from '../mapping/Mapping';

export class TreeGraph implements Graph {
  private root: Node;
  private environment: Graph;
  private orderedNodeMapping: Mapping<{ node: Node; previousNode: Node }, Node>;
  private trunkMapping: Mapping<Node[][], Node[]>;
  private alignedNodesMapping: Mapping<Node[], Node[]>;
  private pathMapping: Mapping<{ start: Node; end: Node }, Graph>;

  constructor(
    treeRoot: Node,
    environment: Graph,
    orderedNodeMapping: Mapping<{ node: Node; previousNode: Node }, Node>,
    trunkMapping: Mapping<Node[][], Node[]>,
    alignedNodesMapping: Mapping<Node[], Node[]>,
    pathMapping: PathMapping
  ) {
    this.root = treeRoot;
    this.environment = environment;
    this.orderedNodeMapping = orderedNodeMapping;
    this.trunkMapping = trunkMapping;
    this.alignedNodesMapping = alignedNodesMapping;
    this.pathMapping = pathMapping;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    const paths = this.getPathsToLeafChildren();
    const trunk = this.trunkMapping.map(paths);
    const comparator = new TrunkBasedComparator(trunk);
    paths.sort((a, b) => comparator.compare(a, b));
    const longestPathLength = Math.max(...paths.map((path) => path.length));
    const longestPath = paths.find((path) => path.length === longestPathLength)!;

    const layers: PathNode[][] = longestPath
      .map((_, index) =>
        paths
          .filter((path) => path.length > index)
          .map((path) => {
            const node = path[index];
            const previousNode = path[index - 1];
            return { node: this.orderedNodeMapping.map({ node, previousNode }), minIndex: paths.indexOf(path) };
          })
      )
      .map((layer) =>
        layer.filter((node, index) => {
          return layer.findIndex((mbSimilarNode) => mbSimilarNode.node.getID() === node.node.getID()) === index;
        })
      );

    const xPathOffset = 100;
    const yLayerOffset = 80;
    const layeredNodes = layers.flatMap((layer, layerIndex) =>
      layer.map((pathNode, index) => {
        const resultIndex = Math.max(index, pathNode.minIndex);
        return new TranslatedNode(pathNode.node, new BasePoint(resultIndex * xPathOffset, layerIndex * yLayerOffset));
      })
    );

    return paths.flatMap((path) => {
      const layeredPath = path.map((node) => layeredNodes.find((layeredNode) => node.getID() === layeredNode.getID())!);
      return this.alignedNodesMapping.map(layeredPath);
    });
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }

  private getPathsToLeafChildren() {
    const children = this.environment.getNodes().filter((node) => node.getID() !== this.root.getID());
    let paths = children
      .map((child) => this.pathMapping.map({ start: this.root, end: child }).getNodes())
      .filter((path) => path.length > 0)
      .filter((path, index, array) => {
        const last = path[path.length - 1];
        return !array.find(
          (otherPath) => otherPath.length > path.length && otherPath.find((node) => node.getID() === last.getID())
        );
      });

    return paths.length === 0 ? [[this.root]] : paths;
  }
}

type PathNode = { node: Node; minIndex: number };

class TrunkBasedComparator {
  private trunk: Node[];

  constructor(trunk: Node[]) {
    this.trunk = trunk;
  }

  compare(a: Node[], b: Node[]): number {
    const getTrunkIntersectionLength = (path: Node[]) =>
      path.filter((pathNode) => this.trunk.find((trunkNode) => trunkNode.getID() === pathNode.getID())).length;

    const aIntLength = getTrunkIntersectionLength(a);
    const bIntLength = getTrunkIntersectionLength(b);
    const trunkIntersectionBasedResult = aIntLength === bIntLength ? 0 : aIntLength > bIntLength ? -1 : 1;
    if (trunkIntersectionBasedResult !== 0) {
      return trunkIntersectionBasedResult;
    }

    return a.length === b.length ? 0 : a.length > b.length ? -1 : 1;
  }
}
