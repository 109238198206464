import { SmartLinkModel } from './SmartLinkModel';
import { SmartLinkPointModel } from '../../point/SmartLinkPointModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { AppendingConnectingPath, DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { PortEndedPath } from './path/default/PortEndedPath';
import { NormalizingAngledPath } from './path/default/NormalizingAngledPath';
import { AngledPath } from './path/default/AngledPath';
import { RestrictedDirectionPoint } from '../../geometry/Point';
import { RemovingConnectingPath } from './path/connecting/RemovingConnectingPath';

export class EnterDividedSmartLinkModel extends SmartLinkModel {
  constructor(
    existingPoints: SmartLinkPointModel[],
    sourcePort: ConnectablePortModel,
    targetPort: ConnectablePortModel
  ) {
    super(sourcePort, targetPort, existingPoints);

    const lastExistingIndex = existingPoints.length - 1;
    const restoredPath = new UpdatingDependenciesConnectingPath(
      new RemovingConnectingPath(
        new AppendingConnectingPath(
          new DefaultConnectingPath(new PortEndedPath(new NormalizingAngledPath(new AngledPath()), targetPort), this),
          existingPoints.slice(0, lastExistingIndex)
        )
      )
    ).connect(
      new RestrictedDirectionPoint(existingPoints[lastExistingIndex].getPosition()),
      new RestrictedDirectionPoint(targetPort.getCenter())
    );
    this.setPoints(restoredPath);
  }
}
