import styled, { css } from 'styled-components';
import { Icon } from './Icon.styled';
import { ToastType } from './Toast';

const Toast = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  width: 335px;
  color: ${(props) => props.color};

  border: 1px solid ${(props) => props.color};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  padding: 16px 16px 24px 54px;
  margin-bottom: 16px;
`;

const Title = styled.div<{ type?: ToastType }>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;

  ${Icon} {
    height: 24px;
    width: 24px;
    position: absolute;
    left: 16px;
  }

  ${(props) =>
    props.type === 'SUCCESS' &&
    css`
      ${Icon} {
        border-radius: 50%;
        background-color: ${(prop) => prop.theme.colors.turquoise}66;
        border: 1px solid ${(props) => props.theme.colors.green}66;
        color: ${(prop) => prop.theme.colors.white};
        padding: 4px;
      }
    `};
`;

const Text = styled.div`
  margin-top: 6px;
  font-size: 12px;
`;

export const Styled = {
  Toast,
  Title,
  Text,
};
