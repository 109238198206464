import * as React from 'react';
import { ExportFormDto } from '../../../../../api/nggrace-back';
import { formatFrameDateEu } from '../../../../../utils/date-utils';
import { SvgOptimizer } from '../../SvgOptimizer';
import { IProjectExport } from '../../types';

interface ExportFrameProps {
  dto: ExportFormDto;
  project: IProjectExport;
  format?: string;
}

export const FrameTableEu = ({ dto, project, format }: ExportFrameProps) => {
  return (
    <g>
      <FrameLines />
      <FrameContent dto={dto} project={project} format={format} />
    </g>
  );
};

const FrameContent = ({ dto, project, format }: ExportFrameProps) => {
  const svgOptimizer = new SvgOptimizer();

  const date = dto.createdAt ? formatFrameDateEu(dto.createdAt) : '';
  return (
    <g style={{ fontFamily: 'GOSTRUS', fill: '#000000' }}>
      {dto.logo && (
        <g
          transform={`translate(137.3768,15.0493)`}
          dangerouslySetInnerHTML={{ __html: svgOptimizer.optimize(dto.logo) }}
        />
      )}
      <g style={{ fontSize: '4.25px' }} textAnchor="middle">
        <text x="39.23025" y="22.6001">
          {project.customerCompany}
        </text>
        {dto.companySecondLine?.trim() === '' ? (
          <text x="107.5212" y="22.6001" dominantBaseline="central">
            {dto.company}
          </text>
        ) : (
          <>
            <text x="107.5212" y="22.1001">
              {dto.company}
            </text>
            <text x="107.5212" y="22.6001" dominantBaseline="hanging">
              {dto.companySecondLine}
            </text>
          </>
        )}
        <text x="89.85655" y="34.94895" dominantBaseline="central">
          {project.stationName}
        </text>
        {dto.titleSecondLine?.trim() === '' ? (
          <text x="89.85655" y="45.7378" dominantBaseline="central">
            {dto.title}
          </text>
        ) : (
          <>
            <text x="89.85655" y="45.2378">
              {dto.title}
            </text>
            <text x="89.85655" y="45.7378" dominantBaseline="hanging">
              {dto.titleSecondLine}
            </text>
          </>
        )}
      </g>
      <g style={{ fontSize: '3px' }} textAnchor="middle" dominantBaseline="central">
        <text x="7.18855" y="3.55955">
          {dto.revision}
        </text>
        <text x="7.18855" y="10.3798">
          Revision
        </text>
        <text x="24.86285" y="3.55955">
          {date}
        </text>
        <text x="24.86285" y="10.3798">
          Date
        </text>
        <text x="42.63695" y="3.55955">
          {dto.status}
        </text>
        <text x="42.63695" y="10.3798">
          Status
        </text>
        <text x="93.2536" y="3.55955">
          {dto.description}
        </text>
        <text x="93.2536" y="10.3798">
          Description
        </text>
        <text x="143.77045" y="3.55955">
          {dto.developedBy}
        </text>
        <text x="143.77045" y="10.3798" dy="-1.64584">
          Prepared
        </text>
        <text x="143.77045" y="10.3798" dy="1.64584">
          by
        </text>
        <text x="158.1475" y="3.55955">
          {dto.verifiedBy}
        </text>
        <text x="158.1475" y="10.3798" dy="-1.64584">
          Checked
        </text>
        <text x="158.1475" y="10.3798" dy="1.64584">
          by
        </text>
        <text x="172.52455" y="3.55955">
          {dto.contractNumber}
        </text>
        <text x="172.52455" y="10.3798" dy="-1.64584">
          Approved
        </text>
        <text x="172.52455" y="10.3798" dy="1.64584">
          by
        </text>

        <text x="0.7949" y="40.247" textAnchor="start" dominantBaseline="text-before-edge">
          Document Title:
        </text>

        <text x="0.7949" y="55.42345" textAnchor="start">
          COMPANY Document Number
        </text>
        <text x="0.7949" y="61.81315" textAnchor="start">
          CONTRACTOR Document Number
        </text>
        <text x="41.7345" y="55.42345" textAnchor="start">
          {dto.code}
        </text>
        <text x="41.7345" y="61.81315" textAnchor="start">
          {dto.contractorDocumentNumber}
        </text>
        <text x="110.61255" y="55.42345">
          Rev. {dto.revision}
        </text>
        <text x="110.61255" y="61.81315">
          Format: {format}
        </text>
        <text x="142.5653" y="55.42345">
          Scale:
        </text>
        <text x="142.5653" y="61.81315">
          Sheet: 01
        </text>
        <text x="170.0252" y="58.6183" dominantBaseline="text-after-edge">
          Class
        </text>
      </g>
    </g>
  );
};

const FrameLines = () => {
  return (
    <g style={{ stroke: '#000000', strokeWidth: 0.26458332 }}>
      <path d="M 0.2949 0.9088 V 65.008" />
      <path d="M 14.672 0.9088 V 14.5493" />
      <path d="M 35.6435 0.9088 V 14.5493" />
      <path d="M 50.2202 0.9088 V 14.5493" />
      <path d="M 136.8768 0.9088 V 30.6509" />
      <path d="M 151.2539 0.9088 V 14.5493" />
      <path d="M 165.6309 0.9088 V 14.5493" />

      <path d="M 78.1656 14.5493 V 30.6509" />
      <path d="M 41.2345 52.2286 V 65.008" />
      <path d="M 96.1369 52.2286 V 65.008" />
      <path d="M 125.0882 52.2286 V 65.008" />
      <path d="M 160.0424 52.2286 V 65.008" />

      <path d="M 0.2949 0.9088 H 180.008" />
      <path d="M 0.2949 6.2103 H 180.008" />
      <path d="M 0.2949 14.5493 H 180.008" />
      <path d="M 0.2949 30.6509 H 180.008" />
      <path d="M 0.2949 39.247 H 180.008" />
      <path d="M 0.2949 52.2286 H 180.008" />
      <path d="M 0.2949 58.6183 H 160.0424" />
    </g>
  );
};
