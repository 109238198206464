import React from 'react';
import { Styled as S } from '../library/LibraryTree.styled';

interface LibraryTreeLeafNodeProps {
  indentLevel: number;
  last: boolean;
  hasParent?: boolean;
}

export const LibraryTreeLeafNode: React.FC<LibraryTreeLeafNodeProps> = ({
  indentLevel,
  last,
  hasParent = true,
  children,
}) => {
  return (
    <S.TreeNode indentLevel={indentLevel}>
      <S.Header highlight={false} canHighlight={false} hasParent={hasParent} last={last} indentLevel={indentLevel}>
        <S.Indent level={indentLevel} />
        <S.ZeroToggle hasParent={hasParent} indentLevel={indentLevel} />
        <S.LibraryTreeItem>{children}</S.LibraryTreeItem>
      </S.Header>
    </S.TreeNode>
  );
};
