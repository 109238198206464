import { ModelObserver, registerNodeListener } from '../NgGraceModelObserver';
import { LanModel } from './LanModel';
import { debounced } from '../FunctionDecorators';
import { isNgGraceLinkModel } from '../NgGraceModel';
import { BaseModelListener } from '@projectstorm/react-canvas-core';
import { RackModel } from './rack/Rack';
import { BuildingModel } from './building/BuildingModel';
import { RoomModel } from './building/RoomModel';

export class LanModelObserver implements ModelObserver {
  onChange = (model: LanModel, listener: () => void): (() => void) => {
    const debouncedListener = debounced(listener, 200);
    const handlers: { deregister: () => any }[] = [];

    handlers.push(
      // @ts-ignore
      model.registerListener({
        nodesUpdated: debouncedListener,
        linksUpdated: ({ link }) => {
          if (!isNgGraceLinkModel(link) || link.isPersistent()) {
            debouncedListener();
          }
        },
      }),
      model.getRackLayer().registerListener({
        modelAdded: debouncedListener,
        modelRemoved: debouncedListener,
      } as any),
      model.getBuildingLayer().registerListener({
        modelAdded: debouncedListener,
        modelRemoved: debouncedListener,
      } as any)
    );

    registerRackListener(
      model,
      {
        relativeModelChanged: debouncedListener,
      },
      handlers
    );

    registerBuildingListener(
      model,
      {
        childrenChanged: debouncedListener,
      },
      handlers
    );

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  };

  onSelectionChange = (model: LanModel, listener: () => void) => {
    const handlers: { deregister: () => any }[] = [];

    registerNodeListener(model, { selectionChanged: listener }, handlers);
    registerRackListener(model, { selectionChanged: listener }, handlers);
    registerBuildingListener(model, { selectionChanged: listener }, handlers);
    registerRoomListener(model, { selectionChanged: listener }, handlers);

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  };
}

export const registerRackListener = (
  model: LanModel,
  listener: BaseModelListener,
  handlers: { deregister: () => any }[]
) => {
  const addRackListener = (node: RackModel) => {
    handlers.push(node.registerListener(listener));
  };

  Object.values(model.getRackLayer().getModels()).forEach(addRackListener);

  // @ts-ignore
  model.getRackLayer().registerListener({
    modelAdded: ({ model }: { model: RackModel }) => {
      addRackListener(model);
    },
  } as any);
};

export const registerBuildingListener = (
  model: LanModel,
  listener: BaseModelListener,
  handlers: { deregister: () => any }[]
) => {
  const addBuildingListener = (node: BuildingModel) => {
    handlers.push(node.registerListener(listener));
  };

  Object.values(model.getBuildingLayer().getModels()).forEach(addBuildingListener);

  // @ts-ignore
  model.getBuildingLayer().registerListener({
    modelAdded: ({ model }: { model: BuildingModel }) => {
      addBuildingListener(model);
    },
  } as any);
};

export const registerRoomListener = (
  model: LanModel,
  listener: BaseModelListener,
  handlers: { deregister: () => any }[]
) => {
  const addRoomListener = (room: RoomModel) => {
    handlers.push(room.registerListener(listener));
  };

  Object.values(model.getBuildingLayer().getModels())
    .flatMap((building) => building.getChildren())
    .forEach(addRoomListener);
  registerBuildingListener(
    model,
    {
      childrenChanged: (event: { child: RoomModel; created: boolean }) => event.created && addRoomListener(event.child),
    } as any,
    handlers
  );
};
