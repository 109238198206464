import React from 'react';
import { Styled as S } from './Feedback.styled';
import { Icon } from './Icon';

export const Feedback: React.FC<{ error?: boolean; text: string }> = ({ error, text }) => {
  return (
    <S.Feedback error={error}>
      {error && <Icon name="error" />}
      {text}
    </S.Feedback>
  );
};
