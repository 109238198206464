const colors = {
  white: '#FFFFFF',
  lightgrey: '#F5F5F5',
  grey: '#D9D9D9',
  darkgrey: '#8F8F8F',
  purple: '#8F00FF',
  darkpurple: '#5C00A4',
  black: '#3A3A3A',
  dark: '#171717',
  blue: '#103C8B',
  red: '#E80404',
  orange: '#DF7C21',
  darkorange: '#FA8F2D80',
  turquoise: '#0CBAA1',
  darkturquoise: '#0D8372',
  green: '#124D54',
  solitude: '#F3F5F9',
  hoverTag: 'linear-gradient(0deg, rgba(16, 60, 139, 0.5), rgba(16, 60, 139, 0.5))',
};

const fonts = {
  primary: 'Mulish, Open Sans, sans-serif',
  onlyPrimary: 'Mulish, Open Sans',
  secondary: 'Nunito, sans-serif',
  gost: 'GostRus',
};

const modal = {
  overlay: {
    backgroundColor: '#00000099',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '20px',
    zIndex: 1000,
  },
  content: {
    backgroundColor: colors.white,
    opacity: 'none',
    border: 'none',
    borderRadius: '16px',
    position: 'unset' as const,
    padding: '0',
    margin: 'auto',
    zIndex: 1001,
    maxHeight: '100%',
    overflow: 'auto',
    boxShadow: '0px 0px 20px 10px rgba(63, 63, 63, 0.1)',
    display: 'flex',
  },
};

const modalInfo = {
  overlay: {
    ...modal.overlay,
    background: 'rgba(30, 34, 70, 0.5)',
    zIndex: 1001,
  },
  content: {
    ...modal.content,
    zIndex: 1002,
    border: '1px solid',
    borderColor: colors.blue,
    borderRadius: '8px',
    boxShadow: '0px 1px 10px 0px #00000033',
    padding: '32px 32px 32px 72px',
  },
};

const editor = {
  footerHeight: '32px',
  headerHeight: '72px',
};

export const theme = {
  colors,
  fonts,
  modal,
  modalInfo,
  editor,
};
