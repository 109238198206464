import React, { useCallback, useEffect, useRef } from 'react';

export const BlockPageScroll: React.FC = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const stopScroll = useCallback((e) => e.preventDefault(), []);

  useEffect(() => {
    if (scrollRef.current) {
      const scroll = scrollRef.current;
      scroll.addEventListener('wheel', stopScroll);
      return () => scroll.removeEventListener('wheel', stopScroll);
    }
  }, [stopScroll]);

  return (
    <div style={{ display: 'contents' }} ref={scrollRef}>
      {children}
    </div>
  );
};
