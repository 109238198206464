import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import {
  ExtendableTreeNode,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { HasNotExtendableParentModel, SelectableTreeBaseModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdModel, ScdNodeModel } from '../../../editor/scd/ScdModel';
import { ControllerModel } from '../../../editor/scd/controller/ControllerModel';
import { ControllerLayerModel } from '../../../editor/scd/layer/controller/ControllerLayerModel';
import { ScdPacsTreeLogicDeviceModelProps } from './ScdPacsTreeLogicDevice';

export const ScdPacsTreeControllerFactory = (
  engine: ScdEngine,
  model: ScdModel,
  logicDeviceFactory: Factory<ScdPacsTreeLogicDeviceModelProps, TreeNodeState>
): Factory<ScdPacsTreeControllerProps, TreeNodeState> => ({ controller, parent }) =>
  ScdPacsTreeController(controller, model.getControllerLayer(), parent, engine, logicDeviceFactory);

export interface ScdPacsTreeControllerProps {
  controller: ControllerModel;
  parent: ExtendableTreeNode;
}

const ScdPacsTreeController = (
  controller: ControllerModel,
  controllerLayer: ControllerLayerModel,
  parent: ExtendableTreeNode,
  engine: ScdEngine,
  logicDeviceFactory: Factory<ScdPacsTreeLogicDeviceModelProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableScdControllerNode(controller, controllerLayer);

  const logicDeviceMapping = (logicDevice: DirectoryLogicDeviceModel<ScdNodeModel>) =>
    logicDeviceFactory({ logicDevice, parent: extendable });

  const controllerModel = ScdPacsTreeControllerModel(controller, logicDeviceMapping);

  return {
    ...controllerModel,
    ...extendable,
    ...HasNotExtendableParentModel(),
    ...SelectableTreeBaseModel(controller, engine),
  };
};

const ScdPacsTreeControllerModel = (
  controller: ControllerModel,
  logicDeviceMapping: (node: DirectoryLogicDeviceModel<ScdNodeModel>) => TreeNodeState
): TreeNodeModel => {
  return {
    getName: () => ControllerNameBuilder(controller.getCodeName(), controller.getProjectName()),
    getKey: () => controller.getID(),
    onChildrenChanged: (cb) => {
      return controller.registerListener({
        childrenChanged: (event: { child: DirectoryLogicDeviceModel<ScdNodeModel>; created: boolean }) =>
          cb(logicDeviceMapping(event.child), event.created),
      } as any).deregister;
    },
    getChildren: () => controller.getChildren().map(logicDeviceMapping),
  };
};

const ControllerNameBuilder = (codeName: string, projectName?: string) => {
  return projectName ? `${projectName} - ${codeName}` : codeName;
};

const ExtendableScdControllerNode = (
  controller: ControllerModel,
  controllerLayer: ControllerLayerModel
): ExtendableTreeNode => ({
  canAddChild: ({ type }) => type === TreeDraggableType.LogicDevice,
  addChild: (childPayload, childToAddAfter) => {
    const logicDevice = Object.values(controllerLayer.getModels())
      .flatMap((model) => model.getChildren())
      .find((child) => child.getID() === childPayload.modelId)!;

    logicDevice.remove();

    const indexToAdd = childToAddAfter
      ? controller.getChildren().findIndex((child) => child.getID() === childToAddAfter.getKey()) + 1
      : 0;

    controller.addChild(logicDevice, indexToAdd);
  },
});
