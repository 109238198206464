import styled from '@emotion/styled';

const Notifications = styled.div`
  position: absolute;
  right: 32px;
  bottom: 48px;
  z-index: 1000;
`;

const Notification = styled.div`
  margin-top: 10px;
`;

export const Styled = {
  Notifications,
  Notification,
};
