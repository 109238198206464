import { Node } from '../node/Node';
import { Link } from '../Link';
import { Polygon, Rectangle } from '@projectstorm/geometry';

export interface NodeSet {
  getNodes(): Node[];
}

export interface LinkSet extends NodeSet {
  getLinks(): Link[];
}

export interface Disposed {
  getRect(): Rectangle;
}

export interface Graph extends LinkSet, Disposed {}

export class DefaultGraph implements Graph {
  private readonly nodes: Node[];
  private readonly links: Link[];

  constructor(singleNode: Node);
  constructor(nodes: Node[], links: Link[]);
  constructor(nodes: Node[] | Node, links?: Link[]) {
    this.nodes = Array.isArray(nodes) ? nodes : [nodes];
    this.links = links || [];
  }

  getNodes(): Node[] {
    return this.nodes;
  }

  getLinks(): Link[] {
    return this.links;
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}

export class EnvironmentalLinkSet implements LinkSet {
  private nodeSet: NodeSet;
  private environment: Graph;

  constructor(nodeSet: NodeSet, environment: Graph) {
    this.nodeSet = nodeSet;
    this.environment = environment;
  }

  getLinks(): Link[] {
    const allLinks = this.environment.getLinks();
    const nodeIds = this.getNodes().map((node) => node.getID());
    return allLinks.filter(
      (link) => nodeIds.includes(link.getSourceNode().getID()) && nodeIds.includes(link.getTargetNode().getID())
    );
  }

  getNodes(): Node[] {
    return this.nodeSet.getNodes();
  }
}

export class DefaultDisposed implements Disposed {
  private nodeSet: NodeSet;

  constructor(nodeSet: NodeSet) {
    this.nodeSet = nodeSet;
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.nodeSet.getNodes().map((node) => node.getRect()));
  }
}
