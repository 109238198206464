import { ExportPlaceholder } from './ExportPlaceholder';
import * as React from 'react';
import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import { DirectoryLogicDeviceModel } from '../../../ssd/logic-device/LogicDeviceModel';

export class ExportLogicDeviceLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: DirectoryLogicDeviceModel[];

  constructor(layer: ExportLayer, models: DirectoryLogicDeviceModel[]) {
    this.layer = layer;
    this.models = models;
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.map((logicDevice) => (
          <ExportPlaceholder placeholderModel={logicDevice} key={logicDevice.getID()} />
        ))}
      </>
    );
  }

  async build(): Promise<void> {
    this.models.forEach((logicDevice) => {
      const rect = logicDevice.getRect()!;
      this.boundary.extendBoundary(rect.getTopLeft());
      this.boundary.extendBoundary(rect.getBottomRight());
    });
    return this.layer.build();
  }
}
