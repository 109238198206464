import styled from 'styled-components';
import { Btn } from '../../widgets/Btn.styled';

const ExportFormPanel = styled.div`
  flex: 1 1 570px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 25px 42px 72px 32px;
  background: ${(props) => props.theme.colors.white};
`;

const Header = styled.div`
  display: flex;
  font-size: 32px;
  line-height: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  color: ${(props) => props.theme.colors.black};
`;

const Info = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;

  ${Btn} {
    height: 40px;
    width: 120px;
    border-radius: 100px;
  }
`;

export const Styled = {
  ExportFormPanel,
  Header,
  Content,
  InfoContent,
  Info,
  Footer,
};
