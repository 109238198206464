import { PointModel } from '@projectstorm/react-diagrams-core';
import { Point } from '@projectstorm/geometry';
import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { SmartLinkPointModel } from '../../../point/SmartLinkPointModel';
import { BasePoint, RestrictedDirectionPoint } from '../../../geometry/Point';

export const PortPointsCount = 2;
export const DefaultPortDependantPointsCount = PortPointsCount + 1;
export const ExtendedPortDependantPointsCount = DefaultPortDependantPointsCount + 1;
export const DefaultConnectedPathLength = PortPointsCount + PortPointsCount + 1;
export const ExtendedConnectedPathLength = DefaultConnectedPathLength + 1;

export interface Path {
  getPoints(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): BasePoint[];
}

export interface ConnectingPath {
  connect(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): SmartLinkPointModel[];
}

export interface EditablePath {
  moveSegment(startPoint: PointModel, displacement: Point): void;
}

export interface PortDependentPath {
  getPoints(changed: ConnectablePortModel): SmartLinkPointModel[];
}
