import { StageType } from '../api/nggrace-back';

export type StagePath = 'sld' | 'ssd' | 'scd' | 'lan' | 'tco' | 'export';

interface Stage {
  type: StageType;
  path: StagePath;
}

const stages: Stage[] = [
  { type: 'SLD', path: 'sld' },
  { type: 'SSD', path: 'ssd' },
  { type: 'SCD', path: 'scd' },
  { type: 'LAN', path: 'lan' },
  { type: 'TCO', path: 'tco' },
  { type: 'EXPORT', path: 'export' },
];

export const convertStageTypeToPath = (stageType: StageType): StagePath => {
  return stages.find((stage) => stage.type === stageType)!.path;
};

export const convertStagePathToType = (stagePath: StagePath): StageType => {
  return stages.find((stage) => stage.path === stagePath)!.type;
};
