import styled from 'styled-components';

const DiagramCanvas = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lightgrey};
  border-top: 1px solid ${(props) => props.theme.colors.grey};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  padding: 0 16px;
  font-weight: 600;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const Styled = {
  DiagramCanvas,
  Header,
  Content,
};
