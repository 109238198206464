import styled from 'styled-components';
import { Brick } from '../Brick.styled';
import SimpleBar from 'simplebar-react';

const Library = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};
  border-left: 1px solid ${(props) => props.theme.colors.grey};
  z-index: 88;
`;

const LibraryAccordion = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${Library} {
    height: 50%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lightgrey};
  border-top: 1px solid ${(props) => props.theme.colors.grey};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  padding: 0 16px;
  font-weight: 600;
`;

const Content = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
`;

const Filter = styled.input`
  border: 1px solid ${(props) => props.theme.colors.lightgrey};
  color: ${(props) => props.theme.colors.darkgrey};
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 10px;
  margin: 16px 0;
  width: calc(100% - 32px);
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 0;

  [draggable='true'] {
    width: 100%;
  }

  div {
    display: flex;
  }

  img {
    min-width: 32px;
    height: 32px;
    display: block;
    cursor: pointer;
  }

  ${Brick} {
    display: block;
    text-align: center;
    font-size: 11px;
    line-height: 11px;
    padding: 5px;
    height: 20px;
    width: 45px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ItemName = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.dark};
`;

export const Styled = {
  Library,
  LibraryAccordion,
  Header,
  Content,
  Filter,
  Item,
  ItemName,
};
