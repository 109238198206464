import { BusNodeFactory } from '../../../bus/BusNodeFactory';
import { BusNodeWithPlaceholdersModel } from '../../../bus/BusNodeWithPlaceholdersModel';
import { ControllerPlaceholder } from '../../controller/ControllerPlaceholder';
import { StageType } from '../../../../../api/nggrace-back';
import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';

export class ScdBusNodeFactory extends BusNodeFactory {
  private stage: StageType = 'SCD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new BusNodeWithPlaceholdersModel(
      (node) => new DefaultNodeWithPlaceholders<ControllerPlaceholder>(node, node, this.stage, event.initialConfig.SCD)
    );
  }
}
