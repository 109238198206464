import { DefaultDisposed, EnvironmentalLinkSet, Graph } from './Graph';
import { Rectangle } from '@projectstorm/geometry';
import { Link } from '../Link';
import { Node } from '../node/Node';

export class NodeNeighbourhoodGraph implements Graph {
  private node: Node;
  private environment: Graph;
  private disposed: DefaultDisposed;
  private linkSet: EnvironmentalLinkSet;

  constructor(node: Node, environment: Graph) {
    this.node = node;
    this.environment = environment;
    this.disposed = new DefaultDisposed(this);
    this.linkSet = new EnvironmentalLinkSet(this, environment);
  }

  getLinks(): Link[] {
    return this.linkSet.getLinks();
  }

  getNodes(): Node[] {
    const environmentLinks = this.environment.getLinks();
    const nodeId = this.node.getID();
    return environmentLinks
      .filter((link) => link.getSourceNode().getID() === nodeId || link.getTargetNode().getID() === nodeId)
      .map((link) => (link.getTargetNode().getID() === nodeId ? link.getSourceNode() : link.getTargetNode()));
  }

  getRect(): Rectangle {
    return this.disposed.getRect();
  }
}
