import styled from 'styled-components';

const DiagramEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Canvas = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - ${(props) => `2*${props.theme.editor.headerHeight} - ${props.theme.editor.footerHeight}`});
`;

export const Styled = {
  DiagramEditor,
  Canvas,
};
