import styled from 'styled-components';
import { Styled as StyledInputField } from './widgets/InputField.styled';
import { Styled as StyledInputRadio } from './widgets/InputRadio.styled';
import { Btn } from './widgets/Btn.styled';
import { Icon } from './widgets/Icon.styled';
import SimpleBar from 'simplebar-react';

const Modal = styled.div`
  display: flex;
  padding: 32px 0 72px 0;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  padding: 0 72px 2px 72px;
`;

const HeaderInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  ${StyledInputField.Input} {
    border: none;
    color: ${(props) => props.theme.colors.dark};
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    padding: 0 0 2px;

    &:focus {
      border: none;
      padding: 0;
      border-bottom: 2px solid ${(props) => props.theme.colors.blue};
    }
  }

  ${StyledInputField.InputField} {
    width: auto;
    margin: 0;
    padding-left: 72px;
  }

  ${Icon} {
    margin-left: 20px;
    width: 30px;
    height: 30px;
  }
`;

const Main = styled.div`
  padding: 0 72px;
  display: flex;
`;

const LeftPanel = styled.div`
  flex: 2 1 auto;
  width: 736px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  padding: 48px;
  margin: 0 0 48px 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 32px;
`;

const Form = styled.div`
  ${StyledInputField.InputField} {
    width: 570px;
    margin-bottom: 24px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  ${StyledInputRadio.InputRadio} {
    margin-right: 24px;
  }
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  width: 368px;
  margin: 16px 0 48px 72px;
`;

const Scroll = styled(SimpleBar)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 1320px;
  margin-bottom: 48px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
`;

const Footer = styled.div`
  padding: 0 72px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`;

const BtnPrimary = styled(Btn)`
  height: 48px;
  width: 125px;
`;

export const Styled = {
  Modal,
  Header,
  HeaderInput,
  Main,
  LeftPanel,
  Title,
  Form,
  RadioGroup,
  RightPanel,
  Description,
  Footer,
  BtnPrimary,
  Scroll,
};
