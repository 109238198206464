import { SmartLinkPointModel } from '../../point/SmartLinkPointModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { AppendingConnectingPath, DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { PortEndedPath } from './path/default/PortEndedPath';
import { NormalizingAngledPath } from './path/default/NormalizingAngledPath';
import { AngledPath } from './path/default/AngledPath';
import { RestrictedDirectionPoint } from '../../geometry/Point';
import { SmartLinkModel } from './SmartLinkModel';
import { RemovingConnectingPath } from './path/connecting/RemovingConnectingPath';

export class ExitDividedSmartLinkModel extends SmartLinkModel {
  constructor(
    existingPoints: SmartLinkPointModel[],
    sourcePort: ConnectablePortModel,
    targetPort: ConnectablePortModel
  ) {
    super(sourcePort, targetPort, existingPoints);

    const restoredPath = new UpdatingDependenciesConnectingPath(
      new RemovingConnectingPath(
        new AppendingConnectingPath(
          new DefaultConnectingPath(new PortEndedPath(new NormalizingAngledPath(new AngledPath()), sourcePort), this),
          existingPoints.slice(1),
          true
        )
      )
    ).connect(
      new RestrictedDirectionPoint(sourcePort.getCenter()),
      new RestrictedDirectionPoint(existingPoints[0].getPosition())
    );
    this.setPoints(restoredPath);
  }
}
