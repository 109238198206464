import React, { ChangeEvent, useCallback } from 'react';
import { Styled as S } from './InputRadio.styled';

interface InputRadioProps<T extends string> {
  value: T;
  label: string;
  selected?: T;
  name?: string;
  disabled?: boolean;

  onChange(option: T): void;
}

export const InputRadio = <T extends string>({
  value,
  label,
  selected,
  name,
  disabled,
  onChange,
}: InputRadioProps<T>) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as T);
    },
    [onChange]
  );

  return (
    <S.InputRadio disabled={disabled}>
      <input checked={value === selected} value={value} name={name} type="radio" onChange={handleChange} />
      <S.InputRadioCheck />
      <S.InputRadioLabel>{label}</S.InputRadioLabel>
    </S.InputRadio>
  );
};
