import { theme } from '../theme';

export interface TextMetrics {
  getWidth(text: string): number;
}

export const CanvasTextMetrics = (font: string): TextMetrics => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;
  return {
    getWidth: (text) => context.measureText(text).width,
  };
};

export const CachingTextMetrics = (origin: TextMetrics): TextMetrics => {
  const cached: { [key: string]: number } = {};
  return {
    getWidth: (text) => (cached[text] ? cached[text] : (cached[text] = origin.getWidth(text))),
  };
};

export const BrickTextMetrics: TextMetrics = CachingTextMetrics(CanvasTextMetrics(`10px ${theme.fonts.gost}`));
