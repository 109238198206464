import { AbstractReactFactory, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { LanEngine } from '../LanEngine';
import { BuildingModel, BuildingModelType } from './BuildingModel';
import { DefaultHasName } from './HasName';

type GenerateModelEvent = { initialConfig: ReturnType<BuildingModel['serialize']> };

export class BuildingFactory extends AbstractReactFactory<BuildingModel, LanEngine> {
  constructor() {
    super(BuildingModelType);
  }

  generateModel(event: GenerateModelEvent): BuildingModel {
    return new BuildingModel(new DefaultHasName(event.initialConfig.name));
  }

  generateReactWidget(event: GenerateWidgetEvent<BuildingModel>): JSX.Element {
    return <></>;
  }
}
