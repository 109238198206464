import { NodeEntry } from '../directory/NodeDirectory';
import { DirectoryNodeModel } from '../directory/DirectoryNodeModel';
import { LabelContent, LabelModel } from './LabelModel';
import { DefaultDirection, SmartDirection } from '../geometry/Direction';
import { BasePoint } from '../geometry/Point';
import { DirectionCoordinate } from '../geometry/Coordinate';
import { LabelLineHeight } from './LabelWidget';
import { BusNodeModel } from '../bus/BusNodeModel';

export class NodeLabelModel implements LabelModel {
  private nodeEntry: NodeEntry;
  private node: DirectoryNodeModel | BusNodeModel;

  constructor(nodeDirectoryEntry: NodeEntry, node: DirectoryNodeModel | BusNodeModel) {
    this.nodeEntry = nodeDirectoryEntry;
    this.node = node;
  }

  getContent(): LabelContent {
    return { text: this.node.getPayload().projectName?.trim() || '' };
  }

  getDirection(): SmartDirection {
    return new DefaultDirection(this.nodeEntry.labelDirection);
  }

  getRelativePosition(): BasePoint {
    const direction = this.getDirection();
    const result = new BasePoint(0, 0);
    const coordName = new DirectionCoordinate(direction).getName();
    result[coordName] = direction.isIncreasing() ? this.node.getSize()[coordName] : -LabelLineHeight;
    return result;
  }
}
