import React, { useContext, useMemo } from 'react';
import { DiagramModelContext } from '../../../editor/DiagramModelContext';
import { EngineContext } from '../../../editor/EngineContext';
import { useDirectory } from '../../../hooks/useDirectory';
import { ScdStructureTreeStation } from './ScdStructureTreeStation';
import { ScdStructureTreeVoltageLevelFactory } from './ScdStructureTreeVoltageLevel';
import { ScdStructureTreeNodeFactory } from './ScdStructureTreeNode';
import { ScdStructureTreeLogicNodeFactory } from './ScdStructureTreeLogicNode';
import { TreeNode, TreeNodeChildFactory } from '../../../widgets/tree/TreeNode';
import { Styled as S } from '../../../widgets/library/Library.styled';
import { Tree } from '../../../widgets/tree/Tree';
import { SelectableTreeNodeWidget } from '../../../widgets/tree/SelectableTreeNodeWidget';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdModel } from '../../../editor/scd/ScdModel';
import { ScdStructureTreeControllerFactory } from './ScdStructureTreeController';
import { ScdStructureTreeLogicDeviceFactory } from './ScdStructureTreeLogicDevice';

interface ScdStructureTreeProps {
  station?: string;
}

export const ScdStructureTree: React.FC<ScdStructureTreeProps> = ({ station }) => {
  const model = useContext(DiagramModelContext) as ScdModel;
  const engine = useContext(EngineContext) as ScdEngine;
  const { voltageLevelDirectory } = useDirectory();

  const stationNode = useMemo(() => {
    return ScdStructureTreeStation(
      station || 'Station',
      model,
      voltageLevelDirectory,
      ScdStructureTreeVoltageLevelFactory(
        ScdStructureTreeNodeFactory(
          engine,
          ScdStructureTreeControllerFactory(
            engine,
            ScdStructureTreeLogicDeviceFactory(engine, ScdStructureTreeLogicNodeFactory())
          )
        )
      )
    );
  }, [model, engine, voltageLevelDirectory, station]);

  const voltageLevelFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
    <TreeNode model={model} childFactory={nodeFactory} key={model.getKey()} indentLevel={indentLevel + 1} last={last} />
  );

  return (
    <S.Library>
      <S.Header>{'Station structure'}</S.Header>
      <Tree>
        <TreeNode
          model={stationNode}
          childFactory={voltageLevelFactory}
          hasParent={false}
          indentLevel={0}
          last
          initialOpen
        />
      </Tree>
    </S.Library>
  );
};

const logicNodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} childFactory={logicNodeFactory} indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);

const logicDeviceFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} childFactory={logicNodeFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);

const controllerFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} childFactory={logicDeviceFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);

const nodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <TreeNode model={model} childFactory={controllerFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
  </SelectableTreeNodeWidget>
);
