import * as React from 'react';
import styled from '@emotion/styled';
import { SmartRotationHour } from './geometry/RotationHour';

export interface RotatedWidgetProps {
  width: number;
  height: number;
  hour: SmartRotationHour;
}

const Rotated = styled.div<{ angle: number; height: number; width: number; translateX: number; translateY: number }>`
  transform: translate(${(p) => p.translateX}px, ${(p) => p.translateY}px) rotate(${(p) => p.angle}deg);
  position: absolute;
  top: 0;
  left: 0;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
`;
export const RotatedWidget: React.FC<RotatedWidgetProps> = ({ height, width, hour, children }) => {
  const translate = 0.5 * width - 0.5 * height;
  return (
    <Rotated
      height={height}
      width={width}
      angle={hour.getNumericAngle()}
      translateX={(hour.isAxisSwapped() && -translate) || 0}
      translateY={(hour.isAxisSwapped() && translate) || 0}
    >
      {children}
    </Rotated>
  );
};
