import { NodeModelGenerics } from '@projectstorm/react-diagrams';
import { BaseModelListener, BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { BaseNodeModel } from './BaseNodeModel';

export interface NodePayload {}

export interface PayloadNodeModelListener extends BaseModelListener {
  payloadChanged: () => void;
}

export interface PayloadNodeModelOptions extends BasePositionModelOptions {
  payload: NodePayload;
}

export interface PayloadNodeModelGenerics extends NodeModelGenerics {
  LISTENER: PayloadNodeModelListener;
  OPTIONS: PayloadNodeModelOptions;
  PAYLOAD: NodePayload;
}

export class PayloadNodeModel<G extends PayloadNodeModelGenerics = PayloadNodeModelGenerics> extends BaseNodeModel<G> {
  protected payload: G['PAYLOAD'];

  constructor(options: G['OPTIONS']) {
    super(options);
    this.payload = options.payload;
  }

  getPayload(): G['PAYLOAD'] {
    return JSON.parse(JSON.stringify(this.payload));
  }

  updatePayload(newValue: G['PAYLOAD']) {
    this.payload = JSON.parse(JSON.stringify(newValue));
    this.fireEvent({}, 'payloadChanged');
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.payload = event.data.payload;
  }

  serialize() {
    return {
      ...super.serialize(),
      payload: this.payload,
      raw: false,
    };
  }
}
