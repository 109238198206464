import { DefaultLinkModelGenerics } from '@projectstorm/react-diagrams';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { LanPortModel } from '../node/port/LanPortModel';
import { LanLinkLayerModel } from './layer/LanLinkLayerModel';
import { LanModel } from '../LanModel';
import { LanNodeModel } from '../node/LanNodeModel';
import { LanLinkZoneGraph } from './graph/LanLinkZoneGraph';
import { LanLinkGraph } from './graph/LanLinkGraph';
import { LanLinkRackGraph } from './graph/LanLinkRackGraph';
import { LanLinkZonesGraph } from './graph/LanLinkZonesGraph';
import { Factory } from '../../../../utils/factory';
import { RawSmartLinkModel } from '../../link/smart/RawSmartLinkModel';
import { onceForLoop } from '../../FunctionDecorators';

export const LanLinkType = 'lan-link';

export class LanLinkModel extends RawSmartLinkModel {
  graph?: LanLinkGraph;
  label: string;

  constructor();
  constructor(source: ConnectablePortModel, target: ConnectablePortModel);
  constructor(source?: ConnectablePortModel, target?: ConnectablePortModel) {
    if (source && target) {
      super(source, target, []);
    } else {
      super();
    }

    this.options.type = LanLinkType;
    this.label = ''; // fills while deserialization;
  }

  setParent(parent: DefaultLinkModelGenerics['PARENT']) {
    super.setParent(parent);
    if (this.getParent() && this.getSourcePort() && this.getTargetPort()) {
      this.setupGraphModel();
      this.updatePoints();
    }
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.label = event.data.label;
    return Promise.all([
      event.getModel<LanPortModel>(event.data.sourcePort),
      event.getModel<LanPortModel>(event.data.targetPort),
    ]).then(() => {
      if (this.getParent() && this.getSourcePort() && this.getTargetPort()) {
        this.setupGraphModel();
      }
    });
  }

  serialize() {
    return { ...super.serialize(), label: this.label };
  }

  setupGraphModel() {
    const factory = this.getCurrentGraphFactory();
    if (factory) {
      this.graph = factory(
        onceForLoop(() => {
          const graph = this.graph!;
          if (graph.needDetach()) {
            graph.detach();
            this.setupGraphModel();
          } else {
            this.updatePoints();
          }
        })
      );
      this.updatePoints();
    } else {
      setTimeout(() => this.setupGraphModel()); // need to wait for all connections established
    }
  }

  portPositionChanged(port: ConnectablePortModel) {}

  updatePoints() {
    this.setPoints(this.graph!.getPoints());
    this.fireEvent({}, 'pointsChanged');
  }

  getColor() {
    return this.graph?.getColor() || super.getColor();
  }

  remove() {
    super.remove();
    this.graph?.detach();
  }

  getConnectorLength() {
    return this.graph?.getConnectorLength() || 40;
  }

  getLabel() {
    return this.label;
  }

  private getCurrentGraphFactory(): Factory<() => void, LanLinkGraph> | undefined {
    const lanModel = ((this.getParent() as unknown) as LanLinkLayerModel).getParent() as LanModel;
    const sourcePort = this.getSourcePort();
    const targetPort = this.getTargetPort();
    const sourceNode = sourcePort.getParent() as LanNodeModel;
    const targetNode = targetPort.getParent() as LanNodeModel;

    if (!sourceNode.getRelativeZone() || !targetNode.getRelativeZone()) {
      return undefined;
    }

    if (sourceNode.getRelativeZone()?.getID() !== targetNode.getRelativeZone()?.getID()) {
      const layer = lanModel.getZoneLayer();
      return (listener) => new LanLinkZonesGraph(layer, this, listener);
    }

    const sourceRack = this.getRackForNode(sourceNode);

    if (!sourceRack) {
      return undefined;
    }

    if (sourceRack.getChildren().find((child) => child.getID() === targetNode.getID())) {
      return (listener) => new LanLinkRackGraph(sourceRack, this, listener);
    }

    const targetRack = this.getRackForNode(targetNode);
    const zone = sourceNode.getRelativeZone()!;
    return (listener) => new LanLinkZoneGraph(zone, this, sourceRack, targetRack, listener);
  }

  private getRackForNode(node: LanNodeModel) {
    const lanModel = ((this.getParent() as unknown) as LanLinkLayerModel).getParent() as LanModel;
    return Object.values(lanModel.getRackLayer().getModels())
      .flatMap((rack) => {
        const composite = rack.asComposite();
        if (composite) {
          return composite.getRacks();
        }
        return [rack.asSingle()!];
      })
      .find((rack) => rack.getChildren().find((child) => child.getID() === node.getID()))!;
  }
}
