import { ProjectStyleSelection, StageType, UserBaseDto } from '../api/nggrace-back';
import { formatMomentShort } from './date-utils';

export const getStageNumber = (stage: StageType): number => {
  switch (stage) {
    case 'SLD':
      return 1;
    case 'SSD':
      return 2;
    case 'SCD':
      return 3;
    case 'LAN':
      return 4;
    case 'TCO':
      return 5;
    case 'EXPORT':
      return 6;
  }
};

export const getNextStage = (stage: StageType): StageType | undefined => {
  switch (stage) {
    case 'SLD':
      return 'SSD';
    case 'SSD':
      return 'SCD';
    case 'SCD':
      return 'LAN';
    case 'LAN':
      return 'TCO';
    case 'TCO':
      return 'EXPORT';
  }
};

export const isStageAvailable = (active: StageType, target: StageType) =>
  getStageNumber(active) >= getStageNumber(target);

export const convertStageTypeShort = (name: StageType): string => {
  switch (name) {
    case 'SLD':
      return 'ГC';
    case 'SSD':
      return 'ИТС1';
    case 'SCD':
      return 'ИТС2';
    case 'LAN':
      return 'ЛВС';
    case 'TCO':
      return 'TCO';
    case 'EXPORT':
      return 'EXPORT';
    default:
      return 'Неизвестно';
  }
};

export const getApprovedBy = ({ finishedAt, finishedBy }: { finishedAt?: number; finishedBy?: UserBaseDto }) => {
  let approvedBy = '';
  if (finishedBy || finishedAt) {
    approvedBy += ' approved';

    if (finishedBy) {
      approvedBy += ' by ' + finishedBy.name;
    }

    if (finishedAt) {
      approvedBy += ' at ' + formatMomentShort(finishedAt);
    }
  }

  return approvedBy;
};

export const getCurrency = (styleSelection: ProjectStyleSelection) => (styleSelection === 'RU' ? '₽' : '€');

const stationNameRuLength = 30;

export const getStationNameRu = (stationName?: string): { first: string; second: string } => {
  if (stationName) {
    const chunks = stationName.split(/([ -])/g);
    let first = '';
    let second = '';
    let i = 0;
    while (i < chunks.length && first.length + chunks[i].length <= stationNameRuLength) {
      first += chunks[i];
      i++;
    }
    while (i < chunks.length) {
      second += chunks[i];
      i++;
    }
    if (first.trim() === '') {
      return {
        first: second.trim(),
        second: '',
      };
    }
    return {
      first: first.trim(),
      second: second.trim(),
    };
  }
  return {
    first: '',
    second: '',
  };
};
