import React, { HTMLProps, RefCallback, useCallback } from 'react';
import { Styled as S } from './InputSlider.styled';

type SliderProps = {
  value?: number;
  disabled?: boolean;
  onChange(value: number): void;
};

export const InputSlider: React.FC<SliderProps> = ({ value = 0, disabled, onChange }) => {
  const Thumb = useCallback(
    (props: HTMLProps<HTMLDivElement> & { ref: RefCallback<HTMLDivElement> }) => (
      <S.Thumb {...props} disabled={disabled} as="div" />
    ),
    [disabled]
  );

  const Track = useCallback(
    (
      props: HTMLProps<HTMLDivElement> & { ref: RefCallback<HTMLDivElement> },
      state: { index: number; value: number }
    ) => <S.Track {...props} index={state.index} disabled={disabled} as="div" />,
    [disabled]
  );

  return (
    <S.InputSlider>
      <S.Header>
        <S.Value>{Math.ceil(value * 100)}</S.Value>
        <S.Label>%</S.Label>
      </S.Header>
      <S.Slider
        disabled={disabled}
        min={0}
        max={1}
        step={0.01}
        value={value}
        onChange={onChange}
        renderThumb={Thumb}
        renderTrack={Track}
      />
    </S.InputSlider>
  );
};
