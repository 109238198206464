import React, { useEffect, useMemo, useState } from 'react';
import { Styled as S } from '../ssd/logic-device/PlaceholderWidget.styled';
import { BasePoint } from '../geometry/Point';
import { PlaceholderModel, PlaceholderWidget } from './PlaceholderWidget';
import { SsdEngine } from '../ssd/SsdEngine';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { NodeWithPlaceholders } from './NodeWithPlaceholders';
import { PlaceholderGroupOffset } from './Placeholder';

export type PlaceholderGroupProps = {
  relativeModel: RotatableNodeModel & NodeWithPlaceholders<PlaceholderModel>;
  engine: SsdEngine;
};

export const PlaceholderGroup: React.FC<PlaceholderGroupProps> = React.memo(({ relativeModel, engine }) => {
  const [position, setPosition] = useState(() => relativeModel.getPositionToRender());
  const [placeholders, setPlaceholders] = useState(() => relativeModel.getChildren());

  useEffect(() => {
    setPlaceholders(relativeModel.getChildren());
    setPosition(relativeModel.getPositionToRender());
    return relativeModel.registerListener({
      childrenChanged: () => setPlaceholders(relativeModel.getChildren()),
      positionChanged: () => setPosition(relativeModel.getPositionToRender()),
    } as any).deregister;
  }, [relativeModel]);

  const groupPosition = useMemo(() => {
    const size = relativeModel.getRotatedSize();
    const offset = PlaceholderGroupOffset;
    return new BasePoint(position.x + size.x + offset.x, position.y + offset.y);
  }, [relativeModel, position]);

  const rowsLength = useMemo(() => (placeholders.length <= 4 ? 2 : 3), [placeholders]);
  return (
    <S.Group left={groupPosition.x} top={groupPosition.y} length={rowsLength}>
      {placeholders.map((device) => (
        <PlaceholderWidget placeholderModel={device} engine={engine} key={device.getID()} />
      ))}
    </S.Group>
  );
});
