import { isDirectoryNodeModel, isNetworkNodeModel, isRotatableNodeModel } from '../../../NgGraceModel';
import { NodeDirectory } from '../../../directory/NodeDirectory';
import { SvgOptimizer } from '../../SvgOptimizer';
import { NodeModel } from '@projectstorm/react-diagrams';
import { BasePoint } from '../../../geometry/Point';
import axios from 'axios';
import { ExportNode } from './ExportNode';
import * as React from 'react';
import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import { NetworkDeviceDirectoryEntry, PropertiesDirectory } from '../../../directory/PropertiesDirectory';

export class ExportNodeLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: NodeModel[];
  private readonly directory: NodeDirectory;
  private readonly networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>;
  private readonly svgCache: { [nodeId: string]: any } = {};
  private readonly svgOptimizer = new SvgOptimizer();

  constructor(
    layer: ExportLayer,
    directory: NodeDirectory,
    networkDeviceDirectory: PropertiesDirectory<NetworkDeviceDirectoryEntry>,
    models: NodeModel[]
  ) {
    this.layer = layer;
    this.directory = directory;
    this.networkDeviceDirectory = networkDeviceDirectory;
    this.models = models;
  }

  private updateNodeBoundary = (node: NodeModel) => {
    if (isRotatableNodeModel(node)) {
      const topLeft = node.getPositionToRender();
      const bottomRight = new BasePoint(topLeft.x + node.getRotatedSize().x, topLeft.y + node.getRotatedSize().y);
      this.boundary.extendBoundary(topLeft);
      this.boundary.extendBoundary(bottomRight);
    }
  };

  private async fillSvgCache() {
    return await Promise.all(
      this.models.map((node) => {
        this.updateNodeBoundary(node);
        if (isDirectoryNodeModel(node)) {
          const record = this.directory.getEntry(node.getDirectoryEntryId());
          return axios.get(record.svg).then((response) => {
            this.svgCache[node.getDirectoryEntryId()] = this.svgOptimizer.optimize(response.data);
          });
        }
        if (isNetworkNodeModel(node) && node.getImage()) {
          return axios.get(node.getImage()!.src).then((response) => {
            this.svgCache[node.getDirectoryEntryId()] = this.svgOptimizer.optimize(response.data);
          });
        }
        return Promise.resolve();
      })
    );
  }

  async build(): Promise<any> {
    await this.fillSvgCache();
    return this.layer.build();
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.map((node) => (
          <ExportNode directory={this.directory} svgCache={this.svgCache} node={node} key={node.getID()} />
        ))}
      </>
    );
  }
}
