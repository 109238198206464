import { LinkModel } from '@projectstorm/react-diagrams';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { ConnectingPath, Path } from '../Path';
import { RestrictedDirectionPoint } from '../../../../geometry/Point';

export class DefaultConnectingPath implements ConnectingPath {
  private readonly path: Path;
  private readonly link: LinkModel;

  constructor(path: Path, link: LinkModel) {
    this.path = path;
    this.link = link;
  }

  connect(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): SmartLinkPointModel[] {
    return this.path
      .getPoints(start, end)
      .map((point) => new SmartLinkPointModel({ position: point, link: this.link }));
  }
}

export class AppendingConnectingPath implements ConnectingPath {
  private readonly origin: ConnectingPath;
  private readonly pointsToAppend: SmartLinkPointModel[];
  private readonly appendAfter: boolean;

  constructor(path: ConnectingPath, pointsToAppend: SmartLinkPointModel[], appendAfter: boolean = false) {
    this.origin = path;
    this.pointsToAppend = pointsToAppend;
    this.appendAfter = appendAfter;
  }

  connect(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): SmartLinkPointModel[] {
    const result = this.origin.connect(start, end);
    return this.appendAfter ? [...result, ...this.pointsToAppend] : [...this.pointsToAppend, ...result];
  }
}
