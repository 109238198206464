import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { Styled as S } from './Library.styled';
import { DirectoryEntry } from '../../editor/directory/Directory';

type LibraryProps<T extends DirectoryEntry> = {
  header: string;
  items: T[];
  onFilter?(filter: string, item: T): boolean;
  widgetFactory(item: T, nameWidget: ReactNode): ReactNode;
};

export const LibraryAccordion: React.FC = ({ children }) => {
  return <S.LibraryAccordion>{children}</S.LibraryAccordion>;
};

export const Library = <T extends DirectoryEntry>({ header, items, onFilter, widgetFactory }: LibraryProps<T>) => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  }, []);

  const filterHandler = (item: T): boolean => {
    if (!filter) {
      return true;
    }

    if (onFilter) {
      return onFilter(filter, item);
    }

    return (
      item.name.en.toLowerCase().includes(filter.toLowerCase()) ||
      item.name.ru.toLowerCase().includes(filter.toLowerCase())
    );
  };

  return (
    <S.Library>
      <S.Header>{header}</S.Header>
      <S.Filter placeholder={'Search...'} value={filter} onChange={handleFilterChange} />
      <S.Content>
        {items.filter(filterHandler).map((entry) => (
          <S.Item key={entry.id}>{widgetFactory(entry, <S.ItemName>{entry.name.en}</S.ItemName>)}</S.Item>
        ))}
      </S.Content>
    </S.Library>
  );
};
