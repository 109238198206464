import React from 'react';
import styled from '@emotion/styled';

export const NodeResizerClassName = 'resizer';
export const NodeResizerIdAttributeName = 'data-resizerid';

export interface NodeResizerProps {
  height: number;
  width: number;
  resizerId: string;
}

export const Resizer = styled.div<{ height: number; width: number }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  z-index: 10;
  background: #000;
`;

export const NodeResizer: React.FC<NodeResizerProps> = ({ height, width, resizerId }) => {
  const resizerIdAttr = { [NodeResizerIdAttributeName]: resizerId };
  return <Resizer className={NodeResizerClassName} {...resizerIdAttr} width={width} height={height} />;
};
