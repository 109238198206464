import { DeserializeEvent, FactoryBank, LayerModel, LayerModelGenerics } from '@projectstorm/react-canvas-core';
import { AbstractModelFactory } from '@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory';
import { LanModel } from '../../LanModel';
import { LanEngine } from '../../LanEngine';
import {
  CoordinateEdgeMergingGraph,
  ZoneHasRectFactory,
  ZoneModel,
  ZoneModelHasChildrenFactory,
  ZoneType,
} from '../ZoneModel';
import { LayerListener } from '../../building/layer/BuildingLayerModel';
import { DefaultHasRect } from '../../../placeholder/HasRect';
import { DefaultHasPosition } from '../../../placeholder/HasPosition';
import { UpdatingChildrenHasPosition } from '../../rack/UpdatingChildrenHasPosition';
import { ZoneHasSize } from '../ZoneHasSize';
import { DefaultCoordinate } from '../../../geometry/Coordinate';
import { ColumnsHasChildren } from '../ColumnsHasChildren';
import { BasePoint } from '../../../geometry/Point';
import { Factory } from '../../../../../utils/factory';
import { ModelCoordinateEquivalentSet } from './ModelCoordinateEquivalentSet';
import { Node } from '../../../auto-layout/node/Node';
import { onceForLoop } from '../../../FunctionDecorators';
import { Graph } from '../../../auto-layout/graph/Graph';
import { CachingGraph } from '../../../auto-layout/graph/CachingGraph';
import { DefaultWaypointGraph, WaypointGraph, WaypointGraphContainer } from '../../waypoint/WaypointGraph';

export const ZoneLayerModelType = 'zone-layer';
export const ZoneLayerModelId = 'zone-layer-model-id';

export interface ZoneLayerModelGenerics extends LayerModelGenerics {
  PARENT: LanModel;
  ENGINE: LanEngine;
  CHILDREN: ZoneModel;
  LISTENER: LayerListener;
}

export class ZoneLayerModel extends LayerModel<ZoneLayerModelGenerics> implements WaypointGraphContainer<ZoneType> {
  private readonly zones: { [key in ZoneType]: ZoneModel };
  private waypointGraph: WaypointGraph<ZoneType> | undefined;

  constructor(options: ZoneLayerModelGenerics['OPTIONS'] = {}) {
    super({ ...options, id: ZoneLayerModelId, type: ZoneLayerModelType, transformed: true });
    this.models = {};
    this.repaintEnabled = true;

    const rectFactory: ZoneHasRectFactory = ({ eventDelegate, hasChildren }) =>
      new DefaultHasRect(
        new UpdatingChildrenHasPosition(new DefaultHasPosition(eventDelegate as any), eventDelegate, hasChildren),
        new ZoneHasSize(eventDelegate, hasChildren, new BasePoint(50, 200))
      );

    const hasChildrenFactory: Factory<number, ZoneModelHasChildrenFactory> = (rowCount) => ({
      origin,
      hasPosition,
      eventDelegate,
    }) =>
      new ColumnsHasChildren(
        origin,
        hasPosition,
        ({ models, coordinate, previousSet, defaultPosition, setsOffset }) => {
          return new ModelCoordinateEquivalentSet(
            models,
            coordinate,
            previousSet,
            defaultPosition ? () => defaultPosition : undefined,
            setsOffset
          );
        },
        eventDelegate,
        rowCount
      );

    const zones: { [key in ZoneType]: ZoneModel } = {
      WORKSTATION: new ZoneModel(ZoneType.WORKSTATION, rectFactory, hasChildrenFactory(1)),
      SCADA: new ZoneModel(ZoneType.SCADA, rectFactory, hasChildrenFactory(1)),
      MIDDLE: new ZoneModel(ZoneType.MIDDLE, rectFactory, hasChildrenFactory(1)),
      LOW_NETWORK: new ZoneModel(ZoneType.LOW_NETWORK, rectFactory, hasChildrenFactory(1)),
      LOW_GENERAL: new ZoneModel(ZoneType.LOW_GENERAL, rectFactory, hasChildrenFactory(1)),
      RPA_PROTECTION: new ZoneModel(ZoneType.RPA_PROTECTION, rectFactory, hasChildrenFactory(4)),
      RPA_PRIMARY_NETWORK_PROCESS: new ZoneModel(
        ZoneType.RPA_PRIMARY_NETWORK_PROCESS,
        rectFactory,
        hasChildrenFactory(1)
      ),
      RPA_BACKUP_NETWORK_PROCESS: new ZoneModel(
        ZoneType.RPA_BACKUP_NETWORK_PROCESS,
        rectFactory,
        hasChildrenFactory(1)
      ),
      RPA_PRIMARY_NETWORK_STATION: new ZoneModel(
        ZoneType.RPA_PRIMARY_NETWORK_STATION,
        rectFactory,
        hasChildrenFactory(1)
      ),
      RPA_BACKUP_NETWORK_STATION: new ZoneModel(
        ZoneType.RPA_BACKUP_NETWORK_STATION,
        rectFactory,
        hasChildrenFactory(1)
      ),
    };

    this.zones = zones;
    this.models = zones;

    const xZoneSets = [
      [
        zones.RPA_PRIMARY_NETWORK_PROCESS,
        zones.RPA_BACKUP_NETWORK_PROCESS,
        zones.RPA_PRIMARY_NETWORK_STATION,
        zones.RPA_BACKUP_NETWORK_STATION,
        zones.RPA_PROTECTION,
      ],
      [zones.LOW_GENERAL, zones.LOW_NETWORK],
    ];

    const xSets = xZoneSets.reduce((prevSets: ModelCoordinateEquivalentSet[], curSet: ZoneModel[]) => {
      return [
        ...prevSets,
        new ModelCoordinateEquivalentSet(curSet, new DefaultCoordinate('x'), prevSets[prevSets.length - 1]),
      ];
    }, []);

    const yZoneSets = [
      [zones.WORKSTATION],
      [zones.SCADA],
      [zones.MIDDLE],
      [zones.RPA_PRIMARY_NETWORK_STATION],
      [zones.RPA_PRIMARY_NETWORK_PROCESS, zones.LOW_NETWORK],
      [zones.RPA_PROTECTION, zones.LOW_GENERAL],
      [zones.RPA_BACKUP_NETWORK_STATION],
      [zones.RPA_BACKUP_NETWORK_PROCESS],
    ];

    const ySets = yZoneSets.reduce((prevSets: ModelCoordinateEquivalentSet[], curSet: ZoneModel[]) => {
      return [
        ...prevSets,
        new ModelCoordinateEquivalentSet(curSet, new DefaultCoordinate('y'), prevSets[prevSets.length - 1]),
      ];
    }, []);

    [xSets, ySets].forEach((sets) => sets[0].fireEvent({}, 'setSizeChanged'));

    this.updateLinkWaypointGraph();

    const updateWaypoints = onceForLoop(() => {
      this.updateLinkWaypointGraph();
      this.fireEvent({}, 'waypointsChanged');
    });
    Object.values(this.zones).forEach((zone) => zone.registerListener({ waypointsChanged: updateWaypoints }));
  }

  getChildModelFactoryBank(engine: ZoneLayerModelGenerics['ENGINE']) {
    return (engine.getZoneFactories() as unknown) as FactoryBank<AbstractModelFactory>; // typing shit again
  }

  addModel(model: ZoneLayerModelGenerics['CHILDREN']) {
    super.addModel(model);
    model.registerListener({
      entityRemoved: () => {
        this.removeModel(model);
        this.fireEvent({ model }, 'modelRemoved');
      },
    });

    this.fireEvent({ model }, 'modelAdded');
  }

  deserialize(event: DeserializeEvent<this>) {
    const savedOptions = { ...this.options };
    super.deserialize(event);
    this.options = { ...savedOptions };
  }

  registerModels(event: DeserializeEvent<any>) {
    Object.values(this.models).forEach((model) => event.registerModel(model));
  }

  serialize() {
    return { ...super.serialize(), models: {} };
  }

  getLinkWaypointGraph() {
    return this.waypointGraph!;
  }

  updateLinkWaypointGraph() {
    const zones = this.zones;
    const xCoord = new DefaultCoordinate('x');
    const yCoord = new DefaultCoordinate('y');
    const now = Date.now();
    const caching = (graph: Graph) => new CachingGraph({ date: now }, graph);
    const zoneWaypoints: Record<ZoneType, Graph> = {
      WORKSTATION: zones.WORKSTATION.getOuterWaypoints(),
      SCADA: zones.SCADA.getOuterWaypoints(),
      MIDDLE: zones.MIDDLE.getOuterWaypoints(),
      RPA_PRIMARY_NETWORK_STATION: zones.RPA_PRIMARY_NETWORK_STATION.getOuterWaypoints(),
      RPA_PRIMARY_NETWORK_PROCESS: zones.RPA_PRIMARY_NETWORK_PROCESS.getOuterWaypoints(),
      LOW_NETWORK: zones.LOW_NETWORK.getOuterWaypoints(),
      RPA_PROTECTION: zones.RPA_PROTECTION.getOuterWaypoints(),
      LOW_GENERAL: zones.LOW_GENERAL.getOuterWaypoints(),
      RPA_BACKUP_NETWORK_STATION: zones.RPA_BACKUP_NETWORK_STATION.getOuterWaypoints(),
      RPA_BACKUP_NETWORK_PROCESS: zones.RPA_BACKUP_NETWORK_PROCESS.getOuterWaypoints(),
    };

    const graph = caching(
      new CoordinateEdgeMergingGraph(
        new CoordinateEdgeMergingGraph(
          new CoordinateEdgeMergingGraph(
            new CoordinateEdgeMergingGraph(
              new CoordinateEdgeMergingGraph(
                new CoordinateEdgeMergingGraph(
                  new CoordinateEdgeMergingGraph(
                    new CoordinateEdgeMergingGraph(zoneWaypoints.WORKSTATION, zoneWaypoints.SCADA, yCoord),
                    zoneWaypoints.MIDDLE,
                    yCoord
                  ),
                  zoneWaypoints.RPA_PRIMARY_NETWORK_STATION,
                  yCoord
                ),
                zoneWaypoints.RPA_PRIMARY_NETWORK_PROCESS,
                yCoord
              ),
              zoneWaypoints.RPA_PROTECTION,
              yCoord
            ),
            zoneWaypoints.RPA_BACKUP_NETWORK_STATION,
            yCoord
          ),
          zoneWaypoints.RPA_BACKUP_NETWORK_PROCESS,
          yCoord
        ),
        new CoordinateEdgeMergingGraph(zoneWaypoints.LOW_NETWORK, zoneWaypoints.LOW_GENERAL, yCoord),
        xCoord
      )
    );

    const nodeTypeFactory = (node: Node): ZoneType => {
      return Object.entries(zoneWaypoints).find(([_, graph]) =>
        graph.getNodes().find((graphNode) => graphNode.getID() === node.getID())
      )![0] as ZoneType;
    };
    this.waypointGraph = new DefaultWaypointGraph(graph, nodeTypeFactory, this);
  }
}
