import { getDistance, LanLinkGraph } from './LanLinkGraph';
import { SmartLinkPointModel } from '../../../point/SmartLinkPointModel';
import { DefaultConnectingPath } from '../../../link/smart/path/connecting/DefaultConnectingPath';
import { PortEndedPath } from '../../../link/smart/path/default/PortEndedPath';
import { NormalizingAngledPath } from '../../../link/smart/path/default/NormalizingAngledPath';
import { AngledPath } from '../../../link/smart/path/default/AngledPath';
import { Direction } from '../../../geometry/Direction';
import { RestrictedDirectionPoint } from '../../../geometry/Point';
import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { Node } from '../../../auto-layout/node/Node';
import { Point } from '@projectstorm/geometry';
import { LanLinkModel } from '../LanLinkModel';
import { ZoneModel } from '../../zone/ZoneModel';
import { LanNodeModel } from '../../node/LanNodeModel';
import { SingleRackModel } from '../../rack/SingleRackModel';

export class LanLinkZoneGraph implements LanLinkGraph {
  private readonly zone: ZoneModel;
  private readonly targetRack: SingleRackModel;
  private readonly sourceRack: SingleRackModel;
  private readonly link: LanLinkModel;
  private readonly deregister: () => any;

  constructor(
    zone: ZoneModel,
    link: LanLinkModel,
    sourceRack: SingleRackModel,
    targetRack: SingleRackModel,
    onChange: () => void
  ) {
    this.targetRack = targetRack;
    this.sourceRack = sourceRack;
    this.link = link;
    this.zone = zone;
    this.deregister = zone.registerListener({ waypointsChanged: onChange } as any).deregister;
  }

  detach(): void {
    this.deregister();
  }

  getId(): string {
    return this.zone.getID();
  }

  needDetach(): boolean {
    const sourceNode = this.link.getSourcePort().getParent() as LanNodeModel;
    const targetNode = this.link.getTargetPort().getParent() as LanNodeModel;
    return (
      sourceNode.getRelativeZone()?.getID() !== targetNode.getRelativeZone()?.getID() ||
      !!this.sourceRack.getChildren().find((child) => child.getID() === targetNode.getID()) ||
      !!this.targetRack.getChildren().find((child) => child.getID() === sourceNode.getID())
    );
  }

  getPoints() {
    const graph = this.zone.getLinkWaypointGraph();
    if (!graph || !graph.getNodes().length) {
      return [];
    }

    const nodes = graph.getNodes();
    const sourcePort = this.link.getSourcePort();
    const targetPort = this.link.getTargetPort();
    const sourceWaypoint = this.getWaypointForFrom(sourcePort, targetPort, nodes);
    const targetWaypoint = this.getWaypointForFrom(targetPort, sourcePort, nodes);

    let path =
      sourceWaypoint.getID() !== targetWaypoint.getID()
        ? graph.find(sourceWaypoint.getID(), targetWaypoint.getID()).map((node) => node.data)
        : [sourceWaypoint];

    if (path[0].getID() !== sourceWaypoint.getID()) {
      path.reverse();
    }

    const pathPositions = path.map((node) => node.getRect().getTopLeft());

    if (pathPositions.length === 1) {
      const pathPoint = pathPositions[0];
      const sourcePoint = sourcePort.getConnector();
      const targetPoint = targetPort.getConnector();
      pathPoint.y = (sourcePoint.y + targetPoint.y) / 2;
    }

    const sourceEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), sourcePort),
      this.link
    );

    const targetEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), targetPort),
      this.link
    );

    return [
      ...sourceEndedPath.connect(
        new RestrictedDirectionPoint(sourcePort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP]),
        new RestrictedDirectionPoint(path[0].getRect().getTopLeft(), [Direction.LEFT, Direction.RIGHT])
      ),
      ...path
        .map(
          (node) =>
            new SmartLinkPointModel({
              position: node.getRect().getTopLeft(),
              link: this.link,
            })
        )
        .slice(1, -1),
      ...targetEndedPath.connect(
        new RestrictedDirectionPoint(path[path.length - 1].getRect().getTopLeft(), [Direction.LEFT, Direction.RIGHT]),
        new RestrictedDirectionPoint(targetPort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP])
      ),
    ];
  }

  getColor(): string {
    return 'green';
  }

  private getWaypointForFrom(from: ConnectablePortModel, to: ConnectablePortModel, waypoints: Node[]) {
    const waypointMapping: {
      accept: (from: ConnectablePortModel, to: ConnectablePortModel) => boolean;
      getClosest: (from: ConnectablePortModel, to: ConnectablePortModel) => Node;
    }[] = [
      {
        accept: () => true,
        getClosest: (from, to) => {
          const sourceNodePosition = from.getParent().getPosition();
          const targetNodePosition = to.getParent().getPosition();
          return this.getClosestWaypoint(
            from.getPosition(),
            waypoints,
            targetNodePosition.x - sourceNodePosition.x > 0 ? Direction.RIGHT : Direction.LEFT
          );
        },
      },
    ];

    return waypointMapping.find((mapping) => mapping.accept(from, to))!.getClosest(from, to);
  }

  private getClosestWaypoint(point: Point, waypoints: Node[], xDirection: Direction) {
    let minDistance = Number.MAX_VALUE;
    let currentId: string | undefined = undefined;

    const xConstraint = (waypointPosition: Point) =>
      xDirection === Direction.LEFT ? waypointPosition.x < point.x : waypointPosition.x > point.x;

    waypoints
      .filter((waypoint) => {
        const position = waypoint.getRect().getTopLeft();
        return xConstraint(position);
      })
      .forEach((waypoint) => {
        const position = waypoint.getRect().getTopLeft();
        const distance = getDistance(point, position);
        if (minDistance > distance) {
          minDistance = distance;
          currentId = waypoint.getID();
        }
      });

    return waypoints.find((waypoint) => waypoint.getID() === currentId)!;
  }

  getConnectorLength(): number {
    return 62;
  }
}
