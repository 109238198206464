import React from 'react';
import { StageDto } from '../../../api/nggrace-back';
import { DiagramCanvas } from '../../editor/DiagramCanvas';
import { Styled as S } from '../../DiagramEditor.styled';
import { ZoomWidget } from '../../editor/ZoomWidget';
import { useDirectory } from '../../hooks/useDirectory';
import { getApprovedBy } from '../../../utils/project-utils';
import { LanToolbar } from './LanToolbar';
import { LanLibrary } from './LanLibrary';
import { useNotifyOnSuccessCallback } from '../../hooks/useNotifyOnSuccessCallback';

type LanDiagramEditorProps = {
  stage: StageDto;

  onNextStage(): void;
  onValidateStage(): Promise<boolean>;
  onFinishStage(): void;
  onRollbackStage(): void;
  onReloadStage(): void;
};

export const LanDiagramEditor: React.FC<LanDiagramEditorProps> = ({
  stage,
  onNextStage,
  onValidateStage,
  onFinishStage,
  onRollbackStage,
  onReloadStage,
}) => {
  const { getNodeDirectory } = useDirectory();
  const nodeDirectory = getNodeDirectory(stage.project.styleSelection);
  const header = 'LAN' + getApprovedBy(stage.baseInfo);
  const handleValidationStage = useNotifyOnSuccessCallback(onValidateStage, 'Validation completed');

  return (
    <S.DiagramEditor>
      <LanToolbar
        stage={stage}
        onNextStage={onNextStage}
        onValidateStage={handleValidationStage}
        onFinishStage={onFinishStage}
        onRollbackStage={onRollbackStage}
        onReloadStage={onReloadStage}
      />
      <S.Canvas>
        <DiagramCanvas header={header} directory={nodeDirectory} />
        <LanLibrary project={stage.project} locked={stage.baseInfo.finished} />
      </S.Canvas>
      <ZoomWidget />
    </S.DiagramEditor>
  );
};
