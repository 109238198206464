import React, { useCallback } from 'react';
import { InputGroup } from '../../../widgets/InputGroup.styled';
import { InputField } from '../../../widgets/InputField';
import { ExportLang as $s } from '../Export.lang';
import { InputRadioGroup, OptionType } from '../../../widgets/InputRadioGroup';
import { DocStageType, ExportFormDto, ProjectStyleSelection } from '../../../../api/nggrace-back';
import { Tooltip } from '../../../widgets/Tooltip';
import { InputRow } from '../../../widgets/InputRow.styled';

const stages: OptionType<DocStageType>[] = [
  { id: 'PROJECT_DOC', name: 'П' },
  { id: 'WORKING_DOC', name: 'Р' },
  { id: 'EXECUTIVE_DOC', name: 'И' },
];

type UpdateFormAction = (form: ExportFormDto) => ExportFormDto;

type ProjectInfoGroupProps = {
  style: ProjectStyleSelection;
  form: ExportFormDto;
  project: {
    stationName?: string;
    stationNameSecondLine?: string;
  };

  onChange(formUpdater: UpdateFormAction): void;
};

export const ProjectInfoGroup: React.FC<ProjectInfoGroupProps> = ({ style, form, project, onChange }) => {
  const handleCodeChange = useCallback(
    (code: string) => {
      onChange((dto) => ({ ...dto, code }));
    },
    [onChange]
  );

  const handleContractorDocumentNumberChange = useCallback(
    (contractorDocumentNumber: string) => {
      onChange((dto) => ({ ...dto, contractorDocumentNumber }));
    },
    [onChange]
  );

  const handleRevisionChange = useCallback(
    (revision: string) => {
      onChange((dto) => ({ ...dto, revision }));
    },
    [onChange]
  );

  const handleStatusChange = useCallback(
    (status: string) => {
      onChange((dto) => ({ ...dto, status }));
    },
    [onChange]
  );

  const handleTitleChange = useCallback(
    (title: string) => {
      onChange((dto) => ({ ...dto, title }));
    },
    [onChange]
  );

  const handleTitleSecondLineChange = useCallback(
    (titleSecondLine: string) => {
      onChange((dto) => ({ ...dto, titleSecondLine }));
    },
    [onChange]
  );

  const handleDescriptionChange = useCallback(
    (description: string) => {
      onChange((dto) => ({ ...dto, description }));
    },
    [onChange]
  );

  const handleSchemaNameChange = useCallback(
    (schemaName: string) => {
      onChange((dto) => ({ ...dto, schemaName }));
    },
    [onChange]
  );

  const handleSchemaNameSecondLineChange = useCallback(
    (schemaNameSecondLine: string) => {
      onChange((dto) => ({ ...dto, schemaNameSecondLine }));
    },
    [onChange]
  );

  const handleStageChange = useCallback(
    (stage?: DocStageType) => {
      onChange((dto) => ({ ...dto, stage }));
    },
    [onChange]
  );

  const ru = style === 'RU';

  return (
    <InputGroup>
      <InputField
        label={$s.infoGroup.id[style]}
        value={form.code}
        onChange={handleCodeChange}
        maxLength={$s.infoGroup.id.size[style]}
      />
      {!ru && (
        <InputField
          label={$s.infoGroup.contractorDocumentNumber.label}
          value={form.contractorDocumentNumber}
          onChange={handleContractorDocumentNumberChange}
          maxLength={$s.infoGroup.contractorDocumentNumber.size}
        />
      )}
      <InputField
        label={$s.infoGroup.title.firstLine[style]}
        value={form.title}
        onChange={handleTitleChange}
        maxLength={$s.infoGroup.title.size[style]}
      />
      <InputField
        label={$s.infoGroup.title.secondLine[style]}
        value={form.titleSecondLine}
        onChange={handleTitleSecondLineChange}
        maxLength={$s.infoGroup.title.size[style]}
      />

      {ru ? (
        <>
          <InputField
            label={$s.infoGroup.diagramName.firstLine}
            value={form.schemaName}
            onChange={handleSchemaNameChange}
            maxLength={$s.infoGroup.diagramName.size}
          />
          <InputField
            label={$s.infoGroup.diagramName.secondLine}
            value={form.schemaNameSecondLine}
            onChange={handleSchemaNameSecondLineChange}
            maxLength={$s.infoGroup.diagramName.size}
          />
        </>
      ) : (
        <InputField
          label={$s.infoGroup.description.label}
          value={form.description}
          onChange={handleDescriptionChange}
          maxLength={$s.infoGroup.description.size}
        />
      )}
      <InputRow>
        <InputField
          label={$s.infoGroup.stationName.label}
          value={project.stationName}
          onChange={() => {}}
          disabled={true}
        />
        <Tooltip text="To change Station name open SLD stage settings" placement={'bottom-end'} offset={[12, 16]} />
      </InputRow>
      {!ru && (
        <InputField
          label={$s.infoGroup.revision.label}
          value={form.revision}
          onChange={handleRevisionChange}
          maxLength={$s.infoGroup.revision.size}
        />
      )}
      {!ru && (
        <InputField
          label={$s.infoGroup.status.label}
          value={form.status}
          onChange={handleStatusChange}
          maxLength={$s.infoGroup.status.size}
        />
      )}
      {ru && (
        <InputRadioGroup<DocStageType>
          label="Stage"
          value={form.stage}
          options={stages}
          onChange={handleStageChange}
          name="stage"
        />
      )}
    </InputGroup>
  );
};
