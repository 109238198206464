import { Path } from '../Path';
import { BasePoint, RestrictedDirectionPoint } from '../../../../geometry/Point';
import { DirectedVector, Vector } from '../../../../geometry/Vector';
import { OppositeDirection } from '../../../../geometry/Direction';
import { DirectionCoordinate } from '../../../../geometry/Coordinate';

export enum PreferredSide {
  TopLeft,
  BottomRight,
}

export class AngledPath implements Path {
  private readonly side: PreferredSide;

  /**
   * @param side -- preferred side for angle, BottomRight by default.
   * We need it because sometimes we have to save existing angle
   */
  constructor(side: PreferredSide = PreferredSide.BottomRight) {
    this.side = side;
  }

  getPoints(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): BasePoint[] {
    return [start, ...this.getConnector(start, end), end];
  }

  private getConnector(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint) {
    const orderedSuppliers = [getBottomRightConnectorIfPossible, getTopLeftConnectorIfPossible];

    if (this.side === PreferredSide.TopLeft) {
      orderedSuppliers.reverse();
    }

    for (const orderedSupplier of orderedSuppliers) {
      const result = orderedSupplier(start, end);
      if (result) {
        return result;
      }
    }
    return getComplexConnector(start, end);
  }
}

const getBottomRightConnectorIfPossible = (
  start: RestrictedDirectionPoint,
  end: RestrictedDirectionPoint
): BasePoint[] | undefined => {
  const directions = new Vector(start, end).getDirections();
  const xDirection = directions.x;
  const yDirection = directions.y;
  if (
    !start.isRestricted(xDirection.getEnumValue()) &&
    !end.isRestricted(new OppositeDirection(yDirection).getEnumValue())
  ) {
    return [new BasePoint(new DirectedVector(xDirection, start, Math.abs(end.x - start.x)).getEnd().x, start.y)];
  }
};

const getTopLeftConnectorIfPossible = (
  start: RestrictedDirectionPoint,
  end: RestrictedDirectionPoint
): BasePoint[] | undefined => {
  const directions = new Vector(start, end).getDirections();
  const xDirection = directions.x;
  const yDirection = directions.y;

  if (
    !start.isRestricted(yDirection.getEnumValue()) &&
    !end.isRestricted(new OppositeDirection(xDirection).getEnumValue())
  ) {
    return [new BasePoint(start.x, new DirectedVector(yDirection, start, Math.abs(end.y - start.y)).getEnd().y)];
  }
};

const getComplexConnector = (start: RestrictedDirectionPoint, end: RestrictedDirectionPoint) => {
  const directions = new Vector(start, end).getDirections();
  const xDirection = directions.x;
  const yDirection = directions.y;

  const startDirection = !start.isRestricted(xDirection.getEnumValue()) ? xDirection : yDirection;

  const sharedCoord = new DirectionCoordinate(startDirection).getName();
  const connectorStart = start.clone();
  const connectorsSharedCoordValue = start[sharedCoord] + (end[sharedCoord] - start[sharedCoord]) / 2;
  connectorStart[sharedCoord] = connectorsSharedCoordValue;
  const connectorEnd = end.clone();
  connectorEnd[sharedCoord] = connectorsSharedCoordValue;
  return [connectorStart, connectorEnd];
};
