import * as React from 'react';
import { PortWidget } from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import { BusPortModel } from './BusPortModel';
import { BusPortVisibleSize } from './BusNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export interface BusPortWidgetProps {
  port: BusPortModel;
  engine: DiagramEngine;
  size: number;
}

export const PortPlacement = styled.div<{ size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Port = styled.div<{ size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background: #fff;
  border-radius: 50%;
`;

export const BusPortWidget: React.FC<BusPortWidgetProps> = ({ port, engine, size }) => {
  return (
    <PortWidget
      style={{
        height: size,
        width: size,
        top: 0,
        left: port.getBusOffset(),
        position: 'absolute',
      }}
      port={port}
      engine={engine as any}
    >
      <PortPlacement size={size}>
        <Port size={BusPortVisibleSize} />
      </PortPlacement>
    </PortWidget>
  );
};
