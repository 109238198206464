import * as React from 'react';
import { PeformanceWidget } from '@projectstorm/react-canvas-core';
import styled from '@emotion/styled';
import ResizeObserver from 'resize-observer-polyfill';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { RotatableNodeModel } from '../../generics/RotatableNodeModel';
import { RotatedWidget } from '../../RotatedWidget';
import { DiagramEngine } from '../engine/DiagramEngine';

export interface NodeWidgetProps {
  node: RotatableNodeModel;
  children?: any;
  diagramEngine: DiagramEngine;
}

namespace S {
  export const Node = styled.div`
    position: absolute;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    user-select: none;
    pointer-events: all;
  `;
}

export const NgGraceNodeWidget: React.FC<NodeWidgetProps> = ({ node, diagramEngine }) => {
  const ref = useMemo<RefObject<HTMLDivElement>>(() => React.createRef(), []);

  const updateNodeDimensions = useCallback((width, height) => node.updateDimensions({ width, height }), [node]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new ResizeObserver((entities: ResizeObserverEntry[]) => {
      const bounds = entities[0].contentRect;
      updateNodeDimensions(bounds.width, bounds.height);
    });
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, updateNodeDimensions]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLastUpdate] = useState(Date.now());
  useEffect(
    () =>
      node.registerListener({
        selectionChanged: () => setLastUpdate(Date.now()),
        positionChanged: () => setLastUpdate(Date.now()),
        sizeChanged: () => setLastUpdate(Date.now()),
        payloadChanged: () => setLastUpdate(Date.now()),
      }).deregister,
    [node]
  );

  const hour = node.getRotation();
  const position = node.getPositionToRender();
  const size = node.getRotatedSize();
  const originalSize = node.getSize();
  return (
    <PeformanceWidget model={node} serialized={node.serialize()}>
      {() => (
        <S.Node
          className="node"
          ref={ref}
          data-nodeid={node.getID()}
          style={{
            top: position.y,
            left: position.x,
            height: size.y,
            width: size.x,
          }}
        >
          <RotatedWidget width={originalSize.x} height={originalSize.y} hour={hour}>
            {diagramEngine.generateWidgetForNode(node)}
          </RotatedWidget>
        </S.Node>
      )}
    </PeformanceWidget>
  );
};
