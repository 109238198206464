import * as React from 'react';
import { FormEvent, MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import { EngineContext } from '../EngineContext';
import { Styled as S } from './Properties.styled';
import { isBusNodeModel, isDirectoryNodeModel } from '../NgGraceModel';
import {
  FieldRecord,
  isDirectoryFieldRecord,
  isInputFieldRecord,
  NodeDirectory,
  PortRecord,
} from '../directory/NodeDirectory';
import { DirectoryNodeModel, DirectoryNodeModelPayload } from '../directory/DirectoryNodeModel';
import { InputField } from '../../widgets/InputField';
import { PropertiesDirectoryField } from './PropertiesDirectoryField';
import { PropertiesInputField } from './PropertiesInputField';
import { Btn } from '../../widgets/Btn.styled';
import { PayloadNodeModel } from '../generics/PayloadNodeModel';
import { BusNodeModel, BusNodeModelPayload } from '../bus/BusNodeModel';

const preventDefault = (event: MouseEvent) => {
  event.stopPropagation();
};

export interface PropertiesProps {
  directory: NodeDirectory;
}

type UnionNodeModelPayload = DirectoryNodeModelPayload | BusNodeModelPayload;

export const Properties: React.FC<PropertiesProps> = ({ directory }) => {
  const [node, setNode] = useState<PayloadNodeModel>();
  const [payload, setPayload] = useState<UnionNodeModelPayload>();
  const [entry, setEntry] = useState<{ fields: FieldRecord[]; ports: PortRecord[] }>({ fields: [], ports: [] });
  const [dirty, setDirty] = useState(false);
  const engine = useContext(EngineContext);

  useEffect(() => {
    return engine.onSelectionChange(() => {
      const selectedNodes = engine
        .getModel()
        .getSelectedEntities()
        .filter((node) => isDirectoryNodeModel(node) || isBusNodeModel(node));

      const selectedNode =
        selectedNodes.length === 1 ? (selectedNodes[0] as DirectoryNodeModel | BusNodeModel) : undefined;

      setNode(selectedNode);
      const payload = selectedNode ? selectedNode.getPayload() : undefined;
      if (selectedNode && isDirectoryNodeModel(selectedNode)) {
        const entry = directory.getEntry((payload as DirectoryNodeModelPayload).directoryEntryId);
        setEntry({ fields: entry.fields, ports: entry.ports });
      } else {
        setEntry({ fields: [], ports: [] });
      }
      setPayload(payload);
      setDirty(false);
    });
  }, [directory, engine]);

  const handlePayloadChange = useCallback((changedPayload: UnionNodeModelPayload) => {
    setPayload(changedPayload);
    setDirty(true);
  }, []);

  const handleProjectNameChange = useCallback(
    (projectName: string) => {
      handlePayloadChange({ ...payload, projectName } as UnionNodeModelPayload);
    },
    [handlePayloadChange, payload]
  );

  const handleOperationalNameChange = useCallback(
    (operationName: string) => {
      handlePayloadChange({ ...payload, operationName } as UnionNodeModelPayload);
    },
    [handlePayloadChange, payload]
  );

  const handleSave = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (node && payload) {
        const currentPayload = node.getPayload() as DirectoryNodeModelPayload;
        const { operationName, projectName, fields, portFields } = payload as DirectoryNodeModelPayload;

        node.updatePayload({ ...currentPayload, operationName, projectName, fields, portFields });
        setDirty(false);
      }
    },
    [node, payload]
  );

  if (!node || !payload) {
    return <></>;
  }

  return (
    <S.Properties onMouseDown={preventDefault} onSubmit={handleSave}>
      <S.Header>Properties</S.Header>
      <S.Content>
        <S.ContentItem>
          <S.ContentItemLabel>Project name</S.ContentItemLabel>
          <InputField value={payload.projectName} disabled={node.isLocked()} onChange={handleProjectNameChange} />
        </S.ContentItem>
        <S.ContentItem>
          <S.ContentItemLabel>Operational name</S.ContentItemLabel>
          <InputField value={payload.operationName} disabled={node.isLocked()} onChange={handleOperationalNameChange} />
        </S.ContentItem>

        {entry.fields.map((field) => (
          <PropertyField
            key={`${field.name}`}
            field={field}
            payload={payload as DirectoryNodeModelPayload}
            disabled={node.isLocked()}
            onChange={handlePayloadChange}
          />
        ))}

        {entry.ports.flatMap((port) => {
          return port.fields.map((field) => (
            <PropertyField
              key={`${port.name}_${field.name}`}
              field={field}
              payload={payload as DirectoryNodeModelPayload}
              port={port}
              disabled={node.isLocked()}
              onChange={handlePayloadChange}
            />
          ));
        })}
        {!node.isLocked() && (
          <S.Footer>
            <Btn type="submit" disabled={!dirty}>
              Save
            </Btn>
          </S.Footer>
        )}
      </S.Content>
    </S.Properties>
  );
};

interface PropertyFieldProps {
  field: FieldRecord;
  port?: PortRecord;
  payload: DirectoryNodeModelPayload;
  disabled?: boolean;

  onChange(payload: DirectoryNodeModelPayload): void;
}

const PropertyField: React.FC<PropertyFieldProps> = ({ field, port, payload, disabled, onChange }) => {
  if (isDirectoryFieldRecord(field)) {
    return (
      <PropertiesDirectoryField field={field} payload={payload} port={port} disabled={disabled} onChange={onChange} />
    );
  }

  if (isInputFieldRecord(field)) {
    return <PropertiesInputField payload={payload} field={field} port={port} disabled={disabled} onChange={onChange} />;
  }

  return <></>;
};
