import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { Segment } from './SmartLinkModel';
import { Point } from '@projectstorm/geometry';
import { SmartLinkPointModel } from '../../point/SmartLinkPointModel';
import { Path } from './path/Path';
import { PortEndedPath } from './path/default/PortEndedPath';
import { AngledPath } from './path/default/AngledPath';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { NgGraceLinkModel } from '../NgGraceLinkModel';
import { BasePoint, RestrictedDirectionPoint } from '../../geometry/Point';
import { NormalizingAngledPath } from './path/default/NormalizingAngledPath';

export const ConnectingSmartLinkModelType = 'connecting-smart';

export class ConnectingSmartLinkModel extends NgGraceLinkModel {
  path: Path;

  constructor(source: ConnectablePortModel) {
    super({
      type: ConnectingSmartLinkModelType,
      width: 1,
      color: 'black',
    });
    this.path = new PortEndedPath(new NormalizingAngledPath(new AngledPath()), source);
  }

  setSourcePort(port: ConnectablePortModel) {
    super.setSourcePort(port);
    this.setPointsByPositions(this.path.getPoints(new RestrictedDirectionPoint(port.getCenter()), port.getConnector()));
  }

  getSourcePort(): ConnectablePortModel {
    return super.getSourcePort() as ConnectablePortModel;
  }

  getPoints(): SmartLinkPointModel[] {
    return super.getPoints() as SmartLinkPointModel[];
  }

  canConnectTarget(port: ConnectablePortModel): boolean {
    const source = this.getSourcePort();
    return source.getParent().getID() !== port.getParent().getID();
  }

  setTargetPort(port: ConnectablePortModel) {
    super.setTargetPort(port);
    this.setPoints(
      new UpdatingDependenciesConnectingPath(
        new DefaultConnectingPath(new PortEndedPath(this.path, port), this)
      ).connect(
        new RestrictedDirectionPoint(this.getSourcePort().getCenter()),
        new RestrictedDirectionPoint(port.getCenter())
      )
    );
  }

  getTargetPort(): ConnectablePortModel {
    return super.getTargetPort() as ConnectablePortModel;
  }

  setEnd(point: BasePoint) {
    this.setPointsByPositions(
      this.path.getPoints(
        new RestrictedDirectionPoint(this.getSourcePort().getCenter()),
        new RestrictedDirectionPoint(point)
      )
    );
  }

  getSegments(): Segment[] {
    const result = [];
    const points = this.getPoints();
    for (let i = 0; i < points.length - 1; i++) {
      result.push(new Segment(points[i], points[i + 1], this.getOptions().width!));
    }
    return result;
  }

  private setPointsByPositions(positions: BasePoint[]) {
    this.setPoints(positions.map((position) => this.generatePoint(position.x, position.y)));
  }

  generatePoint(x: number = 0, y: number = 0): SmartLinkPointModel {
    return new SmartLinkPointModel({
      link: this,
      position: new Point(x, y),
    });
  }

  getColor() {
    return this.getOptions().color;
  }

  isPersistent() {
    return false;
  }
}
