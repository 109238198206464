import { NodeLayerModelGenerics } from '@projectstorm/react-diagrams';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { RotatableNodeModel } from '../../../generics/RotatableNodeModel';
import { DefaultNodeLayerModel } from '../../../insides/layer/DefaultNodeLayerModel';
import { NodeWithPlaceholders } from '../../../placeholder/NodeWithPlaceholders';
import { DefaultCoordinate } from '../../../geometry/Coordinate';
import { DirectoryLogicDeviceModel } from '../../logic-device/LogicDeviceModel';
import { Listenable } from '../../../placeholder/Listenable';
import { HasSizedChildrenListener } from '../../../placeholder/HasSize';
import { NodeWithPlaceholdersEquivalentSet } from '../../../placeholder/node-sets/NodeWithPlaceholdersEquivalentSet';
import { NodeWithPlaceholdersOrderedSets } from '../../../placeholder/node-sets/NodeWithPlaceholdersOrderedSets';

export type SsdNodeModel = RotatableNodeModel &
  NodeWithPlaceholders<DirectoryLogicDeviceModel> &
  Listenable<HasSizedChildrenListener>;

export interface SsdNodeLayerModelGenerics extends NodeLayerModelGenerics {
  CHILDREN: SsdNodeModel;
}

export class SsdNodeLayerModel<
  G extends SsdNodeLayerModelGenerics = SsdNodeLayerModelGenerics
> extends DefaultNodeLayerModel<G> {
  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    Promise.resolve().then(() =>
      [new DefaultCoordinate('x'), new DefaultCoordinate('y')].forEach((coord) => {
        const sets = new NodeWithPlaceholdersOrderedSets(Object.values(this.getNodes()), coord).getSets();
        [...sets.getSets()].reduce(
          (prevSet: NodeWithPlaceholdersEquivalentSet | undefined, curSet: SsdNodeModel[], index) => {
            curSet.forEach((node) => node.setLayerIndex(coord, index));
            return new NodeWithPlaceholdersEquivalentSet(curSet, coord, prevSet);
          },
          undefined
        );
      })
    );
  }
}

export type ComparisonResult = 1 | 0 | -1;
