import styled, { css } from 'styled-components';
import { Styled as StyledSidebar } from './Sidebar.styled';

const Page = styled.div<{ sidebar?: boolean }>`
  margin: 72px 0 0 120px;
  height: 100vh;
  background: ${(props) => props.theme.colors.lightgrey};
  transition: 0.5s;

  ${(props) =>
    !props.sidebar &&
    css`
      margin-left: 0;
    `};
`;

const PageContent = styled.div<{ sidebar?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  flex-grow: 1;

  ${(props) =>
    props.sidebar &&
    css`
      ${StyledSidebar.Sidebar}:first-child {
        box-shadow: none;
      }
    `};
`;

export const Styled = {
  Page,
  PageContent,
};
