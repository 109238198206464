import React, { FormEvent, useCallback, useState } from 'react';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { AnonymUserPage } from '../../AnonymUserPage';
import { Styled as S } from './../AuthForm.styled';
import { Api } from '../../../api/Api';
import { useUser } from './UserContext';
import { useOverlay } from '../../context/OverlayContext';
import Logo from '../../../assets/logo.svg';
import { InputField } from '../../widgets/InputField';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';
import { InputFieldPassword } from '../../widgets/InputFieldPassword';
import { isEmpty } from '../../../utils/string-utils';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { registerCompanyPath, passwordRecoveryPath, landingPath, redirectToProjectsPage } = useRoutes();
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const setUser = useUser()!.setUser;

  const validateInput = useCallback((): string => {
    let errorMessage = '';
    if (isEmpty(email)) {
      errorMessage += 'Email is required\n';
    }
    if (isEmpty(password)) {
      errorMessage += 'Password is required';
    }
    return errorMessage;
  }, [email, password]);

  const handleLogin = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      const inputValidationResult = validateInput();
      if (!isEmpty(inputValidationResult)) {
        setError(inputValidationResult);
        return;
      }
      setLoading(true);
      overlay.show();
      Api.authenticate({ username: email, password: password }, { omitInterceptorErrorModal: true })
        .then(() => {
          Api.getCurrentUser().then((res) => {
            setUser(res.data);
            redirectToProjectsPage();
          });
          overlay.hide();
        })
        .catch((error) => {
          setError(error.response.data);
          setLoading(false);
          overlay.hide();
        });
    },
    [validateInput, overlay, email, password, setUser, redirectToProjectsPage]
  );

  const handleEmailChange = useCallback((email: string) => {
    setError(undefined);
    setEmail(email);
  }, []);

  const handlePasswordChange = useCallback((password: string) => {
    setError(undefined);
    setPassword(password);
  }, []);

  return (
    <AnonymUserPage>
      <S.AuthForm>
        <S.Main onSubmit={handleLogin}>
          <S.LeftPanel>
            <S.Title>Login</S.Title>
            <S.Description>
              Please use your login credentials to log in.
              <br />
              If you are not a member, please make&nbsp;
              <S.Link to={registerCompanyPath}>company registration request</S.Link>.
              <br /> <br />
              About <S.Link to={landingPath}>ng.Grace</S.Link>
            </S.Description>
          </S.LeftPanel>
          <S.RightPanel>
            <S.Logo src={Logo} alt="ngGrace Logo" />
            <div>Login</div>
            <S.Form>
              <InputField label={'Work email'} value={email} onChange={handleEmailChange} />
              <InputFieldPassword label={'Password'} value={password} onChange={handlePasswordChange} />
            </S.Form>
            {error && <Feedback error text={error} />}
            <S.Footer>
              <S.Links>
                <S.Link to={registerCompanyPath}>Register company</S.Link>
                <S.Link to={passwordRecoveryPath}>Forgot password?</S.Link>
              </S.Links>
              <S.Buttons>
                <Btn type="submit" disabled={loading}>
                  {!loading ? 'Login' : 'Processing'}
                </Btn>
              </S.Buttons>
            </S.Footer>
          </S.RightPanel>
        </S.Main>
      </S.AuthForm>
    </AnonymUserPage>
  );
};
