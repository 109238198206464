import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { HasNotExtendableParentModel, NotSelectableModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { LanModel } from '../../../editor/lan/LanModel';
import { BuildingLayerModel } from '../../../editor/lan/building/layer/BuildingLayerModel';
import { LanStructureTreeBuildingProps } from './LanStructureTreeBuilding';
import { BuildingModel } from '../../../editor/lan/building/BuildingModel';
import { DefaultHasName } from '../../../editor/lan/building/HasName';
import { NameConfiguration } from './LanStructureTree';

export const LanStructureTreeStation = (
  station: string,
  model: LanModel,
  buildingConfigurator: () => Promise<NameConfiguration>,
  buildingFactory: Factory<LanStructureTreeBuildingProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableLanStructureTreeStationNode(model.getBuildingLayer(), buildingConfigurator);
  return {
    ...LanStructureTreeStationModel(extendable, station, model.getBuildingLayer(), buildingFactory),
    ...extendable,
    ...HasNotExtendableParentModel(),
    ...NotSelectableModel(),
  };
};

const LanStructureTreeStationModel = (
  parent: ExtendableTreeNode,
  station: string,
  buildingLayer: BuildingLayerModel,
  buildingFactory: Factory<LanStructureTreeBuildingProps, TreeNodeState>
): TreeNodeModel => ({
  getName: () => station,
  getKey: () => station,
  onChildrenChanged: (callback) => {
    return buildingLayer.registerListener({
      modelAdded: ({ model }: { model: BuildingModel }) => callback(buildingFactory({ model, parent }), true),
      modelRemoved: ({ model }: { model: BuildingModel }) => callback(buildingFactory({ model, parent }), false),
    } as any).deregister;
  },
  getChildren: () => {
    const buildings = Object.values(buildingLayer.getModels());
    return buildings.map((model) => buildingFactory({ parent, model }));
  },
});

const ExtendableLanStructureTreeStationNode = (
  buildingLayer: BuildingLayerModel,
  buildingConfigurator: () => Promise<NameConfiguration>
): ExtendableTreeNode => ({
  canAddChild: (childPayload) => {
    return childPayload.type === TreeDraggableType.Building;
  },
  addChild: (childPayload: TreeDraggablePayload) => {
    if (!childPayload.modelId) {
      createBuilding()
        .then((building) => buildingLayer.addModel(building))
        .catch(() => {});
    } else {
      const building = buildingLayer.getModels()[childPayload.modelId];

      building.remove();
      buildingLayer.addModel(building);
    }

    async function createBuilding(): Promise<BuildingModel> {
      const configuration = await buildingConfigurator();
      return new BuildingModel(new DefaultHasName(configuration.name));
    }
  },
});
