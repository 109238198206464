import { DirectoryNodeModel } from '../../../directory/DirectoryNodeModel';
import { ExportDirectoryNodeLabel } from './ExportDirectoryNodeLabel';
import * as React from 'react';
import { NodeDirectory } from '../../../directory/NodeDirectory';

type DirectoryNodeSvgCache = { [nodeId: string]: any };

interface ExportDirectoryNodeProps {
  directory: NodeDirectory;
  svgCache: DirectoryNodeSvgCache;
  node: DirectoryNodeModel;
}

export const ExportDirectoryNode: React.FC<ExportDirectoryNodeProps> = ({ directory, svgCache, node }) => {
  const record = directory.getEntry(node.getDirectoryEntryId());
  const hour = node.getRotation();
  const rotatedOffset = hour.getDirection().isHorizontal() ? (record.width - record.height) / 2 : 0;
  const translateX = node.getPositionToRender().x - rotatedOffset;
  const translateY = node.getPositionToRender().y + rotatedOffset;
  const angle = hour.getNumericAngle();
  return (
    <g transform={`translate(${translateX}, ${translateY})`}>
      <ExportDirectoryNodeLabel node={node} />
      <g
        transform={`rotate(${angle} ${record.width / 2} ${record.height / 2})`}
        dangerouslySetInnerHTML={{ __html: svgCache[node.getDirectoryEntryId()] }}
      />
    </g>
  );
};
