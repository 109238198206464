import styled from 'styled-components';

const Accordion = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Button = styled.button<{ expanded: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 16px 0 56px;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => (props.expanded ? props.theme.colors.turquoise : props.theme.colors.grey)};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% + 1px);
    left: 23px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: ${(props) => (props.expanded ? 'none' : `5px solid ${props.theme.colors.grey}`)};
    border-bottom: ${(props) => (props.expanded ? `5px solid ${props.theme.colors.turquoise}` : 'none')};
    transform: translateY(-50%);
  }
`;

const Title = styled.span`
  font-weight: 700;
`;

const Subtitle = styled.span``;

const Content = styled.div``;

const List = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 48px;
  gap: 12px;
  width: 100%;
  padding: 0 16px 0 56px;
`;

const ListItem = styled.div`
  display: contents;
`;

const ListItemName = styled.span``;

const ListItemValue = styled.span``;

export const Styled = {
  Accordion,
  Button,
  Title,
  Subtitle,
  Content,
  List,
  ListItem,
  ListItemName,
  ListItemValue,
};
