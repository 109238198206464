import { HasRect } from '../HasRect';
import { HasRelativeModel } from '../HasRelativeModel';

import { AddedPoint, BasePoint } from '../../geometry/Point';
import { Point, Rectangle } from '@projectstorm/geometry';
import { PlaceholderGroupOffset } from '../Placeholder';
import { RotatableNodeModel } from '../../generics/RotatableNodeModel';

export class HasAbsoluteRect<T extends RotatableNodeModel> implements HasRect {
  private relativeOrigin: HasRect;
  private hasRelativeModel: HasRelativeModel<T>;

  constructor(relativeOrigin: HasRect, hasRelativeModel: HasRelativeModel<T>) {
    this.relativeOrigin = relativeOrigin;
    this.hasRelativeModel = hasRelativeModel;
  }

  getPosition(): BasePoint {
    return this.relativeOrigin.getPosition();
  }

  getRect(): Rectangle | undefined {
    const relativeRect = this.relativeOrigin.getRect();
    const modelPosition = this.hasRelativeModel.getRelativeModel()?.getPositionToRender();
    const modelSize = this.hasRelativeModel.getRelativeModel()?.getRotatedSize();
    if (!modelPosition || !relativeRect || !modelSize) {
      return relativeRect;
    }

    return new Rectangle(
      new AddedPoint(
        new AddedPoint(new AddedPoint(relativeRect.getTopLeft(), modelPosition), PlaceholderGroupOffset),
        new Point(modelSize.x, 0)
      ),
      relativeRect.getWidth(),
      relativeRect.getHeight()
    );
  }

  getSize(): BasePoint {
    return this.relativeOrigin.getSize();
  }

  setPosition(newValue: BasePoint): void {
    return this.relativeOrigin.setPosition(newValue);
  }
}
