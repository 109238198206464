import { Action, ActionEvent, ActionEventBus, InputType } from '@projectstorm/react-canvas-core';
import { KeyboardEvent } from 'react';

export class NgGraceActionEventBus extends ActionEventBus {
  getActionsForEvent(actionEvent: ActionEvent): Action[] {
    const { event } = actionEvent;
    if (event.type === 'mousedown') {
      return this.getActionsForType(InputType.MOUSE_DOWN);
    } else if (event.type === 'mouseup') {
      return this.getActionsForType(InputType.MOUSE_UP);
    } else if (event.type === 'keydown') {
      // store the recorded key
      this.keys[(event as KeyboardEvent).key.toLowerCase()] = true;
      return this.getActionsForType(InputType.KEY_DOWN);
    } else if (event.type === 'keyup') {
      // delete the recorded key
      delete this.keys[(event as KeyboardEvent).key.toLowerCase()];
      return this.getActionsForType(InputType.KEY_UP);
    } else if (event.type === 'mousemove') {
      return this.getActionsForType(InputType.MOUSE_MOVE);
    } else if (event.type === 'wheel') {
      return this.getActionsForType(InputType.MOUSE_WHEEL);
    } else if (event.type === 'blur' || event.type === 'focus') {
      this.keys = {};
    }
    return [];
  }
}
