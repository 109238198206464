import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { ConnectivityNodeWidget } from './ConnectivityNodeWidget';
import { ConnectivityNodeModel } from './ConnectivityNodeModel';
import { RawConnectivityNodeModel } from './RawConnectivityNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export class ConnectivityNodeFactory extends AbstractReactFactory<ConnectivityNodeModel, DiagramEngine> {
  constructor() {
    super('connectivity');
  }

  generateReactWidget(event: { model: ConnectivityNodeModel }): JSX.Element {
    const model = event.model;
    return <ConnectivityNodeWidget engine={this.engine} node={model} />;
  }

  generateModel(event: any) {
    return new RawConnectivityNodeModel();
  }
}
