import styled from 'styled-components';
import { Icon } from './Icon.styled';
import { Btn } from './Btn.styled';

const MessageModal = styled.div<{ error?: boolean; warn?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding: 32px 32px 32px 24px;
  color: ${(props) =>
    props.error ? props.theme.colors.red : props.warn ? props.theme.colors.orange : props.theme.colors.blue};

  ${Btn} {
    width: 100px;
    border-radius: 100px;
    font-size: 14px;
    line-height: 20px;

    background: ${(props) =>
      props.error ? props.theme.colors.red : props.warn ? props.theme.colors.orange : props.theme.colors.blue};
  }
`;

const Left = styled.div`
  margin-right: 24px;

  ${Icon} {
    width: 21px;
    height: 21px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 488px;
`;

const Header = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const Message = styled.div`
  font-size: 12px;
  margin-bottom: 32px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Styled = {
  MessageModal,
  Left,
  Right,
  Header,
  Message,
  Footer,
};
