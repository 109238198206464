import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '../../Page';
import { ProjectSidebar } from '../project/ProjectSidebar';
import { Styled as S } from './TcoPage.styled';
import { TcoToolbar } from './TcoToolbar';
import { TcoCard } from './TcoCard';
import { CapexCard } from './CapexCard';
import { OpexCard } from './OpexCard';
import { ReliabilityCard } from './ReliabilityCard';
import { TcoType } from './types';
import { useStage } from '../../hooks/useStage';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Api } from '../../../api/Api';
import { getCurrency } from '../../../utils/project-utils';

export const TcoPage: React.FC = () => {
  const { projectId } = useParams<{ projectId?: string }>();
  const { stage, finishStage, rollbackStage, reloadStage } = useStage(projectId!, 'TCO');
  const { redirectToStagePage } = useRoutes();

  const nextStage = useCallback(async () => {
    if (stage) {
      const { baseInfo, project } = stage;

      if (project.activeStage.id === baseInfo.id) {
        await Api.createExport(project.id);
        redirectToStagePage(project.id, 'EXPORT');
      } else if (baseInfo.finished) {
        redirectToStagePage(project.id, 'EXPORT');
      }
    }
  }, [redirectToStagePage, stage]);

  const tco = useMemo(() => stage && stage.json && (JSON.parse(stage.json) as TcoType), [stage]);

  return (
    <Page hasSidebar>
      {stage && tco && (
        <>
          <ProjectSidebar project={stage.project} current="TCO" />
          <S.TcoPage>
            <TcoToolbar
              stage={stage}
              onRollbackStage={rollbackStage}
              onFinishStage={finishStage}
              onReloadStage={reloadStage}
              onNext={nextStage}
            />
            <S.Scroll>
              <S.Content>
                <TcoCard title="CAPEX">
                  <CapexCard currency={getCurrency(stage.project.styleSelection)} capex={tco.capex} />
                </TcoCard>
                <TcoCard title="OPEX">
                  <OpexCard
                    currency={getCurrency(stage.project.styleSelection)}
                    opexForm={stage.opexForm!!}
                    opex={tco.opex}
                  />
                </TcoCard>
                <TcoCard title="Reliability">
                  <ReliabilityCard reliability={tco.reliability} />
                </TcoCard>
              </S.Content>
            </S.Scroll>
          </S.TcoPage>
        </>
      )}
    </Page>
  );
};
