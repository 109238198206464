import { SsdEngine } from '../ssd/SsdEngine';
import { FactoryBank } from '@projectstorm/react-canvas-core';
import { ControllerFactory } from './controller/ControllerFactory';
import { NodeDirectory } from '../directory/NodeDirectory';
import {
  ControllerDirectoryEntry,
  LogicDeviceDirectoryEntry,
  LogicNodeDirectoryEntry,
  PropertiesDirectory,
} from '../directory/PropertiesDirectory';
import { ScdModel } from './ScdModel';
import { ControllerLayerFactory } from './layer/controller/ControllerLayerFactory';
import { ScdLogicDeviceFactory } from './logic-device/ScdLogicDeviceFactory';
import { ScdLogicDeviceLayerFactory } from './layer/logic-device/ScdLogicDeviceLayerFactory';
import { ScdDirectoryNodeFactory } from './node/directory/SсdDirectoryNodeFactory';
import { ScdBusNodeFactory } from './node/bus/SсdBusNodeFactory';
import { ScdConnectivityNodeFactory } from './node/connectivity/SсdConnectivityNodeFactory';
import { ScdModelObserver } from './ScdModelObserver';

export class ScdEngine extends SsdEngine {
  protected readonly controllersFactories: FactoryBank<ControllerFactory> = new FactoryBank();

  constructor(
    directory: NodeDirectory,
    logicDeviceDirectory: PropertiesDirectory<LogicDeviceDirectoryEntry>,
    logicNodeDirectory: PropertiesDirectory<LogicNodeDirectoryEntry>,
    controllerDirectory: PropertiesDirectory<ControllerDirectoryEntry>
  ) {
    super(directory, logicDeviceDirectory, logicNodeDirectory, new ScdModelObserver(), 'SCD');

    this.controllersFactories.registerListener({
      factoryAdded: (event: any) => {
        event.factory.setDiagramEngine(this);
      },
      factoryRemoved: (event: any) => {
        event.factory.setDiagramEngine(null);
      },
    });

    this.layerFactories.registerFactory(new ControllerLayerFactory());
    this.layerFactories.registerFactory(new ScdLogicDeviceLayerFactory());

    this.controllersFactories.registerFactory(new ControllerFactory(controllerDirectory));

    this.logicDevicesFactories.clearFactories();
    this.logicDevicesFactories.registerFactory(new ScdLogicDeviceFactory(logicDeviceDirectory, logicNodeDirectory));

    this.nodeFactories.clearFactories();
    [
      new ScdDirectoryNodeFactory(directory),
      new ScdBusNodeFactory(),
      new ScdConnectivityNodeFactory(),
    ].forEach((factory) => this.nodeFactories.registerFactory(factory));
  }

  getControllerFactories() {
    return this.controllersFactories;
  }

  getModel(): ScdModel {
    return super.getModel() as ScdModel;
  }

  buildNewModel() {
    return new ScdModel();
  }
}
