import * as React from 'react';
import { LanPortModel } from '../../../lan/node/port/LanPortModel';
import { LanStrokeWidth } from '../../../lan/node/LanNodeWidget';
import { AddedPoint } from '../../../geometry/Point';
import { Point } from '@projectstorm/geometry';
import { LanLinkModel } from '../../../lan/link/LanLinkModel';
import { ExportLanPortLabel } from './ExportLanPortLabel';
import { ExportLanLinkLabel } from '../link/ExportLanLinkLabel';

interface ExportSwitchPortProps {
  port: LanPortModel;
}

export const ExportLanPort: React.FC<ExportSwitchPortProps> = ({ port }) => {
  const position = port.getRelativePosition();
  const size = port.getSize();
  const link = Object.values(port.getLinks())[0];
  const index = Object.values(port.getParent().getPorts()).lastIndexOf(port);

  const offset = port.getLabelOffset();
  const labelOffset = new Point(offset.x < 0 ? index.toString().length * offset.x : offset.x, offset.y);
  const linkLabelOffset = new AddedPoint(new Point(-3, 5), position);

  return (
    <>
      <ExportLanPortLabel
        port={port}
        position={new Point(position.x + labelOffset.x, position.y - labelOffset.y)}
        size={'s'}
      />
      <rect
        x={LanStrokeWidth / 2 + position.x}
        y={LanStrokeWidth / 2 + position.y}
        fill={port.getPortColor()}
        stroke={port.getPortColor()}
        strokeWidth={LanStrokeWidth}
        width={size - LanStrokeWidth}
        height={size - LanStrokeWidth}
      />
      {link && link instanceof LanLinkModel && <ExportLanLinkLabel link={link} position={linkLabelOffset} size={'s'} />}
    </>
  );
};
