import React from 'react';
import { DroppableChildProps } from '../../editor/dnd/Droppable';
import { Styled as S } from './ListDropHighlighter.styled';

export const ListDropHighlighter: React.FC<DroppableChildProps> = ({
  dropWillSucceed,
  dropWillFail,
  dropAfterWillSucceed,
  children,
}) => {
  return (
    <S.Container>
      {children}
      <S.Highlighter fail={dropWillFail} success={dropWillSucceed} after={dropAfterWillSucceed} />
    </S.Container>
  );
};
