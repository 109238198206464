import {
  AbstractDisplacementState,
  AbstractDisplacementStateEvent,
  Action,
  ActionEvent,
  InputType,
  State,
} from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { LanEngine } from '../LanEngine';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { ConnectingSmartLinkModel } from '../../link/smart/ConnectingSmartLinkModel';
import { BasePoint } from '../../geometry/Point';
import { LanLinkConnectionCommand } from '../command/LanLinkConnectionCommand';
import { LanModel } from '../LanModel';
import { LanPortHighlight, LanPortModel } from '../node/port/LanPortModel';

export interface CreateLinkStateOptions {
  /**
   * If enabled, the links will stay on the canvas if they dont connect to a port
   * when dragging finishes
   */
  allowLooseLinks?: boolean;
  /**
   * If enabled, then a link can still be drawn from the port even if it is locked
   */
  allowLinksFromLockedPorts?: boolean;
}

export class LanCreateLinkState extends AbstractDisplacementState<LanEngine> {
  notifyError: (message: string, title?: string) => void;
  port: ConnectablePortModel | null = null;
  link: ConnectingSmartLinkModel | null = null;
  config: CreateLinkStateOptions;
  highlightedPort?: LanPortModel;

  constructor(notifyError: (message: string, title?: string) => void, options: CreateLinkStateOptions = {}) {
    super({ name: 'create-link-state' });

    this.notifyError = notifyError;

    this.config = {
      allowLooseLinks: true,
      allowLinksFromLockedPorts: false,
      ...options,
    };

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          if (this.port && this.link) {
            // mouse down has been already pressed, ignore further fire (for instance, right click while mouse move)
            this.eject();
            return;
          }

          const mouseEvent: MouseEvent = event.event as MouseEvent;
          this.port = this.engine.getMouseElement(mouseEvent) as ConnectablePortModel;
          if (!this.config.allowLinksFromLockedPorts && this.port.isLocked()) {
            this.eject();
            return;
          }
          this.link = this.port.createLinkModel();
          if (!this.link) {
            this.eject();
            return;
          }
          this.engine.getModel().clearSelection();
          this.link.setSelected(true);
          this.link.setSourcePort(this.port);
          this.engine.getModel().addLink(this.link);
          this.port.reportPosition();
        },
      })
    );

    this.registerAction(
      new Action({
        type: InputType.MOUSE_UP,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const mouseEvent: MouseEvent = event.event as MouseEvent;
          const model = this.engine.getMouseElement(mouseEvent);
          // check to see if we connected to a new port
          // noinspection SuspiciousTypeOfGuard
          if (model instanceof ConnectablePortModel) {
            if (this.port!.canLinkToPort(model)) {
              return new LanLinkConnectionCommand(this.engine.getModel() as LanModel, this.link!, model).execute();
            } else {
              this.link!.remove();
              this.engine.repaintCanvas();
              this.notifyError('Incompatible port types');
              return;
            }
          }

          if (!this.config.allowLooseLinks) {
            this.link!.remove();
            this.engine.repaintCanvas();
          }
        },
      })
    );
  }

  activated(previous: State) {
    super.activated(previous);
    this.port = null;
    this.link = null;
  }

  deactivated(next: State) {
    this.link?.remove();
    this.engine.repaintCanvas();
    this.highlightedPort?.setHighlight(LanPortHighlight.NONE);
    super.deactivated(next);
  }

  /**
   * Calculates the link's far-end point position on mouse move.
   * In order to be as precise as possible the mouse initialXRelative & initialYRelative are taken into account as well
   * as the possible engine offset
   */
  fireMouseMoved(event: AbstractDisplacementStateEvent): any {
    const mouseEvent: MouseEvent = event.event as MouseEvent;
    const model = this.engine.getMouseElement(mouseEvent);

    if (model instanceof LanPortModel) {
      if (this.highlightedPort?.getID() !== model.getID()) {
        this.highlightedPort?.setHighlight(LanPortHighlight.NONE);
        this.highlightedPort = model;
        this.highlightedPort.setHighlight(
          this.port!.canLinkToPort(model) ? LanPortHighlight.ALLOW : LanPortHighlight.FORBID
        );
      }
    } else {
      this.highlightedPort?.setHighlight(LanPortHighlight.NONE);
      this.highlightedPort = undefined;
    }

    const portPos = this.port!.getPosition();
    const zoomLevelPercentage = this.engine.getModel().getZoomLevel() / 100;
    const engineOffsetX = this.engine.getModel().getOffsetX() / zoomLevelPercentage;
    const engineOffsetY = this.engine.getModel().getOffsetY() / zoomLevelPercentage;
    const initialXRelative = this.initialXRelative / zoomLevelPercentage;
    const initialYRelative = this.initialYRelative / zoomLevelPercentage;

    // need to add offset otherwise engine.getMouseElement(mouseEvent) will return connecting link if there is no nodes under cursor
    const cursorOffset = -5;
    const linkNextX =
      portPos.x - engineOffsetX + (initialXRelative - portPos.x) + event.virtualDisplacementX + cursorOffset;
    const linkNextY =
      portPos.y - engineOffsetY + (initialYRelative - portPos.y) + event.virtualDisplacementY + cursorOffset;

    if (this.link instanceof ConnectingSmartLinkModel) {
      (this.link as ConnectingSmartLinkModel).setEnd(new BasePoint(linkNextX, linkNextY));
    } else {
      this.link!.getLastPoint().setPosition(linkNextX, linkNextY);
    }
    this.engine.repaintCanvas();
  }
}
