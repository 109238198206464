import React, { useCallback, useMemo, useState } from 'react';
import { CompanyDto, CompanyUpdateDto } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { Btn } from '../../widgets/Btn.styled';
import { InputField } from '../../widgets/InputField';
import { Styled as S } from '../Settings.styled';
import { Feedback } from '../../widgets/Feedback';
import { useOverlay } from '../../context/OverlayContext';
import { useUser } from '../login/UserContext';
import { useNotifications } from '../../context/NotificationContext';

interface CompanySettingsProps {
  company?: CompanyDto;
  onClose: (company?: CompanyUpdateDto) => void;
}

export const CompanySettings: React.FC<CompanySettingsProps> = ({ company: initCompany, onClose }) => {
  const [company, setCompany] = useState<CompanyUpdateDto>({ ...initCompany });
  const [error, setError] = useState<string>();
  const overlay = useOverlay();
  const [loading, setLoading] = useState(false);
  const update = useMemo(() => !!initCompany, [initCompany]);
  const { user, setUser } = useUser()!;
  const notifications = useNotifications();

  const handleNameChange = useCallback((name: string) => {
    setError(undefined);
    setCompany((company) => ({ ...company, name }));
  }, []);

  const handleClose = useCallback(() => {
    setCompany({ ...initCompany });
    onClose();
  }, [initCompany, onClose]);

  const handleCreate = useCallback(() => {
    setLoading(true);
    overlay.show();
    Api.createCompany(company, { omitInterceptorErrorModal: true })
      .then(() => {
        onClose(company);
        overlay.hide();
        notifications.notifySuccess('Company created.');
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [company, notifications, onClose, overlay]);

  const handleUpdate = useCallback(() => {
    if (company.name === initCompany!.name) {
      onClose();
      return;
    }
    setLoading(true);
    overlay.show();
    Api.updateCompany(initCompany!.id, { name: company.name }, { omitInterceptorErrorModal: true })
      .then(() => {
        if (user?.company.id === initCompany!.id) {
          setUser({ ...user, company: { name: company.name!, id: initCompany!.id } });
        }
        onClose(company);
        overlay.hide();
        notifications.notifySuccess('Company edited.');
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
        overlay.hide();
      });
  }, [company, initCompany, notifications, onClose, overlay, setUser, user]);

  return (
    <S.Settings>
      <S.Title>{update ? 'Edit' : 'New'} company</S.Title>
      <S.Form>
        <InputField label={'Company'} value={company.name} onChange={handleNameChange} />
      </S.Form>
      <Feedback error={!!error} text={error ? error : ''} />
      <S.Buttons>
        <Btn secondary onClick={handleClose}>
          Cancel
        </Btn>
        <Btn onClick={update ? handleUpdate : handleCreate} disabled={loading}>
          {!loading ? 'Ok' : 'Processing'}
        </Btn>
      </S.Buttons>
    </S.Settings>
  );
};
