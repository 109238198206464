import { KeyboardEvent } from 'react';
import * as _ from 'lodash';
import { NgGraceEngine } from '../NgGraceEngine';
import { Action, InputType } from '@projectstorm/react-canvas-core';

export interface DeleteItemsActionOptions {
  keyCodes?: number[];
  modifiers?: {
    ctrlKey?: boolean;
    shiftKey?: boolean;
    altKey?: boolean;
    metaKey?: boolean;
  };
}

/**
 * Deletes all selected items
 */
export class DeleteItemsAction extends Action {
  constructor(options: DeleteItemsActionOptions = {}) {
    const keyCodes = options.keyCodes || [46, 8];
    const modifiers = {
      ctrlKey: false,
      shiftKey: false,
      altKey: false,
      metaKey: false,
      ...options.modifiers,
    };

    super({
      type: InputType.KEY_DOWN,
      fire: async ({ event }) => {
        const { keyCode, ctrlKey, shiftKey, altKey, metaKey, target } = event as KeyboardEvent;
        const notInput = (target as HTMLElement).nodeName !== 'INPUT';
        const withModifiers = _.isEqual({ ctrlKey, shiftKey, altKey, metaKey }, modifiers);

        if (notInput && keyCodes.indexOf(keyCode) !== -1 && withModifiers) {
          const engine = this.engine as NgGraceEngine;
          await engine.deleteSelected();
        }
      },
    });
  }
}
