import { BaseObserver, DeserializeEvent, ListenerHandle } from '@projectstorm/react-canvas-core';
import { RawConnectivityNodeModel } from './RawConnectivityNodeModel';
import { LayerIndexes, NodeWithPlaceholders, NodeWithPlaceholdersListener } from '../placeholder/NodeWithPlaceholders';
import { ConnectivityNodeModelGenerics } from './ConnectivityNodeModel';
import { BasePoint } from '../geometry/Point';
import { Coordinate } from '../geometry/Coordinate';
import { HasSize } from '../placeholder/HasSize';
import { HasPosition } from '../placeholder/HasPosition';
import { Factory } from '../../../utils/factory';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';

export class ConnectivityNodeWithPlaceholdersModel<PLACEHOLDER_CHILD extends BaseObserver & HasSize & HasPosition>
  extends RawConnectivityNodeModel
  implements NodeWithPlaceholders<PLACEHOLDER_CHILD> {
  private nodeWithPlaceholder: NodeWithPlaceholders<PLACEHOLDER_CHILD>;

  constructor(nodeWithPlaceholderFactory: Factory<RotatableNodeModel, NodeWithPlaceholders<PLACEHOLDER_CHILD>>) {
    super();
    this.nodeWithPlaceholder = nodeWithPlaceholderFactory(this);
  }

  registerListener(
    listener: Partial<ConnectivityNodeModelGenerics['LISTENER'] | NodeWithPlaceholdersListener<PLACEHOLDER_CHILD>>
  ): ListenerHandle {
    return super.registerListener(listener as any);
  }

  addChild(device: PLACEHOLDER_CHILD, index?: number) {
    this.nodeWithPlaceholder.addChild(device, index);
  }

  getChildren() {
    return this.nodeWithPlaceholder.getChildren();
  }

  getStaticPosition(): BasePoint {
    return this.nodeWithPlaceholder.getStaticPosition();
  }

  getOffsetPosition(): BasePoint {
    return this.nodeWithPlaceholder.getOffsetPosition();
  }

  setOffset(offset: number, coordinate: Coordinate): void {
    this.nodeWithPlaceholder.setOffset(offset, coordinate);
  }

  serialize() {
    return {
      ...super.serialize(),
      ...this.nodeWithPlaceholder.serialize(),
    };
  }

  getPlaceholdersSize(): BasePoint {
    return this.nodeWithPlaceholder.getPlaceholdersSize();
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    event.registerModel(this);
  }

  getPositionToRender(): BasePoint {
    return this.getOffsetPosition();
  }

  getLayerIndexes(): LayerIndexes {
    return this.nodeWithPlaceholder.getLayerIndexes();
  }

  setLayerIndex(coord: Coordinate, index: number): void {
    this.nodeWithPlaceholder.setLayerIndex(coord, index);
  }
}
