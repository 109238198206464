import * as React from 'react';
import { DirectoryNodeModel } from './DirectoryNodeModel';
import { DirectoryPortWidget } from './DirectoryPortWidget';
import { NodeDirectoryEntry } from './NodeDirectory';
import { SvgNodeWidget } from '../SvgNodeWidget';
import { DirectoryPortVisibleRadius } from './DirectoryPortModel';
import { LabelWidget } from '../label/LabelWidget';
import styled from 'styled-components';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

interface DirectoryNodeWidgetProps {
  node: DirectoryNodeModel;
  engine: DiagramEngine;
  record: NodeDirectoryEntry;
}

const Node = styled.div<{ selected: boolean; width: number; height: number }>`
  box-sizing: content-box;
  cursor: pointer;

  position: relative;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  transform-origin: center;

  img {
    display: block;
  }
`;

const SelectionBorder = styled.div<{ width: number; height: number }>`
  border: 1px solid deepskyblue;
  border-radius: 1px;
  position: absolute;

  top: ${-1 - DirectoryPortVisibleRadius}px;
  left: ${-1 - DirectoryPortVisibleRadius}px;

  height: ${(p) => p.height + DirectoryPortVisibleRadius * 2}px;
  width: ${(p) => p.width + DirectoryPortVisibleRadius * 2}px;
`;

export const DirectoryNodeWidget: React.FC<DirectoryNodeWidgetProps> = ({ node, engine, record }) => {
  return (
    <>
      <Node selected={node.isSelected()} height={record.height} width={record.width}>
        {node.isSelected() && <SelectionBorder height={record.height} width={record.width} />}
        <SvgNodeWidget width={record.width} height={record.height} source={record.svg} />
        {record.ports.map((portRecord) => (
          <DirectoryPortWidget
            port={node.getPort(portRecord.name)!}
            engine={engine}
            record={portRecord}
            key={node.getPort(portRecord.name)?.getID()}
          />
        ))}
      </Node>
      <LabelWidget label={node.getLabel()} node={node} />
    </>
  );
};
