import React, { useCallback, useContext, useMemo } from 'react';
import { DiagramModelContext } from '../../../editor/DiagramModelContext';
import { TreeNode, TreeNodeChildFactory } from '../../../widgets/tree/TreeNode';
import { Styled as S } from '../../../widgets/library/Library.styled';
import { Tree } from '../../../widgets/tree/Tree';
import { SelectableTreeNodeWidget } from '../../../widgets/tree/SelectableTreeNodeWidget';
import { LanModel } from '../../../editor/lan/LanModel';
import { LanStructureTreeStation } from './LanStructureTreeStation';
import { LanStructureTreeBuildingFactory } from './LanStructureTreeBuilding';
import { TreeDraggable } from '../../../widgets/tree/TreeDraggable';
import {
  ExtendableTreeNode,
  HasExtendableParent,
  TreeDraggableType,
  TreeNodeModel,
} from '../../../widgets/tree/state/TreeState';
import { TreeDroppable } from '../../../widgets/tree/TreeDroppable';
import { ListDropHighlighter } from '../../../widgets/tree/ListDropHighlighter';
import { LanStructureTreeRoomFactory } from './LanStructureTreeRoom';
import { LanStructureTreeRackFactory, LanStructureTreeRackModelFactory } from './LanStructureTreeRack';
import { RackLayerModel } from '../../../editor/lan/rack/layer/RackLayerModel';
import { useDirectory } from '../../../hooks/useDirectory';
import { useLanConfiguration } from './LanConfigurationContext';
import { EngineContext } from '../../../editor/EngineContext';
import { LanEngine } from '../../../editor/lan/LanEngine';
import { LanStructureTreeLanNodeFactory } from './LanStructureTreeLanNode';

export const LanTreeDndFormatDragId: Partial<Record<TreeDraggableType, string>> = {
  Building: 'LanTreeDndFormatBuilding',
  Room: 'LanTreeDndFormatRoom',
  Rack: 'LanTreeDndFormatRack',
  LanNode: 'LanTreeDndFormatLanNode',
};
export const LanTreeDndFormatDropId: Partial<Record<TreeDraggableType, string[]>> = {
  Building: [
    LanTreeDndFormatDragId[TreeDraggableType.Building]!,
    LanTreeDndFormatDragId[TreeDraggableType.Room]!,
    LanTreeDndFormatDragId[TreeDraggableType.Rack]!,
    LanTreeDndFormatDragId[TreeDraggableType.LanNode]!,
  ],
  Room: [
    LanTreeDndFormatDragId[TreeDraggableType.Room]!,
    LanTreeDndFormatDragId[TreeDraggableType.Rack]!,
    LanTreeDndFormatDragId[TreeDraggableType.LanNode]!,
  ],
  Rack: [LanTreeDndFormatDragId[TreeDraggableType.Rack]!, LanTreeDndFormatDragId[TreeDraggableType.LanNode]!],
  LanNode: [LanTreeDndFormatDragId[TreeDraggableType.LanNode]!],
};

interface ScdStructureTreeProps {
  station?: string;
}

export interface NameConfiguration {
  name: string;
}

export const LanStructureTree: React.FC<ScdStructureTreeProps> = ({ station }) => {
  const lanModel = useContext(DiagramModelContext) as LanModel;
  const engine = useContext(EngineContext) as LanEngine;
  const { switchDirectory, networkDeviceTypeDirectory, networkDeviceDirectory } = useDirectory();
  const { buildingConfigurator, roomConfigurator, rackConfigurator } = useLanConfiguration();

  const stationNode = useMemo(() => {
    return LanStructureTreeStation(
      station || 'Station',
      lanModel,
      buildingConfigurator,
      LanStructureTreeBuildingFactory(
        engine,
        lanModel,
        roomConfigurator,
        LanStructureTreeRoomFactory(
          engine,
          lanModel,
          rackConfigurator,
          LanStructureTreeRackFactory(
            engine,
            lanModel,
            switchDirectory,
            networkDeviceTypeDirectory,
            networkDeviceDirectory,
            LanStructureTreeLanNodeFactory(engine),
            () => engine.repaintCanvas()
          ),
          LanStructureTreeRackModelFactory(engine)
        )
      )
    );
  }, [
    station,
    lanModel,
    buildingConfigurator,
    roomConfigurator,
    rackConfigurator,
    switchDirectory,
    networkDeviceTypeDirectory,
    networkDeviceDirectory,
    engine,
  ]);

  const factory = useMemo(() => buildingFactory(roomFactory(rackFactory(lanModel.getRackLayer()))), [lanModel]);

  return (
    <S.Library>
      <S.Header>{'LAN structure'}</S.Header>
      <Tree>
        <LanStructureTreeDroppable model={stationNode} type={TreeDraggableType.Building}>
          <TreeNode model={stationNode} childFactory={factory} hasParent={false} indentLevel={0} last initialOpen />
        </LanStructureTreeDroppable>
      </Tree>
    </S.Library>
  );
};

const buildingFactory = (roomFactory: TreeNodeChildFactory): TreeNodeChildFactory => ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDroppable model={model} type={TreeDraggableType.Room}>
      <TreeNode
        model={model}
        childFactory={roomFactory}
        canHighlight
        indentLevel={indentLevel + 1}
        last={last}
        initialOpen
      />
    </LanStructureTreeDroppable>
  </SelectableTreeNodeWidget>
);

const roomFactory = (rackFactory: TreeNodeChildFactory): TreeNodeChildFactory => ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDraggable type={TreeDraggableType.Room} model={model}>
      <LanStructureTreeDroppable model={model} type={TreeDraggableType.Room}>
        <TreeNode model={model} childFactory={rackFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
      </LanStructureTreeDroppable>
    </LanStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

const rackFactory = (rackLayer: RackLayerModel): TreeNodeChildFactory => ({ model, indentLevel, last }) => {
  const rack = rackLayer.getModels()[model.getKey()];

  // on undo/redo removing rack
  // first of all rackFactory updates and triggers room's useMemo for children mapping
  // but children(racks) array not has been changed yet that's why rack can be not present in rackLayer
  if (!rack) {
    return null;
  }

  if (rack.asSingle()) {
    return SingleRackFactory({ model, indentLevel, last });
  } else if (rack.asComposite()) {
    return CompositeRackFactory({ model, indentLevel, last });
  }
  return <></>;
};

const CompositeRackFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDraggable type={TreeDraggableType.Rack} model={model}>
      <TreeNode
        model={model}
        childFactory={CompositeRackChildFactory}
        canHighlight
        indentLevel={indentLevel + 1}
        last={last}
        initialOpen
      />
    </LanStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

const CompositeRackChildFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDroppable model={model} type={TreeDraggableType.Rack}>
      <TreeNode model={model} childFactory={LanNodeFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
    </LanStructureTreeDroppable>
  </SelectableTreeNodeWidget>
);

const SingleRackFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDraggable type={TreeDraggableType.Rack} model={model}>
      <LanStructureTreeDroppable model={model} type={TreeDraggableType.Rack}>
        <TreeNode model={model} childFactory={LanNodeFactory} canHighlight indentLevel={indentLevel + 1} last={last} />
      </LanStructureTreeDroppable>
    </LanStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

const LanNodeFactory: TreeNodeChildFactory = ({ model, indentLevel, last }) => (
  <SelectableTreeNodeWidget node={model} key={model.getKey()}>
    <LanStructureTreeDraggable type={TreeDraggableType.LanNode} model={model}>
      <LanStructureTreeDroppable model={model} type={TreeDraggableType.LanNode}>
        <TreeNode model={model} canHighlight indentLevel={indentLevel + 1} last={last} />
      </LanStructureTreeDroppable>
    </LanStructureTreeDraggable>
  </SelectableTreeNodeWidget>
);

interface LanStructureTreeDraggableProps {
  type: TreeDraggableType;
  model: TreeNodeModel;
}

const LanStructureTreeDraggable: React.FC<LanStructureTreeDraggableProps> = ({ type, model, children }) => {
  const payload = useMemo(() => ({ type, modelId: model.getKey() }), [model, type]);
  const handleGetDragElement = useCallback(() => undefined, []);
  return (
    <TreeDraggable getDragElement={handleGetDragElement} formatId={LanTreeDndFormatDragId[type]!} payload={payload}>
      {children}
    </TreeDraggable>
  );
};

const LanStructureTreeDroppable: React.FC<{
  model: ExtendableTreeNode & HasExtendableParent;
  type: TreeDraggableType;
}> = ({ model, type, children }) => {
  return (
    <TreeDroppable formatId={LanTreeDndFormatDropId[type]!} model={model} dropChild={ListDropHighlighter}>
      {children}
    </TreeDroppable>
  );
};
