import { ModelCommand } from './ModelCommand';
import { NgGraceModel } from '../../NgGraceModel';
import { Segment, SmartLinkModel } from '../../link/smart/SmartLinkModel';
import { ConnectingSmartLinkModel } from '../../link/smart/ConnectingSmartLinkModel';
import { ConnectivityNodeModel } from '../../connectivity/ConnectivityNodeModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { Point } from '@projectstorm/geometry';
import { ConnectivityPortModel } from '../../connectivity/ConnectivityPortModel';
import { BasePoint } from '../../geometry/Point';
import { OppositeDirection, SmartDirection, SmartPortAlignment } from '../../geometry/Direction';
import { DefaultRightAngledVector, RightAngledVector } from '../../geometry/Vector';
import { DirectionCoordinate, OppositeCoordinate } from '../../geometry/Coordinate';
import { EnterDividedSmartLinkModel } from '../../link/smart/EnterDividedSmartLinkModel';
import { ExitDividedSmartLinkModel } from '../../link/smart/ExitDividedSmartLinkModel';

export class ConnectivityNodeCreationCommand implements ModelCommand {
  private readonly model: NgGraceModel;
  private readonly existingLink: SmartLinkModel;
  private readonly newLink: ConnectingSmartLinkModel;
  private readonly segmentId: string;
  private readonly eventPoint: Point;

  constructor(
    model: NgGraceModel,
    existingLink: SmartLinkModel,
    newLink: ConnectingSmartLinkModel,
    segmentId: string,
    eventPoint: Point
  ) {
    this.model = model;
    this.existingLink = existingLink;
    this.newLink = newLink;
    this.segmentId = segmentId;
    this.eventPoint = eventPoint;
  }

  execute() {
    const segmentToDivide = this.existingLink.getSegmentToDivide(this.segmentId);
    const newLinkSourceNodeId = this.newLink.getSourcePort().getParent().getID();
    const existingLinkNodesId = [
      this.existingLink.getSourcePort().getParent().getID(),
      this.existingLink.getTargetPort().getParent().getID(),
    ];

    if (!segmentToDivide || existingLinkNodesId.indexOf(newLinkSourceNodeId) !== -1) {
      return;
    }
    const vector = new DefaultRightAngledVector(segmentToDivide.start.getPosition(), segmentToDivide.end.getPosition());
    const direction = vector.getDirection();
    const node = this.createNode(vector);

    const ports = Object.values(node.getPorts()) as ConnectablePortModel[];
    const enterPort = ports.find(
      (port) => port.getEffectiveAlignment() === new SmartPortAlignment(new OppositeDirection(direction)).getEnumValue()
    )!;
    const exitPort = ports.find(
      (port) => port.getEffectiveAlignment() === new SmartPortAlignment(direction).getEnumValue()
    )!;
    ports.forEach((p) => p.reportPosition());
    this.divideExisting(enterPort, exitPort, segmentToDivide);
    this.connectNewLink(node, direction, ports);
  }

  private divideExisting(enterPort: ConnectivityPortModel, exitPort: ConnectivityPortModel, segmentToDivide: Segment) {
    const existingPoints = this.existingLink.getPoints();
    const existingTarget = this.existingLink.getTargetPort();
    const existingSource = this.existingLink.getSourcePort();
    const enterLink = new EnterDividedSmartLinkModel(
      existingPoints.slice(0, existingPoints.indexOf(segmentToDivide.getStart()) + 1),
      existingSource,
      enterPort
    );

    const exitLink = new ExitDividedSmartLinkModel(
      existingPoints.slice(existingPoints.indexOf(segmentToDivide.getEnd())),
      exitPort,
      existingTarget
    );
    existingSource.removeLink(this.existingLink);
    existingTarget.removeLink(this.existingLink);
    enterPort.removeLink(this.existingLink);
    exitPort.removeLink(this.existingLink);
    this.model.removeLink(this.existingLink);
    this.model.addAll(enterLink, exitLink);
  }

  private createNode(vector: RightAngledVector): ConnectivityNodeModel {
    const direction = vector.getDirection();
    const coord = new DirectionCoordinate(direction);
    const oppositeCoord = new OppositeCoordinate(coord).getName();

    const node = new ConnectivityNodeModel();
    this.model.addNode(node);
    const connectivityCenter = new BasePoint(0, 0);
    connectivityCenter[coord.getName()] = this.eventPoint[coord.getName()];
    connectivityCenter[oppositeCoord] = vector.getStart()[oppositeCoord];
    node.setCenter(connectivityCenter);

    return node;
  }

  private connectNewLink(node: ConnectivityNodeModel, direction: SmartDirection, ports: ConnectivityPortModel[]) {
    const sourcePort = this.newLink.getSourcePort();
    const coordToCompare = new OppositeCoordinate(new DirectionCoordinate(direction)).getName();
    const normalizedVector = new DefaultRightAngledVector(new Point(0, 0), new Point(0, 0));
    normalizedVector.getEnd()[coordToCompare] = node.getCenter()[coordToCompare];
    normalizedVector.getStart()[coordToCompare] = sourcePort.getCenter()[coordToCompare];

    const portAlignment = new SmartPortAlignment(new OppositeDirection(normalizedVector.getDirection()));
    const portForNewLink = ports.find((port) => port.getEffectiveAlignment() === portAlignment.getEnumValue())!;

    this.newLink.setTargetPort(portForNewLink);
    this.model.addLink(new SmartLinkModel(sourcePort, portForNewLink, this.newLink.getPoints()));

    sourcePort.removeLink(this.newLink);
    portForNewLink.removeLink(this.newLink);
    this.model.removeLink(this.newLink);
  }
}
