import styled from 'styled-components';

const TcoCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 32px 0 24px 0;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.white};
  height: 902px;
  flex: 1 0 400px;
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 32px;
  padding: 0 24px 16px 24px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  width: 100%;
`;

export const Styled = {
  TcoCard,
  Title,
  Content,
};
