import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Highlighter = styled.div<{ success: boolean; fail: boolean; after: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  border: 2px dashed ${(p) => (p.success ? p.theme.colors.turquoise : p.fail ? p.theme.colors.red : 'transparent')};

  ${(p) =>
    p.after &&
    css`
      border-bottom: 2px dashed ${p.theme.colors.blue};
    `}
`;

export const Styled = {
  Container,
  Highlighter,
};
