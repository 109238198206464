import { DirectoryNodeModel } from '../../../directory/DirectoryNodeModel';
import * as React from 'react';
import { LabelFontSize } from '../../../label/LabelWidget';
import { Direction, RotatedDirection } from '../../../geometry/Direction';
import { ClockwiseRotatedPoint } from '../../../geometry/Point';
import { Point } from '@projectstorm/geometry';

interface ExportDirectoryNodeLabelProps {
  node: DirectoryNodeModel;
}

export const ExportDirectoryNodeLabel: React.FC<ExportDirectoryNodeLabelProps> = ({ node }) => {
  const label = node.getLabel();
  const labelDirection = label.getDirection().getEnumValue();
  const direction = new RotatedDirection(label.getDirection(), node.getRotation());
  const textRotation = direction.isHorizontal() ? 270 : 0;
  const dominantBaseline = direction.isIncreasing() ? 'text-before-edge' : 'text-after-edge';
  const x =
    labelDirection === Direction.LEFT
      ? 0
      : labelDirection === Direction.RIGHT
      ? node.getSize().x
      : node.getSize().x / 2;
  const y =
    labelDirection === Direction.TOP
      ? 0
      : labelDirection === Direction.BOTTOM
      ? node.getSize().y
      : node.getSize().y / 2;
  const anchor = new ClockwiseRotatedPoint(
    new Point(x, y),
    node.getRotation(),
    new Point(node.getSize().x / 2, node.getSize().y / 2)
  );

  return (
    <text
      x={anchor.x}
      y={anchor.y}
      fontFamily="GOSTRUS"
      fontSize={`${LabelFontSize}px`}
      textAnchor="middle"
      dominantBaseline={dominantBaseline}
      transform={`rotate(${textRotation} ${anchor.x} ${anchor.y})`}
    >
      {node.getLabel().getContent().text}
    </text>
  );
};
