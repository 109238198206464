import { LanHorizontalPortsYPosition, LanPortModel, LanPortModelProps, LanPortSize } from '../port/LanPortModel';
import { BasePoint } from '../../../geometry/Point';
import { LanNodeBorderWidth } from './SwitchNodeWidget';
import { Point } from '@projectstorm/geometry';

const horizontalPortsCount = 2;

export class SwitchPortModel extends LanPortModel {
  constructor(props: LanPortModelProps) {
    super('switch', props);
  }

  getRelativePosition(): BasePoint {
    const node = this.getParent();
    const horizontalPortIndex = this.getHorizontalIndex();

    if (horizontalPortIndex === 0) {
      return new BasePoint(-(LanPortSize - LanNodeBorderWidth), LanHorizontalPortsYPosition);
    }

    const nodeSize = node.getSize();
    if (horizontalPortsCount - 1 === horizontalPortIndex) {
      return new BasePoint(nodeSize.x - LanNodeBorderWidth, LanHorizontalPortsYPosition);
    }

    return super.getRelativePosition();
  }

  getConnectorLength(): number {
    const defaultLength = super.getConnectorLength();
    const nodeSize = this.getParent().getSize();
    const verticalOffset =
      this.getHorizontalIndex() !== -1 ? nodeSize.y / 2 + this.getSize() / 2 - LanNodeBorderWidth : 0;
    return defaultLength + verticalOffset;
  }

  getHorizontalIndex() {
    return Object.values(this.getParent().getPorts()).slice(-horizontalPortsCount).indexOf(this);
  }

  getLabelOffset(): Point {
    const horizontalPortIndex = this.getHorizontalIndex();
    const rightHorizontalPort = horizontalPortsCount - 1 === horizontalPortIndex;
    return rightHorizontalPort ? new Point(LanPortSize + 1, super.getLabelOffset().y) : super.getLabelOffset();
  }
}
