import { useCallback, useMemo, useState } from 'react';
import { StageDto } from '../../api/nggrace-back';
import { getNextStage } from '../../utils/project-utils';
import { useRoutes } from '../../routes/hooks/useRoutes';

export const useNextStage = (stage?: StageDto) => {
  const [showNextStageModal, setShowNextStage] = useState(false);
  const { redirectToStagePage } = useRoutes();

  const nextStage = useCallback(() => {
    if (stage) {
      if (stage.project.activeStage.id === stage.baseInfo.id) {
        setShowNextStage(true);
      } else if (stage!.baseInfo.finished) {
        redirectToStagePage(stage!.project.id, getNextStage(stage.baseInfo.type)!);
      }
    }
  }, [redirectToStagePage, stage]);

  const closeNextStageModal = useCallback(() => setShowNextStage(false), []);

  return useMemo(() => ({ nextStage, showNextStageModal, closeNextStageModal }), [
    closeNextStageModal,
    showNextStageModal,
    nextStage,
  ]);
};
