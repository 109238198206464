import { NgGraceEngine } from '../NgGraceEngine';
import { NodeDirectory } from '../directory/NodeDirectory';
import { BusNodeFactory } from '../bus/BusNodeFactory';
import { ConnectivityNodeFactory } from '../connectivity/ConnectivityNodeFactory';
import { DirectoryNodeFactory } from '../directory/DirectoryNodeFactory';
import { ConnectingSmartLinkFactory } from '../link/smart/ConnectingSmartLinkFactory';
import { SmartLinkFactory } from '../link/smart/SmartLinkFactory';
import { NgGraceNodeLayerFactory } from '../insides/layer/NgGraceNodeLayerFactory';

export class SldEngine extends NgGraceEngine {
  constructor(directory: NodeDirectory) {
    super(directory);

    [new DirectoryNodeFactory(directory), new BusNodeFactory(), new ConnectivityNodeFactory()].forEach((factory) =>
      this.nodeFactories.registerFactory(factory)
    );

    [new ConnectingSmartLinkFactory(), new SmartLinkFactory()].forEach((factory) =>
      this.linkFactories.registerFactory(factory)
    );

    this.layerFactories.registerFactory(new NgGraceNodeLayerFactory());
  }
}
