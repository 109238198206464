import { SsdEngine } from '../ssd/SsdEngine';
import React, { useEffect, useState } from 'react';
import { Styled as S } from '../ssd/logic-device/PlaceholderWidget.styled';
import { BaseModel } from '@projectstorm/react-canvas-core';
import { PlaceholderBaseChild } from './Placeholder';

export interface PlaceholderBrickWidgetProps {
  brickModel: { getCodeName(): string; getBrickColor(): string } & PlaceholderBaseChild;
  engine: SsdEngine;
  parent: BaseModel;
}

export const PlaceholderBrickWidget: React.FC<PlaceholderBrickWidgetProps> = React.memo<PlaceholderBrickWidgetProps>(
  ({ brickModel }) => {
    const [selected, setSelected] = useState(brickModel.isSelected());

    useEffect(() => {
      setSelected(brickModel.isSelected());
      return brickModel.registerListener({
        selectionChanged: () => setSelected(brickModel.isSelected()),
      }).deregister;
    }, [brickModel]);

    return (
      <S.PlaceholderBrick color={brickModel.getBrickColor()}>
        {brickModel.getCodeName()}
        {selected && <S.BrickHighlight />}
      </S.PlaceholderBrick>
    );
  }
);
