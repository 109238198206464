import React from 'react';
import { DroppableProvider } from '../../editor/dnd/DroppableContext';
import { Styled as S } from './Tree.styled';

export const Tree: React.FC = ({ children }) => {
  return (
    <DroppableProvider>
      <S.Tree>{children}</S.Tree>
    </DroppableProvider>
  );
};
