import { RawSmartLinkModel } from '../../link/smart/RawSmartLinkModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { SsdNodeModel } from '../layer/node/SsdNodeLayerModel';
import { AddedPoint, BasePoint, RestrictedDirectionPoint, SubtractedPoint } from '../../geometry/Point';
import { SmartLinkPointModel } from '../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';
import { Coordinate, DefaultCoordinate } from '../../geometry/Coordinate';
import { isBusNodeModel, isBusPortModel } from '../../NgGraceModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { AngledPath } from '../../link/smart/path/default/AngledPath';
import { DefaultConnectingPath } from '../../link/smart/path/connecting/DefaultConnectingPath';
import { StageType } from '../../../../api/nggrace-back';
import { UpdatingDependenciesConnectingPath } from '../../link/smart/path/connecting/UpdatingDependenciesConnectingPath';
import { PathPort, PortEndedPath } from '../../link/smart/path/default/PortEndedPath';

export class SsdSmartLinkModel extends RawSmartLinkModel {
  private originalPoints: SmartLinkPointModel[] = [];
  private offsetPoints: SmartLinkPointModel[] = [];
  private stageType: StageType;

  constructor(stageType: StageType) {
    super();
    this.stageType = stageType;
  }

  portPositionChanged(port: ConnectablePortModel) {}

  serialize() {
    return {
      ...super.serialize(),
      originalPoints: this.originalPoints.map((p) => p.serialize()),
      stageType: this.stageType,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    return super.deserialize(event).then(() => {
      this.originalPoints = [
        ...(event.data.originalPoints || this.getPoints().map((point) => point.serialize())).map((point) =>
          this.deserializePoint(event, point)
        ),
      ];
      this.offsetPoints = this.originalPoints.map((point) => this.deserializePoint(event, point.serialize()));
      const sourceNode = this.getSourcePort().getParent() as SsdNodeModel;
      const targetNode = this.getTargetPort().getParent() as SsdNodeModel;

      [sourceNode, targetNode].forEach((node) =>
        node.registerListener({ positionChanged: () => this.updatePoints() } as any)
      );

      if (event.data.stageType !== this.stageType) {
        this.updatePoints();
      }
    });
  }

  private updatePoints() {
    [new DefaultCoordinate('x'), new DefaultCoordinate('y')].forEach((coord) => {
      this.addPointsOffset(coord);
    });

    this.fireEvent({}, 'pointsChanged');
  }

  private addPointsOffset(coordinate: Coordinate) {
    const coord = coordinate.getName();
    const originalStart = this.getOriginalFor(this.getStartPoint(coordinate));
    const startOffset = this.getStartPosition(coordinate);
    const scaleFactor = this.getScaleFactor(coordinate);

    if (!this.isCanBeRecalculated()) {
      this.setPoints(this.getFallbackPath()());
      return;
    }

    this.setPoints(
      this.offsetPoints.map((point) => {
        const original = this.getOriginalFor(point);
        const originalOffset = Math.abs(original.getPosition()[coord] - originalStart.getPosition()[coord]);
        const coordPosition = startOffset + originalOffset * (scaleFactor || 1);
        point.getPosition()[coord] = coordPosition;
        const result = new SmartLinkPointModel({
          link: this,
          position: new BasePoint({ ...point.getPosition(), [coord]: coordPosition }),
        });
        result.setSourceDependent(point.isSourceDependent());
        result.setTargetDependent(point.isTargetDependent());
        return result;
      })
    );
  }

  private getStartPoint(coordinate: Coordinate) {
    const offsetNode = this.getOffsetNode(coordinate);
    const points = this.getPoints();
    if (this.getSourcePort().getParent().getID() === offsetNode.getID()) {
      return points[0];
    } else {
      return points[points.length - 1];
    }
  }

  private getStartPosition(coordinate: Coordinate) {
    const offsetNode = this.getOffsetNode(coordinate);
    const startPoint = this.getStartPoint(coordinate);
    const offsets = new SubtractedPoint(offsetNode.getOffsetPosition(), offsetNode.getStaticPosition());
    return new AddedPoint(this.getOriginalFor(startPoint).getPosition(), offsets, coordinate)[coordinate.getName()];
  }

  private getScaleFactor(coordinate: Coordinate) {
    const { originalRange, newRange } = this.getSourceTargetRanges(coordinate);
    if (originalRange === 0) {
      return undefined;
    }
    return newRange / originalRange;
  }

  private getSourceTargetRanges(coordinate: Coordinate) {
    const coord = coordinate.getName();

    const originalSourcePointPosition = this.originalPoints[0].getPosition()[coord];
    const sourcePointPosition = this.getPortPointPosition(this.getSourcePort())[coord];

    const originalTargetPointPosition = this.originalPoints[this.originalPoints.length - 1].getPosition()[coord];
    const targetPointPosition = this.getPortPointPosition(this.getTargetPort())[coord];

    return {
      originalRange: Math.abs(originalSourcePointPosition - originalTargetPointPosition),
      newRange: Math.abs(sourcePointPosition - targetPointPosition),
    };
  }

  private getOriginalFor(point: SmartLinkPointModel) {
    const points = this.getPoints();

    if (points.indexOf(point) === 0) {
      return this.originalPoints[0];
    }
    if (points.indexOf(point) === points.length - 1) {
      return this.originalPoints[this.originalPoints.length - 1];
    }

    return this.originalPoints.find((p) => p.getID() === point.getID())!;
  }

  private getOffsetNode(coordinate: Coordinate) {
    const coord = coordinate.getName();
    const source = this.getSourcePort().getParent() as SsdNodeModel;
    const target = this.getTargetPort().getParent() as SsdNodeModel;
    const sourceLayer = source.getLayerIndexes()[coord];
    const targetLayer = target.getLayerIndexes()[coord];
    if (sourceLayer !== targetLayer) {
      return source.getLayerIndexes()[coord] < target.getLayerIndexes()[coord] ? source : target;
    }

    return this.getPortPointPosition(this.getSourcePort())[coord] <
      this.getPortPointPosition(this.getTargetPort())[coord]
      ? source
      : target;
  }

  private isLinkToBus() {
    return isBusNodeModel(this.getSourcePort().getParent()) || isBusNodeModel(this.getTargetPort().getParent());
  }

  private isCanBeRecalculated() {
    return (
      this.getScaleFactor(new DefaultCoordinate('x')) &&
      this.getScaleFactor(new DefaultCoordinate('y')) &&
      !this.isLinkToBus()
    );
  }

  private getPortPointPosition(port: ConnectablePortModel) {
    const node = port.getParent() as SsdNodeModel;
    if (isBusNodeModel(node) && isBusPortModel(port)) {
      const busPosition = node.getOffsetPosition();
      return new Point(busPosition.x + port.getBusOffset() + port.getSize() / 2, busPosition.y + port.getSize() / 2);
    }

    const index = port === this.getSourcePort() ? 0 : this.originalPoints.length - 1;
    const originalPointPosition = this.originalPoints[index].getPosition();
    const pointNodeOffset = new SubtractedPoint(originalPointPosition, node.getStaticPosition());
    return new AddedPoint(pointNodeOffset, node.getOffsetPosition());
  }

  private deserializePoint(event: DeserializeEvent<this>, serialized: ReturnType<SmartLinkPointModel['serialize']>) {
    const p = new SmartLinkPointModel({
      link: this,
      position: new Point(serialized.x, serialized.y),
    });
    p.deserialize({ ...event, data: { ...serialized } });
    return p;
  }

  getFallbackPath() {
    return () =>
      new UpdatingDependenciesConnectingPath(
        new DefaultConnectingPath(
          new PortEndedPath(
            new PortEndedPath(new AngledPath(), new SsdPathPort(this.getSourcePort())),
            new SsdPathPort(this.getTargetPort())
          ),
          this
        )
      ).connect(
        new RestrictedDirectionPoint(new SsdPathPort(this.getSourcePort()).getCenter()),
        new RestrictedDirectionPoint(new SsdPathPort(this.getTargetPort()).getCenter())
      );
  }

  isSelected(): boolean {
    return false;
  }
}

export class SsdPathPort implements PathPort {
  private origin: ConnectablePortModel;

  constructor(origin: ConnectablePortModel) {
    this.origin = origin;
  }

  getCenter(): BasePoint {
    const node = this.origin.getParent() as SsdNodeModel;
    const oldCenter = this.origin.getCenter();
    const oldNodePosition = node.getStaticPosition();
    const newNodePosition = node.getOffsetPosition();
    return new AddedPoint(new SubtractedPoint(oldCenter, oldNodePosition), newNodePosition);
  }

  getConnector(): RestrictedDirectionPoint {
    const oldCenter = this.origin.getCenter();
    const newCenter = this.getCenter();
    const oldConnector = this.origin.getConnector();
    return new RestrictedDirectionPoint(
      new AddedPoint(new SubtractedPoint(oldConnector, oldCenter), newCenter),
      oldConnector.restricted
    );
  }
}
