import { Rectangle } from '@projectstorm/geometry';
import { HasSize } from './HasSize';
import { HasPosition } from './HasPosition';
import { BasePoint } from '../geometry/Point';

export type HasRectListener = {
  updateRect?: () => void;
  childRectChanged?: () => void;
};

export interface HasRect extends HasSize, HasPosition {
  getRect(): Rectangle | undefined;
}

export class DefaultHasRect implements HasRect {
  private hasPosition: HasPosition;
  private hasSize: HasSize;

  constructor(hasPosition: HasPosition, hasSize: HasSize) {
    this.hasPosition = hasPosition;
    this.hasSize = hasSize;
  }

  getPosition(): BasePoint {
    return this.hasPosition.getPosition();
  }

  getSize(): BasePoint {
    return this.hasSize.getSize();
  }

  setPosition(newValue: BasePoint): void {
    this.hasPosition.setPosition(newValue);
  }

  getRect(): Rectangle | undefined {
    const size = this.getSize();
    return new Rectangle(this.getPosition(), size.x, size.y);
  }
}
