import * as React from 'react';
import styled from '@emotion/styled';
import { ConnectivityNodeModel, ConnectivityNodeSize } from './ConnectivityNodeModel';
import { ConnectivityPortWidget } from './ConnectivityPortWidget';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export interface ConnectivityNodeWidgetProps {
  node: ConnectivityNodeModel;
  engine: DiagramEngine;
}

const Node = styled.div<{ selected: boolean; size: number }>`
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;

  position: relative;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  background-color: ${(p) => (p.selected ? 'deepskyblue' : 'black')};
`;

export const ConnectivityNodeWidget: React.FC<ConnectivityNodeWidgetProps> = ({ node, engine }) => {
  return (
    <Node selected={node.isSelected()} size={ConnectivityNodeSize}>
      {Object.values(node.getPorts()).map((port) => (
        <ConnectivityPortWidget port={port} engine={engine} key={port.getID()} />
      ))}
    </Node>
  );
};
