import React, { useCallback } from 'react';
import { InputGroup } from '../../../widgets/InputGroup.styled';
import { InputField } from '../../../widgets/InputField';
import { ExportLang as $s } from '../Export.lang';
import { ExportFormDto, ProjectStyleSelection } from '../../../../api/nggrace-back';
import { InputUpload } from '../../../widgets/InputUpload';
import { Tooltip } from '../../../widgets/Tooltip';
import { InputRow } from '../../../widgets/InputRow.styled';

type UpdateFormAction = (form: ExportFormDto) => ExportFormDto;

type CompanyInfoGroupProps = {
  style: ProjectStyleSelection;
  form: ExportFormDto;
  project: { customerCompany?: string };

  onChange(formUpdater: UpdateFormAction): void;
};

export const CompanyInfoGroup: React.FC<CompanyInfoGroupProps> = ({ style, form, project, onChange }) => {
  const handleCompanyChange = useCallback(
    (company?: string) => {
      onChange((dto) => ({ ...dto, company }));
    },
    [onChange]
  );

  const handleCompanySecondLineChange = useCallback(
    (companySecondLine?: string) => {
      onChange((dto) => ({ ...dto, companySecondLine }));
    },
    [onChange]
  );

  const handleLogoChange = useCallback(
    (file: File) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target !== null) {
          const logo = event.target.result as string;
          onChange((dto) => ({ ...dto, logoFilename: file.name, logo }));
        }
      };

      reader.readAsText(file);
    },
    [onChange]
  );

  const ru = style === 'RU';

  const maxLength = 255;

  return (
    <InputGroup>
      {!ru && (
        <InputRow>
          <InputField
            label="Customer company"
            value={project.customerCompany}
            onChange={() => {}}
            disabled={true}
            maxLength={maxLength}
          />
          <Tooltip
            text="To change Customer company open SLD stage settings"
            placement={'bottom-end'}
            offset={[12, 16]}
          />
        </InputRow>
      )}
      <InputField
        label={ru ? 'Company' : 'Company line 1'}
        value={form.company}
        onChange={handleCompanyChange}
        maxLength={ru ? maxLength : 30}
      />
      {!ru && (
        <InputField
          label={'Company line 2'}
          value={form.companySecondLine}
          onChange={handleCompanySecondLineChange}
          maxLength={30}
        />
      )}
      <InputRow>
        <InputUpload label="Logo company" file={form.logoFilename} onChange={handleLogoChange} accept=".svg" />
        <Tooltip text={$s.logoTooltip[style]} placement={'bottom-end'} offset={[12, 16]} />
      </InputRow>
    </InputGroup>
  );
};
