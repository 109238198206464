import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import {
  ConnectingPath,
  DefaultPortDependantPointsCount,
  ExtendedConnectedPathLength,
  ExtendedPortDependantPointsCount,
} from '../Path';
import { RestrictedDirectionPoint } from '../../../../geometry/Point';

export class UpdatingDependenciesConnectingPath implements ConnectingPath {
  private readonly origin: ConnectingPath;

  constructor(path: ConnectingPath) {
    this.origin = path;
  }

  connect(start: RestrictedDirectionPoint, end: RestrictedDirectionPoint): SmartLinkPointModel[] {
    const result = this.origin.connect(start, end);

    result.forEach((point) => {
      point.setSourceDependent(false);
      point.setTargetDependent(false);
    });

    const portDependentPointsCount =
      result.length === ExtendedConnectedPathLength
        ? ExtendedPortDependantPointsCount
        : DefaultPortDependantPointsCount;

    result.slice(0, portDependentPointsCount).forEach((point) => point.setSourceDependent(true));

    result.slice(-portDependentPointsCount).forEach((point) => point.setTargetDependent(true));
    return result;
  }
}
