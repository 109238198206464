import styled, { css } from 'styled-components';

export const Icon = styled.svg<{ button?: boolean }>`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: ${(props) => props.theme.colors.lightgrey};
  transition: fill 0.15s;

  ${(props) =>
    props.button &&
    css`
      cursor: pointer;
    `};
`;

export const Styled = {
  Icon,
};
