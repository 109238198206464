import { Point } from '@projectstorm/geometry';
import {
  NodePayload,
  PayloadNodeModel,
  PayloadNodeModelGenerics,
  PayloadNodeModelListener,
  PayloadNodeModelOptions,
} from './PayloadNodeModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { BasePoint } from '../geometry/Point';

export interface ResizableNodeModelListener extends PayloadNodeModelListener {
  sizeChanged: () => void;
}

export interface ResizableNodeModelPayload extends NodePayload {}

export interface ResizableNodeModelOptions extends PayloadNodeModelOptions {
  payload: ResizableNodeModelPayload;
  resizers: Resizer[];
  defaultSize: Point;
}

export interface ResizableNodeModelGenerics extends PayloadNodeModelGenerics {
  OPTIONS: ResizableNodeModelOptions;
  PAYLOAD: ResizableNodeModelPayload;
  LISTENER: ResizableNodeModelListener;
}

export enum ResizeEdge {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export interface Resizer {
  id: string;
  edges: ResizeEdge[];
}

export abstract class ResizableNodeModel<
  G extends ResizableNodeModelGenerics = ResizableNodeModelGenerics
> extends PayloadNodeModel<G> {
  private readonly resizers: Resizer[];
  protected size: Point;

  protected constructor(options: G['OPTIONS']) {
    super(options);
    this.resizers = options.resizers;
    this.size = options.defaultSize;
  }

  abstract getResizeStrategy(): ResizeStrategy;

  getSize(): BasePoint {
    return new BasePoint(this.size.x, this.size.y);
  }

  setSize(point: BasePoint) {
    if (!point.equals(this.size)) {
      this.size = point;
      this.fireEvent({}, 'sizeChanged');
    }
  }

  resize(resizerId: string, resizerPosition: Point): ResizeEdge[] {
    return this.getResizeStrategy().resize(this.getEdges(resizerId), resizerPosition);
  }

  getCenter(): Point {
    const position = this.getPosition();
    const size = this.getSize();
    return new Point(position.x + size.x / 2, position.y + size.y / 2);
  }

  serialize() {
    return {
      ...super.serialize(),
      size: this.size,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.size = new Point(event.data.size?.x, event.data.size?.y);
  }

  private getEdges(resizerId: string): ResizeEdge[] {
    return this.resizers.find((resizer) => resizer.id === resizerId)!.edges;
  }
}

export interface ResizeStrategy {
  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[];
}

export class DefaultResizeStrategy implements ResizeStrategy {
  private model: ResizableNodeModel;

  constructor(model: ResizableNodeModel) {
    this.model = model;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    const topLeft = this.model.getPosition();
    const currentSize = this.model.getSize();
    const resultSize = currentSize.clone();
    if (edges.indexOf(ResizeEdge.RIGHT) >= 0) {
      resultSize.x = resizePosition.x - topLeft.x;
    }

    if (edges.indexOf(ResizeEdge.BOTTOM) >= 0) {
      resultSize.y = resizePosition.y - topLeft.y;
    }

    const currentPosition = this.model.getPosition();
    const resultPosition = currentPosition.clone();
    if (edges.indexOf(ResizeEdge.LEFT) >= 0) {
      const rightX = currentPosition.x + currentSize.x;
      resultPosition.x = resizePosition.x;
      resultSize.x = rightX - resultPosition.x;
    }

    if (edges.indexOf(ResizeEdge.TOP) >= 0) {
      const rightY = currentPosition.y + currentSize.y;
      resultPosition.y = resizePosition.y;
      resultSize.y = rightY - resultPosition.y;
    }

    this.model.setSize(resultSize);
    this.model.setPosition(resultPosition.x, resultPosition.y);
    return edges;
  }
}

export class NotAffectingPortsResizeStrategy implements ResizeStrategy {
  private origin: ResizeStrategy;
  private model: ResizableNodeModel;

  constructor(origin: ResizeStrategy, model: ResizableNodeModel) {
    this.origin = origin;
    this.model = model;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    const ports = Object.values(this.model.getPorts());
    const portPositions = ports.map((port) => ({
      id: port.getID(),
      position: port.getPosition().clone(),
    }));

    const affectedEdges = this.origin.resize(edges, resizePosition);

    ports.forEach((port) => {
      const position = portPositions.find((p) => p.id === port.getID())!.position;
      port.setPosition(position.x, position.y);
    });
    return affectedEdges;
  }
}

export class ConditionalResizeStrategy implements ResizeStrategy {
  private readonly condition: () => boolean;
  private readonly positive: ResizeStrategy;
  private readonly negative: ResizeStrategy;

  constructor(condition: () => boolean, positive: ResizeStrategy, negative: ResizeStrategy) {
    this.condition = condition;
    this.positive = positive;
    this.negative = negative;
  }

  resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
    const conditionResult = this.condition() ? this.positive : this.negative;
    return conditionResult.resize(edges, resizePosition);
  }
}
