import { FrameLeftEu } from './FrameLeftEu';

interface Size {
  width: number;
  height: number;
}

export const FrameEu = ({ height, width }: Size) => {
  return (
    <g>
      <rect
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 0.26458332 }}
        x="0"
        y="0"
        width={width}
        height={height}
      />
      <rect
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 0.26458332 }}
        x="5"
        y="5"
        width={width - 10}
        height={height - 10}
      />
      <g transform={`translate(0, ${height - 5})`}>
        <FrameLeftEu />
      </g>
    </g>
  );
};
