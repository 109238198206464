import { BasePositionModel } from '@projectstorm/react-canvas-core';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { BasePoint } from '../geometry/Point';
import { Coordinate } from '../geometry/Coordinate';

export interface HasOffsetPosition {
  getStaticPosition(): BasePoint;

  getOffsetPosition(): BasePoint;

  setOffset(offset: number, coordinate: Coordinate): void;
}

export class DefaultHasOffsetPosition implements HasOffsetPosition {
  private origin: RotatableNodeModel;
  private eventDelegate: BasePositionModel;
  private offsetPosition?: BasePoint;

  constructor(origin: RotatableNodeModel, eventDelegate: BasePositionModel, deserializedPosition?: BasePoint) {
    this.origin = origin;
    this.eventDelegate = eventDelegate;
    this.offsetPosition = deserializedPosition;
  }

  getOffsetPosition() {
    return new BasePoint(this.offsetPosition || this.getStaticPosition());
  }

  getStaticPosition() {
    return this.origin.getRotatedPosition();
  }

  setOffset(offset: number, coordinate: Coordinate) {
    const coord = coordinate.getName();
    this.offsetPosition = this.getOffsetPosition();

    const newOffsetPositionCoord = this.getStaticPosition()[coord] + offset;
    if (newOffsetPositionCoord !== this.offsetPosition[coord]) {
      this.offsetPosition[coord] = newOffsetPositionCoord;
      this.eventDelegate.fireEvent(
        {
          firing: true,
          stopPropagation: () => {},
        },
        'positionChanged'
      );
    }
  }
}
