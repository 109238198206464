import { Graph } from '../Graph';
import { Node } from '../../node/Node';
import { Link } from '../../Link';
import { Polygon, Rectangle } from '@projectstorm/geometry';
import { Mapping } from '../../mapping/Mapping';

export class BusNeighbourhood implements Graph {
  private readonly bus: Node;
  private readonly transformers: Graph;
  private readonly trBranchMapping: Mapping<Node, Graph>;

  constructor(bus: Node, transformers: Graph, trBranchMapping: Mapping<Node, Graph>) {
    this.bus = bus;
    this.transformers = transformers;
    this.trBranchMapping = trBranchMapping;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    return this.transformers
      .getNodes()
      .map((tr) => this.trBranchMapping.map(tr))
      .flatMap((branch) => branch.getNodes());
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}
