import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import * as React from 'react';
import { BuildingLayerModel, BuildingLayerModelType } from './BuildingLayerModel';
import { LanEngine } from '../../LanEngine';

export class BuildingLayerFactory extends AbstractReactFactory<BuildingLayerModel, LanEngine> {
  constructor() {
    super(BuildingLayerModelType);
  }

  generateModel(event: GenerateModelEvent): BuildingLayerModel {
    return new BuildingLayerModel();
  }

  generateReactWidget(event: GenerateWidgetEvent<BuildingLayerModel>): JSX.Element {
    return <></>;
  }
}
