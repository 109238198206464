import styled from 'styled-components';
import { Btn } from '../widgets/Btn.styled';
import { Icon } from '../widgets/Icon.styled';

const Toolbar = styled.div`
  height: ${(props) => props.theme.editor.headerHeight};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 0 48px;
`;

const Left = styled.div`
  flex: 1 0 100px;
  display: block;
  color: ${(props) => props.theme.colors.black};
  font-size: 24px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Center = styled.div`
  display: flex;
  flex: 1 0 100px;
  align-content: center;
  justify-content: center;
`;

const Right = styled.div`
  display: flex;
  flex: 1 0 100px;
  align-content: center;
  justify-content: flex-end;

  ${Btn} {
    width: 120px;
    font-size: 14px;
    line-height: 16px;
  }
`;

const ToolbarItem = styled.div`
  margin-left: 24px;
`;

const ToolbarBtn = styled.button<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => (props.active ? props.theme.colors.turquoise : props.theme.colors.black)};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.turquoise};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.darkgrey};
    cursor: default;
  }

  ${Icon} {
    width: 21px;
    height: 21px;
  }
`;

export const Styled = {
  Toolbar,
  ToolbarBtn,
  ToolbarItem,
  Left,
  Center,
  Right,
};
