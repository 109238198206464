import { Point } from '@projectstorm/geometry';
import { HasChildren } from '../../placeholder/HasChildren';
import { BaseModel, BaseObserver } from '@projectstorm/react-canvas-core';
import { HasRelativeModel } from '../../placeholder/HasRelativeModel';
import { HasName } from '../building/HasName';
import { SingleRackModel } from './SingleRackModel';
import { Factory } from '../../../../utils/factory';
import { CompositeRackModel } from './CompositeRackModel';
import { RoomModel } from '../building/RoomModel';
import { LanNodeModel } from '../node/LanNodeModel';

export const DefaultRackSize = new Point(123, 400);

export type RackModel = Rack & BaseModel;

export interface Rack extends HasName {
  getFullName(): string;

  asSingle(): SingleRackModel | undefined;

  asComposite(): CompositeRackModel | undefined;

  setRoom(room: RoomModel, indexToAdd?: number): void;

  notCascadeRemove(): void;
}

export type RackHasRelativeRoomFactory = Factory<BaseObserver, HasRelativeModel<RoomModel>>;

export class BaseRackModel implements Rack {
  private hasName: HasName;
  private hasChildren: HasChildren<LanNodeModel>;
  private hasRelativeRoom: HasRelativeModel<RoomModel>;

  constructor(hasChildren: HasChildren<LanNodeModel>, hasName: HasName, hasRelativeRoom: HasRelativeModel<RoomModel>) {
    this.hasName = hasName;
    this.hasChildren = hasChildren;
    this.hasRelativeRoom = hasRelativeRoom;
  }

  addChild(childToAdd: LanNodeModel, index?: number): void {
    this.hasChildren.addChild(childToAdd, index);
  }

  getChildren() {
    return this.hasChildren.getChildren();
  }

  getName(): string {
    return this.hasName.getName();
  }

  asComposite(): CompositeRackModel | undefined {
    return undefined;
  }

  asSingle(): SingleRackModel | undefined {
    return undefined;
  }

  getFullName(): string {
    const name = this.getName();
    const room = this.hasRelativeRoom.getRelativeModel()?.getName();
    const building = this.hasRelativeRoom.getRelativeModel()?.getRelativeModel()?.getName();
    return room && building ? [building, room, name].join('/') : name;
  }

  setRoom(room: RoomModel, indexToAdd?: number): void {
    this.hasRelativeRoom.setRelativeModel(room, indexToAdd);
  }

  notCascadeRemove(): void {
    // not implemented
  }
}
