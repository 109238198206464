import * as React from 'react';
import { DocStageType, ExportFormDto } from '../../../../../api/nggrace-back';
import { formatFrameDateRu } from '../../../../../utils/date-utils';
import { SvgOptimizer } from '../../SvgOptimizer';
import { IProjectExport } from '../../types';
import { getStationNameRu } from '../../../../../utils/project-utils';

interface ExportFrameProps {
  dto: ExportFormDto;
  project: IProjectExport;
}

const printStageRu = (stage?: DocStageType) => {
  switch (stage) {
    case 'PROJECT_DOC':
      return 'П';
    case 'WORKING_DOC':
      return 'Р';
    case 'EXECUTIVE_DOC':
      return 'И';
    default:
      return '';
  }
};

export const FrameTableRu = ({ dto, project }: ExportFrameProps) => {
  return (
    <g>
      <FrameLines />
      <FrameContent dto={dto} project={project} />
    </g>
  );
};

const FrameContent = ({ dto, project }: { dto: ExportFormDto; project: IProjectExport }) => {
  const svgOptimizer = new SvgOptimizer();

  const date = dto.createdAt ? formatFrameDateRu(dto.createdAt) : '';
  const stationNameRu = getStationNameRu(project.stationName);

  return (
    <g style={{ fontFamily: 'GOSTRUS', fill: '#000000', letterSpacing: '-0.26458332px' }}>
      {dto.logo && (
        <g transform={`translate(135.5,40.5)`} dangerouslySetInnerHTML={{ __html: svgOptimizer.optimize(dto.logo) }} />
      )}
      <g style={{ fontSize: '4.23333311px' }}>
        <text x="0.5" y="23.5">
          Изм.
        </text>
        <text x="10.5" y="23.5">
          Кол.уч.
        </text>
        <text x="20.5" y="23.5">
          Лист
        </text>
        <text x="30.5" y="23.5">
          № док.
        </text>
        <text x="47.5" y="23.5" textAnchor="middle">
          Подп.
        </text>
        <text x="55.5" y="23.5">
          Дата
        </text>
        <text x="142.5" y="28.5" textAnchor="middle">
          Стадия
        </text>
        <text x="157.5" y="28.5" textAnchor="middle">
          Лист
        </text>
        <text x="175" y="28.5" textAnchor="middle">
          Листов
        </text>
        <text x="0.5" y="28.5">
          Разраб.
        </text>
        <text x="0.5" y="33.5">
          Проверил
        </text>
        <text x="0.5" y="38.5">
          ГИП
        </text>
        <text x="0.5" y="48.5">
          Н. контр.
        </text>
        <text x="20.5" y="28.5">
          {dto.developedBy}
        </text>
        <text x="20.5" y="33.5">
          {dto.verifiedBy}
        </text>
        <text x="20.5" y="38.5">
          {dto.chiefProjectEngineer}
        </text>
        <text x="20.5" y="48.5">
          {dto.contractNumber}
        </text>
        <text x="55.5" y="28.5">
          {date}
        </text>
        <text x="55.5" y="33.5">
          {date}
        </text>
        <text x="55.5" y="38.5">
          {date}
        </text>
        <text x="55.5" y="48.5">
          {date}
        </text>
      </g>
      <g style={{ fontSize: '6.3499999px' }}>
        <text x="125" y="5" textAnchor="middle" dominantBaseline="central">
          {dto.code}
        </text>
      </g>
      <g style={{ fontSize: '5.64444447px' }}>
        {dto.titleSecondLine?.trim() === '' ? (
          <text x="125" y="17.5" textAnchor="middle" dominantBaseline="central">
            {dto.title}
          </text>
        ) : (
          <>
            <text x="125" y="17" textAnchor="middle">
              {dto.title}
            </text>
            <text x="125" y="17.5" textAnchor="middle" dominantBaseline="hanging">
              {dto.titleSecondLine}
            </text>
          </>
        )}
        {stationNameRu?.second?.trim() === '' ? (
          <text x="100" y="32.5" textAnchor="middle" dominantBaseline="central">
            {stationNameRu?.first.trim()}
          </text>
        ) : (
          <>
            <text x="100" y="32" textAnchor="middle">
              {stationNameRu?.first.trim()}
            </text>
            <text x="100" y="32.5" textAnchor="middle" dominantBaseline="hanging">
              {stationNameRu?.second.trim()}
            </text>
          </>
        )}
        <text x="142.5" y="35" textAnchor="middle" dominantBaseline="central">
          {printStageRu(dto.stage)}
        </text>
        <text x="157.5" y="35" textAnchor="middle" dominantBaseline="central">
          1
        </text>
        {dto.schemaNameSecondLine?.trim() === '' ? (
          <text x="100" y="47.5" textAnchor="middle" dominantBaseline="central">
            {dto.schemaName}
          </text>
        ) : (
          <>
            <text x="100" y="47" textAnchor="middle">
              {dto.schemaName}
            </text>
            <text x="100" y="47.5" textAnchor="middle" dominantBaseline="hanging">
              {dto.schemaNameSecondLine}
            </text>
          </>
        )}
        {dto.logo ? (
          <text x="160.5" y="47.5" dominantBaseline="central">
            {dto.company}
          </text>
        ) : (
          <text x="160" y="47.5" textAnchor="middle" dominantBaseline="central">
            {dto.company}
          </text>
        )}
      </g>
    </g>
  );
};

const FrameLines = () => {
  return (
    <g style={{ stroke: '#000000' }}>
      <g style={{ strokeWidth: 0.5 }}>
        <path d="M 0,0 H 185" />
        <path d="M 0,10 H 185" />
        <path d="M 0,20 H 65" />
        <path d="M 0,25 H 185" />
        <path d="M 135,30 H 185" />
        <path d="M 65,40 H 185" />

        <path d="M 0,0 V 55" />
        <path d="M 10,0 V 25" />
        <path d="M 20,0 V 55" />
        <path d="M 30,0 V 25" />
        <path d="M 40,0 V 55" />
        <path d="M 55,0 V 55" />
        <path d="M 65,0 V 55" />
        <path d="M 135,25 V 55" />
        <path d="M 150,25 V 40" />
        <path d="M 165,25 V 40" />
      </g>
      <g style={{ strokeWidth: 0.2 }}>
        <path d="M 0,5 H 65" />
        <path d="M 0,10 H 65" />
        <path d="M 0,15 H 65" />
        <path d="M 0,30 H 65" />
        <path d="M 0,35 H 65" />
        <path d="M 0,40 H 65" />
        <path d="M 0,45 H 65" />
        <path d="M 0,50 H 65" />
      </g>
    </g>
  );
};
