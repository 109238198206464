import styled from 'styled-components';

const OpexCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
`;

const GraphContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

const Graph = styled.svg`
  height: 90px;
`;

const GraphNameText = styled.text`
  font: 900 16px 'Mulish', 'Open Sans', sans-serif;
  fill: ${(props) => props.theme.colors.white};
`;

const GraphValueText = styled.text`
  font: 700 32px 'Mulish', 'Open Sans', sans-serif;
  fill: ${(props) => props.theme.colors.white};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 90px 0 24px 0;
  width: 386px;
`;

export const Styled = {
  OpexCard,
  GraphContainer,
  Graph,
  GraphNameText,
  GraphValueText,
  Content,
};
