import { BaseObserver, ListenerHandle } from '@projectstorm/react-canvas-core';
import { HasRect } from '../../../placeholder/HasRect';
import { Coordinate } from '../../../geometry/Coordinate';
import { BasePoint } from '../../../geometry/Point';

export class ModelCoordinateEquivalentSet extends BaseObserver {
  private readonly models: (BaseObserver & HasRect)[];
  private readonly previousSet?: ModelCoordinateEquivalentSet;
  private readonly defaultPosition?: () => number;
  private readonly setsOffset: number;
  private readonly coordinate: Coordinate;
  private maxSize: number = 0;
  private positionOffset?: number;

  private listenerHandles: ListenerHandle[] = [];

  constructor(
    models: (BaseObserver & HasRect)[],
    coordinate: Coordinate,
    previousSet?: ModelCoordinateEquivalentSet,
    defaultPosition?: () => number,
    setsOffset: number = 50
  ) {
    super();
    this.defaultPosition = defaultPosition;
    this.coordinate = coordinate;
    this.models = models;
    this.previousSet = previousSet;
    this.setsOffset = setsOffset;

    models.forEach((model) =>
      this.listenerHandles.push(model.registerListener({ sizeChanged: () => this.updateSize() } as any))
    );

    if (this.previousSet) {
      this.listenerHandles.push(
        this.previousSet?.registerListener({ setSizeChanged: () => this.updateCurrentOffset() })
      );
    }

    this.updateSize();
    this.updateCurrentOffset();
  }

  registerListener(listener: any): ListenerHandle {
    return super.registerListener(listener);
  }

  getMaxPosition(): number {
    const size = this.getMaxSize();
    const effectiveSize = size !== 0 ? size + this.setsOffset : 0;
    return this.getStartPosition() + effectiveSize;
  }

  getModels() {
    return this.models;
  }

  remove() {
    this.listenerHandles.forEach((handle) => handle.deregister());
    this.fireEvent({}, 'entityRemoved');
  }

  private getMaxSize() {
    return Math.max(0, ...this.models.map((model) => model.getSize()[this.coordinate.getName()]));
  }

  private getStartPosition(): number {
    return this.previousSet ? this.previousSet.getMaxPosition() : (this.defaultPosition && this.defaultPosition()) || 0;
  }

  private updateCurrentOffset() {
    const newOffset = this.getStartPosition();
    if (this.positionOffset !== newOffset) {
      this.positionOffset = newOffset;
      this.models.forEach((model) => {
        const modelPosition = new BasePoint(model.getPosition());
        modelPosition[this.coordinate.getName()] = newOffset;
        model.setPosition(modelPosition);
      });
      this.fireEvent({}, 'setSizeChanged');
    }
  }

  private updateSize() {
    const newSize = this.getMaxSize();
    if (this.maxSize !== newSize) {
      this.maxSize = newSize;
      this.fireEvent({}, 'setSizeChanged');
    }
  }
}
