import { GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { SmartLinkModel } from '../../link/smart/SmartLinkModel';
import { SmartLinkFactory } from '../../link/smart/SmartLinkFactory';
import { SsdSmartLinkModel } from './SsdSmartLinkModel';
import { StageType } from '../../../../api/nggrace-back';

export interface GenerateSmartLinkModelEvent extends GenerateModelEvent {
  initialConfig: ReturnType<SmartLinkModel['serialize']>;
}

export class SsdSmartLinkFactory extends SmartLinkFactory {
  private readonly stageType: StageType;

  constructor(stageType: StageType) {
    super();
    this.stageType = stageType;
  }

  generateModel(event: GenerateSmartLinkModelEvent): SmartLinkModel {
    return new SsdSmartLinkModel(this.stageType);
  }
}
