import {
  ResizableNodeModel,
  ResizableNodeModelGenerics,
  ResizableNodeModelOptions,
  ResizableNodeModelPayload,
} from './ResizableNodeModel';
import { Point, Rectangle } from '@projectstorm/geometry';
import { BasePoint } from '../geometry/Point';
import { DefaultSmartRotationHour, RotationHour, SmartRotationHour } from '../geometry/RotationHour';

export interface RotatableNodeModelPayload extends ResizableNodeModelPayload {
  hour: RotationHour;
}

export interface RotatableNodeModelOptions extends ResizableNodeModelOptions {
  payload: RotatableNodeModelPayload;
}

export interface RotatableNodeModelGenerics extends ResizableNodeModelGenerics {
  PAYLOAD: RotatableNodeModelPayload;
  OPTIONS: RotatableNodeModelOptions;
}

export abstract class RotatableNodeModel<
  G extends RotatableNodeModelGenerics = RotatableNodeModelGenerics
> extends ResizableNodeModel<G> {
  protected constructor(options: G['OPTIONS']) {
    super(options);
  }

  getRotation(): SmartRotationHour {
    return new DefaultSmartRotationHour(this.getPayload().hour);
  }

  setRotation(hour: SmartRotationHour) {
    this.updatePayload({ ...this.getPayload(), hour: hour.getEnumValue() });
  }

  getRotatedPosition(): BasePoint {
    const center = this.getCenter();
    const size = this.getRotatedSize();
    return new BasePoint(center.x - size.x / 2, center.y - size.y / 2);
  }

  getPositionToRender(): BasePoint {
    return this.getRotatedPosition();
  }

  getRotatedSize(): BasePoint {
    const originalSize = this.getSize();
    return this.getRotation().isAxisSwapped() ? new BasePoint(originalSize.y, originalSize.x) : originalSize;
  }

  canRotate(): boolean {
    return true;
  }

  rotateClockwise() {
    this.setHour((this.getRotation().getEnumValue() + 1) % 4);
    Object.values(this.getPorts()).forEach((port) => port.reportPosition());
  }

  rotateCounterClockwise() {
    this.setHour((4 + this.getRotation().getEnumValue() - 1) % 4);
    Object.values(this.getPorts()).forEach((port) => port.reportPosition());
  }

  rotatePoint(pointToRotate: Point) {
    const center = this.getCenter();
    const hour = this.getRotation().getEnumValue();

    if (hour === RotationHour.ZERO) {
      return new BasePoint(pointToRotate);
    }
    const cos = hour === RotationHour.THREE || hour === RotationHour.NINE ? 0 : -1;
    const sin = hour === RotationHour.THREE ? 1 : hour === RotationHour.SIX ? 0 : -1;
    const x = pointToRotate.x;
    const y = pointToRotate.y;
    const cx = center.x;
    const cy = center.y;
    return new BasePoint(cos * (x - cx) - sin * (y - cy) + cx, cos * (y - cy) + sin * (x - cx) + cy);
  }

  inversePointRotation(pointToInverse: Point) {
    const center = this.getCenter();
    const hour = this.getRotation().getEnumValue();

    if (hour === RotationHour.ZERO) {
      return new BasePoint(pointToInverse);
    }
    const cos = hour === RotationHour.THREE || hour === RotationHour.NINE ? 0 : -1;
    const sin = hour === RotationHour.THREE ? 1 : hour === RotationHour.SIX ? 0 : -1;
    const x = pointToInverse.x;
    const y = pointToInverse.y;
    const cx = center.x;
    const cy = center.y;
    return new BasePoint(cos * (x - cx) + sin * (y - cy) + cx, cos * (y - cy) - sin * (x - cx) + cy);
  }

  getBoundingBox(): Rectangle {
    const size = this.getRotatedSize();
    return new Rectangle(this.getPositionToRender(), size.x, size.y);
  }

  private setHour(newValue: RotationHour) {
    this.updatePayload({
      ...this.getPayload(),
      hour: newValue,
    });
  }
}
