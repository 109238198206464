import React, { PropsWithChildren, useCallback } from 'react';
import { List } from './List.styled';
import { Direction } from '../../api/nggrace-back';

interface SortCellProps<T extends any> {
  type: T;
  direction?: Direction;

  onSort(property: T, direction: Direction): void;
}

export const SortCell = <T extends any>({ type, direction, onSort, children }: PropsWithChildren<SortCellProps<T>>) => {
  const handleSort = useCallback(() => {
    onSort(type, direction === 'ASC' ? 'DESC' : 'ASC');
  }, [onSort, type, direction]);

  return (
    <List.SortCell asc={direction === 'ASC'} desc={direction === 'DESC'} onClick={handleSort}>
      <List.Content>{children}</List.Content>
    </List.SortCell>
  );
};
