import { NodeModel } from '@projectstorm/react-diagrams';
import { NodeDirectory } from '../../../directory/NodeDirectory';
import { isBusNodeModel, isConnectivityNodeModel, isDirectoryNodeModel, isLanNodeModel } from '../../../NgGraceModel';
import { ExportDirectoryNode } from './ExportDirectoryNode';
import { ExportBusNode } from './ExportBusNode';
import * as React from 'react';
import { ExportConnectivityNode } from './ExportConnectivityNode';
import { ExportLanNode } from './ExportLanNode';

export type DirectoryNodeSvgCache = { [nodeId: string]: any };

interface ExportNodeProps {
  directory: NodeDirectory;
  svgCache: DirectoryNodeSvgCache;
  node: NodeModel;
}

export const ExportNode: React.FC<ExportNodeProps> = ({ directory, svgCache, node }) => {
  if (isDirectoryNodeModel(node)) {
    return <ExportDirectoryNode directory={directory} svgCache={svgCache} node={node} />;
  }

  if (isLanNodeModel(node)) {
    return <ExportLanNode svgCache={svgCache} node={node} />;
  }

  if (isBusNodeModel(node)) {
    return <ExportBusNode node={node} />;
  }
  if (isConnectivityNodeModel(node)) {
    return <ExportConnectivityNode node={node} />;
  }

  return <></>;
};
