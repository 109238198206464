import { EditablePath } from '../Path';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';
import { SmartLinkModel } from '../../SmartLinkModel';
import { DefaultRightAngledVector } from '../../../../geometry/Vector';

export class DefaultEditablePath implements EditablePath {
  private readonly link: SmartLinkModel;

  constructor(link: SmartLinkModel) {
    this.link = link;
  }

  moveSegment(startPoint: SmartLinkPointModel, displacement: Point): void {
    const existingPoints = this.link.getPoints();
    const endPoint = existingPoints[existingPoints.indexOf(startPoint) + 1];

    const segmentDirection = new DefaultRightAngledVector(
      startPoint.getPosition(),
      endPoint.getPosition()
    ).getDirection();
    if (segmentDirection.isHorizontal()) {
      startPoint.setPosition(startPoint.getX(), startPoint.getY() + displacement.y);
      endPoint.setPosition(endPoint.getX(), endPoint.getY() + displacement.y);
    } else {
      startPoint.setPosition(startPoint.getX() + displacement.x, startPoint.getY());
      endPoint.setPosition(endPoint.getX() + displacement.x, endPoint.getY());
    }
  }
}
