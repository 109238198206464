import { ModelObserver, registerLinkListener, registerNodeListener } from '../NgGraceModelObserver';
import { SsdModel } from './SsdModel';
import { debounced } from '../FunctionDecorators';
import { BaseModel } from '@projectstorm/react-canvas-core';
import { DirectoryLogicDeviceModel } from './logic-device/LogicDeviceModel';

export class SsdModelObserver implements ModelObserver {
  onChange(model: SsdModel, listener: () => void): () => void {
    const debouncedListener = debounced(listener, 200);
    const handlers: { deregister: () => any }[] = [];

    registerNodeListener(
      model,
      {
        payloadChanged: debouncedListener,
      },
      handlers
    );

    registerLinkListener(
      model,
      {
        positionChanged: debouncedListener,
      },
      handlers
    );

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  }

  onSelectionChange(model: SsdModel, listener: () => void): () => void {
    const handlers: { deregister: () => any }[] = [];
    const selectionListener = { selectionChanged: listener };

    registerNodeListener(model, selectionListener, handlers);

    const addEntityListener = (entity: BaseModel, additional?: object) => {
      handlers.push(entity.registerListener({ ...selectionListener, ...additional }));
    };

    const addDeviceListener = (device: DirectoryLogicDeviceModel) => {
      addEntityListener(device, {
        childrenChanged: ({ child, created }: { child: DirectoryLogicDeviceModel; created: boolean }) =>
          created && addEntityListener(child),
      });
      device.getChildren().map((child) => addEntityListener(child));
    };

    model.getLogicDeviceLayer().registerListener({
      logicDeviceAdded: ({ device }: { device: DirectoryLogicDeviceModel }) => addDeviceListener(device),
    } as any);

    Object.values(model.getLogicDeviceLayer().getModels()).forEach(addDeviceListener);

    return () => {
      handlers.forEach((handler) => handler.deregister());
    };
  }
}
