import { SimplePortFactory } from '../../../generics/SimplePortFactory';
import { LanControllerModelType } from './LanControllerModel';
import { RawLanControllerPortModel } from './RawLanControllerPortModel';
import { ControllerDirectoryEntry, PropertiesDirectory } from '../../../directory/PropertiesDirectory';

export class LanControllerPortFactory extends SimplePortFactory {
  constructor(directory: PropertiesDirectory<ControllerDirectoryEntry>) {
    super(LanControllerModelType, () => new RawLanControllerPortModel({ directory }));
  }
}
