import { HasSize, HasSizeListener } from '../HasSize';
import { BasePoint } from '../../geometry/Point';
import { HasChildren, HasChildrenListener } from '../HasChildren';
import { Listenable } from '../Listenable';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { PlaceholderWidgetSizes } from '../../ssd/logic-device/PlaceholderWidget.styled';
import { HasPosition } from '../HasPosition';

export class PlaceholderGroupHasSize implements HasSize {
  private eventDelegate: Listenable<HasSizeListener & HasChildrenListener<BaseObserver & HasSize>>;
  private hasChildren: HasChildren<BaseObserver & HasSize & HasPosition>;

  constructor(
    eventDelegate: Listenable<HasSizeListener & HasChildrenListener<BaseObserver & HasSize>>,
    hasChildren: HasChildren<BaseObserver & HasSize & HasPosition>
  ) {
    this.eventDelegate = eventDelegate;
    this.hasChildren = hasChildren;
    this.eventDelegate.registerListener({});

    eventDelegate.registerListener({
      childrenChanged: () => this.getSize(),
    });
  }

  getSize(): BasePoint {
    const children = this.hasChildren.getChildren();

    if (children.length === 0) {
      return new BasePoint(0, 0);
    }

    const placeholderWidth =
      PlaceholderWidgetSizes.rightHeaderRect.getRightMiddle().x + PlaceholderWidgetSizes.borderWidth;
    const placeholderMargin = PlaceholderWidgetSizes.placeholderMargin;

    const masonryChildren = children.map((child) => ({
      model: child,
      height: child.getSize().y + PlaceholderWidgetSizes.placeholderMargin,
    }));

    const columns: { model: BaseObserver & HasSize & HasPosition; height: number }[][] = [[], [], []];
    masonryChildren.forEach((child) => {
      const index = getIndexWithMin(columns.map((column) => column.reduce((res, cur) => res + cur.height, 0)));
      columns[index].push(child);
    });

    columns.forEach((column, columnIndex) => {
      let height = 0;
      column.forEach((masonryChild) => {
        masonryChild.model.setPosition(new BasePoint((placeholderWidth + placeholderMargin) * columnIndex, height));
        height += masonryChild.height;
      });
    });

    const colLength = columns.filter((col) => col.length).length;
    const width = placeholderWidth * colLength + placeholderMargin * colLength - 1;

    const height = Math.max(...columns.map((column) => column.reduce((res, cur) => res + cur.height, 0))) - 40;
    return new BasePoint(width, height);
  }
}

const getIndexWithMin = (array: number[]): number => {
  let min = Infinity;
  let result = 0;
  array.forEach((element, index) => {
    if (element < min) {
      min = element;
      result = index;
    }
  });

  return result;
};
