import { getDistance, LanLinkGraph } from './LanLinkGraph';
import { SmartLinkPointModel } from '../../../point/SmartLinkPointModel';
import { DefaultConnectingPath } from '../../../link/smart/path/connecting/DefaultConnectingPath';
import { PortEndedPath } from '../../../link/smart/path/default/PortEndedPath';
import { NormalizingAngledPath } from '../../../link/smart/path/default/NormalizingAngledPath';
import { AngledPath } from '../../../link/smart/path/default/AngledPath';
import { Direction } from '../../../geometry/Direction';
import { AddedPoint, BasePoint, RestrictedDirectionPoint } from '../../../geometry/Point';
import { ConnectablePortModel } from '../../../generics/ConnectablePortModel';
import { Node } from '../../../auto-layout/node/Node';
import { Point } from '@projectstorm/geometry';
import { LanLinkModel } from '../LanLinkModel';
import { ZoneLayerModel } from '../../zone/layer/ZoneLayerModel';
import { ZoneType } from '../../zone/ZoneModel';
import { LanNodeModel } from '../../node/LanNodeModel';
import { WaypointGraph } from '../../waypoint/WaypointGraph';

enum ZonesLinkLanType {
  PROTECTION_PRIMARY,
  PROTECTION_BACKUP,
  NOT_PROTECTION,
  OTHER,
}

enum Highway {
  PRIMARY,
  BACKUP,
  OTHER,
}

export class LanLinkZonesGraph implements LanLinkGraph {
  private readonly zoneLayer: ZoneLayerModel;
  private readonly link: LanLinkModel;
  private readonly deregister: () => any;

  constructor(zoneLayer: ZoneLayerModel, link: LanLinkModel, onChange: () => void) {
    this.link = link;
    this.zoneLayer = zoneLayer;
    this.deregister = zoneLayer.registerListener({ waypointsChanged: onChange } as any).deregister;
  }

  detach(): void {
    this.deregister();
  }

  getId(): string {
    return this.zoneLayer.getID();
  }

  needDetach(): boolean {
    const sourceNode = this.link.getSourcePort().getParent() as LanNodeModel;
    const targetNode = this.link.getTargetPort().getParent() as LanNodeModel;
    return sourceNode.getRelativeZone()?.getID() === targetNode.getRelativeZone()?.getID();
  }

  getPoints() {
    const graph = this.zoneLayer.getLinkWaypointGraph()!;
    if (!graph || !graph.getNodes().length) {
      return [];
    }
    const sourcePort = this.link.getSourcePort();
    const targetPort = this.link.getTargetPort();
    const sourceWaypoint = this.getWaypointForFrom(sourcePort, targetPort, this.getZoneWaypoints(sourcePort, graph));
    const targetWaypoint = this.getWaypointForFrom(targetPort, sourcePort, this.getZoneWaypoints(targetPort, graph));
    if (!sourceWaypoint || !targetWaypoint) {
      return [];
    }
    let path =
      sourceWaypoint.getID() !== targetWaypoint.getID()
        ? graph.find(sourceWaypoint.getID(), targetWaypoint.getID()).map((node) => node.data)
        : [sourceWaypoint];

    if (path[0].getID() !== sourceWaypoint.getID()) {
      path.reverse();
    }

    const offset = this.getOffset();
    const pathPositions = path.map((node) => new AddedPoint(node.getRect().getTopLeft(), offset));

    const sourceEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), sourcePort),
      this.link
    );

    const targetEndedPath = new DefaultConnectingPath(
      new PortEndedPath(new NormalizingAngledPath(new AngledPath()), targetPort),
      this.link
    );

    const resultWithRedundant = [
      ...sourceEndedPath.connect(
        new RestrictedDirectionPoint(sourcePort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP]),
        new RestrictedDirectionPoint(pathPositions[0], [Direction.LEFT, Direction.RIGHT])
      ),
      ...pathPositions.map((position) => new SmartLinkPointModel({ position: position, link: this.link })).slice(1, -1),
      ...targetEndedPath.connect(
        new RestrictedDirectionPoint(pathPositions[pathPositions.length - 1], [Direction.LEFT, Direction.RIGHT]),
        new RestrictedDirectionPoint(targetPort.getCenter(), [Direction.LEFT, Direction.RIGHT, Direction.TOP])
      ),
    ];

    const duplicates: SmartLinkPointModel[][] = [];
    resultWithRedundant.forEach((point, index) => {
      const duplicate = resultWithRedundant.find(
        (anotherPoint, anotherIndex) =>
          new BasePoint(point.getPosition()).equals(anotherPoint.getPosition()) && index < anotherIndex
      );

      if (duplicate) {
        duplicates.push([point, duplicate]);
      }
    });

    duplicates.forEach(([point, duplicate]) => {
      const pointIndex = resultWithRedundant.indexOf(point);
      const duplicateIndex = resultWithRedundant.indexOf(duplicate);
      resultWithRedundant.splice(pointIndex, duplicateIndex - pointIndex);
    });

    return resultWithRedundant;
  }

  getColor() {
    const colorMapping: Record<Highway, string> = {
      [Highway.PRIMARY]: 'red',
      [Highway.BACKUP]: 'blue',
      [Highway.OTHER]: 'green',
    };

    return colorMapping[this.getHighway()];
  }

  getConnectorLength(): number {
    const lengthMapping: Record<Highway, number> = {
      [Highway.PRIMARY]: 59,
      [Highway.BACKUP]: 56,
      [Highway.OTHER]: 53,
    };
    return lengthMapping[this.getHighway()];
  }

  private getHighway() {
    const zones = [
      getPortZone(this.link.getSourcePort()).getZoneType(),
      getPortZone(this.link.getTargetPort()).getZoneType(),
    ];

    if (zones.includes(ZoneType.RPA_PRIMARY_NETWORK_PROCESS) || zones.includes(ZoneType.RPA_PRIMARY_NETWORK_STATION)) {
      return Highway.PRIMARY;
    }

    if (zones.includes(ZoneType.RPA_BACKUP_NETWORK_PROCESS) || zones.includes(ZoneType.RPA_BACKUP_NETWORK_STATION)) {
      return Highway.BACKUP;
    }

    return Highway.OTHER;
  }

  private getOffset() {
    const offsetMapping: Record<Highway, BasePoint> = {
      [Highway.PRIMARY]: new BasePoint(5, -5),
      [Highway.BACKUP]: new BasePoint(-5, 5),
      [Highway.OTHER]: new BasePoint(10, -10),
    };
    return offsetMapping[this.getHighway()];
  }

  private getLanType() {
    const zones = [
      getPortZone(this.link.getSourcePort()).getZoneType(),
      getPortZone(this.link.getTargetPort()).getZoneType(),
    ];

    if (
      zones.includes(ZoneType.RPA_PROTECTION) &&
      (zones.includes(ZoneType.RPA_PRIMARY_NETWORK_STATION) || zones.includes(ZoneType.RPA_PRIMARY_NETWORK_PROCESS))
    ) {
      return ZonesLinkLanType.PROTECTION_PRIMARY;
    }

    if (
      zones.includes(ZoneType.RPA_PROTECTION) &&
      (zones.includes(ZoneType.RPA_BACKUP_NETWORK_PROCESS) || zones.includes(ZoneType.RPA_BACKUP_NETWORK_STATION))
    ) {
      return ZonesLinkLanType.PROTECTION_BACKUP;
    }

    if (
      (zones.includes(ZoneType.SCADA) || zones.includes(ZoneType.MIDDLE) || zones.includes(ZoneType.WORKSTATION)) &&
      (zones.includes(ZoneType.RPA_BACKUP_NETWORK_PROCESS) ||
        zones.includes(ZoneType.RPA_BACKUP_NETWORK_STATION) ||
        zones.includes(ZoneType.RPA_PRIMARY_NETWORK_STATION) ||
        zones.includes(ZoneType.RPA_PRIMARY_NETWORK_PROCESS))
    ) {
      return ZonesLinkLanType.NOT_PROTECTION;
    }

    return ZonesLinkLanType.OTHER;
  }

  private getWaypointForFrom(from: ConnectablePortModel, to: ConnectablePortModel, waypoints: Node[]) {
    const waypointMapping: {
      accept: (from: ConnectablePortModel, to: ConnectablePortModel) => boolean;
      getClosest: (from: ConnectablePortModel, to: ConnectablePortModel) => Node;
    }[] = [
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.PROTECTION_PRIMARY &&
          getPortZone(from).getZoneType() === ZoneType.RPA_PROTECTION,
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.LEFT, Direction.TOP),
      },
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.PROTECTION_PRIMARY &&
          getPortZone(from).getZoneType() !== ZoneType.RPA_PROTECTION,
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.LEFT, Direction.BOTTOM),
      },
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.PROTECTION_BACKUP &&
          getPortZone(from).getZoneType() === ZoneType.RPA_PROTECTION,
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.RIGHT, Direction.BOTTOM),
      },
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.PROTECTION_BACKUP &&
          getPortZone(from).getZoneType() !== ZoneType.RPA_PROTECTION,
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.LEFT, Direction.TOP),
      },
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.NOT_PROTECTION &&
          [ZoneType.SCADA, ZoneType.MIDDLE, ZoneType.WORKSTATION].includes(getPortZone(from).getZoneType()),
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.LEFT, Direction.BOTTOM),
      },
      {
        accept: (from) =>
          this.getLanType() === ZonesLinkLanType.NOT_PROTECTION &&
          ![ZoneType.SCADA, ZoneType.MIDDLE, ZoneType.WORKSTATION].includes(getPortZone(from).getZoneType()),
        getClosest: (from) => this.getClosestWaypoint(from.getPosition(), waypoints, Direction.LEFT, Direction.TOP),
      },
      {
        accept: () => this.getLanType() === ZonesLinkLanType.OTHER,
        getClosest: (from, to) => {
          const sourceNodePosition = from.getParent().getPosition();
          const targetNodePosition = to.getParent().getPosition();
          return this.getClosestWaypoint(
            from.getPosition(),
            waypoints,
            targetNodePosition.x - sourceNodePosition.x > 0 ? Direction.RIGHT : Direction.LEFT,
            targetNodePosition.y - sourceNodePosition.y > 0 ? Direction.BOTTOM : Direction.TOP
          );
        },
      },
    ];

    return waypointMapping.find((mapping) => mapping.accept(from, to))!.getClosest(from, to);
  }

  private getClosestWaypoint(point: Point, waypoints: Node[], xDirection: Direction, yDirection: Direction) {
    let minDistance = Number.MAX_VALUE;
    let currentId: string | undefined = undefined;

    const xConstraint = (waypointPosition: Point) =>
      xDirection === Direction.LEFT ? waypointPosition.x < point.x : waypointPosition.x > point.x;
    const yConstraint = (waypointPosition: Point) =>
      yDirection === Direction.TOP ? waypointPosition.y < point.y : waypointPosition.y > point.y;

    waypoints
      .filter((waypoint) => {
        const position = waypoint.getRect().getTopLeft();
        return xConstraint(position) && yConstraint(position);
      })
      .forEach((waypoint) => {
        const position = waypoint.getRect().getTopLeft();
        const distance = getDistance(point, position);
        if (minDistance > distance) {
          minDistance = distance;
          currentId = waypoint.getID();
        }
      });

    return waypoints.find((waypoint) => waypoint.getID() === currentId)!;
  }

  private getZoneWaypoints(port: ConnectablePortModel, waypointGraph: WaypointGraph<ZoneType>) {
    const zoneType = getPortZone(port).getZoneType();
    return waypointGraph.getNodes().filter((node) => waypointGraph.getNodeType(node) === zoneType);
  }
}

const getPortZone = (port: ConnectablePortModel) => {
  return (port.getParent() as LanNodeModel).getRelativeZone()!;
};
