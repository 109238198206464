import { HasChildren } from '../../placeholder/HasChildren';
import { LanNodeModel } from '../node/LanNodeModel';
import { BaseObserver } from '@projectstorm/react-canvas-core';

export class RackHasChildren implements HasChildren<LanNodeModel> {
  private readonly origin: HasChildren<LanNodeModel>;
  private readonly updateChildren: (children: LanNodeModel[]) => void;

  constructor(
    eventDelegate: BaseObserver,
    origin: HasChildren<LanNodeModel>,
    updateChildren: (children: LanNodeModel[]) => void
  ) {
    this.origin = origin;
    this.updateChildren = updateChildren;
  }

  addChild(childToAdd: LanNodeModel, index?: number): void {
    const children = [...this.getChildren()];

    if (index === undefined) {
      children.push(childToAdd);
    } else {
      children.splice(index, 0, childToAdd);
    }

    const handle = childToAdd.registerListener({
      entityRemoved: () => {
        this.updateChildren(this.getChildren().filter((child) => child.getID() !== childToAdd.getID()));
        handle.deregister();
      },
    } as any);

    this.updateChildren(children);
    this.origin.addChild(childToAdd, index);
  }

  getChildren(): LanNodeModel[] {
    return this.origin.getChildren();
  }
}
