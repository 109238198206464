import { Node, NodeType } from './Node';
import { Rectangle } from '@projectstorm/geometry';
import { BasePoint } from '../../geometry/Point';
import { SmartRotationHour } from '../../geometry/RotationHour';

export class TranslatedNode implements Node {
  private origin: Node;
  private translation: BasePoint;

  constructor(node: Node, translation: BasePoint) {
    this.origin = node;
    this.translation = translation;
  }

  getID(): string {
    return this.origin.getID();
  }

  getRect(): Rectangle {
    const rect = this.origin.getRect().clone();
    rect.translate(this.translation.x, this.translation.y);
    return rect;
  }

  getType(): NodeType {
    return this.origin.getType();
  }

  getHour(): SmartRotationHour {
    return this.origin.getHour();
  }
}
