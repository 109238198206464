import React from 'react';
import { Styled as S } from './Icon.styled';
import icons from '../../assets/icons.svg';

export type IconName =
  | 'plus'
  | 'logout'
  | 'users'
  | 'projects'
  | 'companies'
  | 'error'
  | 'warn'
  | 'info'
  | 'rotate-left'
  | 'rotate-right'
  | 'drag-handle-horizontal'
  | 'move'
  | 'target'
  | 'play'
  | 'gear'
  | 'lock'
  | 'lock-fill'
  | 'unlock'
  | 'question'
  | 'share'
  | 'undo'
  | 'redo'
  | 'trash'
  | 'cross'
  | 'checkmark'
  | 'loading'
  | 'edit'
  | 'copy'
  | 'filter-off'
  | 'filter-on'
  | 'minus'
  | 'list'
  | 'toggle'
  | 'eye'
  | 'closed-eye';

interface IconProps {
  name: IconName;
  onClick?: (event?: any) => void;
}

export const Icon = React.forwardRef<SVGSVGElement, IconProps>(({ name, onClick, ...other }, ref) => {
  return (
    <S.Icon ref={ref} onClick={onClick} button={!!onClick} {...other}>
      <use xlinkHref={`${icons}#${name}`} />
    </S.Icon>
  );
});
