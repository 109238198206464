import styled from 'styled-components';
import { Styled as StyledInputField } from '../widgets/InputField.styled';
import { Styled as StyledFeedback } from '../widgets/Feedback.styled';
import { Btn } from '../widgets/Btn.styled';
import { Styled as StyledInputRadio } from '../widgets/InputRadio.styled';

const Settings = styled.div`
  display: flex;
  padding: 72px;
  flex-direction: column;

  ${StyledFeedback.Feedback} {
    height: 24px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 32px;
`;

const Form = styled.div`
  ${StyledInputField.InputField} {
    width: 570px;
    margin-bottom: 24px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;

  ${StyledInputRadio.InputRadio} {
    margin-right: 24px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-top: 24px;

  font-size: 14px;

  & > * + * {
    margin-left: 8px;
  }

  ${Btn} {
    height: 40px;
    width: 120px;
  }

  ${Btn}:last-child {
    text-transform: uppercase;
  }
`;

export const Styled = {
  Settings,
  Title,
  Form,
  RadioGroup,
  Buttons,
};
