import { Styled as S } from '../../Modal.styled';
import React from 'react';
import { useToolbarModal } from '../../toolbar/ToolbarModalContext';

export const LanHelp: React.FC = () => {
  const { hide } = useToolbarModal();
  return (
    <S.Modal>
      <S.Header>
        <S.Title>LAN Help</S.Title>
      </S.Header>
      <S.Scroll>
        <S.Main>
          <S.Description>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in varius odio. Sed vestibulum vulputate
              congue. Nunc id congue ipsum, fringilla rhoncus urna. Etiam sodales et elit in scelerisque. Vestibulum
              scelerisque, nulla eget posuere finibus, sapien arcu feugiat nibh, sit amet interdum purus justo vitae
              arcu. Morbi vel luctus libero, vitae convallis est. Nullam imperdiet elementum condimentum. Morbi
              tristique leo leo, vitae tempor leo fermentum at. Quisque in arcu massa. Integer augue tellus, aliquam
              eget lorem ac, placerat maximus mauris. Sed bibendum semper elit, id fermentum eros cursus sollicitudin.
              Nunc porta scelerisque tincidunt. Aenean vel sapien at orci interdum fringilla.
            </p>
            <p>
              Donec sed nibh at enim finibus lobortis. Duis tincidunt justo accumsan dapibus faucibus. Aenean a sagittis
              ex, at venenatis nulla. Aliquam egestas interdum ligula et euismod. Nulla pharetra massa at magna laoreet
              consectetur a ut leo. Praesent neque velit, rutrum eget purus in, venenatis feugiat tellus. Sed vitae odio
              sed turpis laoreet ultrices vel sit amet felis. Proin vitae risus viverra, viverra arcu non, efficitur ex.
              Donec pulvinar lacus nec massa imperdiet, nec sollicitudin risus elementum. Ut non diam auctor, euismod
              felis vitae, egestas urna. Aenean dictum, purus ut venenatis commodo, ligula tortor accumsan neque, nec
              fermentum eros ante placerat purus. Nam sodales id tellus sed laoreet. Ut sed viverra mauris.
            </p>
            <p>
              Proin massa nibh, laoreet quis malesuada id, hendrerit nec libero. Nulla eleifend, metus vel tempus
              aliquam, mi nulla lobortis nisl, id lobortis nunc ante ut tortor. Aliquam risus enim, tincidunt vel
              consectetur at, condimentum non ipsum. Donec molestie nec nisi eu elementum. Sed ut nisi a mi sollicitudin
              scelerisque eget ornare orci. Integer quis euismod turpis. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. In id nisl feugiat, blandit purus nec, pretium massa. Nam
              lacus est, luctus vel pharetra in, vulputate vitae metus.
            </p>
            <p>
              Proin elit justo, sagittis sit amet aliquam id, finibus quis diam. Curabitur dictum eleifend metus, porta
              fringilla mauris dignissim congue. Sed tellus diam, mattis id nibh quis, vulputate varius sem. Praesent
              faucibus, nisl vel laoreet sodales, lacus erat faucibus ligula, vel euismod tortor risus id libero. Nulla
              nec diam sit amet ante dictum egestas. Proin ac pellentesque ex, nec auctor purus. Cras placerat, sem at
              auctor convallis, velit turpis molestie metus, et imperdiet nibh leo ut lorem. Proin tristique odio purus,
              scelerisque feugiat enim egestas et.
            </p>
            <p>
              Fusce non varius felis, a dictum ante. Ut bibendum tempor sapien, sit amet aliquet velit posuere sed.
              Nullam egestas, ante non tempus elementum, leo dui blandit tortor, eu malesuada massa mauris id dui. Nunc
              vel vulputate erat. Vestibulum orci quam, dignissim quis aliquet eget, vehicula ut risus. Aliquam metus
              ex, rutrum a accumsan ut, maximus sed lorem. Donec faucibus nisl blandit vulputate rhoncus. Nunc eu tortor
              congue, fringilla arcu at, finibus elit. Maecenas a libero dolor. Praesent sagittis nibh a dictum
              porttitor. Proin semper neque vitae nibh varius, nec mattis ligula ornare. Duis sapien lorem, finibus et
              venenatis quis, gravida a sapien. Aliquam bibendum porttitor erat, id viverra sem. Integer dictum suscipit
              orci, a bibendum elit. Aenean vel mattis justo. Donec lacinia vitae ligula et feugiat.
            </p>
            <p>
              Donec tincidunt arcu quis convallis pretium. Fusce viverra sodales lobortis. Morbi congue vel nisl et
              vehicula. Vivamus eros mauris, eleifend a posuere vel, euismod eu nunc. Aenean sem leo, aliquam sed urna
              ac, volutpat commodo magna. Sed eleifend placerat finibus. Nulla eu odio diam. Suspendisse auctor dui vel
              convallis gravida. Morbi consequat erat sit amet velit venenatis, quis tincidunt nibh convallis. Nulla
              facilisi.
            </p>
            <p>
              Donec accumsan magna eget lobortis convallis. Phasellus non commodo enim, quis accumsan felis. Proin
              mauris magna, volutpat vehicula faucibus eu, feugiat nec justo. Nunc sollicitudin, odio eget porta
              porttitor, libero felis ultrices sapien, et scelerisque lorem velit non dui. Sed sodales mauris sit amet
              pulvinar ornare. Pellentesque condimentum enim et augue eleifend, eleifend cursus sapien dictum. Nam nunc
              dui, ullamcorper id mattis a, tempus vel nisi. Duis convallis justo non justo laoreet laoreet. Proin
              aliquam, odio tincidunt viverra egestas, dui augue imperdiet augue, vel tempor lectus velit eu elit. Morbi
              sed nisi pulvinar, lobortis erat a, iaculis massa.
            </p>
          </S.Description>
        </S.Main>
      </S.Scroll>
      <S.Footer>
        <S.BtnPrimary onClick={hide}>Close</S.BtnPrimary>
      </S.Footer>
    </S.Modal>
  );
};
