import { SmartLinkWidget } from './SmartLinkWidget';
import * as React from 'react';
import { ConnectingSmartLinkModel, ConnectingSmartLinkModelType } from './ConnectingSmartLinkModel';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '../../insides/engine/DiagramEngine';

export class ConnectingSmartLinkFactory extends AbstractReactFactory<ConnectingSmartLinkModel, DiagramEngine> {
  constructor() {
    super(ConnectingSmartLinkModelType);
  }

  generateReactWidget(event: any): JSX.Element {
    return <SmartLinkWidget engine={this.engine} link={event.model} />;
  }

  generateModel(): ConnectingSmartLinkModel {
    throw new Error('connecting only can be instantiated manually (serialization/deserialization is prohibited)');
  }
}
