import { NetworkPortModel } from './NetworkPortModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { Point } from '@projectstorm/geometry';

export class RawNetworkPortModel extends NetworkPortModel {
  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    if (event.data.raw) {
      this.options.name = this.options.id!;
      this.position = new Point(0, 0);
    }
  }
}
