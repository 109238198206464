import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import * as React from 'react';
import { ExportRack } from './ExportRack';
import { RackModel } from '../../../lan/rack/Rack';

export class ExportRackLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: RackModel[];

  constructor(layer: ExportLayer, models: RackModel[]) {
    this.layer = layer;
    this.models = models;
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.flatMap((model) => {
          const racks = model.asSingle() ? [model.asSingle()!] : model.asComposite()!.getRacks();
          return racks.map(
            (rack) => rack && !!rack.getChildren().length && <ExportRack rack={rack!} size={'l'} key={rack.getID()} />
          );
        })}
      </>
    );
  }

  async build(): Promise<void> {
    this.models.forEach((model) => {
      const compositeModel = model.asComposite() ? model.asComposite()!.getRacks() : [];
      [model.asSingle(), ...compositeModel.map((rack) => rack)].forEach((placeholder) => {
        const rect = placeholder?.getRect()!;
        rect && this.boundary.extendBoundary(rect.getTopLeft());
        rect && this.boundary.extendBoundary(rect.getBottomRight());
      });
    });
    return this.layer.build();
  }
}
