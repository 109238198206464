import React, { useCallback, useEffect, useState } from 'react';
import {
  CompanyDto,
  CompanySortDto,
  CompanySortProperty,
  CompanyUpdateDto,
  Direction,
  RestResponse,
} from '../../../api/nggrace-back';
import { formatMomentShort } from '../../../utils/date-utils';
import { Styled as S } from './CompanyList.styled';
import { Icon } from '../../widgets/Icon';
import { List } from '../../widgets/List.styled';
import { Api } from '../../../api/Api';
import { SortCell } from '../../widgets/SortCell';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StatusTag } from '../../widgets/StatusTag';
import { theme } from '../../../theme';
import { Confirmation } from '../../widgets/Confirmation';
import ReactModal from 'react-modal';
import { CompanySettings } from './CompanySettings';
import { useUser } from '../login/UserContext';
import { useNotifications } from '../../context/NotificationContext';

interface CompanyRowProps {
  company: CompanyDto;
  remove: (companyId: number) => void;
  update: (companyId: number, company: CompanyUpdateDto) => RestResponse<void>;
}

interface CompanyListState {
  companies: CompanyDto[];
  hasMore: boolean;
  page: number;
  size: number;
  sortProperty: CompanySortProperty;
  sortDirection: Direction;
}

interface CompanyListProps {
  companyCreationVisibility: boolean;
  hideCompanyCreation: () => void;
}

export const CompanyList: React.FC<CompanyListProps> = ({ companyCreationVisibility, hideCompanyCreation }) => {
  const [state, setState] = useState<CompanyListState>({
    companies: [],
    hasMore: true,
    page: 0,
    size: 20,
    sortProperty: 'CREATED_AT',
    sortDirection: 'DESC',
  });
  const notifications = useNotifications();

  const fetch = useCallback(
    (page, size, reload?: boolean) => {
      const sort: CompanySortDto = {
        property: state.sortProperty,
        direction: state.sortDirection,
      };
      Api.getCompanies(sort, { page, size }).then((response) => {
        setState((state) => ({
          ...state,
          companies: reload ? response.data.content || [] : state.companies.concat(response.data.content || []),
          hasMore: !response.data.last,
        }));
      });
    },
    [state.sortDirection, state.sortProperty]
  );

  const reload = useCallback(() => {
    setState((state) => ({ ...state, hasMore: true, page: 0 }));
    fetch(0, state.size, true);
  }, [fetch, state.size]);

  useEffect(reload, [reload]);

  const direction = (property: CompanySortProperty) => {
    return state.sortProperty === property ? state.sortDirection : undefined;
  };

  const handleSort = useCallback((property: CompanySortProperty, direction: Direction) => {
    setState((state) => ({ ...state, sortProperty: property, sortDirection: direction }));
  }, []);

  const handleNext = useCallback(() => {
    fetch(state.page + 1, state.size);
    setState((state) => ({ ...state, page: state.page + 1 }));
  }, [fetch, state.page, state.size]);

  const handleUpdate = useCallback((companyId: number, company: CompanyUpdateDto) => {
    return Api.updateCompany(companyId, company);
  }, []);

  const handleDelete = useCallback(
    (companyId) => {
      Api.deleteCompany(companyId)
        .then(() => {
          fetch(state.companies.length - 1, 1);
          notifications.notifySuccess('Company deleted.');
        })
        .then(() =>
          setState((state) => ({
            ...state,
            companies: state.companies.filter((it) => it.id !== companyId),
          }))
        );
    },
    [fetch, notifications, state.companies.length]
  );

  const handleCreate = useCallback(
    (company?: CompanyUpdateDto) => {
      company && reload();
      hideCompanyCreation();
    },
    [hideCompanyCreation, reload]
  );

  return (
    <S.CompanyList>
      <InfiniteScroll
        dataLength={state.companies.length}
        next={handleNext}
        hasMore={state.hasMore}
        scrollableTarget="scrollableTarget"
        loader={null}
      >
        <S.Grid id="scrollableTarget">
          <List.Row>
            <SortCell<CompanySortProperty>
              type={'COMPANY_NAME'}
              direction={direction('COMPANY_NAME')}
              onSort={handleSort}
            >
              Company
            </SortCell>
            <SortCell<CompanySortProperty> type={'CREATED_AT'} direction={direction('CREATED_AT')} onSort={handleSort}>
              Created
            </SortCell>
            <SortCell<CompanySortProperty>
              type={'LAST_ACTIVITY_AT'}
              direction={direction('LAST_ACTIVITY_AT')}
              onSort={handleSort}
            >
              Last activity
            </SortCell>
            <SortCell<CompanySortProperty>
              type={'CREATOR_NAME'}
              direction={direction('CREATOR_NAME')}
              onSort={handleSort}
            >
              Created by
            </SortCell>
            <SortCell<CompanySortProperty> type={'STATUS'} direction={direction('STATUS')} onSort={handleSort}>
              Status
            </SortCell>
            <List.HeaderCell center>Edit</List.HeaderCell>
            <List.HeaderCell center>Delete</List.HeaderCell>
          </List.Row>

          {state.companies.map((company) => (
            <CompanyRow key={company.id} company={company} remove={handleDelete} update={handleUpdate} />
          ))}
        </S.Grid>
      </InfiniteScroll>
      <ReactModal isOpen={companyCreationVisibility} onRequestClose={hideCompanyCreation} style={theme.modal}>
        <CompanySettings onClose={handleCreate} />
      </ReactModal>
    </S.CompanyList>
  );
};

const CompanyRow: React.FC<CompanyRowProps> = ({ company, remove, update }) => {
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const [active, setActive] = useState(!company.disabled);
  const usersCompany = company.id === useUser()!.user!.company.id;

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const showSettings = useCallback(() => {
    setSettingsVisibility(true);
  }, []);

  const updateStatus = useCallback(
    (active) => {
      update(company.id, {
        ...company,
        disabled: !active,
      }).then(() => setActive(active));
    },
    [company, update]
  );

  const handleCloseSettings = useCallback(
    (companyUpdate?: CompanyUpdateDto) => {
      if (companyUpdate) {
        company.name = companyUpdate.name!;
      }
      setSettingsVisibility(false);
    },
    [company]
  );

  const handleConfirmDelete = useCallback(() => {
    remove(company.id);
    hideConfirmationModal();
  }, [company.id, hideConfirmationModal, remove]);

  return (
    <>
      <List.Row key={company.id}>
        <List.Cell>{company.name}</List.Cell>
        <List.Cell>{formatMomentShort(company.createdAt)}</List.Cell>
        <List.Cell>{formatMomentShort(company.lastActivityAt)}</List.Cell>
        <List.Cell>{company.creator.name}</List.Cell>
        <List.Cell>
          <StatusTag active={active} onClick={updateStatus} disabled={usersCompany} />
        </List.Cell>
        <List.Cell center>
          <List.Button onClick={showSettings}>
            <Icon name={'edit'} />
          </List.Button>
        </List.Cell>
        <List.Cell center>
          <List.Button onClick={showConfirmationModal} disabled={usersCompany}>
            <Icon name={'trash'} />
          </List.Button>
        </List.Cell>
      </List.Row>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Remove company'}
          text={`Are you sure you want to remove ${company.name}?`}
          onClose={hideConfirmationModal}
          onConfirm={handleConfirmDelete}
        />
      </ReactModal>
      <ReactModal isOpen={settingsVisibility} onRequestClose={() => handleCloseSettings()} style={theme.modal}>
        <CompanySettings onClose={handleCloseSettings} company={company} />
      </ReactModal>
    </>
  );
};
