import { Point } from '@projectstorm/geometry';
import { RotationHour } from '../../../geometry/RotationHour';
import { LanNodeModel } from '../LanNodeModel';
import { PropertiesDirectoryName, SwitchDirectoryEntry } from '../../../directory/PropertiesDirectory';
import { SwitchPortModel } from './SwitchPortModel';
import { LanPortModel, LanPortSize } from '../port/LanPortModel';
import { BasePoint } from '../../../geometry/Point';

export const DefaultSwitchSize = new Point(30, 30);
export const SwitchNodeType = 'switch';
const SwitchMaxPortCount = 36;

export const getMaxSwitchWidth = () => {
  return getSwitchSize(SwitchMaxPortCount);
};

export class SwitchNodeModel extends LanNodeModel {
  constructor(directoryEntry: SwitchDirectoryEntry) {
    super(
      {
        type: SwitchNodeType,
        payload: {
          hour: RotationHour.ZERO,
        },
        defaultSize: DefaultSwitchSize,
        resizers: [],
      },
      directoryEntry
    );
  }

  createPort(label: string): LanPortModel {
    return new SwitchPortModel({ directoryEntry: this.getDirectoryEntry(), label });
  }

  getSize(): BasePoint {
    const portCount = this.getDirectoryEntry()
      .ports.map((port) => port.value)
      .reduce((a, b) => a + b, 0);
    return new BasePoint(getSwitchSize(portCount), this.options.defaultSize.y);
  }

  getDirectory(): PropertiesDirectoryName {
    return 'SwitchDirectory';
  }
}

const getSwitchSize = (portCount: number) => {
  const bottomAlignedPortsCount = portCount - 2;
  const indentNumber = bottomAlignedPortsCount + 1;
  return (bottomAlignedPortsCount + indentNumber) * LanPortSize;
};
