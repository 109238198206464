import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface SvgNodeWidgetProps {
  width: number;
  height: number;
  source: string;
}

export const SvgNodeWidget = React.forwardRef<HTMLImageElement, SvgNodeWidgetProps>(
  ({ width, height, source }, ref) => {
    const [icon, setIcon] = useState(<></>);
    const preventDrag = useCallback((event) => event.preventDefault(), []);

    useEffect(() => {
      setIcon(
        <img
          ref={ref}
          draggable={'false'}
          onDragStart={preventDrag} //Firefox fix
          src={source}
          alt={''}
          width={width}
          height={height}
        />
      );
    }, [height, ref, source, width, preventDrag]);

    return React.cloneElement(icon, {
      height: height,
      width: width,
    });
  }
);
