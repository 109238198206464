import * as React from 'react';
import { PlaceholderBaseChild } from '../../../placeholder/Placeholder';

interface ExportPlaceholderBrickProps {
  brickModel: PlaceholderBaseChild & { getBrickColor(): string; getCodeName(): string };
}

export const ExportPlaceholderBrick: React.FC<ExportPlaceholderBrickProps> = ({ brickModel }) => {
  const rect = brickModel.getRect()!;
  return (
    <g
      transform={`translate(${rect.getTopLeft().x},${rect.getTopLeft().y})`}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize="10px"
      fontFamily="GOSTRUS, serif"
    >
      <rect x={0} y={0} width={rect.getWidth()} height={rect.getHeight()} fill={brickModel.getBrickColor()} />
      <text fill="white" x={rect.getWidth() / 2} y={rect.getHeight() / 2}>
        {brickModel.getCodeName()}
      </text>
    </g>
  );
};
