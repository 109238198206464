import React, { useCallback, useMemo, useState } from 'react';
import { CapexType } from './types';
import { Styled as S } from './CapexCard.styled';
import { theme } from '../../../theme';
import { Accordion } from '../../widgets/Accordion';
import { RoundComparisonChart } from '../../widgets/RoundComparisonChart';

type CapexGraphProps = {
  currency: string;
  capex: CapexType;
};

export const CapexCard: React.FC<CapexGraphProps> = ({ currency, capex }) => {
  const [protectionExpanded, setProtectionExpanded] = useState(false);

  const handleToggleAccordion = useCallback(
    () => setProtectionExpanded((protectionExpanded) => !protectionExpanded),
    []
  );

  const protectionList = useMemo(() => {
    const protection = capex.protection;
    return [
      { name: 'IED', value: protection.ied },
      { name: 'MU', value: protection.mu },
      { name: 'Ethernet switch', value: protection.ethernetSwitch },
      { name: 'Cube', value: protection.cube },
    ];
  }, [capex.protection]);

  const scadaList = useMemo(() => {
    const scada = capex.scada;
    return [
      { name: 'IED', value: scada.ied },
      { name: 'Ethernet switch', value: scada.ethernetSwitch },
      { name: 'Cube', value: scada.cube },
      { name: 'SCADA system', value: scada.scadaSystem },
      { name: 'WorkStation', value: scada.workStation },
    ];
  }, [capex.scada]);

  return (
    <S.CapexCard>
      <RoundComparisonChart
        comparableA={{
          label: 'SCADA',
          percent: capex.scada.percentage,
          color: theme.colors.purple,
        }}
        comparableB={{
          label: 'Protection',
          percent: capex.protection.percentage,
          color: theme.colors.turquoise,
        }}
      />
      <S.Legend>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.turquoise} />
          <S.LegendLabel>Protection</S.LegendLabel>
        </S.LegendItem>
        <S.LegendItem>
          <S.LegendIcon color={theme.colors.purple} />
          <S.LegendLabel>Scada</S.LegendLabel>
        </S.LegendItem>
      </S.Legend>
      <S.Content>
        <Accordion
          title="Protection"
          subtitle={`${capex.protection.cost} ${currency}`}
          expanded={protectionExpanded}
          list={protectionList}
          onClick={handleToggleAccordion}
        />
        <Accordion
          title="SCADA"
          subtitle={`${capex.scada.cost} ${currency}`}
          expanded={!protectionExpanded}
          list={scadaList}
          onClick={handleToggleAccordion}
        />
      </S.Content>
      <S.Footer>
        {capex.total.cost} {currency}
      </S.Footer>
    </S.CapexCard>
  );
};
