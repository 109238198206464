import { DirectoryPortModel } from './DirectoryPortModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { Point } from '@projectstorm/geometry';
import { NodeDirectory, PortRecord } from './NodeDirectory';
import { BasePoint } from '../geometry/Point';

export class RawDirectoryPortModel extends DirectoryPortModel {
  private readonly directory: NodeDirectory;

  constructor(directory: NodeDirectory, record: PortRecord) {
    super(record);
    this.directory = directory;
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    if (event.data.raw) {
      const directoryEntry = this.directory.getEntry(event.data.directoryEntryId);

      // temporary hack
      let portEntry = directoryEntry.ports.find((port) => port.name === event.data.name);
      if (!portEntry && event.data.name === 'top') {
        portEntry = directoryEntry.ports.find((port) => port.name === 'bottom');
      }

      this.position = new Point(0, 0);
      this.relativePosition = new BasePoint(portEntry!.x - this.getSize() / 2, portEntry!.y - this.getSize() / 2);
      this.options.alignment = portEntry!.alignment;
    }
  }
}
