import React, { useCallback } from 'react';
import { InputGroup } from '../../../widgets/InputGroup.styled';
import { ExportFormatType, ExportFormDto, ExportFormType } from '../../../../api/nggrace-back';
import { CheckboxGroup } from '../../../widgets/CheckboxGroup';

type UpdateFormAction = (form: ExportFormDto) => ExportFormDto;

type FormatsGroupProps = {
  form: ExportFormDto;

  onChange(formUpdater: UpdateFormAction): void;
};

const diagramTypes: ExportFormType[] = ['SLD', 'SSD', 'SCD', 'LAN'];

const diagramFormats: ExportFormatType[] = ['SVG', 'SCL'];
const disabledDiagramFormats: ExportFormatType[] = [];

const externalFormats: ExportFormatType[] = ['PDF', 'XLS'];
const disabledExternalFormats: ExportFormatType[] = [];

export const FormatsGroup: React.FC<FormatsGroupProps> = ({ form, onChange }) => {
  const handleChange = useCallback(
    (formats: ExportFormatType[]) => {
      onChange((form) => ({ ...form, formats }));
    },
    [onChange]
  );

  const formats = diagramTypes.includes(form.type) ? diagramFormats : externalFormats;
  const disabledFormats = diagramTypes.includes(form.type) ? disabledDiagramFormats : disabledExternalFormats;

  return (
    <InputGroup>
      <CheckboxGroup<ExportFormatType>
        values={formats}
        selectedValues={form.formats}
        disabledValues={disabledFormats}
        onChange={handleChange}
      />
    </InputGroup>
  );
};
