import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { DefaultHasExtendableParentModel, SelectableTreeBaseModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Factory } from '../../../../utils/factory';
import { RoomModel } from '../../../editor/lan/building/RoomModel';
import { LanModel } from '../../../editor/lan/LanModel';
import { RackLayerModel } from '../../../editor/lan/rack/layer/RackLayerModel';
import { LanStructureTreeRackModelFactoryType, LanStructureTreeRackProps } from './LanStructureTreeRack';
import { RackModel } from '../../../editor/lan/rack/Rack';
import { RackConfiguration } from './LanConfigurationContext';
import { ZoneLayerModel } from '../../../editor/lan/zone/layer/ZoneLayerModel';
import { LanEngine } from '../../../editor/lan/LanEngine';

export const LanStructureTreeRoomFactory = (
  engine: LanEngine,
  lanModel: LanModel,
  rackConfigurator: () => Promise<RackConfiguration>,
  rackFactory: Factory<LanStructureTreeRackProps, TreeNodeState>,
  rackModelFactory: LanStructureTreeRackModelFactoryType
): Factory<LanStructureTreeRoomProps, TreeNodeState> => ({ model, parent }) =>
  LanStructureTreeRoom(
    engine,
    parent,
    model,
    lanModel.getRackLayer(),
    lanModel.getZoneLayer(),
    rackConfigurator,
    rackFactory,
    rackModelFactory
  );

export interface LanStructureTreeRoomProps {
  model: RoomModel;
  parent: ExtendableTreeNode;
}

export const LanStructureTreeRoom = (
  engine: LanEngine,
  parent: ExtendableTreeNode,
  room: RoomModel,
  rackLayer: RackLayerModel,
  zoneLayer: ZoneLayerModel,
  rackConfigurator: () => Promise<RackConfiguration>,
  rackFactory: Factory<LanStructureTreeRackProps, TreeNodeState>,
  rackModelFactory: LanStructureTreeRackModelFactoryType
): TreeNodeState => {
  const extendable = ExtendableLanStructureTreeRoomNode(rackLayer, room, zoneLayer, rackConfigurator, rackModelFactory);

  const model = LanStructureTreeRoomModel(extendable, room, rackLayer, rackFactory);

  return {
    ...model,
    ...extendable,
    ...DefaultHasExtendableParentModel(model, parent),
    ...SelectableTreeBaseModel(room, engine),
  };
};

const LanStructureTreeRoomModel = (
  parent: ExtendableTreeNode,
  room: RoomModel,
  rackLayer: RackLayerModel,
  rackFactory: Factory<LanStructureTreeRackProps, TreeNodeState>
): TreeNodeModel => {
  return {
    getName: () => room.getName(),
    getKey: () => room.getID(),
    onChildrenChanged: (callback) => {
      return room.registerListener({
        childrenChanged: (event: { child: RackModel; created: boolean }) =>
          callback(rackFactory({ model: event.child, parent }), event.created),
      } as any).deregister;
    },
    getChildren: () => room.getChildren().map((model) => rackFactory({ parent, model })),
  };
};

const ExtendableLanStructureTreeRoomNode = (
  rackLayer: RackLayerModel,
  room: RoomModel,
  zoneLayer: ZoneLayerModel,
  rackConfigurator: () => Promise<RackConfiguration>,
  rackModelFactory: LanStructureTreeRackModelFactoryType
): ExtendableTreeNode => ({
  canAddChild: (childPayload) => {
    return childPayload.type === TreeDraggableType.Rack;
  },
  addChild: (childPayload: TreeDraggablePayload, childToAddAfter?: TreeNodeModel) => {
    if (!childPayload.modelId) {
      createRack()
        .then(addRack)
        .catch(() => {});
    } else {
      const rack = Object.values(rackLayer.getModels()).find((rack) => rack.getID() === childPayload.modelId)!;

      rack.notCascadeRemove();

      addRack(rack);
    }

    function addRack(rack: RackModel) {
      const indexToAdd = childToAddAfter
        ? room.getChildren().findIndex((child) => child.getID() === childToAddAfter.getKey()) + 1
        : 0;

      rackLayer.addModel(rack);
      rack.setRoom(room, indexToAdd);
    }

    async function createRack(): Promise<RackModel> {
      const configuration = await rackConfigurator();
      return rackModelFactory({ ...configuration, zoneId: configuration.zone!, zoneLayer });
    }
  },
});
