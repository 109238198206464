import * as React from 'react';
import { SsdEngine } from '../../SsdEngine';
import { LogicDeviceLayerModel } from './LogicDeviceLayerModel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SsdNodeModel } from '../node/SsdNodeLayerModel';
import { PlaceholderGroup } from '../../../placeholder/PlaceholderGroup';

export interface LogicDeviceLayerWidgetProps {
  layer: LogicDeviceLayerModel;
  engine: SsdEngine;
}

export const LogicDeviceLayerWidget: React.FC<LogicDeviceLayerWidgetProps> = ({ layer, engine }) => {
  const getDevices = useCallback(() => Object.values(layer.getModels()), [layer]);
  const [devices, setDevices] = useState(getDevices);

  const relativeModelsWithDevices = useMemo(() => {
    return Object.values(
      devices.reduce((obj, device) => {
        obj[device.getRelativeModel()!.getID()] = device.getRelativeModel()!;
        return obj;
      }, {} as { [key: string]: SsdNodeModel })
    );
  }, [devices]);

  useEffect(() => {
    setDevices(getDevices());
    return layer.registerListener({
      logicDeviceAdded: () => {
        setDevices(getDevices());
      },
      logicDeviceRemoved: () => setDevices(getDevices()),
    }).deregister;
  }, [layer, getDevices]);

  const groupWidgets = useMemo(
    () =>
      relativeModelsWithDevices.map((node) => (
        <PlaceholderGroup relativeModel={node} key={node.getID()} engine={engine} />
      )),
    [relativeModelsWithDevices, engine]
  );
  return <>{groupWidgets}</>;
};
