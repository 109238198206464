import { ObservableEngine } from './ObservableEngine';
import { ModelObserver } from './NgGraceModelObserver';
import { CanvasEngineOptions } from '@projectstorm/react-canvas-core';
import { isConnectivityNodeModel, isNodeModel, NgGraceModel } from './NgGraceModel';
import { ConnectivityNodeModel } from './connectivity/ConnectivityNodeModel';
import { ConnectivityNodeDeletionCommand } from './model/command/ConnectivityNodeDeletionCommand';
import { PortModel } from '@projectstorm/react-diagrams';
import { Rectangle } from '@projectstorm/geometry';
import { DeleteItemsAction } from './actions/DeleteItemsAction';
import { UndoRedoAction } from './actions/UndoRedoAction';
import { ZoomAction } from './actions/ZoomAction';
import { NgGraceActionEventBus } from './NgGraceActionEventBus';

export class CommonFunctionalEngine extends ObservableEngine {
  private history: any[] = [];
  private historyPosition: number = 0;
  private historyChangeListenerDeregister?: () => void;

  constructor(options: CanvasEngineOptions, observer: ModelObserver) {
    super({ ...options, registerDefaultDeleteItemsAction: false, registerDefaultZoomCanvasAction: false }, observer);
    this.eventBus = new NgGraceActionEventBus(this);
    this.eventBus.registerAction(new DeleteItemsAction());
    this.eventBus.registerAction(new UndoRedoAction());
    this.eventBus.registerAction(new ZoomAction({ inverseZoom: true }));
  }

  async undo(): Promise<void> {
    if (this.historyPosition > 0 && !this.getModel().isLocked()) {
      const state = this.history[--this.historyPosition];
      await this.restoreState(state);
    }
  }

  canUndo(): boolean {
    return this.historyPosition > 0;
  }

  async redo(): Promise<void> {
    if (this.historyPosition < this.history.length - 1 && !this.getModel().isLocked()) {
      const state = this.history[++this.historyPosition];
      await this.restoreState(state);
    }
  }

  canRedo(): boolean {
    return this.historyPosition < this.history.length - 1;
  }

  async copy(): Promise<void> {
    const model = this.getModel().copy();
    const serialized = JSON.stringify(model.serialize());
    await navigator.clipboard.writeText(serialized);
  }

  async paste(data: string): Promise<void> {
    const parsed = JSON.parse(data);
    const deserializedModel = this.buildNewModel();
    await deserializedModel.deserializeModel(parsed, this);
    if (deserializedModel.getModels().length === 0) {
      return Promise.reject();
    }

    this.getModel().paste(deserializedModel);

    await this.repaintCanvas();

    // await for canvas repaint doesn't work
    // we must wait didMount to get width/height of pasted items
    await Promise.resolve();

    await this.moveToFitNodes(10);
  }

  async deleteSelected() {
    this.getModel()
      .getSelectedEntities()
      .forEach((model) => {
        // only delete items which are not locked
        if (!model.isLocked()) {
          model.remove();
        }
      });

    this.getModel()
      .getModels()
      .filter(isConnectivityNodeModel)
      .map((model: unknown) => model as ConnectivityNodeModel)
      .forEach((node: ConnectivityNodeModel) =>
        new ConnectivityNodeDeletionCommand((this.getModel() as unknown) as NgGraceModel, node).execute()
      );

    this.fireSelectionChange();
    await this.repaintCanvas();
  }

  selectAll() {
    this.getModel()
      .getSelectionEntities()
      .forEach((entity) => entity.setSelected(true));
  }

  setModel(model: NgGraceModel) {
    super.setModel(model);

    // clear undo/redo history and init with current model
    this.history = [model.serialize()];
    this.historyPosition = 0;

    this.setHistoryChangeListener(model);
  }

  getPortCoords(port: PortModel, element?: HTMLDivElement): Rectangle {
    const result = super.getPortCoords(port, element);
    result.translate(-1, -1); // engine always return value with one pixel offset
    return result;
  }

  async moveToFitNodes(margin?: number) {
    let nodesRect; // nodes bounding rectangle
    const selectedNodes = this.model.getSelectedEntities().filter(isNodeModel);

    // no node selected
    if (selectedNodes.length === 0) {
      const allNodes = this.model.getSelectionEntities().filter(isNodeModel);

      // get nodes bounding box with margin
      nodesRect = this.getBoundingNodesRect(allNodes, margin);
    } else {
      // get nodes bounding box with margin
      nodesRect = this.getBoundingNodesRect(selectedNodes, margin);
    }

    if (nodesRect) {
      const model = this.getModel();
      const offsetX = model.getOffsetX(); // zoomed value
      const offsetY = model.getOffsetY(); // zoomed value

      const canvasRect = this.canvas.getBoundingClientRect();

      const nodesTopLeft = nodesRect.getTopLeft();
      const nodesLeft = offsetX + model.getZoomedValue(nodesTopLeft.x);
      const nodesTop = offsetY + model.getZoomedValue(nodesTopLeft.y);

      let requireRepaintCanvas = false;

      if (nodesLeft < 0 || nodesLeft > canvasRect.width) {
        model.setOffsetX(-nodesLeft + offsetX);
        requireRepaintCanvas = true;
      }

      if (nodesTop < 0 || nodesTop > canvasRect.height) {
        model.setOffsetY(-nodesTop + offsetY);
        requireRepaintCanvas = true;
      }

      if (requireRepaintCanvas) {
        await this.repaintCanvas();
      }
    }
  }

  async highlightNode(nodeId: string, margin: number = 50) {
    const node = this.model.getNode(nodeId);
    const nodeRect = this.getBoundingNodesRect([node], margin);

    if (nodeRect) {
      const model = this.getModel();
      model.setZoomLevel(200);

      const offsetX = model.getOffsetX(); // zoomed value
      const offsetY = model.getOffsetY(); // zoomed value

      const nodesTopLeft = nodeRect.getTopLeft();
      const nodesLeft = offsetX + model.getZoomedValue(nodesTopLeft.x);
      const nodesTop = offsetY + model.getZoomedValue(nodesTopLeft.y);

      model.setOffsetX(-nodesLeft + offsetX);
      model.setOffsetY(-nodesTop + offsetY);

      await this.repaintCanvas();
    }
  }

  async restoreState(state: any, fireChangeEvent = true): Promise<NgGraceModel> {
    const model = this.buildNewModel();
    await model.deserializeModel(state, this);

    if (this.getModel()) {
      // update existent model
      super.setModel(model); // super is important here, as we don't want to clear history

      if (fireChangeEvent) {
        this.fireChange();
      }

      this.fireSelectionChange();

      this.setHistoryChangeListener(model);
    } else {
      this.setModel(model); // set initial model
    }

    return model;
  }

  buildNewModel() {
    return new NgGraceModel();
  }

  private setHistoryChangeListener(model: NgGraceModel) {
    // set listener to observer to avoid change event on undo/redo
    this.historyChangeListenerDeregister && this.historyChangeListenerDeregister();
    this.observer.onChange(model, () => {
      // remove redo if needed
      if (this.history.length > this.historyPosition + 1) {
        this.history.splice(this.historyPosition + 1);
      }

      // limit history size
      if (this.history.length >= 20) {
        this.history.splice(0, 1);
      }

      // add new state
      this.history.push(model.serialize());
      this.historyPosition = this.history.length - 1;
    });
  }
}
