import React, { ChangeEvent, useCallback } from 'react';

type CheckboxFieldProps = {
  value: boolean;
  disabled?: boolean;

  onChange(value: boolean): void;
};

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ value, disabled, onChange }) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  return <input type="checkbox" onChange={handleChange} checked={value} disabled={disabled} />;
};
