import { PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { SmartRotationHour } from './RotationHour';

export enum Direction {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export interface SmartDirection {
  getEnumValue(): Direction;

  isIncreasing(): boolean;

  isHorizontal(): boolean;

  equals(other: SmartDirection): boolean;
}

export class DefaultDirection implements SmartDirection {
  readonly direction: Direction;

  constructor(direction: Direction) {
    this.direction = direction;
  }

  getEnumValue() {
    return this.direction;
  }

  isIncreasing() {
    return this.direction === Direction.BOTTOM || this.direction === Direction.RIGHT;
  }

  isHorizontal() {
    return this.direction === Direction.LEFT || this.direction === Direction.RIGHT;
  }

  equals(other: SmartDirection) {
    return this.getEnumValue() === other.getEnumValue();
  }
}

export class OppositeDirection implements SmartDirection {
  private origin: SmartDirection;

  constructor(origin: SmartDirection) {
    this.origin = origin;
  }

  equals(other: SmartDirection) {
    return this.getEnumValue() === other.getEnumValue();
  }

  getEnumValue(): Direction {
    const original = this.origin.getEnumValue();

    if (original === Direction.TOP) {
      return Direction.BOTTOM;
    } else if (original === Direction.BOTTOM) {
      return Direction.TOP;
    } else if (original === Direction.LEFT) {
      return Direction.RIGHT;
    } else {
      return Direction.LEFT;
    }
  }

  isHorizontal(): boolean {
    return this.origin.isHorizontal();
  }

  isIncreasing(): boolean {
    return !this.origin.isIncreasing();
  }
}

export class RotatedDirection implements SmartDirection {
  private origin: SmartDirection;
  private hour: SmartRotationHour;

  constructor(origin: SmartDirection, hour: SmartRotationHour) {
    this.origin = origin;
    this.hour = hour;
  }

  equals(other: SmartDirection) {
    return this.getEnumValue() === other.getEnumValue();
  }

  getEnumValue(): Direction {
    const intMapping: { [key in Direction]: number } = {
      [Direction.TOP]: 0,
      [Direction.RIGHT]: 1,
      [Direction.BOTTOM]: 2,
      [Direction.LEFT]: 3,
    };
    const rotationDirection = this.hour.getDirection().getEnumValue();
    const intResult = (intMapping[rotationDirection] + intMapping[this.origin.getEnumValue()]) % 4;

    return Object.entries(intMapping).find(([key, value]) => value === intResult)![0] as Direction;
  }

  isHorizontal(): boolean {
    return new DefaultDirection(this.getEnumValue()).isHorizontal();
  }

  isIncreasing(): boolean {
    return new DefaultDirection(this.getEnumValue()).isIncreasing();
  }
}

export class SmartPortAlignment {
  private direction: SmartDirection;
  constructor(direction: SmartDirection) {
    this.direction = direction;
  }

  getEnumValue(): PortModelAlignment {
    return (this.direction.getEnumValue() as unknown) as PortModelAlignment;
  }
}
