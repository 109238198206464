import { Point } from '@projectstorm/geometry';
import { Direction } from './Direction';
import { RotationHour, SmartRotationHour } from './RotationHour';
import { Coordinate } from './Coordinate';

export class BasePoint extends Point {
  constructor(x: number, y: number);
  constructor(point: Point);
  constructor(point: { x: number; y: number });
  constructor(point: { x: number; y: number } | Point | number, y?: number) {
    if (typeof point === 'object') {
      super(point.x, point.y);
    } else {
      super(point, y!);
    }
  }

  equals(another: Point) {
    return another.x === this.x && this.y === another.y;
  }

  clone(): BasePoint {
    return new BasePoint(this);
  }
}

export class RestrictedDirectionPoint extends BasePoint {
  readonly restricted: Direction[];

  constructor(point: Point, direction?: Direction | Direction[]) {
    super(point);
    this.restricted = (direction && (Array.isArray(direction) ? direction : [direction])) || [];
  }

  isRestricted(direction: Direction) {
    return !this.restricted || this.restricted.indexOf(direction) !== -1;
  }
}

export class ClockwiseRotatedPoint extends BasePoint {
  private pointToRotate: Point;
  private hour: SmartRotationHour;
  private center: Point;

  constructor(pointToRotate: Point, hour: SmartRotationHour, center: Point) {
    super(pointToRotate);
    this.pointToRotate = pointToRotate;
    this.hour = hour;
    this.center = center;
    const rotated = rotatePoint(pointToRotate, hour, center);
    this.x = rotated.x;
    this.y = rotated.y;
  }
}

export class CounterClockwiseRotatedPoint extends BasePoint {
  private pointToRotate: Point;
  private hour: SmartRotationHour;
  private center: Point;

  constructor(pointToRotate: Point, hour: SmartRotationHour, center: Point) {
    super(pointToRotate);
    this.pointToRotate = pointToRotate;
    this.hour = hour;
    this.center = center;
    const rotated = rotatePointInverse(pointToRotate, hour, center);
    this.x = rotated.x;
    this.y = rotated.y;
  }
}

export class SubtractedPoint extends BasePoint {
  constructor(minuend: Point, subtrahend: Point, coordinate?: Coordinate) {
    const x = !coordinate || coordinate.getName() === 'x' ? minuend.x - subtrahend.x : minuend.x;
    const y = !coordinate || coordinate.getName() === 'y' ? minuend.y - subtrahend.y : minuend.y;
    super(x, y);
  }
}

export class AddedPoint extends BasePoint {
  constructor(firstAddendum: Point, secondAddendum2: Point, coordinate?: Coordinate) {
    const x = !coordinate || coordinate.getName() === 'x' ? firstAddendum.x + secondAddendum2.x : firstAddendum.x;
    const y = !coordinate || coordinate.getName() === 'y' ? firstAddendum.y + secondAddendum2.y : firstAddendum.y;
    super(x, y);
  }
}

export class ScaledPoint extends BasePoint {
  constructor(origin: Point, scaleFactor: number, coordinate: Coordinate) {
    const x = !coordinate || coordinate.getName() === 'x' ? origin.x * scaleFactor : origin.x;
    const y = !coordinate || coordinate.getName() === 'y' ? origin.y * scaleFactor : origin.y;
    super(x, y);
  }
}

const rotatePoint = (pointToRotate: Point, smartHour: SmartRotationHour, center: Point) => {
  const hour = smartHour.getEnumValue();

  if (hour === RotationHour.ZERO) {
    return new BasePoint(pointToRotate);
  }
  const cos = hour === RotationHour.THREE || hour === RotationHour.NINE ? 0 : -1;
  const sin = hour === RotationHour.THREE ? 1 : hour === RotationHour.SIX ? 0 : -1;
  const x = pointToRotate.x;
  const y = pointToRotate.y;
  const cx = center.x;
  const cy = center.y;
  return new BasePoint(cos * (x - cx) - sin * (y - cy) + cx, cos * (y - cy) + sin * (x - cx) + cy);
};

const rotatePointInverse = (pointToInverse: Point, smartHour: SmartRotationHour, center: Point) => {
  const hour = smartHour.getEnumValue();

  if (hour === RotationHour.ZERO) {
    return new BasePoint(pointToInverse);
  }
  const cos = hour === RotationHour.THREE || hour === RotationHour.NINE ? 0 : -1;
  const sin = hour === RotationHour.THREE ? 1 : hour === RotationHour.SIX ? 0 : -1;
  const x = pointToInverse.x;
  const y = pointToInverse.y;
  const cx = center.x;
  const cy = center.y;
  return new BasePoint(cos * (x - cx) + sin * (y - cy) + cx, cos * (y - cy) - sin * (x - cx) + cy);
};
