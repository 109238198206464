import styled, { css } from 'styled-components';
import { Styled as S } from './Icon.styled';

// the value is selected by manual search
// the reason for the narrowing of the field is unknown
const mozIncreaseConst = 2.1;

const Input = styled.input<{ width?: number }>`
  display: block;
  width: ${(props) => (props.width ? `${Math.min(props.width, 1000)}px` : '100%')};
  min-height: 48px;
  border-radius: 2px;
  padding: 12px 8px;
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  line-height: normal;
  color: ${(props) => props.theme.colors.dark};
  font-family: ${(props) => props.theme.fonts.onlyPrimary};

  @-moz-document url-prefix() {
    width: ${(props) => (props.width ? `${Math.min(props.width * mozIncreaseConst, 1000)}px` : '100%')};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.white};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  border-radius: 2px;
  padding: 12px 8px;
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  line-height: normal;
  transition: box-shadow 0.15s, border-color 0.15s;

  input {
    border: none;
    outline: none;
    width: 0;
    height: 0;
    padding: 0;
    line-height: 30px;
    margin: 0 2px;

    &:focus {
      width: 100%;
      height: revert;
      padding: revert;
    }
  }
`;

const InputLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  padding: 0 4px;
  background-color: ${(props) => props.theme.colors.white};
  transform-origin: left;
  color: ${(props) => props.theme.colors.darkgrey};
  transition: color 0.15s, transform 0.15s, top 0.15s, left 0.15s;
`;

const InputField = styled.div<{ empty?: boolean; disabled?: boolean }>`
  position: relative;
  margin-top: 12px;
  width: 300px;

  ${Input}:focus,
  ${InputContainer}:focus-within {
    border: 1px solid ${(props) => props.theme.colors.blue};
  }

  ${Input}:focus + ${InputLabel},
  ${Input}:-webkit-autofill + ${InputLabel},
  ${Input}:-webkit-autofill:hover + ${InputLabel},
  ${Input}:-webkit-autofill:focus + ${InputLabel},
  ${Input}:-webkit-autofill:active + ${InputLabel},
  ${InputContainer}:focus-within ${InputLabel} {
    top: 0;
    transform: translateY(-63%) scale(0.75);
    color: ${(props) => props.theme.colors.blue};
  }

  ${(props) =>
    !props.empty &&
    css`
      ${Input}, ${InputContainer} {
        outline: 0;
      }

      ${Input} + ${InputLabel}, ${InputContainer} + ${InputLabel}, ${InputContainer} ${InputLabel} {
        top: 0;
        transform: translateY(-63%) scale(0.75);
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default !important;
      pointer-events: none !important;
      user-select: none !important;
      opacity: 0.3 !important;
      box-shadow: none !important;
    `}
`;

const InputFieldPassword = styled(InputField)`
  ${Input}, ${InputContainer} {
    padding-right: 50px;
  }

  ${S.Icon} {
    display: flex;
    position: absolute;
    top: 50%;
    right: 18px;
    width: 22px;
    transform: translateY(-50%);
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
`;

export const Styled = {
  Input,
  InputContainer,
  InputLabel,
  InputField,
  InputFieldPassword,
};
