import styled from 'styled-components';
import { Brick } from '../../../widgets/Brick.styled';
import { Rectangle } from '@projectstorm/geometry';

const placeholderMargin = 8;
const leftHeaderWidth = 70;
const rightHeaderWidth = 53;
const headerHeight = 12;
const borderWidth = 1;
const brickMargin = 4;
const contentHorizontalPadding = 4;
const contentVerticalPadding = 4;

const primaryHeaderRect = new Rectangle(
  borderWidth,
  borderWidth,
  leftHeaderWidth + borderWidth + rightHeaderWidth,
  headerHeight
);
const leftHeaderRect = new Rectangle(
  borderWidth,
  primaryHeaderRect.getBottomLeft().y + borderWidth,
  leftHeaderWidth,
  headerHeight
);

const rightHeaderRect = new Rectangle(
  leftHeaderRect.getTopRight().x + borderWidth,
  leftHeaderRect.getTopRight().y,
  rightHeaderWidth,
  headerHeight
);

export const PlaceholderWidgetSizes = {
  primaryHeaderRect,
  leftHeaderRect,
  rightHeaderRect,
  borderWidth,
  placeholderMargin,
  brickMargin,
  contentHorizontalPadding,
  contentVerticalPadding,
};

const Group = styled.div.attrs<{ left: number; top: number }>(({ left, top }) => ({
  style: {
    left,
    top,
  },
}))<{ left: number; top: number; length: number }>`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: ${({ length }) => length * (rightHeaderRect.getBottomRight().x + borderWidth + placeholderMargin)}px;

  gap: ${placeholderMargin}px;
`;

const PlaceholderCore = styled.div.attrs<{ left: number; top: number; selected?: boolean; height: number }>(
  ({ left, top }) => ({
    style: {
      left,
      top,
    },
  })
)<{ left: number; top: number; selected?: boolean; height: number }>`
  position: absolute;
  display: grid;
  grid-template-columns: ${leftHeaderWidth}px ${rightHeaderWidth}px;

  box-sizing: border-box;
  font-family: GostRus, serif;
  font-size: 10px;
  height: ${({ height }) => height}px;

  border: ${borderWidth}px solid ${(props) => (props.selected ? 'deepskyblue' : props.theme.colors.grey)};
`;

const PlaceholderWithHeader = styled(PlaceholderCore)`
  grid-template-rows: ${headerHeight}px ${headerHeight}px minmax(${headerHeight}px, auto);
  grid-template-areas:
    'p p'
    'l r'
    'c c';
`;

const Placeholder = styled(PlaceholderCore)`
  grid-template-rows: ${headerHeight}px minmax(${headerHeight}px, min-content);
  grid-template-areas:
    'l r'
    'c c';
`;

const Cell = styled.div<{ selected?: boolean }>`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 12px;
`;

const PrimaryHeader = styled(Cell)`
  grid-area: p;
  border-bottom: 1px solid ${(props) => (props.selected ? 'deepskyblue' : props.theme.colors.grey)};
`;

const LeftHeader = styled(Cell)`
  grid-area: l;
  border-bottom: 1px solid ${(props) => (props.selected ? 'deepskyblue' : props.theme.colors.grey)};
`;

const RightHeader = styled(Cell)`
  grid-area: r;
  border-bottom: 1px solid ${(props) => (props.selected ? 'deepskyblue' : props.theme.colors.grey)};
  border-left: 1px solid ${(props) => (props.selected ? 'deepskyblue' : props.theme.colors.grey)};
`;

const Content = styled(Cell)`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 4px;
  grid-area: c;
  padding: ${contentVerticalPadding}px ${contentHorizontalPadding}px;
`;

const placeholderBrickHeight = 12;
const placeholderBrickHorizontalPadding = 3;

export const PlaceholderBrickSizes = {
  placeholderBrickHeight,
  placeholderBrickHorizontalPadding,
};

const PlaceholderBrick = styled(Brick)`
  position: relative;
  font-size: 10px;
  display: flex;
  align-items: center;
  height: ${placeholderBrickHeight}px;
  padding: 0 ${placeholderBrickHorizontalPadding}px;
`;

const BrickHighlight = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: solid 1px deepskyblue;
`;
export const Styled = {
  Group,
  Placeholder,
  PlaceholderWithHeader,
  PrimaryHeader,
  LeftHeader,
  RightHeader,
  Content,
  PlaceholderBrick,
  BrickHighlight,
};
