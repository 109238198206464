import React, { createContext, FormEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Styled as S } from './LanConfigurationContext.styled';
import { Btn } from '../../../widgets/Btn.styled';
import { InputField } from '../../../widgets/InputField';
import { theme } from '../../../../theme';
import ReactModal from 'react-modal';
import { ZoneType } from '../../../editor/lan/zone/ZoneModel';
import { InputRadioGroup, OptionType } from '../../../widgets/InputRadioGroup';
import { Deferred } from '../../../../utils/promise-utils';

interface ConfigurationModalProps<T> {
  title: string;

  resolve(configuration: T): void;

  onClose(): void;
}

export interface NameConfiguration {
  name: string;
}

export const NameConfigurationModal: React.VFC<ConfigurationModalProps<NameConfiguration>> = ({
  title,
  resolve,
  onClose,
}) => {
  const [name, setName] = useState('');

  const handleNameChange = useCallback((value?: string | number) => {
    if (value !== undefined) {
      setName('' + value);
    }
  }, []);

  const handleCreate = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      resolve({
        name,
      });
    },
    [name, resolve]
  );

  return (
    <S.ConfigurationModal onSubmit={handleCreate}>
      <S.Header>
        <b>{title}</b>
      </S.Header>
      <S.Content>
        <S.Group>
          <S.GroupTitle>Name</S.GroupTitle>
          <InputField label="Name" value={name} onChange={handleNameChange} required />
        </S.Group>
      </S.Content>
      <S.Footer>
        <Btn type="button" secondary onClick={onClose}>
          Cancel
        </Btn>
        <S.CreateBtn type="submit">Create</S.CreateBtn>
      </S.Footer>
    </S.ConfigurationModal>
  );
};

type ZoneTypeOption =
  | 'WORKSTATION'
  | 'SCADA'
  | 'MIDDLE'
  | 'LOW_NETWORK'
  | 'LOW_GENERAL'
  | 'RPA_NETWORK'
  | 'RPA_PROTECTION';

const zones: OptionType<ZoneTypeOption>[] = [
  { id: 'WORKSTATION', name: 'Workstation' },
  { id: 'SCADA', name: 'SCADA' },
  { id: 'MIDDLE', name: 'Middle' },
  { id: 'LOW_NETWORK', name: 'Low -  network' },
  { id: 'LOW_GENERAL', name: 'Low - general' },
  { id: 'RPA_PROTECTION', name: 'RPA - protection' },
  { id: 'RPA_NETWORK', name: 'RPA - network' },
];

const mapZoneTypeOption = (zoneTypeOption: ZoneTypeOption): ZoneType => {
  switch (zoneTypeOption) {
    case 'WORKSTATION':
      return ZoneType.WORKSTATION;
    case 'SCADA':
      return ZoneType.SCADA;
    case 'MIDDLE':
      return ZoneType.MIDDLE;
    case 'LOW_NETWORK':
      return ZoneType.LOW_NETWORK;
    case 'LOW_GENERAL':
      return ZoneType.LOW_GENERAL;
    case 'RPA_PROTECTION':
      return ZoneType.RPA_PROTECTION;
    default:
      throw new Error(`Incorrect zone type: ${zoneTypeOption}`);
  }
};

export interface RackConfiguration extends NameConfiguration {
  zone?: ZoneType;
  composite: boolean;
}

export const RackConfigurationModal: React.VFC<ConfigurationModalProps<RackConfiguration>> = ({
  title,
  resolve,
  onClose,
}) => {
  const [name, setName] = useState('');
  const [zone, setZone] = useState<ZoneTypeOption>('WORKSTATION');

  const handleNameChange = useCallback((value?: string | number) => {
    if (value !== undefined) {
      setName('' + value);
    }
  }, []);

  const handleCreate = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      if (zone === 'RPA_NETWORK') {
        resolve({
          name,
          composite: true,
        });
      } else {
        resolve({
          name,
          zone: mapZoneTypeOption(zone),
          composite: false,
        } as RackConfiguration);
      }
    },
    [name, resolve, zone]
  );

  return (
    <S.ConfigurationModal onSubmit={handleCreate}>
      <S.Header>
        <b>{title}</b>
      </S.Header>
      <S.Content>
        <S.Group>
          <S.GroupTitle>Name</S.GroupTitle>
          <InputField label="Name" value={name} onChange={handleNameChange} required />
        </S.Group>
        <S.Group>
          <S.GroupTitle>Zone</S.GroupTitle>
          <InputRadioGroup<ZoneTypeOption>
            label={'Zone'}
            value={zone}
            options={zones}
            onChange={setZone}
            name="zone"
            hideBorder
          />
        </S.Group>
      </S.Content>
      <S.Footer>
        <Btn type="button" secondary onClick={onClose}>
          Cancel
        </Btn>
        <S.CreateBtn type="submit">Create</S.CreateBtn>
      </S.Footer>
    </S.ConfigurationModal>
  );
};

interface LanConfigurationContextType {
  buildingConfigurator(): Promise<NameConfiguration>;

  roomConfigurator(): Promise<NameConfiguration>;

  rackConfigurator(): Promise<RackConfiguration>;
}

const defaultValue: LanConfigurationContextType = {
  buildingConfigurator: () => Promise.reject(),
  roomConfigurator: () => Promise.reject(),
  rackConfigurator: () => Promise.reject(),
};

const LanConfigurationContext = createContext<LanConfigurationContextType>(defaultValue);

export const useLanConfiguration = (): LanConfigurationContextType => {
  return useContext(LanConfigurationContext);
};

type LanConfigurationType = 'building' | 'room' | 'rack';

export const LanConfigurationProvider: React.FC = ({ children }) => {
  const [configurationType, setConfigurationType] = useState<LanConfigurationType>();
  const nameResolve = useRef<(value: NameConfiguration) => void>();
  const rackResolve = useRef<(value: RackConfiguration) => void>();
  const allReject = useRef<() => void>();

  const buildingConfigurator = useCallback(() => {
    const { promise, resolve, reject } = Deferred<NameConfiguration>();
    nameResolve.current = resolve;
    allReject.current = reject;
    setConfigurationType('building');
    return promise;
  }, []);

  const roomConfigurator = useCallback(() => {
    const { promise, resolve, reject } = Deferred<NameConfiguration>();
    nameResolve.current = resolve;
    allReject.current = reject;
    setConfigurationType('room');
    return promise;
  }, []);

  const rackConfigurator = useCallback(() => {
    const { promise, resolve, reject } = Deferred<RackConfiguration>();
    rackResolve.current = resolve;
    allReject.current = reject;
    setConfigurationType('rack');
    return promise;
  }, []);

  const contextValue: LanConfigurationContextType = useMemo(() => {
    return {
      buildingConfigurator,
      roomConfigurator,
      rackConfigurator,
    };
  }, [buildingConfigurator, rackConfigurator, roomConfigurator]);

  const handleNameResolve = useCallback((value: NameConfiguration) => {
    setConfigurationType(undefined);
    nameResolve.current!(value);
  }, []);

  const handleRackResolve = useCallback((value: RackConfiguration) => {
    setConfigurationType(undefined);
    rackResolve.current!(value);
  }, []);

  const handleClose = useCallback(() => {
    setConfigurationType(undefined);
    allReject.current!();
  }, []);

  return (
    <LanConfigurationContext.Provider value={contextValue}>
      {children}
      <ReactModal isOpen={!!configurationType} onRequestClose={handleClose} style={theme.modal}>
        {configurationType === 'building' && (
          <NameConfigurationModal title="Create building" resolve={handleNameResolve} onClose={handleClose} />
        )}
        {configurationType === 'room' && (
          <NameConfigurationModal title="Create room" resolve={handleNameResolve} onClose={handleClose} />
        )}
        {configurationType === 'rack' && (
          <RackConfigurationModal title="Create rack" resolve={handleRackResolve} onClose={handleClose} />
        )}
      </ReactModal>
    </LanConfigurationContext.Provider>
  );
};
