import { Action, ActionEvent, BaseModel, InputType, State } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '../../insides/engine/DiagramEngine';
import { DragCanvasState } from '../../states/DragCanvasState';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { DefaultState } from '../../states/DefaultState';
import { isSmartLinkModel } from '../../NgGraceModel';
import { SmartLinkDragState } from '../../states/SmartLinkDragState';

export class SsdDefaultState extends State<DiagramEngine> {
  dragCanvas: DragCanvasState;
  private smartLinkDragState: SmartLinkDragState;

  constructor() {
    super({ name: DefaultState.Name });
    this.childStates = [];
    this.dragCanvas = new DragCanvasState({ clearSelectionOnClick: false });
    this.smartLinkDragState = new SmartLinkDragState();
    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const model: BaseModel | null = this.engine
            .getActionEventBus()
            .getModelForEvent(event as ActionEvent<MouseEvent>);

          if (isSmartLinkModel(model)) {
            this.transitionWithEvent(this.smartLinkDragState, event);
            return;
          }
          this.transitionWithEvent(this.dragCanvas, event);
          return;
        },
      })
    );
  }
}
