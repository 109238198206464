import { BasePoint } from '../geometry/Point';
import { Listenable } from './Listenable';

export interface HasPosition {
  getPosition(): BasePoint;

  setPosition(newValue: BasePoint): void;
}

export type HasPositionListener = {
  positionChanged?: (event: { newPosition: BasePoint }) => void;
};

export class DefaultHasPosition implements HasPosition {
  savedPosition: BasePoint;
  private eventDelegate: Listenable<any>;

  constructor(eventDelegate: Listenable<HasPositionListener>, savedPosition?: BasePoint) {
    this.eventDelegate = eventDelegate;
    this.savedPosition = savedPosition || new BasePoint(0, 0);
  }

  getPosition(): BasePoint {
    return this.savedPosition;
  }

  setPosition(newValue: BasePoint): void {
    if (this.savedPosition.equals(newValue)) {
      return;
    }

    this.savedPosition = newValue;
    this.eventDelegate.fireEvent({ newPosition: newValue }, 'positionChanged');
  }
}
