import styled from 'styled-components';
import ReactSlider from 'react-slider';

const InputSlider = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Value = styled.div`
  color: ${(props) => props.theme.colors.dark};
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

const Thumb = styled.div<{ disabled?: boolean }>`
  height: 16px;
  width: 16px;
  background-color: ${({ disabled, theme: { colors } }) => (disabled ? colors.darkgrey : colors.blue)};
  border-radius: 50%;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'grab')};
`;

const Track = styled.div<{ index: number; disabled?: boolean }>`
  margin-top: 7px;
  height: 2px;
  background: ${({ disabled, index, theme: { colors } }) =>
    index === 1 ? colors.grey : disabled ? colors.darkgrey : colors.blue};
  border-radius: 50px;
`;

const Slider = (styled(ReactSlider)`
  width: 100%;
  height: 25px;
` as unknown) as typeof ReactSlider;

export const Styled = {
  InputSlider,
  Header,
  Value,
  Label,
  Slider,
  Thumb,
  Track,
};
