import styled from 'styled-components';
import { Styled as S } from '../Table.styled';

const Grid = styled(S.Grid)<{ showCompany: boolean }>`
  grid-template-columns: ${(props) =>
    `repeat(2, 3.2fr) repeat(${props.showCompany ? '3' : '2'}, 2.5fr) repeat(2, 0.9fr)`};
`;

export const Styled = {
  Grid,
  UserList: S.Content,
};
