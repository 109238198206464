import { HasPosition } from '../../placeholder/HasPosition';
import { AddedPoint, BasePoint, SubtractedPoint } from '../../geometry/Point';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasChildren } from '../../placeholder/HasChildren';
import { Point } from '@projectstorm/geometry';

type ChildToUpdate = BaseObserver & { setPosition(newValue: Point): void; getPosition(): Point };

export class UpdatingChildrenHasPosition implements HasPosition {
  private origin: HasPosition;
  private eventDelegate: BaseObserver;
  private hasChildren: HasChildren<ChildToUpdate>;

  constructor(origin: HasPosition, eventDelegate: BaseObserver, hasChildren: HasChildren<ChildToUpdate>) {
    this.origin = origin;
    this.eventDelegate = eventDelegate;
    this.hasChildren = hasChildren;
  }

  getPosition(): BasePoint {
    return this.origin.getPosition();
  }

  setPosition(newValue: BasePoint): void {
    const oldPosition = this.getPosition();
    this.hasChildren.getChildren().forEach((child) => {
      const offset = new SubtractedPoint(child.getPosition(), oldPosition);
      return child.setPosition(new AddedPoint(offset, newValue));
    });

    return this.origin.setPosition(newValue);
  }
}
