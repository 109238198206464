import React, { useEffect, useState } from 'react';
import { AnonymUserPage } from '../../AnonymUserPage';
import { useParams } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Styled as S } from '../AuthFeedback.styled';
import { UserRegisterSettings } from './UserRegisterSettings';

export const UserRegisterPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [userInviteTokenValid, setUserInviteTokenValid] = useState<boolean>();
  const { userInviteToken } = useParams<{ userInviteToken?: string }>();

  useEffect(() => {
    Api.isUserInvited(userInviteToken!).then((response) => {
      setUserInviteTokenValid(response.data);
      setLoading(false);
    });
  }, [userInviteToken]);

  if (loading) {
    return <></>;
  }

  return (
    <AnonymUserPage>
      {userInviteTokenValid ? <UserRegisterSettings userInviteToken={userInviteToken!} /> : <InvalidRegisterLinkInfo />}
    </AnonymUserPage>
  );
};

const InvalidRegisterLinkInfo: React.FC = () => {
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  return (
    <S.Form>
      <S.Main>
        <S.Title>We are sorry</S.Title>
        <S.Info>Registration link is invalid.</S.Info>
        <S.Button onClick={redirectToLoginPage}>To log in</S.Button>
      </S.Main>
    </S.Form>
  );
};
