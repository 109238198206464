import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { ControllerDirectoryEntry } from '../../../editor/directory/PropertiesDirectory';
import { HasNotExtendableParentModel, NotSelectableModel } from '../../../widgets/tree/state/TreeBaseModel';
import { Directory } from '../../../editor/directory/Directory';
import { Factory } from '../../../../utils/factory';
import { ScdPacsTreeControllerProps } from './ScdPacsTreeController';
import { ScdModel } from '../../../editor/scd/ScdModel';
import { ControllerModel, ControllerType } from '../../../editor/scd/controller/ControllerModel';
import { ControllerLayerModel } from '../../../editor/scd/layer/controller/ControllerLayerModel';

export const ScdPacsTreeControllerType = (
  model: ScdModel,
  controllerType: ControllerType,
  controllerDirectory: Directory<ControllerDirectoryEntry>,
  controllerFactory: Factory<ScdPacsTreeControllerProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableScdControllerTypeNode(controllerType, model.getControllerLayer(), controllerDirectory);

  const controllerTypeModel = ScdPacsTreeControllerTypeModel(
    extendable,
    model.getControllerLayer(),
    controllerType,
    controllerDirectory,
    controllerFactory
  );

  return {
    ...controllerTypeModel,
    ...extendable,
    ...HasNotExtendableParentModel(),
    ...NotSelectableModel(),
  };
};

const ScdPacsTreeControllerTypeModel = (
  parent: ExtendableTreeNode,
  controllerLayer: ControllerLayerModel,
  controllerType: ControllerType,
  controllerDirectory: Directory<ControllerDirectoryEntry>,
  controllerFactory: Factory<ScdPacsTreeControllerProps, TreeNodeState>
): TreeNodeModel => ({
  getName: () => controllerType,
  getKey: () => controllerType,
  onChildrenChanged: (cb) => {
    return controllerLayer.registerListener({
      controllerAdded: ({ controller }: { controller: ControllerModel }) =>
        cb(controllerFactory({ controller, parent }), true),
      controllerRemoved: ({ controller }: { controller: ControllerModel }) =>
        cb(controllerFactory({ controller, parent }), false),
    } as any).deregister;
  },
  getChildren: () => {
    return Object.values(controllerLayer.getModels())
      .filter((controllerModel) => {
        const controllerEntry = controllerDirectory.getEntry(controllerModel.getDirectoryId());
        return (controllerEntry.ied && controllerType === 'IED') || (controllerEntry.mu && controllerType === 'MU');
      })
      .map((controller) => controllerFactory({ controller, parent }));
  },
});

const ExtendableScdControllerTypeNode = (
  controllerType: ControllerType,
  controllerLayer: ControllerLayerModel,
  controllerDirectory: Directory<ControllerDirectoryEntry>
): ExtendableTreeNode => ({
  canAddChild: (childPayload) => {
    if (childPayload.type !== TreeDraggableType.Controller) {
      return false;
    }

    const modelId = childPayload.modelId;
    const directoryId = modelId ? controllerLayer.getModel(modelId).getDirectoryId() : childPayload.directoryId;
    const controller = controllerDirectory.getEntry(directoryId!);

    return (controller.mu && controllerType === 'MU') || (controller.ied && controllerType === 'IED');
  },
  addChild: (childPayload: TreeDraggablePayload) => {
    const controller = !childPayload.modelId
      ? new ControllerModel(controllerDirectory.getEntry(childPayload.directoryId!), '')
      : controllerLayer.getModels()[childPayload.modelId];

    controller.remove();
    controllerLayer.addModel(controller);
  },
});
