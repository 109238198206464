import { PortModelAlignment } from '@projectstorm/react-diagrams';
import { PropertiesDirectoryName } from './PropertiesDirectory';
import { LocalizedValue } from '../../../utils/language-util';
import { Direction } from '../geometry/Direction';
import { Directory, DirectoryEntry } from './Directory';

export interface NodeDirectory extends Directory<NodeDirectoryEntry> {}

export interface RawNodeDirectoryEntry {
  height: LocalizedValue<number>;
  width: LocalizedValue<number>;

  id: string;
  svg: LocalizedValue<string>;
  name: LocalizedValue<string>;
  deviceType: string;
  labelDirection: LocalizedValue<Direction>;
  ports: RawPortRecord[];
  fields: FieldRecord[];
  transformer?: boolean;
}

export enum NodeEntryType {
  BUS,
  DIRECTORY,
}

export interface NodeEntry extends DirectoryEntry {
  height: number;
  width: number;

  svg: string;
  labelDirection: Direction;
  type: NodeEntryType;
}

export interface NodeDirectoryEntry extends NodeEntry {
  deviceType: string;
  ports: PortRecord[];
  fields: FieldRecord[];
  transformer: boolean;
}

export interface RawPortRecord {
  name: string;
  x: LocalizedValue<number>; //relative for svg
  y: LocalizedValue<number>; //relative for svg
  alignment: LocalizedValue<PortModelAlignment>;
  fields: FieldRecord[];
}

export interface PortRecord {
  name: string;
  x: number; //relative for svg
  y: number; //relative for svg
  alignment: PortModelAlignment;
  fields: FieldRecord[];
}

export type FieldRecord = DirectoryFieldRecord | InputFieldRecord;

interface BaseFieldRecord {
  name: string;
  displayName: LocalizedValue<string>;
  fieldType: 'directory' | 'input';
  defaultValue?: string;
}

export interface DirectoryFieldRecord extends BaseFieldRecord {
  fieldType: 'directory';
  directoryName: PropertiesDirectoryName;
}

export const isDirectoryFieldRecord = (arg: BaseFieldRecord): arg is DirectoryFieldRecord => {
  return arg.fieldType === 'directory';
};

export interface InputFieldRecord extends BaseFieldRecord {
  fieldType: 'input';
  inputType: 'number' | 'string' | 'boolean';
}

export const isInputFieldRecord = (arg: BaseFieldRecord): arg is InputFieldRecord => {
  return arg.fieldType === 'input';
};
