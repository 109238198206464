import styled from 'styled-components';
import { Icon } from './widgets/Icon.styled';
import { Link } from 'react-router-dom';

const PageHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 72px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  padding: 0 72px;
`;

const Company = styled.div`
  display: flex;
  flex: 1 0 100px;
`;

const LogoLink = styled(Link)`
  display: flex;
  flex: 1 0 100px;
  align-content: center;
  justify-content: center;
`;

const Options = styled.div`
  display: flex;
  flex: 1 0 100px;
  align-content: center;
  justify-content: flex-end;
`;

const Username = styled.div``;

const LogoutBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  margin-left: 24px;

  ${Icon} {
    width: 21px;
    height: 21px;
  }
`;

const MenuBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  position: absolute;
  left: 20px;

  ${Icon} {
    width: 30px;
    height: 30px;
  }
`;

export const Styled = {
  PageHeader,
  Company,
  LogoLink,
  Options,
  Username,
  LogoutBtn,
  MenuBtn,
};
