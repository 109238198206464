import styled from '@emotion/styled';
import { CanvasEngine, TransformLayerWidget, SmartLayerWidget } from '@projectstorm/react-canvas-core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Toolkit } from '@projectstorm/react-canvas-core';
import { DefaultState } from '../../states/DefaultState';
import { DiagramModelContext } from '../../DiagramModelContext';

export interface NgGraceCanvasWidgetProps {
  engine: CanvasEngine;
  className?: string;
}

namespace S {
  export const Canvas = styled.div`
    position: relative;
    overflow: hidden;
  `;
}

export const NgGraceCanvasWidget: React.FC<NgGraceCanvasWidgetProps> = ({ engine, className }) => {
  const [lastUpdate, setLastUpdate] = useState<string>(Toolkit.UID());
  const model = useContext(DiagramModelContext);
  useEffect(
    () =>
      engine.registerListener({
        repaintCanvas: () => {
          setLastUpdate(Toolkit.UID());
        },
      }).deregister,
    [engine]
  );

  const ref = useRef(null);

  useEffect(() => {
    engine.setCanvas(ref.current || undefined);
    engine.iterateListeners((list) => {
      list.rendered && list.rendered();
    });
  }, [lastUpdate, engine]);

  const fireAction = useCallback(
    (event) => {
      engine.getActionEventBus().fireAction({ event });
    },
    [engine]
  );

  const resetEngineState = useCallback(
    (event) => {
      fireAction(event);
      const currentState = engine.getStateMachine().getCurrentState();
      if (currentState.getOptions().name !== DefaultState.Name) {
        currentState.eject();
      }
    },
    [fireAction, engine]
  );

  useEffect(() => {
    document.addEventListener('keyup', fireAction);
    document.addEventListener('keydown', fireAction);
    return () => {
      document.removeEventListener('keyup', fireAction);
      document.removeEventListener('keydown', fireAction);
    };
  }, [fireAction]);

  useEffect(() => () => engine.setCanvas(undefined), [engine]);
  return (
    <S.Canvas
      className={className}
      ref={ref}
      onWheel={fireAction}
      onMouseDown={fireAction}
      onMouseUp={fireAction}
      onMouseMove={fireAction}
      onBlur={resetEngineState}
      tabIndex={0}
    >
      {model.getLayers().map((layer) => (
        <TransformLayerWidget layer={layer} key={layer.getID()}>
          <SmartLayerWidget layer={layer} engine={engine} key={layer.getID()} />
        </TransformLayerWidget>
      ))}
    </S.Canvas>
  );
};
