import * as React from 'react';
import { ReactNode } from 'react';
import { Styled as S } from './Toolbar.styled';
import { Icon, IconName } from '../widgets/Icon';
import Tippy from '@tippyjs/react';

type ToolbarProps = {
  title: string;
  btn?: ReactNode;
};

type ToolbarItemProps = {
  icon: IconName;
  disabled?: boolean;
  active?: boolean;
  hint: string;

  onClick(): void;
};

export const Toolbar: React.FC<ToolbarProps> = ({ title, btn, children }) => {
  return (
    <S.Toolbar>
      <S.Left>{title}</S.Left>
      <S.Center>{children}</S.Center>
      <S.Right>{btn}</S.Right>
    </S.Toolbar>
  );
};

export const ToolbarItem: React.FC<ToolbarItemProps> = ({ icon, disabled, active, hint, onClick, children }) => {
  return (
    <Tippy content={hint} delay={[800, 0]}>
      <S.ToolbarItem>
        <S.ToolbarBtn onClick={onClick} disabled={disabled} active={active} data-testid={icon}>
          <Icon name={icon} />
        </S.ToolbarBtn>
        {children}
      </S.ToolbarItem>
    </Tippy>
  );
};
