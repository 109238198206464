import * as React from 'react';
import { ExportLayer, ExportLayerBoundary } from '../ExportLayer';
import { ControllerPlaceholder } from '../../../scd/controller/ControllerPlaceholder';
import { ExportPlaceholder } from './ExportPlaceholder';

export class ExportControllerLayer implements ExportLayer {
  private readonly layer: ExportLayer;
  private readonly models: ControllerPlaceholder[];

  constructor(layer: ExportLayer, models: ControllerPlaceholder[]) {
    this.layer = layer;
    this.models = models;
  }

  get boundary(): ExportLayerBoundary {
    return this.layer.boundary;
  }

  get element(): JSX.Element {
    return (
      <>
        {this.layer.element}
        {this.models.map((placeholder) => (
          <ExportPlaceholder placeholderModel={placeholder} key={placeholder.getID()} />
        ))}
      </>
    );
  }

  async build(): Promise<void> {
    this.models.forEach((placeholder) => {
      const rect = placeholder.getRect()!;
      this.boundary.extendBoundary(rect.getTopLeft());
      this.boundary.extendBoundary(rect.getBottomRight());
    });
    return this.layer.build();
  }
}
