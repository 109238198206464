import { AbstractReactFactory, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { SmartLinkModel } from './SmartLinkModel';
import { SmartLinkWidget } from './SmartLinkWidget';
import * as React from 'react';
import { RawSmartLinkModel } from './RawSmartLinkModel';
import { DiagramEngine } from '../../insides/engine/DiagramEngine';

export interface GenerateSmartLinkModelEvent extends GenerateModelEvent {
  initialConfig: ReturnType<SmartLinkModel['serialize']>;
}

export class SmartLinkFactory extends AbstractReactFactory<SmartLinkModel, DiagramEngine> {
  constructor() {
    super('smart');
  }

  generateReactWidget(event: any): JSX.Element {
    return <SmartLinkWidget engine={this.engine} link={event.model} />;
  }

  generateModel(event: GenerateSmartLinkModelEvent): SmartLinkModel {
    return new RawSmartLinkModel();
  }
}
