import { DirectoryNodeModelPayload } from '../../../directory/DirectoryNodeModel';
import { DirectoryNodeFactory } from '../../../directory/DirectoryNodeFactory';
import { DirectoryNodeWithPlaceholdersModel } from '../../../directory/DirectoryNodeWithPlaceholdersModel';
import { DirectoryLogicDeviceModel } from '../../logic-device/LogicDeviceModel';
import {
  DefaultNodeWithPlaceholders,
  StagedSerializedNodeWithPlaceholder,
} from '../../../placeholder/NodeWithPlaceholders';
import { StageType } from '../../../../../api/nggrace-back';

export class SsdDirectoryNodeFactory extends DirectoryNodeFactory {
  private stage: StageType = 'SSD';

  generateModel(event: {
    initialConfig: { payload: DirectoryNodeModelPayload } & StagedSerializedNodeWithPlaceholder;
  }) {
    return new DirectoryNodeWithPlaceholdersModel(
      this.directory.getEntry(event.initialConfig.payload.directoryEntryId),
      (node) =>
        new DefaultNodeWithPlaceholders<DirectoryLogicDeviceModel>(node, node, this.stage, event.initialConfig.SSD)
    );
  }
}
