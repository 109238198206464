import { HasChildren } from '../../placeholder/HasChildren';
import { SingleRackModel } from '../rack/SingleRackModel';
import { HasPosition } from '../../placeholder/HasPosition';
import { Coordinate, DefaultCoordinate } from '../../geometry/Coordinate';
import { AddedPoint, BasePoint } from '../../geometry/Point';
import { Factory } from '../../../../utils/factory';
import { BaseObserver } from '@projectstorm/react-canvas-core';
import { HasRect } from '../../placeholder/HasRect';
import { ModelCoordinateEquivalentSet } from './layer/ModelCoordinateEquivalentSet';

const RowCount = 4;
const ModelsGap = 50;

export type EquivalentSetFactory = Factory<
  {
    models: (BaseObserver & HasRect)[];
    coordinate: Coordinate;
    previousSet?: ModelCoordinateEquivalentSet;
    defaultPosition?: number;
    setsOffset: number;
  },
  ModelCoordinateEquivalentSet
>;

export class ColumnsHasChildren implements HasChildren<SingleRackModel> {
  private readonly origin: HasChildren<SingleRackModel>;
  private readonly hasPosition: HasPosition;
  private readonly equivalentSetFactory: EquivalentSetFactory;
  private readonly eventDelegate: BaseObserver;
  private readonly rowCount: number;
  private columnSets: ModelCoordinateEquivalentSet[] = [];
  private rowsSets: ModelCoordinateEquivalentSet[][] = [];

  constructor(
    origin: HasChildren<SingleRackModel>,
    hasPosition: HasPosition,
    equivalentSetFactory: EquivalentSetFactory,
    eventDelegate: BaseObserver,
    rowCount: number = RowCount
  ) {
    this.equivalentSetFactory = equivalentSetFactory;
    this.eventDelegate = eventDelegate;
    this.origin = origin;
    this.hasPosition = hasPosition;
    this.rowCount = rowCount;
  }

  addChild(childToAdd: SingleRackModel, index?: number): void {
    const children = [...this.getChildren()];
    if (index === undefined) {
      children.push(childToAdd);
    } else {
      children.splice(index, 0, childToAdd);
    }

    const deregister = childToAdd.registerListener({
      entityRemoved: () => {
        this.updateChildren(this.getChildren().filter((child) => child.getID() !== childToAdd.getID()));
        deregister();
      },
    } as any).deregister;

    this.updateChildren(children);
    this.origin.addChild(childToAdd, index);
  }

  getChildren(): SingleRackModel[] {
    return this.origin.getChildren();
  }

  private updateChildren(children: SingleRackModel[]) {
    const coordinate = new DefaultCoordinate('x');

    this.columnSets.forEach((set) => set.remove());
    this.columnSets = [];
    const columnSets = this.columnSets;

    this.rowsSets.flat().forEach((set) => set.remove());
    this.rowsSets = [];
    const startPosition = () => new AddedPoint(this.hasPosition.getPosition(), new BasePoint(ModelsGap, ModelsGap));

    for (let i = 0; i < children.length; i += this.rowCount) {
      let currentPositionY = startPosition().y;
      const rowSet: ModelCoordinateEquivalentSet[] = [];
      const models = children.slice(i, i + this.rowCount);
      models.forEach((model) => {
        model.setPosition(new BasePoint(model.getPosition().x, currentPositionY));
        currentPositionY += model.getSize().y + ModelsGap;
        if (models.length > 1) {
          rowSet.push(
            new ModelCoordinateEquivalentSet(
              [model],
              new DefaultCoordinate('y'),
              rowSet[rowSet.length - 1],
              () => startPosition().y
            )
          );
        }
      });

      columnSets.push(
        new ModelCoordinateEquivalentSet(models, coordinate, columnSets[columnSets.length - 1], () => startPosition().x)
      );

      this.rowsSets.push(rowSet);
      this.eventDelegate.fireEvent({ sets: columnSets } as any, 'columnSetsChanged');
    }
  }
}
