import styled from 'styled-components';

const ReliabilityCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

const Graph = styled.svg`
  width: 340px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: 100%;
  margin: 8px 0 0 0;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LegendIcon = styled.div<{ color: string }>`
  width: 32px;
  height: 2px;
  background-color: ${(props) => props.color};
`;

const LegendLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.darkgrey};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 24px;
`;

export const Styled = {
  ReliabilityCard,
  Graph,
  Legend,
  LegendItem,
  LegendIcon,
  LegendLabel,
  Content,
};
