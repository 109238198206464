import React, { useCallback } from 'react';
import { OpexFormDto } from '../../../api/nggrace-back';
import { Styled as S } from './OpexForm.styled';
import { InputField } from '../../widgets/InputField';
import { InputSlider } from '../../widgets/InputSlider';

type OpexFormProps = {
  currency: string;
  form: OpexFormDto;
  disabled?: boolean;
  onChange?(action: (form: OpexFormDto) => OpexFormDto): void;
};

export const OpexForm: React.FC<OpexFormProps> = ({ currency, form, disabled, onChange }) => {
  const handlePersonPerHourCostChange = useCallback(
    (personPerHourCost: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        personPerHourCost,
      })),
    [onChange]
  );

  const handlePersonPerHourCostFactorChange = useCallback(
    (personPerHourCostFactor: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        personPerHourCostFactor,
      })),
    [onChange]
  );

  const handleDiscountFactorChange = useCallback(
    (discountFactor: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        discountFactor,
      })),
    [onChange]
  );

  const handleInflationFactorChange = useCallback(
    (inflationFactor: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        inflationFactor,
      })),
    [onChange]
  );

  const handleNumberOfTechnicalServiceChange = useCallback(
    (numberOfTechnicalService: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        numberOfTechnicalService,
      })),
    [onChange]
  );

  const handleBillingPeriodInYearsChange = useCallback(
    (billingPeriodInYears: number) =>
      onChange &&
      onChange((form) => ({
        ...form,
        billingPeriodInYears,
      })),
    [onChange]
  );

  return (
    <S.OpexForm>
      <>
        <S.Name>S</S.Name>
        <S.Description>Basic cost per person / hour ({currency})</S.Description>
        <InputField
          type="number"
          min={0}
          step={1}
          value={form.personPerHourCost}
          disabled={disabled}
          onChange={handlePersonPerHourCostChange}
        />
      </>
      <>
        <S.Name>ks</S.Name>
        <S.Description>Operational cost estimation coefficient of operation</S.Description>
        <InputSlider
          value={form.personPerHourCostFactor}
          disabled={disabled}
          onChange={handlePersonPerHourCostFactorChange}
        />
      </>
      <>
        <S.Name>kd</S.Name>
        <S.Description>Discount rate</S.Description>
        <InputSlider value={form.discountFactor} disabled={disabled} onChange={handleDiscountFactorChange} />
      </>
      <>
        <S.Name>kinf</S.Name>
        <S.Description>Inflation rate</S.Description>
        <InputSlider value={form.inflationFactor} disabled={disabled} onChange={handleInflationFactorChange} />
      </>
      <>
        <S.Name>Tx</S.Name>
        <S.Description>Number of technical services during the billing period</S.Description>
        <InputField
          type="number"
          min={0}
          step={1}
          value={form.numberOfTechnicalService}
          disabled={disabled}
          onChange={handleNumberOfTechnicalServiceChange}
        />
      </>
      <>
        <S.Name>Td</S.Name>
        <S.Description>Full billing period</S.Description>
        <InputField
          type="number"
          min={0}
          step={1}
          value={form.billingPeriodInYears}
          disabled={disabled}
          onChange={handleBillingPeriodInYearsChange}
        />
      </>
    </S.OpexForm>
  );
};
