import { TreeNodeWidget } from './TreeNodeWidget';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectableTreeNode, TreeNodeModel, TreeNodeState } from './state/TreeState';
import { Factory } from '../../../utils/factory';

export type TreeNodeChildFactory = Factory<{ model: TreeNodeState; indentLevel: number; last: boolean }, ReactNode>;

interface TreeNodeProps {
  model: TreeNodeModel & SelectableTreeNode;
  childFactory?: TreeNodeChildFactory;
  canHighlight?: boolean;
  hasParent?: boolean;
  indentLevel: number;
  last: boolean;
  initialOpen?: boolean;
}

export const TreeNode: React.FC<TreeNodeProps> = ({
  model,
  childFactory,
  canHighlight,
  hasParent,
  indentLevel,
  last,
  initialOpen,
}: TreeNodeProps) => {
  const [children, setChildren] = useState(model.getChildren());
  const [open, setOpen] = useState(initialOpen || false);
  const toggleOpen = useCallback(() => setOpen((o) => !o), []);
  useEffect(() => {
    setChildren(model.getChildren());
    return model.onChildrenChanged((child, added) => {
      setChildren(model.getChildren());

      if (added) {
        setOpen(true);
      }

      if (added && child.canSelect()) {
        child.setSelected(true);
      }
    });
  }, [model]);

  const [selected, setSelected] = useState(model.isSelected());
  useEffect(() => {
    setSelected(model.isSelected());
    return model.onSelectionChanged(() => setSelected(model.isSelected()));
  }, [model]);

  const childrenComponents = useMemo(
    () =>
      childFactory &&
      children.map((child, index) =>
        childFactory({
          model: child,
          indentLevel,
          last: children.length - 1 === index,
        })
      ),
    [children, childFactory, indentLevel]
  );
  return (
    <TreeNodeWidget
      highlight={selected}
      title={model.getName()}
      key={model.getKey()}
      canHighlight={canHighlight}
      hasParent={hasParent}
      indentLevel={indentLevel}
      last={last}
      open={open}
      toggleOpen={toggleOpen}
    >
      {childrenComponents?.length ? childrenComponents : null}
    </TreeNodeWidget>
  );
};
