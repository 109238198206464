import { ModelCommand } from './ModelCommand';
import { ConnectingSmartLinkModel } from '../../link/smart/ConnectingSmartLinkModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { NgGraceModel } from '../../NgGraceModel';
import { SmartLinkModel } from '../../link/smart/SmartLinkModel';

export class LinkConnectionCommand implements ModelCommand {
  private model: NgGraceModel;
  private readonly origin: ConnectingSmartLinkModel;
  private readonly targetPort: ConnectablePortModel;

  constructor(model: NgGraceModel, connectingLink: ConnectingSmartLinkModel, targetPort: ConnectablePortModel) {
    this.model = model;
    this.origin = connectingLink;
    this.targetPort = targetPort;
  }

  execute() {
    if (!this.origin.canConnectTarget(this.targetPort)) {
      this.origin!.remove();
      return;
    }

    this.origin!.setTargetPort(this.targetPort);
    const editableLink = new SmartLinkModel(
      this.origin.getSourcePort(),
      this.origin.getTargetPort(),
      this.origin.getPoints()
    );
    const sourcePort = this.origin.getSourcePort();
    sourcePort.removeLink(this.origin);
    this.targetPort.removeLink(this.origin);
    this.model.removeLink(this.origin);
    this.model.addLink(editableLink);
    this.targetPort.notifyLinks();
    this.targetPort.reportPosition();
  }
}
