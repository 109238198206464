import styled from 'styled-components';
import { Icon } from './Icon.styled';

const Feedback = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: flex-start;
  color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.lightgrey)};
  white-space: pre-wrap;

  ${Icon} {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    margin-top: 2px;
    fill: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.lightgrey)};
    flex-shrink: 0;
  }
`;

export const Styled = {
  Feedback,
};
