import * as React from 'react';
import { useCallback, useState } from 'react';
import { ToolbarItem } from '../../toolbar/Toolbar';
import { StageDto } from '../../../api/nggrace-back';
import { ScdSettings } from './ScdSettings';

export const ScdSettingsToolbarItem: React.FC<{ stage: StageDto; onReloadStage: () => void }> = ({
  stage,
  onReloadStage,
}) => {
  const [showNextStage, setShowNextStage] = useState(false);
  const { finished } = stage.baseInfo;

  const handleOnClick = useCallback(() => setShowNextStage(true), []);

  const handleNextStageClose = useCallback(() => {
    setShowNextStage(false);
  }, []);

  return (
    <ToolbarItem icon={'gear'} hint={'Settings'} disabled={finished} onClick={handleOnClick}>
      {showNextStage && <ScdSettings stage={stage} onClose={handleNextStageClose} onReloadStage={onReloadStage} />}
    </ToolbarItem>
  );
};
