import { debounced } from './FunctionDecorators';
import { LinkModel, NodeModel } from '@projectstorm/react-diagrams';
import { isNgGraceLinkModel, NgGraceModel } from './NgGraceModel';
import { BaseModelListener } from '@projectstorm/react-canvas-core';

export interface ModelObserver {
  onChange(model: NgGraceModel, listener: () => void): () => void;

  onSelectionChange(model: NgGraceModel, listener: () => void): () => void;
}

export class NgGraceModelObserver implements ModelObserver {
  onChange = (model: NgGraceModel, listener: () => void): (() => void) => {
    const debouncedListener = debounced(listener, 200);
    const handlers: { deregister: () => any }[] = [];

    handlers.push(
      // @ts-ignore
      model.registerListener({
        nodesUpdated: debouncedListener,
        linksUpdated: ({ link }) => {
          if (!isNgGraceLinkModel(link) || link.isPersistent()) {
            debouncedListener();
          }
        },
      })
    );

    registerNodeListener(
      model,
      {
        positionChanged: debouncedListener,
        payloadChanged: debouncedListener,
      },
      handlers
    );

    registerLinkListener(
      model,
      {
        positionChanged: debouncedListener,
      },
      handlers
    );

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  };

  onSelectionChange = (model: NgGraceModel, listener: () => void) => {
    const handlers: { deregister: () => any }[] = [];

    registerNodeListener(model, { selectionChanged: listener }, handlers);

    return () => {
      handlers.forEach(({ deregister }) => deregister());
    };
  };
}

export const registerNodeListener = (
  model: NgGraceModel,
  listener: BaseModelListener,
  handlers: { deregister: () => any }[]
) => {
  const addNodeListener = (node: NodeModel) => {
    handlers.push(node.registerListener(listener));
  };

  model.getNodes().forEach(addNodeListener);

  // @ts-ignore
  model.registerListener({
    nodesUpdated: ({ node, isCreated }) => {
      if (isCreated) {
        addNodeListener(node);
      }
    },
  });
};

export const registerLinkListener = (
  model: NgGraceModel,
  listener: BaseModelListener,
  handlers: { deregister: () => any }[]
) => {
  const addLinkListener = (node: LinkModel) => {
    handlers.push(node.registerListener(listener));
  };

  model.getLinks().forEach(addLinkListener);

  // @ts-ignore
  model.registerListener({
    linksUpdated: ({ link, isCreated }) => {
      if (isCreated && (!isNgGraceLinkModel(link) || link.isPersistent())) {
        addLinkListener(link);
      }
    },
  });
};
