import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { Point } from '@projectstorm/geometry';
import { SmartLinkModel } from '../../SmartLinkModel';
import { EditablePath } from '../Path';

export class NotAffectingPortsEditablePath implements EditablePath {
  private readonly link: SmartLinkModel;
  private readonly origin: EditablePath;

  constructor(link: SmartLinkModel, origin: EditablePath) {
    this.link = link;
    this.origin = origin;
  }

  moveSegment(startPoint: SmartLinkPointModel, displacement: Point): void {
    const existingPoints = this.link.getPoints();
    const endPoint = existingPoints[existingPoints.indexOf(startPoint) + 1];
    const sourcePortPointId = this.link.getPoints()[0].getID();
    const targetPortPointId = this.link.getPoints()[this.link.getPoints().length - 1].getID();
    if (
      startPoint.getID() === targetPortPointId ||
      startPoint.getID() === sourcePortPointId ||
      endPoint.getID() === targetPortPointId ||
      endPoint.getID() === sourcePortPointId
    ) {
      return;
    }
    this.origin.moveSegment(startPoint, displacement);
  }
}
