import styled from 'styled-components';
import { Styled as StyledIcon } from './Icon.styled';

const InfoPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grey};
  border-radius: 8px;
  width: 100%;
  height: 50px;
  padding: 0 16px;
`;

const Icon = styled.span`
  flex: 0 0 40px;
  display: flex;
  align-items: center;
  margin-left: 2px;

  ${StyledIcon.Icon} {
    width: 22px;
    height: 22px;
    color: ${(props) => props.theme.colors.darkgrey};
  }
`;

const Title = styled.span`
  flex: 1;
  font-weight: 700;
`;

const Subtitle = styled.span``;

export const Styled = {
  InfoPanel,
  Icon,
  Title,
  Subtitle,
};
