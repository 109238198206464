import { PortModelAlignment, PortModelOptions } from '@projectstorm/react-diagrams';
import { ConnectablePortModel } from '../generics/ConnectablePortModel';
import { BasePoint } from '../geometry/Point';
import { ConnectivityNodeSize } from './ConnectivityNodeModel';

export const ConnectivityPortSize = 0;

export class ConnectivityPortModel extends ConnectablePortModel {
  constructor(); // deserialization
  constructor(alignment: PortModelAlignment); // manual instantiation
  constructor(alignment?: PortModelAlignment) {
    let options: PortModelOptions = {
      type: 'connectivity',
      name: alignment ? alignment.toString() : '', //will be filled in deserialization
      alignment: alignment,
    };

    super(options);
  }

  getCenter(): BasePoint {
    return new BasePoint(this.getParent().getCenter());
  }

  getSize(): number {
    return ConnectivityPortSize;
  }

  getConnectorLength(): number {
    return Math.round(ConnectivityNodeSize / 2 + 1);
  }
}
