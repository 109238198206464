import { Node, NodeType } from './Node';
import { Rectangle } from '@projectstorm/geometry';
import { RotatedRectangle } from '../../geometry/Rectangle';
import { BasePoint } from '../../geometry/Point';
import { CompositeRotationHour, SmartRotationHour } from '../../geometry/RotationHour';

export class RotatedNode implements Node {
  private origin: Node;
  private hour: SmartRotationHour;
  private center: BasePoint;

  constructor(node: Node, hour: SmartRotationHour);
  constructor(node: Node, hour: SmartRotationHour, center: BasePoint);
  constructor(node: Node, hour: SmartRotationHour, center?: BasePoint) {
    this.origin = node;
    this.hour = hour;
    this.center = center || new BasePoint(node.getRect().getOrigin());
  }

  getID(): string {
    return this.origin.getID();
  }

  getRect(): Rectangle {
    return new RotatedRectangle(this.origin.getRect(), this.hour, this.center);
  }

  getType(): NodeType {
    return this.origin.getType();
  }

  getHour(): SmartRotationHour {
    return new CompositeRotationHour(this.hour, this.origin.getHour());
  }
}
