import { NgGraceModel } from './NgGraceModel';
import { ModelObserver } from './NgGraceModelObserver';
import { CanvasEngineListener, CanvasEngineOptions } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from './insides/engine/DiagramEngine';

export interface ObservableEngineListener extends CanvasEngineListener {
  modelChanged: () => void;
}

export class ObservableEngine extends DiagramEngine<ObservableEngineListener> {
  static readonly MIN_ZOOM_LEVEL = 10;

  protected readonly observer: ModelObserver;

  private readonly zoomListeners: ((event: { zoom: number }) => void)[] = [];
  private readonly changeListeners: (() => void)[] = [];
  private readonly selectListeners: (() => void)[] = [];
  private readonly listenerDeregister: (() => void)[] = [];

  constructor(options: CanvasEngineOptions = {}, observer: ModelObserver) {
    super(options);
    this.observer = observer;
  }

  onZoom(listener: (event: { zoom: number }) => void): () => void {
    this.zoomListeners.push(listener);
    const model = this.getModel();
    if (!model) {
      return () => {};
    }

    const deregister = this.registerZoomListener(listener);
    this.listenerDeregister.push(deregister);

    return () => {
      deregister();

      removeFromArray(this.listenerDeregister, deregister);
      removeFromArray(this.changeListeners, listener);
    };
  }

  onChange(listener: () => void): () => void {
    this.changeListeners.push(listener);
    const model = this.getModel();
    if (!model) {
      return () => {};
    }

    const deregister = this.observer.onChange(model, listener);
    this.listenerDeregister.push(deregister);

    return () => {
      deregister();

      removeFromArray(this.listenerDeregister, deregister);
      removeFromArray(this.changeListeners, listener);
    };
  }

  onSelectionChange(listener: () => void): () => void {
    this.selectListeners.push(listener);

    const model = this.getModel();
    if (!model) {
      return () => {};
    }

    const deregister = this.observer.onSelectionChange(model, listener);
    this.listenerDeregister.push(deregister);

    return () => {
      deregister();

      removeFromArray(this.listenerDeregister, deregister);
      removeFromArray(this.selectListeners, listener);
    };
  }

  zoomToFitNodes(margin: number = 50) {
    if (this.getModel().getSelectionEntities().length !== 0 && this.getModel().getNodes().length !== 0) {
      super.zoomToFitNodes(margin);
    }
  }

  zoom(scrollDelta: number, mouse?: { clientX: number; clientY: number }, fast: boolean = false) {
    const model = this.getModel();
    for (const layer of model.getLayers()) {
      layer.allowRepaint(false);
    }

    const oldZoomFactor = model.getZoomLevel() / 100;
    if (fast) {
      scrollDelta *= 2;
    }

    if (model.getZoomLevel() + scrollDelta < ObservableEngine.MIN_ZOOM_LEVEL) {
      model.setZoomLevel(ObservableEngine.MIN_ZOOM_LEVEL);
    } else {
      model.setZoomLevel(model.getZoomLevel() + scrollDelta);
    }

    const zoomFactor = model.getZoomLevel() / 100;
    const boundingRect = this.getCanvas().getBoundingClientRect();
    const clientWidth = boundingRect.width;
    const clientHeight = boundingRect.height;
    // compute difference between rect before and after scroll
    const widthDiff = clientWidth * zoomFactor - clientWidth * oldZoomFactor;
    const heightDiff = clientHeight * zoomFactor - clientHeight * oldZoomFactor;
    // compute mouse coords relative to canvas
    let { clientX, clientY } = mouse || { clientX: clientWidth / 2, clientY: clientHeight / 2 };
    if (mouse) {
      clientX -= boundingRect.left;
      clientY -= boundingRect.top;
    }

    // compute width and height increment factor
    const xFactor = (clientX - model.getOffsetX()) / oldZoomFactor / clientWidth;
    const yFactor = (clientY - model.getOffsetY()) / oldZoomFactor / clientHeight;

    model.setOffset(model.getOffsetX() - widthDiff * xFactor, model.getOffsetY() - heightDiff * yFactor);
    this.repaintCanvas();

    for (let layer of model.getLayers()) {
      layer.allowRepaint(true);
    }
  }

  setModel(model: NgGraceModel) {
    // preserve zoom and position
    const currentModel = this.getModel();
    if (currentModel) {
      model.setOffsetX(currentModel.getOffsetX());
      model.setOffsetY(currentModel.getOffsetY());
      model.setZoomLevel(currentModel.getZoomLevel());
    }

    // clear selection
    model.getSelectedEntities().forEach((entity) => entity.setSelected(false));

    // set model
    super.setModel(model);

    // deregister old listeners
    this.listenerDeregister.forEach((deregister) => deregister());
    this.listenerDeregister.length = 0;

    // register new listeners
    this.zoomListeners.forEach((listener) => this.registerZoomListener(listener));
    this.changeListeners.forEach((listener) => this.listenerDeregister.push(this.observer.onChange(model, listener)));
    this.selectListeners.forEach((listener) =>
      this.listenerDeregister.push(this.observer.onSelectionChange(model, listener))
    );

    this.fireEvent({}, 'modelChanged');
  }

  getModel(): NgGraceModel {
    return super.getModel() as NgGraceModel;
  }

  protected fireSelectionChange() {
    this.selectListeners.forEach((listener) => listener());
  }

  protected fireChange() {
    this.changeListeners.forEach((listener) => listener());
  }

  private registerZoomListener(listener: (event: { zoom: number }) => void) {
    const model = this.getModel();
    return model.registerListener({
      zoomUpdated: (event) => {
        // @ts-ignore
        listener({ zoom: Math.floor(event.zoom) });
      },
    }).deregister;
  }
}

const removeFromArray = <T>(items: T[], item: T) => {
  const index = items.indexOf(item);
  if (index >= 0) {
    items.splice(index, 1);
  }
};
