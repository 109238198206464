import React from 'react';
import { NetworkNodeModel } from './NetworkNodeModel';
import { LanNodeWidget } from '../LanNodeWidget';
import { LanEngine } from '../../LanEngine';
import { Styled as S } from './NetworkNodeWidget.styled';
import { SvgNodeWidget } from '../../../SvgNodeWidget';

export interface NetworkNodeWidgetProps {
  node: NetworkNodeModel;
  engine: LanEngine;
}

export const LanNodeImageMargin = 15;
export const LanNodeLabelSize = 10;

export const getNetworkNodeImageOffset = (node: NetworkNodeModel) => {
  const image = node.getImage();
  return (
    image && {
      left: Math.max((node.getSize().x - image.size.x) / 2, LanNodeImageMargin),
      top: LanNodeLabelSize + LanNodeImageMargin,
    }
  );
};

export const NetworkNodeWidget: React.FC<NetworkNodeWidgetProps> = ({ node, engine }) => {
  const image = node.getImage();
  const imageOffset = getNetworkNodeImageOffset(node);
  const size = node.getSize();
  return (
    <>
      {
        <S.Label height={size.y} width={size.x} yCenter={!image}>
          <div>{node.getProjectName()}</div>
          <div>{node.getName()}</div>
        </S.Label>
      }
      {image && (
        <S.Image x={imageOffset!.left} y={imageOffset!.top}>
          <SvgNodeWidget width={image.size.x} height={image.size.y} source={image.src} />
        </S.Image>
      )}
      <LanNodeWidget node={node} engine={engine} />
    </>
  );
};
