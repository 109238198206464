import { LinkModel, PortModel, PortModelAlignment, PortModelOptions } from '@projectstorm/react-diagrams';
import { ConnectablePortModel, ConnectablePortModelGenerics } from '../generics/ConnectablePortModel';
import { BusDefaultSize, BusNodeModel, BusPortSize, BusResizerSize } from './BusNodeModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { AddedPoint, BasePoint } from '../geometry/Point';
import { OppositeCoordinate } from '../geometry/Coordinate';
import { Point } from '@projectstorm/geometry';

export interface BusPortModelGenerics extends ConnectablePortModelGenerics {
  PARENT: BusNodeModel;
}

export class BusPortModel extends ConnectablePortModel<BusPortModelGenerics> {
  protected busOffset: number;

  constructor();
  constructor(name: string, busOffset: number);
  constructor(name?: string, busOffset?: number) {
    let options: PortModelOptions = {
      type: 'bus',
      name: name || '', //will be filled in deserialization,
      alignment: PortModelAlignment.BOTTOM,
    };
    super(options);

    this.busOffset = busOffset ? busOffset - this.getSize() / 2 : 0;
  }

  getSize(): number {
    return BusPortSize;
  }

  getBusOffset() {
    return this.busOffset;
  }

  setBusOffset(newOffset: number) {
    this.busOffset = newOffset;
    this.notifyLinks();
  }

  getConnectorLength(): number {
    return Math.round(BusDefaultSize.y / 2) + 5;
  }

  getPosition(): BasePoint {
    const bus = this.getParent();
    const offsetCoord = bus.getOffsetCoordinate();
    const oppositeCoord = new OppositeCoordinate(offsetCoord).getName();
    const result = new BasePoint(0, 0);
    const busPosition = bus.rotatePoint(bus.getPosition());
    let oppositeAlignment = 0;
    if (bus.getRotation().isAxisSwapped()) {
      oppositeAlignment = -this.getSize();
    }
    result[offsetCoord.getName()] = busPosition[offsetCoord.getName()] + this.getBusOffset();
    result[oppositeCoord] = busPosition[oppositeCoord] + oppositeAlignment;
    return result;
  }

  getCenter(): BasePoint {
    const center = this.getPosition().clone();
    center.translate(this.getSize() / 2, this.getSize() / 2);
    return center;
  }

  addLink(link: LinkModel) {
    super.addLink(link);
    const linkListener = (event: any) => this.onLinkPortChanged(event.port); // typing shit again
    link.registerListener({
      sourcePortChanged: linkListener,
      targetPortChanged: linkListener,
    });
  }

  getLink(): LinkModel {
    return Object.values(this.getLinks())[0];
  }

  getAlignment(): PortModelAlignment {
    const link = this.getLink();
    const otherPort = link.getSourcePort().getID() !== this.getID() ? link.getSourcePort() : link.getTargetPort();
    const bus = this.getParent();
    const coordName = new OppositeCoordinate(bus.getOffsetCoordinate()).getName();

    if (bus.getRotation().isAxisSwapped()) {
      return this.getCenter()[coordName] > otherPort.getCenter()[coordName]
        ? PortModelAlignment.BOTTOM
        : PortModelAlignment.TOP;
    } else {
      return this.getCenter()[coordName] < otherPort.getCenter()[coordName]
        ? PortModelAlignment.BOTTOM
        : PortModelAlignment.TOP;
    }
  }

  serialize() {
    return {
      ...super.serialize(),
      busOffset: this.busOffset,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.busOffset = event.data.busOffset;
  }

  move(eventPosition: Point) {
    const bus = this.getParent();
    const coord = bus.getOffsetCoordinate().getName();
    const edges = [
      bus.getRotatedPosition()[coord],
      new AddedPoint(bus.getRotatedPosition(), bus.getRotatedSize())[coord],
    ];
    const max = Math.max(...edges) - BusResizerSize;
    const min = Math.min(...edges) + (BusResizerSize - BusPortSize);
    const preferable = eventPosition[coord];
    const resultPosition = preferable < min ? min : preferable > max ? max : preferable;
    this.setBusOffset(this.busOffset + (resultPosition - this.getPosition()[coord]));
  }

  private onLinkPortChanged(port: PortModel) {
    if (this.getID() !== port.getID()) {
      this.reportPosition();
    }
  }
}
