import {
  HasNotExtendableParentModel,
  NavigatingSelectableTreeModel,
  NotExtendableModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { TreeNodeModel, TreeNodeState } from '../../../widgets/tree/state/TreeState';
import { Factory } from '../../../../utils/factory';
import { ScdEngine } from '../../../editor/scd/ScdEngine';
import { ScdStructureTreeControllerProps } from './ScdStructureTreeController';
import { DirectoryNodeWithPlaceholdersModel } from '../../../editor/directory/DirectoryNodeWithPlaceholdersModel';
import { BusNodeWithPlaceholdersModel } from '../../../editor/bus/BusNodeWithPlaceholdersModel';
import { ControllerPlaceholder } from '../../../editor/scd/controller/ControllerPlaceholder';

export const ScdStructureTreeNodeFactory = (
  engine: ScdEngine,
  treeControllerFactory: Factory<ScdStructureTreeControllerProps, TreeNodeState>
): Factory<ScdStructureTreeNodeModelType, TreeNodeState> => (node: ScdStructureTreeNodeModelType) =>
  ScdStructureTreeNode(node, engine, treeControllerFactory);

const ScdStructureTreeNode = (
  node: ScdStructureTreeNodeModelType,
  engine: ScdEngine,
  treeControllerFactory: Factory<ScdStructureTreeControllerProps, TreeNodeState>
): TreeNodeState => {
  const treeNodeModel = ScdStructureTreeNodeModel(node, (controller) => treeControllerFactory({ controller }));

  return {
    ...treeNodeModel,
    ...NotExtendableModel(),
    ...HasNotExtendableParentModel(),
    ...NavigatingSelectableTreeModel(SelectableTreeBaseModel(node, engine), node.getID(), engine),
  };
};

export type ScdStructureTreeNodeModelType =
  | DirectoryNodeWithPlaceholdersModel<ControllerPlaceholder>
  | BusNodeWithPlaceholdersModel<ControllerPlaceholder>;

const ScdStructureTreeNodeModel = (
  node: ScdStructureTreeNodeModelType,
  controllerMapping: (controller: ControllerPlaceholder) => TreeNodeState
): TreeNodeModel => {
  return {
    getName: () => node.getPayload().projectName || node.getID(),
    getKey: () => node.getID(),
    onChildrenChanged: (cb) =>
      node.registerListener({
        childrenChanged: (event: { child: ControllerPlaceholder; created?: boolean }) =>
          cb(controllerMapping(event.child), !!event.created),
      }).deregister,
    getChildren: () => node.getChildren().map(controllerMapping),
  };
};
