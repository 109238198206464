import React, { useCallback } from 'react';
import { ExtendableTreeNode, HasExtendableParent, TreeDraggablePayload } from './state/TreeState';
import { Droppable, DroppableChild } from '../../editor/dnd/Droppable';

interface StructureTreeDroppableProps {
  formatId: string | string[];
  model: ExtendableTreeNode & HasExtendableParent;
  dropChild: DroppableChild;
}

export const TreeDroppable: React.FC<StructureTreeDroppableProps> = ({ formatId, model, dropChild, children }) => {
  const canDrop = useCallback(
    (payload: TreeDraggablePayload) => {
      return model.canAddChild(payload);
    },
    [model]
  );

  const canDropAfter = useCallback(
    (payload: TreeDraggablePayload) => {
      return model.canAddNeighbour(payload);
    },
    [model]
  );

  const onDrop = useCallback(
    (payload: TreeDraggablePayload) => {
      if (canDrop(payload)) {
        model.addChild(payload);
      } else if (canDropAfter(payload)) {
        model.addNeighbour(payload);
      }
    },
    [model, canDropAfter, canDrop]
  );

  return (
    <Droppable<TreeDraggablePayload>
      canDrop={canDrop}
      canDropAfter={canDropAfter}
      onDrop={onDrop}
      formatId={formatId}
      dropChild={dropChild}
    >
      {children}
    </Droppable>
  );
};
