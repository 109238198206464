import { RotatableNodeModel, RotatableNodeModelGenerics } from '../../generics/RotatableNodeModel';
import { ResizeEdge, ResizeStrategy } from '../../generics/ResizableNodeModel';
import { Point } from '@projectstorm/geometry';
import { DefaultHasRelativeModel, HasRelativeModel } from '../../placeholder/HasRelativeModel';
import { ZoneModel } from '../zone/ZoneModel';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { LanNodeDirectoryEntry, LanNodePort, PropertiesDirectoryName } from '../../directory/PropertiesDirectory';
import { LanPortModel, LanPortSize } from './port/LanPortModel';
import { BasePoint } from '../../geometry/Point';

export abstract class LanNodeModel extends RotatableNodeModel {
  private hasRelativeZone: HasRelativeModel<ZoneModel>;
  private directoryEntry: LanNodeDirectoryEntry;
  private projectName: string = '';

  constructor(options: RotatableNodeModelGenerics['OPTIONS'], directoryEntry: LanNodeDirectoryEntry) {
    super(options);

    this.directoryEntry = directoryEntry;
    this.hasRelativeZone = new DefaultHasRelativeModel<ZoneModel>();

    this.createPorts();
  }

  serialize() {
    return {
      ...super.serialize(),
      directoryId: this.directoryEntry.id,
      zone: this.hasRelativeZone.getRelativeModel()!.getID(),
      projectName: this.projectName,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    this.ports = {};
    super.deserialize(event);
    event.getModel<ZoneModel>(event.data.zone).then((zone) => {
      this.hasRelativeZone.setRelativeModel(zone);
    });
    event.registerModel(this);
    this.projectName = event.data.projectName;
  }

  canRotate(): boolean {
    return false;
  }

  getResizeStrategy(): ResizeStrategy {
    return new (class implements ResizeStrategy {
      resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
        return [];
      }
    })();
  }

  setRelativeZone(zone: ZoneModel, index?: number) {
    return this.hasRelativeZone.setRelativeModel(zone, index);
  }

  getRelativeZone() {
    return this.hasRelativeZone.getRelativeModel();
  }

  getName() {
    return this.directoryEntry.name.en;
  }

  getProjectName() {
    return this.projectName;
  }

  getDirectoryEntryId() {
    return this.directoryEntry.id;
  }

  abstract getDirectory(): PropertiesDirectoryName;

  getSize() {
    const portCount = this.directoryEntry.ports.map((port) => port.value).reduce((a, b) => a + b, 0);
    const indentNumber = portCount + 1;
    const xSize = (portCount + indentNumber) * LanPortSize;
    return new BasePoint(xSize, this.options.defaultSize.y);
  }

  abstract createPort(label: string): LanPortModel;

  canRemove() {
    return !this.isLocked();
  }

  notCascadeRemove() {
    this.fireEvent({}, 'entityRemoved');
  }

  setSelected(selected?: boolean) {
    if (selected) {
      Object.values(this.getPorts())
        .flatMap((port) => Object.values(port.getLinks()))
        .forEach((link) => link.setSelected(true));
    }
    super.setSelected(selected);
  }

  getDirectoryEntry() {
    return this.directoryEntry;
  }

  private createPorts() {
    getLanPortCountsFromLanNodeFields(this.directoryEntry.ports).forEach((count) => {
      const portNumber = count.number;
      for (let i = 0; i < portNumber; i++) {
        this.addPort(this.createPort(count.label));
      }
    });
  }
}

const getLanPortCountsFromLanNodeFields = (ports: LanNodePort[]) => {
  return ports.map((port) => ({ label: port.name, type: port.type, number: port.value }));
};
