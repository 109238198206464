import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { LanEngine } from '../../LanEngine';
import { Directory } from '../../../directory/Directory';
import { NetworkDeviceDirectoryEntry, PropertiesDirectoryEntry } from '../../../directory/PropertiesDirectory';
import { GenerateModelEvent } from '../../../scd/controller/ControllerFactory';
import { NetworkNodeWidget } from './NetworkNodeWidget';
import { NetworkNodeModel, NetworkNodeType } from './NetworkNodeModel';
import { RawNetworkNodeModel } from './RawNetworkNodeModel';

export class NetworkNodeFactory extends AbstractReactFactory<NetworkNodeModel, LanEngine> {
  private networkDeviceTypeDirectory: Directory<PropertiesDirectoryEntry>;
  private networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>;

  constructor(
    networkDeviceTypeDirectory: Directory<PropertiesDirectoryEntry>,
    networkDeviceDirectory: Directory<NetworkDeviceDirectoryEntry>
  ) {
    super(NetworkNodeType);
    this.networkDeviceTypeDirectory = networkDeviceTypeDirectory;
    this.networkDeviceDirectory = networkDeviceDirectory;
  }

  generateReactWidget(event: { model: NetworkNodeModel }): JSX.Element {
    const model = event.model;
    return <NetworkNodeWidget node={model} engine={this.engine} />;
  }

  generateModel(event: GenerateModelEvent<NetworkNodeModel>) {
    const entry = this.networkDeviceDirectory.getEntry(event.initialConfig.directoryId);
    return new RawNetworkNodeModel(this.networkDeviceTypeDirectory.getEntry(entry.deviceType), entry);
  }
}
