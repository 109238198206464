import * as React from 'react';
import { BusNodeModel, BusPortVisibleSize } from '../../../bus/BusNodeModel';
import { ExportBusPort } from './ExportBusPort';
import { ExportBusNodeLabel } from './ExportBusNodeLabel';

interface ExportBusNodeProps {
  node: BusNodeModel;
}

const ExportBusNodeRadius = 1;

export const ExportBusNode: React.FC<ExportBusNodeProps> = ({ node }) => {
  return (
    <g transform={`translate(${node.getPositionToRender().x},${node.getPositionToRender().y})`}>
      <ExportBusNodeLabel node={node} />
      <rect
        x={0}
        y={0}
        rx={ExportBusNodeRadius}
        ry={ExportBusNodeRadius}
        height={node.getRotatedSize().y}
        width={node.getRotatedSize().x}
        fill={'black'}
      />
      {Object.values(node.getPorts()).map((port) => (
        <ExportBusPort key={port.getID()} port={port} size={BusPortVisibleSize} />
      ))}
    </g>
  );
};
