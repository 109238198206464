import styled, { css } from 'styled-components';

const InputRadioCheck = styled.span`
  position: relative;
  width: 13px;
  min-width: 13px;
  height: 13px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.darkgrey};
  animation-delay: 0s;
  border-radius: 50%;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    outline: 0;
    overflow: hidden;
    box-sizing: border-box;
    transform: scale(0);
    transition: ease, 0.15s;
  }

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    background: ${(props) => props.theme.colors.blue};
    border-radius: 50%;
  }

  &:after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
`;

const InputRadioLabel = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.black};
  padding: 0 0 0 17px;
`;

const InputRadio = styled.label<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
  }

  input:checked + ${InputRadioCheck} {
    border: 1px solid ${(props) => props.theme.colors.blue};

    &:after {
      background: transparent;
      transform: scale(1);
    }

    &:before {
      transform: scale(1);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default !important;
      pointer-events: none !important;
      user-select: none !important;
      opacity: 0.3 !important;
      box-shadow: none !important;
    `}
`;

export const Styled = {
  InputRadio,
  InputRadioCheck,
  InputRadioLabel,
};
