import { ConnectablePortModel } from '../../../../generics/ConnectablePortModel';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import { SmartLinkModel } from '../../SmartLinkModel';
import { PortDependentPath } from '../Path';
import { SidedAngledPath } from '../default/SidedAngledPath';
import { DefaultRightAngledVector } from '../../../../geometry/Vector';
import { Direction, OppositeDirection } from '../../../../geometry/Direction';
import { RestrictedDirectionPoint } from '../../../../geometry/Point';

export class DefaultPortDependentPath implements PortDependentPath {
  private readonly link: SmartLinkModel;

  constructor(link: SmartLinkModel) {
    this.link = link;
  }

  getPoints(changed: ConnectablePortModel): SmartLinkPointModel[] {
    const link = this.link;
    const sourcePort = link.getSourcePort();
    const existingPoints = [...link.getPoints()];
    let result: SmartLinkPointModel[] = [];
    if (changed.getID() === sourcePort.getID()) {
      result = this.rebuildDependent(changed, existingPoints, (point) => !point.isSourceDependent());
    }

    const targetPort = link.getTargetPort();
    if (changed.getID() === targetPort.getID()) {
      existingPoints.reverse();
      result = this.rebuildDependent(changed, existingPoints, (point) => !point.isTargetDependent());
      result.reverse();
    }

    return result;
  }

  rebuildDependent(
    changed: ConnectablePortModel,
    existingPoints: SmartLinkPointModel[],
    independentPointsFilter: (point: SmartLinkPointModel) => boolean
  ) {
    const independentPoints = existingPoints.filter(independentPointsFilter);
    const firstIndependentPoint = independentPoints[0].getPosition();
    const dependentPositions = existingPoints
      .filter((point) => !independentPointsFilter(point))
      .map((point) => point.getPosition());
    const addedSourcePoints = [
      changed.getCenter(),
      ...new SidedAngledPath(dependentPositions).getPoints(
        changed.getConnector(),
        new RestrictedDirectionPoint(
          firstIndependentPoint,
          this.getIndependentSegmentRestrictedDirections(changed, independentPoints)
        )
      ),
    ];
    const link = this.link;
    addedSourcePoints.splice(addedSourcePoints.length - 1, 1);
    const addedModels = addedSourcePoints.map((point) => new SmartLinkPointModel({ position: point, link }));
    return [...addedModels, ...independentPoints];
  }

  getIndependentSegmentRestrictedDirections(
    changed: ConnectablePortModel,
    independentPoints: SmartLinkPointModel[]
  ): Direction[] {
    const firstIndependentPoint = independentPoints[0].getPosition();
    const secondIndependentPoint = independentPoints[1].getPosition();
    const otherPort =
      this.link.getTargetPort().getID() === changed.getID() ? this.link.getSourcePort() : this.link.getTargetPort();

    if (otherPort.getConnector().equals(firstIndependentPoint)) {
      return otherPort.getConnector().restricted;
    }
    const independentSegmentDirection = new DefaultRightAngledVector(
      firstIndependentPoint,
      secondIndependentPoint
    ).getDirection();
    return [
      independentSegmentDirection.getEnumValue(),
      new OppositeDirection(independentSegmentDirection).getEnumValue(),
    ];
  }
}
