import React, { ReactNode } from 'react';
import { useDrop } from 'react-dnd';

export interface DroppableChildProps {
  dropWillSucceed: boolean;
  dropWillFail: boolean;
  dropAfterWillSucceed: boolean;
}

export interface DroppableChild extends React.FC<DroppableChildProps> {}

export interface DroppableProps<PAYLOAD extends { type: string }> {
  canDrop: (payload: PAYLOAD) => boolean;
  canDropAfter: (payload: PAYLOAD) => boolean;
  onDrop: (payload: PAYLOAD) => void;
  dropChild?: DroppableChild;
  formatId: string | string[];
  children: ReactNode;
}

export const Droppable = <PAYLOAD extends { type: string }>({
  canDrop,
  canDropAfter,
  onDrop,
  dropChild,
  children,
  formatId,
}: DroppableProps<PAYLOAD>) => {
  type Payload = PAYLOAD;
  type DropResult = unknown;
  type CollectedProps = { willDrop: boolean | null; willFail: boolean | null; willDropAfter: boolean };
  const [{ willDrop, willFail, willDropAfter }, ref] = useDrop<Payload, DropResult, CollectedProps>(
    () => ({
      accept: formatId,
      canDrop: (payload) => canDrop(payload) || canDropAfter(payload),
      drop: (payload, monitor) => {
        if (!monitor.didDrop()) {
          onDrop(payload);
        }
      },
      collect: (monitor) => {
        const payload = monitor.getItem<Payload>();
        const over = monitor.isOver();
        const overCurrent = monitor.isOver({ shallow: true });
        return {
          willDrop: over ? canDrop(payload) : null,
          willFail: overCurrent && !canDrop(payload) && !canDropAfter(payload),
          willDropAfter: over && payload && canDropAfter(payload),
        };
      },
    }),
    [canDrop, canDropAfter, onDrop, formatId]
  );

  return (
    <div ref={ref}>
      {dropChild
        ? React.createElement(
            dropChild,
            {
              dropWillSucceed: willDrop || false,
              dropWillFail: willFail || false,
              dropAfterWillSucceed: willDropAfter,
            },
            children
          )
        : children}
    </div>
  );
};
