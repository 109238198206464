import styled, { css } from 'styled-components';

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Checkbox = styled.div``;

const Check = styled.svg.attrs(() => ({ viewBox: '0 0 14 14', fill: 'none' }))`
  width: 20px;
  height: 20px;
`;

const Label = styled.label<{ disabled?: boolean; checked?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: auto;
    `}

  ${(props) =>
    props.checked &&
    css`
      color: ${props.theme.colors.blue};
    `}
`;

const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

export const Styled = {
  CheckboxGroup,
  Checkbox,
  Input,
  Label,
  Check,
};
