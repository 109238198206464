import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Styled as S } from './InputSelect.styled';
import { useComponentVisible } from '../hooks/useComponentVisible';
import { Icon } from './Icon';

type OptionType = {
  id: string | number;
  name: string;
  disabled?: boolean;
};

type InputSelectProps = {
  label?: string;
  selected?: string | number;
  options: OptionType[];
  hideLabel?: boolean;
  hideBorder?: boolean;
  filter?: boolean;
  disabled?: boolean;

  onChange(selected?: string | number): void;
};

export const InputSelect: React.FC<InputSelectProps> = ({
  label,
  selected,
  options,
  hideLabel,
  hideBorder,
  filter,
  disabled,
  onChange,
}) => {
  const { ref, visible, setVisible } = useComponentVisible<HTMLDivElement>(false);
  const [filterValue, setFilterValue] = useState('');

  const handleOptionChanged = useCallback(
    (newOption: OptionType) => {
      setVisible(false);
      newOption.id !== selected && onChange(newOption.id);
    },
    [onChange, selected, setVisible]
  );

  const handleSelectionClick = useCallback(() => {
    !disabled && setVisible((visible) => !visible);
  }, [disabled, setVisible]);

  const handleFilterClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  }, []);

  const resetFilter = useCallback(
    (event) => {
      event.stopPropagation();
      onChange(undefined);
    },
    [onChange]
  );

  useEffect(() => {
    setFilterValue('');
  }, [visible]);

  return (
    <S.InputSelect
      disabled={disabled}
      active={visible}
      empty={!selected}
      hideBorder={hideBorder}
      ref={ref}
      onClick={handleSelectionClick}
    >
      <S.InputSelected icon={filter}>
        {filter && (selected ? <Icon name={'filter-on'} onClick={resetFilter} /> : <Icon name={'filter-off'} />)}
        {selected && <S.InputSelectedValue>{options.find((o) => o.id === selected)?.name}</S.InputSelectedValue>}
        {!selected && hideLabel && label && <S.InputSelectedValue>{label}</S.InputSelectedValue>}
      </S.InputSelected>
      {!hideLabel && label && <S.InputLabel>{label}</S.InputLabel>}
      <S.InputOptions>
        {filter && (
          <S.InputFilter
            value={filterValue}
            placeholder={'Quick search'}
            autoFocus
            onClick={handleFilterClick}
            onChange={handleFilterChange}
          />
        )}
        {options
          .filter((o) => !filterValue || o.name.toLowerCase().includes(filterValue.toLowerCase()))
          .map((o) => (
            <SelectOption onSelect={handleOptionChanged} option={o} current={selected === o.id} key={o.id} />
          ))}
      </S.InputOptions>
    </S.InputSelect>
  );
};

interface SelectOptionProps {
  option: OptionType;
  current: boolean;

  onSelect(option: OptionType): void;
}

export const SelectOption: React.FC<SelectOptionProps> = ({ option, current, onSelect }) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onSelect(option);
    },
    [onSelect, option]
  );

  return (
    <S.InputOption onClick={handleClick} current={current} disabled={option.disabled}>
      {option.name}
    </S.InputOption>
  );
};
