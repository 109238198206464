import * as React from 'react';
import { ControllerLayerModel } from './ControllerLayerModel';
import { ScdEngine } from '../../ScdEngine';
import { useMemo } from 'react';
import { PlaceholderGroup } from '../../../placeholder/PlaceholderGroup';
import { ScdNodeModel } from '../../ScdModel';

export interface ControllerLayerWidgetProps {
  layer: ControllerLayerModel;
  engine: ScdEngine;
}

export const ControllerLayerWidget: React.FC<ControllerLayerWidgetProps> = ({ layer, engine }) => {
  const nodes = useMemo(() => layer.getParent().getNodes() as ScdNodeModel[], [layer]);

  const nodeGroups = useMemo(
    () => nodes.map((node) => <PlaceholderGroup relativeModel={node} engine={engine} key={node.getID()} />),
    [nodes, engine]
  );
  return <>{nodeGroups}</>;
};
