import React, { useCallback, useState } from 'react';
import { Styled as S } from './Page.styled';
import { PageHeader } from './PageHeader';
import { NavigationSidebar, NavigationSidebarType } from './NavigationSidebar';
import { Overlay } from './widgets/Overlay';

interface PageProps {
  active?: NavigationSidebarType;
  hasSidebar?: boolean;
  loading?: boolean;
}

export const Page: React.FC<PageProps> = ({ active, hasSidebar, loading = false, children }) => {
  const [navigationVisibility, setNavigationVisibility] = useState(false);

  const onClickMenu = useCallback(() => {
    setNavigationVisibility((prev) => !prev);
  }, []);

  return (
    <S.Page sidebar={hasSidebar || navigationVisibility}>
      <PageHeader onClickMenu={onClickMenu} />
      <S.PageContent sidebar={hasSidebar}>
        <NavigationSidebar active={active} hidden={!navigationVisibility} />
        {children}
      </S.PageContent>
      {loading && <Overlay />}
    </S.Page>
  );
};
