import styled from 'styled-components';
import { Styled as StyledModal } from '../../Modal.styled';
import SimpleBar from 'simplebar-react';

const Modal = styled.div`
  width: 1320px;
  padding: 0;
`;

const Scroll = styled(SimpleBar)`
  height: 100%;
`;

const ScrollContent = styled.div`
  padding: 32px 0 72px 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Styled = {
  Modal,
  Scroll,
  ScrollContent,
  HeaderInput: StyledModal.HeaderInput,
  Main: StyledModal.Main,
  LeftPanel: StyledModal.LeftPanel,
  Title: StyledModal.Title,
  Form: StyledModal.Form,
  RadioGroup: StyledModal.RadioGroup,
  RightPanel: StyledModal.RightPanel,
  Description: StyledModal.Description,
  Footer: StyledModal.Footer,
  BtnPrimary: StyledModal.BtnPrimary,
};
