import styled from 'styled-components';

const colors = [
  '#190259',
  '#3E11A7',
  '#1F460C',
  '#007B00',
  '#50B400',
  '#7A2D00',
  '#DD640C',
  '#008F91',
  '#005A59',
  '#BABC1B',
  '#9A7800',
  '#9F053D',
  '#41015B',
  '#5B135C',
  '#B50CB9',
  '#0C70B9',
  '#0A4977',
  '#0A0A0A',
  '#494949',
  '#818181',
  '#600404',
  '#AB0001',
  '#F00000',
];

export const getBrickColor = (codes: string[], code: string) => {
  const uniqueCodes = codes
    .map((code) => code.charCodeAt(0))
    .filter((code, index, self) => self.indexOf(code) === index);
  return colors[uniqueCodes.indexOf(code.charCodeAt(0)) % colors.length];
};

export const Brick = styled.div<{ color: string }>`
  background-color: ${(p) => p.color};
  color: white;
  font-family: GostRus, serif;
  font-size: 12px;
  height: 21px;
  padding: 0 5px;
`;
