import React, { useEffect, useState } from 'react';
import { PasswordRecoveryLinkStatus } from '../../../api/nggrace-back';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import { Styled as S } from '../AuthFeedback.styled';
import { PasswordResetSettings } from './PasswordResetSettings';
import { useParams } from 'react-router-dom';
import { Api } from '../../../api/Api';
import { AnonymUserPage } from '../../AnonymUserPage';

interface InvalidRecoveryLinkInfoProps {
  passwordRecoveryTokenStatus: PasswordRecoveryLinkStatus;
}

export const PasswordResetPage: React.FC = () => {
  const { passwordRecoveryToken } = useParams<{ passwordRecoveryToken?: string }>();
  const [passwordRecoveryTokenStatus, setPasswordRecoveryTokenStatus] = useState<PasswordRecoveryLinkStatus>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Api.getPasswordRecoveryLinkStatus(passwordRecoveryToken!).then((response) => {
      setPasswordRecoveryTokenStatus(response.data);
      setLoading(false);
    });
  }, [passwordRecoveryToken]);

  if (loading) {
    return <></>;
  }

  return (
    <AnonymUserPage>
      {passwordRecoveryTokenStatus === 'VALID' ? (
        <PasswordResetSettings passwordRecoveryToken={passwordRecoveryToken!} />
      ) : (
        <InvalidRecoveryLinkInfo passwordRecoveryTokenStatus={passwordRecoveryTokenStatus!} />
      )}
    </AnonymUserPage>
  );
};

const InvalidRecoveryLinkInfo: React.FC<InvalidRecoveryLinkInfoProps> = ({ passwordRecoveryTokenStatus }) => {
  const { redirectToLoginPage, passwordRecoveryPath } = useRoutes();

  return (
    <S.Form>
      <S.Main>
        <S.Title>We are sorry</S.Title>
        <S.Info>
          {'Recovery password link is ' + (passwordRecoveryTokenStatus === 'EXPIRED' ? 'expired.' : 'invalid.')}
          <br />
          To reset your password&nbsp;
          <S.Link to={passwordRecoveryPath}>make recovery password request.</S.Link>
        </S.Info>
        <S.Button onClick={redirectToLoginPage}>To log in</S.Button>
      </S.Main>
    </S.Form>
  );
};
