import styled from 'styled-components';

export const Header = styled.span`
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: ${(props) => props.theme.colors.black};
`;
