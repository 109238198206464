export const debounced = (fn: () => void, ms: number) => {
  let lastCall = 0;
  let timer: any;

  return () => {
    let previousCall = lastCall;
    lastCall = Date.now();
    if (previousCall && lastCall - previousCall <= ms) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => fn(), ms);
  };
};

export const queued = (fn: () => Promise<any>) => {
  const queue: (() => Promise<any>)[] = [];

  const execute = () => {
    if (queue.length === 0) {
      return;
    }
    queue[0]()
      .then(() => queue.shift())
      .finally(() => execute());
  };

  return () => {
    queue.push(fn);

    if (queue.length === 1) {
      execute();
    }
  };
};

export const onceForLoop = (fn: () => void): (() => void) => {
  let planned = false;

  return () => {
    if (!planned) {
      planned = true;
      Promise.resolve().then(() => {
        fn();
        planned = false;
      });
    }
  };
};
