import React from 'react';
import { Styled as S } from './AnonymUserPage.styled';

export const AnonymUserPage: React.FC = ({ children }) => {
  return (
    <S.Page>
      <S.PageContent>{children}</S.PageContent>
    </S.Page>
  );
};
