import { Styled as LibraryStyled } from './Library.styled';
import { Styled as TreeStyled } from '../tree/Tree.styled';
import styled from 'styled-components';

const LibraryTreeItem = styled(LibraryStyled.Item)`
  width: 100%;
  margin-bottom: 0;

  & > * {
    width: 100%;
  }
`;

const ZeroToggle = styled(TreeStyled.TreeToggle)`
  height: 0;
  width: 0;
  flex-shrink: 0;

  :before {
    width: 14px;
  }

  :after {
    display: none;
  }
`;

export const Styled = {
  TreeNode: TreeStyled.TreeNode,
  Header: TreeStyled.Header,
  Indent: TreeStyled.Indent,
  LibraryTreeItem,
  ZeroToggle,
};
