import React, { useCallback, useMemo, useState } from 'react';
import { Styled as S } from '../../Modal.styled';
import { LanCreationDto, StageDto } from '../../../api/nggrace-back';
import { InputSelect } from '../../widgets/InputSelect';
import { useDirectory } from '../../hooks/useDirectory';
import { Feedback } from '../../widgets/Feedback';
import { Btn } from '../../widgets/Btn.styled';
import { Api } from '../../../api/Api';
import { useRoutes } from '../../../routes/hooks/useRoutes';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Confirmation } from '../../widgets/Confirmation';
import { useNotifications } from '../../context/NotificationContext';

type LanSettingsProps = {
  stage: StageDto;
  onReloadStage?: () => void;
  onClose(): void;
};

export const LanSettings: React.FC<LanSettingsProps> = ({ stage, onReloadStage, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [state, setState] = useState<LanCreationDto>({ ...stage.project });
  const { stationLevelDirectory, switchVendorDirectory } = useDirectory();
  const { redirectToStagePage } = useRoutes();
  const update = useMemo(() => stage.baseInfo.type === 'LAN', [stage]);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const notifications = useNotifications();

  const showConfirmationModal = useCallback(() => {
    setConfirmVisibility(true);
  }, []);

  const hideConfirmationModal = useCallback(() => {
    setConfirmVisibility(false);
  }, []);

  const stationLevelOptions = useMemo(() => {
    return stationLevelDirectory
      .getAll()
      .map((entry) => ({ id: entry.id, name: entry.name.en, disabled: entry.disabled }));
  }, [stationLevelDirectory]);

  const switchVendorOptions = useMemo(() => {
    return switchVendorDirectory.getAll().map((entry) => ({ id: entry.id, name: entry.name.en }));
  }, [switchVendorDirectory]);

  const handleStationLevelChange = useCallback((stationLevel: string) => {
    setState((state) => ({ ...state, stationLevel }));
  }, []);

  const handleSwitchVendorChange = useCallback((switchVendor: string) => {
    setState((state) => ({ ...state, switchVendor }));
  }, []);

  const handleSynthesisLan = useCallback(() => {
    setLoading(true);
    Api.createLan(stage.project.id, state, { omitInterceptorErrorModal: true })
      .then(() => {
        if (update) {
          onReloadStage!!();
          setLoading(false);
          onClose();
          notifications.notifySuccess('LAN settings updated.');
        } else {
          redirectToStagePage(stage.project.id, 'LAN');
        }
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  }, [stage.project.id, state, update, onReloadStage, onClose, notifications, redirectToStagePage]);

  const handleUpdateLan = useCallback(() => {
    hideConfirmationModal();
    handleSynthesisLan();
  }, [handleSynthesisLan, hideConfirmationModal]);

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  return (
    <>
      <ReactModal isOpen onRequestClose={handleClose} style={theme.modal}>
        <S.Modal>
          <S.Header>LAN Settings</S.Header>
          <S.Main>
            <S.LeftPanel>
              <S.Form>
                <InputSelect
                  label="Station level"
                  selected={state.stationLevel}
                  options={stationLevelOptions}
                  onChange={handleStationLevelChange}
                />
                <InputSelect
                  label="Ethernet switch vendor"
                  selected={state.switchVendor}
                  options={switchVendorOptions}
                  onChange={handleSwitchVendorChange}
                />
              </S.Form>
            </S.LeftPanel>
            <S.RightPanel>
              <S.Description>
                <p>You must select the parameters for LAN system synthesis.</p>
                <p>The station level architecture defines the variations of possible designed systems.</p>
                <p>You must select your preferred system hardware vendors from the list of possible vendors.</p>
              </S.Description>
              {error && <Feedback error text={error} />}
            </S.RightPanel>
          </S.Main>
          <S.Footer>
            <Btn secondary onClick={handleClose}>
              Cancel
            </Btn>
            <S.BtnPrimary onClick={update ? showConfirmationModal : handleSynthesisLan} disabled={loading}>
              {!loading ? 'Synthesis' : 'Processing'}
            </S.BtnPrimary>
          </S.Footer>
        </S.Modal>
      </ReactModal>
      <ReactModal isOpen={confirmVisibility} onRequestClose={hideConfirmationModal} style={theme.modalInfo}>
        <Confirmation
          title={'Are you sure?'}
          text={`All changes made to diagram would be lost`}
          onClose={hideConfirmationModal}
          onConfirm={handleUpdateLan}
        />
      </ReactModal>
    </>
  );
};
