import React from 'react';
import { Styled as S } from './TcoCard.styled';

type TcoCardProps = {
  title: string;
};

export const TcoCard: React.FC<TcoCardProps> = ({ title, children }) => {
  return (
    <S.TcoCard>
      <S.Title>{title}</S.Title>
      <S.Content>{children}</S.Content>
    </S.TcoCard>
  );
};
