import { RotationHour } from '../../../geometry/RotationHour';
import { LanNodeModel } from '../LanNodeModel';
import { ControllerDirectoryEntry, PropertiesDirectoryName } from '../../../directory/PropertiesDirectory';
import { LanPortModel } from '../port/LanPortModel';
import { LanControllerPortModel } from './LanControllerPortModel';
import { Point } from '@projectstorm/geometry';

export const DefaultLanControllerSize = new Point(30, 30);
export const LanControllerModelType = 'lan-controller';

export class LanControllerModel extends LanNodeModel {
  constructor(directoryEntry: ControllerDirectoryEntry) {
    super(
      {
        type: LanControllerModelType,
        payload: {
          hour: RotationHour.ZERO,
        },
        defaultSize: DefaultLanControllerSize,
        resizers: [],
      },
      directoryEntry
    );
  }

  createPort(label: string): LanPortModel {
    return new LanControllerPortModel({ directoryEntry: this.getDirectoryEntry(), label });
  }

  getDirectory(): PropertiesDirectoryName {
    return 'ControllerDirectory';
  }

  canRemove(): boolean {
    return false;
  }
}
