import { PointModelOptions } from '@projectstorm/react-diagrams';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';

export class SmartLinkPointModel extends PointModel {
  private sourceDependent: boolean = false;

  private targetDependent: boolean = false;

  constructor(options: PointModelOptions) {
    super({
      ...options,
    });
  }

  setSourceDependent(dependent: boolean) {
    this.sourceDependent = dependent;
  }

  setTargetDependent(dependent: boolean) {
    this.targetDependent = dependent;
  }

  isSourceDependent() {
    return this.sourceDependent;
  }

  isTargetDependent() {
    return this.targetDependent;
  }

  isSelected(): boolean {
    const link = this.getParent();
    return link.getSourcePort()?.isSelected() && link.getTargetPort()?.isSelected();
  }

  serialize() {
    return {
      ...super.serialize(),
      sourceDependent: this.sourceDependent,
      targetDependent: this.targetDependent,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.sourceDependent = event.data.sourceDependent;
    this.targetDependent = event.data.targetDependent;
  }
}
