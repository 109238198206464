import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '../../Page';
import { Direction, UserDto, UserSortProperty } from '../../../api/nggrace-back';
import { Api } from '../../../api/Api';
import { UserSettings } from './UserSettings';
import { UserDeleteConfirmation } from './UserDeleteConfirmation';
import { UserList } from './UserList';
import ReactModal from 'react-modal';
import { theme } from '../../../theme';
import { Styled as S } from '../TablePage.styled';
import { Header } from '../../widgets/Header.styled';
import { BtnIcon } from '../../widgets/Btn.styled';
import { Icon } from '../../widgets/Icon';
import { NotificationProvider } from '../../context/NotificationContext';

interface PaginationState {
  page: number;
  size: number;
}

export interface SortState {
  direction: Direction;
  property: UserSortProperty;
}

interface PageSate {
  pagination: PaginationState;
  sort: SortState;
  companyName?: string;
}

export const UsersPage: React.FC = () => {
  const [pageState, setPageState] = useState<PageSate>({
    pagination: { page: 0, size: 1500 },
    sort: { direction: 'ASC', property: 'NAME' },
  });
  const [users, setUsers] = useState<UserDto[]>([]);
  const [updatedUser, setUpdatedUser] = useState<UserDto>();
  const [userSettingsVisibility, setUserSettingsVisibility] = useState(false);
  const [userDeletingConfirmation, setUserDeletingConfirmation] = useState(false);
  const [userDelete, setUserDelete] = useState<UserDto>();

  const loadUsers = useCallback(() => {
    Api.getUsers(
      {
        sortDirection: pageState.sort.direction,
        sortProperty: pageState.sort.property,
        companyName: pageState.companyName,
      },
      pageState.pagination
    ).then((res) => {
      if (res.data.totalElements && res.data.content) {
        setUsers(res.data.content);
      }
    });
  }, [pageState]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const setSort = useCallback(
    (sort: SortState) => {
      setPageState({ ...pageState, sort: sort, pagination: { ...pageState.pagination, page: 0 } });
    },
    [pageState]
  );

  const handleDeleteClick = useCallback((user: UserDto) => {
    setUserDelete(user);
    setUserDeletingConfirmation(true);
  }, []);

  const handleUpdate = useCallback((user: UserDto) => {
    setUpdatedUser(user);
    setUserSettingsVisibility(true);
  }, []);

  const handleInvite = useCallback(() => {
    setUpdatedUser(undefined);
    setUserSettingsVisibility(true);
  }, []);

  const hideUserSettings = useCallback(() => {
    setUserSettingsVisibility(false);
  }, []);

  const hideDeletingConfirmation = useCallback(() => {
    setUserDeletingConfirmation(false);
  }, []);

  return (
    <NotificationProvider>
      <Page active={'users'}>
        <S.Content>
          <S.Header>
            <Header>Users</Header>
            <BtnIcon onClick={handleInvite}>
              <Icon name={'plus'} /> New
            </BtnIcon>
          </S.Header>
          <UserList users={users} setSort={setSort} handleUpdate={handleUpdate} handleDelete={handleDeleteClick} />
        </S.Content>
        <ReactModal isOpen={userSettingsVisibility} onRequestClose={hideUserSettings} style={theme.modal}>
          <UserSettings onHide={hideUserSettings} loadPageHandler={loadUsers} user={updatedUser} />
        </ReactModal>
        <ReactModal isOpen={userDeletingConfirmation} onRequestClose={hideDeletingConfirmation} style={theme.modalInfo}>
          <UserDeleteConfirmation onHide={hideDeletingConfirmation} loadPageHandler={loadUsers} user={userDelete!} />
        </ReactModal>
      </Page>
    </NotificationProvider>
  );
};
