import styled from 'styled-components';
import { Styled as StyledInputField } from '../widgets/InputField.styled';
import { Btn } from '../widgets/Btn.styled';
import { Link as _Link } from 'react-router-dom';

const AuthForm = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Main = styled.form`
  display: flex;
`;

const LeftPanel = styled.div`
  width: 450px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  padding: 60px;
  border-radius: 16px 0 0 16px;
  color: ${(props) => props.theme.colors.lightgrey};
  font-family: ${(props) => props.theme.fonts.secondary};
`;

const Title = styled.div`
  margin: 14px 0;
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
`;

const Description = styled.div`
  margin: 10px 0;
`;

const RightPanel = styled.div`
  width: 700px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 0 16px 16px 0;
  padding: 60px;
`;

const Logo = styled.img`
  margin-top: 10px;
  margin-bottom: 24px;
`;

const Form = styled.div`
  ${StyledInputField.InputField} {
    width: 570px;
    margin-top: 24px;
  }
  margin-bottom: 12px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 12px;
`;

const FooterOnlyButtons = styled.div`
  align-items: center;
  width: 100%;
  padding-top: 12px;
`;

const ExtraInfo = styled.div`
  a {
    text-decoration: underline;
  };
  width: 312px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue};
`;

const Link = styled(_Link)`
  font-size: inherit;
  color: inherit;
  position: relative;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 2px;
    left: 0;
  }
`;

const Links = styled(ExtraInfo)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${Link} {
    margin-bottom: 8px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * + * {
    margin-left: 8px;
  }

  ${Btn} {
    height: 40px;
    min-width: 120px;
  }
`;

export const Styled = {
  AuthForm,
  Main,
  LeftPanel,
  Title,
  Description,
  RightPanel,
  Logo,
  Form,
  Footer,
  FooterOnlyButtons,
  ExtraInfo,
  Links,
  Link,
  Buttons,
};
