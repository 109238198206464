import {
  HasNotExtendableParentModel,
  NavigatingSelectableTreeModel,
  SelectableTreeBaseModel,
} from '../../../widgets/tree/state/TreeBaseModel';
import { SsdEngine } from '../../../editor/ssd/SsdEngine';
import {
  ExtendableTreeNode,
  TreeDraggablePayload,
  TreeDraggableType,
  TreeNodeModel,
  TreeNodeState,
} from '../../../widgets/tree/state/TreeState';
import { SsdStructureTreeLogicDeviceModelProps, SsdStructureTreeLogicDeviceProps } from './SsdStructureTreeLogicDevice';
import { DirectoryLogicDeviceModel } from '../../../editor/ssd/logic-device/LogicDeviceModel';
import { Factory } from '../../../../utils/factory';
import { DirectoryNodeWithPlaceholdersModel } from '../../../editor/directory/DirectoryNodeWithPlaceholdersModel';
import { BusNodeWithPlaceholdersModel } from '../../../editor/bus/BusNodeWithPlaceholdersModel';
import { Listenable } from '../../../editor/placeholder/Listenable';
import { HasSizedChildrenListener } from '../../../editor/placeholder/HasSize';

export const SsdStructureTreeNodeFactory = (
  engine: SsdEngine,
  modelLogicDeviceFactory: Factory<SsdStructureTreeLogicDeviceModelProps, DirectoryLogicDeviceModel>,
  treeLogicDeviceFactory: Factory<SsdStructureTreeLogicDeviceProps, TreeNodeState>
): Factory<SsdStructureTreeNodeModelType, TreeNodeState> => (node: SsdStructureTreeNodeModelType) =>
  SsdStructureTreeNode(node, engine, modelLogicDeviceFactory, treeLogicDeviceFactory);

const SsdStructureTreeNode = (
  node: SsdStructureTreeNodeModelType,
  engine: SsdEngine,
  modelLogicDeviceFactory: Factory<SsdStructureTreeLogicDeviceModelProps, DirectoryLogicDeviceModel>,
  treeLogicDeviceFactory: Factory<SsdStructureTreeLogicDeviceProps, TreeNodeState>
): TreeNodeState => {
  const extendable = ExtendableSsdStructureTreeNode(({ devicePayload, deviceToAddAfter }) =>
    modelLogicDeviceFactory({
      devicePayload,
      deviceToAddAfter,
      parent: node,
    })
  );

  const treeNodeModel = SsdStructureTreeNodeModel(node, (device) =>
    treeLogicDeviceFactory({
      model: device,
      parent: extendable,
    })
  );

  return {
    ...treeNodeModel,
    ...extendable,
    ...HasNotExtendableParentModel(),
    ...NavigatingSelectableTreeModel(SelectableTreeBaseModel(node, engine), node.getID(), engine),
  };
};

export type SsdStructureTreeNodeModelType = (
  | DirectoryNodeWithPlaceholdersModel<DirectoryLogicDeviceModel>
  | BusNodeWithPlaceholdersModel<DirectoryLogicDeviceModel>
) &
  Listenable<HasSizedChildrenListener>;
const SsdStructureTreeNodeModel = (
  node: SsdStructureTreeNodeModelType,
  logicDeviceMapping: (device: DirectoryLogicDeviceModel) => TreeNodeState
): TreeNodeModel => {
  return {
    getName: () => node.getPayload().projectName || node.getID(),
    getKey: () => node.getID(),
    onChildrenChanged: (cb) =>
      node.registerListener({
        childrenChanged: (event: { child: DirectoryLogicDeviceModel; created?: boolean }) =>
          cb(logicDeviceMapping(event.child), !!event.created),
      }).deregister,
    getChildren: () => node.getChildren().map(logicDeviceMapping),
  };
};

const ExtendableSsdStructureTreeNode = (
  modelDeviceFactory: Factory<Omit<SsdStructureTreeLogicDeviceModelProps, 'parent'>, DirectoryLogicDeviceModel>
): ExtendableTreeNode => ({
  canAddChild: ({ type }) => type === TreeDraggableType.LogicDevice,
  addChild: (childPayload: TreeDraggablePayload, childToAddAfter?: TreeNodeModel) => {
    modelDeviceFactory({ devicePayload: childPayload, deviceToAddAfter: childToAddAfter });
  },
});
