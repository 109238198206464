import { SmartLinkModel } from './SmartLinkModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { AppendingConnectingPath, DefaultConnectingPath } from './path/connecting/DefaultConnectingPath';
import { UpdatingDependenciesConnectingPath } from './path/connecting/UpdatingDependenciesConnectingPath';
import { RemovingConnectingPath } from './path/connecting/RemovingConnectingPath';
import { RestrictedDirectionPoint } from '../../geometry/Point';
import { SidedAngledPath } from './path/default/SidedAngledPath';
import { Point } from '@projectstorm/geometry';

export class JoinedSmartLinkModel extends SmartLinkModel {
  constructor(
    joiningPoint: Point,
    sourcePort: ConnectablePortModel,
    targetPort: ConnectablePortModel,
    sourceLink: SmartLinkModel,
    targetLink: SmartLinkModel
  ) {
    super(sourcePort, targetPort, []);
    const portPointsCount = 2;
    const joiningPointIndex = 1;
    const sourceLinkPoints = [...sourceLink.getPoints()];
    const sourcePoints = sourceLinkPoints.slice(0, sourceLinkPoints.length - portPointsCount);
    const sourceJoiningPoint = sourceLinkPoints[sourceLinkPoints.length - 1 - joiningPointIndex].getPosition();
    const sourceLastPoint = sourcePoints[sourcePoints.length - 1].getPosition();

    const targetLinkPoints = [...targetLink.getPoints()];

    const needToReverseTargetLink = targetLink.getTargetPort().getID() !== targetPort.getID();
    if (needToReverseTargetLink) {
      targetLinkPoints.reverse();
    }

    const targetPoints = targetLinkPoints.slice(portPointsCount);
    const targetJoiningPoint = targetLinkPoints[joiningPointIndex].getPosition();
    const targetLastPoint = targetPoints[0].getPosition();

    const joiningPoints: Point[] = [
      sourceLastPoint,
      sourceJoiningPoint,
      joiningPoint,
      targetJoiningPoint,
      targetLastPoint,
    ];

    this.setPoints(
      new UpdatingDependenciesConnectingPath(
        new RemovingConnectingPath(
          new AppendingConnectingPath(
            new AppendingConnectingPath(
              new DefaultConnectingPath(new SidedAngledPath(joiningPoints), this),
              sourcePoints.slice(0, sourcePoints.length - 1)
            ),
            targetPoints.slice(1),
            true
          )
        )
      ).connect(new RestrictedDirectionPoint(sourceLastPoint), new RestrictedDirectionPoint(targetLastPoint))
    );
  }
}
