import { DirectoryPortModel } from './DirectoryPortModel';
import { FieldRecord, NodeDirectoryEntry, PortRecord } from './NodeDirectory';
import { Point } from '@projectstorm/geometry';
import {
  RotatableNodeModel,
  RotatableNodeModelGenerics,
  RotatableNodeModelOptions,
  RotatableNodeModelPayload,
} from '../generics/RotatableNodeModel';
import { ResizeEdge, ResizeStrategy } from '../generics/ResizableNodeModel';
import { LabelModel } from '../label/LabelModel';
import { NodeLabelModel } from '../label/NodeLabelModel';
import { RotationHour } from '../geometry/RotationHour';
import { NamedNodeModelPayload } from '../generics/NamedNodeModelPayload';

export interface DirectoryNodeModelPayload extends RotatableNodeModelPayload, NamedNodeModelPayload {
  directoryEntryId: string;
  fields: DirectoryNodeFields;
  portFields: { [key: string]: DirectoryNodeFields };
  operationName?: string;
  voltageLevel?: string;
}

export type DirectoryNodeFields = { [key: string]: DirectoryNodeField };

export type DirectoryNodeField = DirectoryNodeDirectoryField | DirectoryNodeInputField;

export interface DirectoryNodeDirectoryField {
  directoryId: string;
}

export interface DirectoryNodeInputField {
  value: string;
}

export interface DirectoryNodeModelOptions extends RotatableNodeModelOptions {
  payload: DirectoryNodeModelPayload;
}

export interface DirectoryNodeModelGenerics extends RotatableNodeModelGenerics {
  PAYLOAD: DirectoryNodeModelPayload;
  OPTIONS: DirectoryNodeModelOptions;
}

const getDefaultFields = (fieldRecords: FieldRecord[]): DirectoryNodeFields => {
  const fields: DirectoryNodeFields = {};
  fieldRecords.forEach((field) => {
    if (field.defaultValue) {
      switch (field.fieldType) {
        case 'input':
          const inputField: DirectoryNodeInputField = {
            value: field.defaultValue,
          };
          fields[field.name] = inputField;
          break;
        case 'directory':
          const directoryField: DirectoryNodeDirectoryField = {
            directoryId: field.defaultValue,
          };
          fields[field.name] = directoryField;
          break;
      }
    }
  });
  return fields;
};

const getDefaultPortFields = (ports: PortRecord[]): { [key: string]: DirectoryNodeFields } => {
  const portFields: { [key: string]: DirectoryNodeFields } = {};
  ports.forEach((port) => {
    portFields[port.name] = getDefaultFields(port.fields);
  });
  return portFields;
};

const getDefaultPayload = (directoryEntry: NodeDirectoryEntry): DirectoryNodeModelPayload => {
  return {
    directoryEntryId: directoryEntry.id,
    hour: RotationHour.ZERO,
    fields: getDefaultFields(directoryEntry.fields),
    portFields: getDefaultPortFields(directoryEntry.ports),
  };
};

export class DirectoryNodeModel extends RotatableNodeModel<DirectoryNodeModelGenerics> {
  private readonly label: LabelModel;
  private readonly directoryEntry: NodeDirectoryEntry;

  constructor(directoryEntry: NodeDirectoryEntry) {
    super({
      type: 'directory',
      payload: getDefaultPayload(directoryEntry),
      defaultSize: new Point(directoryEntry.width, directoryEntry.height),
      resizers: [],
    });

    this.directoryEntry = directoryEntry;

    directoryEntry.ports.forEach((port) => {
      this.addPort(new DirectoryPortModel(port));
    });

    this.label = new NodeLabelModel(directoryEntry, this);
  }

  getDirectoryEntry(): NodeDirectoryEntry {
    return this.directoryEntry;
  }

  getDirectoryEntryId() {
    return this.getPayload().directoryEntryId;
  }

  getCenter(): Point {
    const position = this.getPosition();
    const size = this.getSize();
    return new Point(position.x + size.x / 2, position.y + size.y / 2);
  }

  getResizeStrategy(): ResizeStrategy {
    return new (class implements ResizeStrategy {
      resize(edges: ResizeEdge[], resizePosition: Point): ResizeEdge[] {
        return [];
      }
    })();
  }

  getLabel() {
    return this.label;
  }
}
