import { LanEngine } from '../../LanEngine';
import { LanControllerModel } from './LanControllerModel';
import React from 'react';
import { Styled as S } from '../network/NetworkNodeWidget.styled';
import { LanNodeWidget } from '../LanNodeWidget';

export interface LanControllerNodeWidgetProps {
  engine: LanEngine;
  node: LanControllerModel;
}

export const LanControllerNodeWidget: React.FC<LanControllerNodeWidgetProps> = ({ node, engine }) => {
  return (
    <>
      <S.Label height={node.getSize().y} width={node.getSize().x} yCenter={true}>
        <div>{node.getProjectName()}</div>
        <div>{node.getName()}</div>
      </S.Label>
      <LanNodeWidget node={node} engine={engine} />
    </>
  );
};
