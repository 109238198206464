import { AbstractReactFactory, BaseModel } from '@projectstorm/react-canvas-core';
import { Rack, RackHasRelativeRoomFactory } from './Rack';
import { RackWidget } from './RackWidget';
import { LanEngine } from '../LanEngine';
import {
  DefaultRackHasRect,
  RackHasChildrenFactory,
  RackHasRectFactory,
  SingleRackModel,
  SingleRackModelType,
} from './SingleRackModel';
import { GenerateModelEvent } from '../../scd/controller/ControllerFactory';
import { DefaultHasName, HasName } from '../building/HasName';
import { HasRelativeParent } from '../../placeholder/HasRelativeModel';
import { DefaultHasChildren } from '../../placeholder/HasChildren';
import { ZoneModel, ZoneType } from '../zone/ZoneModel';
import { Factory } from '../../../../utils/factory';
import { RackPadding, TopRackPadding } from './RackHasSize';
import { RackHasChildren } from './RackHasChildren';
import { AddedPoint, BasePoint } from '../../geometry/Point';
import { Coordinate, DefaultCoordinate } from '../../geometry/Coordinate';

export class SingleRackFactory extends AbstractReactFactory<Rack & BaseModel, LanEngine> {
  constructor() {
    super(SingleRackModelType);
  }

  generateReactWidget(event: { model: SingleRackModel }): JSX.Element {
    const model = event.model;
    return <RackWidget rack={model} key={event.model.getID()} />;
  }

  generateModel(event: GenerateModelEvent<SingleRackModel>) {
    const parts = this.getRackParts(event.initialConfig.zones[0]);
    return new SingleRackModel(
      new DefaultHasName(event.initialConfig.name),
      (eventDelegate) => new HasRelativeParent(eventDelegate),
      parts.childrenFactory,
      parts.rectFactory,
      parts.coordinate
    );
  }

  createNewModel(hasName: HasName, zone: ZoneModel, hasRelativeRoomFactory?: RackHasRelativeRoomFactory) {
    const parts = this.getRackParts(zone.getZoneType());
    return new SingleRackModel(
      hasName,
      hasRelativeRoomFactory || ((eventDelegate) => new HasRelativeParent(eventDelegate)),
      parts.childrenFactory,
      parts.rectFactory,
      parts.coordinate,
      zone
    );
  }

  getRackParts(zoneType: string) {
    const type = zoneType as ZoneType;
    const gap = /*getMaxSwitchWidth() + RackPadding * 2; */ undefined;
    const offset = /* gap */ undefined;
    const startOffsetX = /* gap */ 10;
    const xCoord = new DefaultCoordinate('x');
    const yCoord = new DefaultCoordinate('y');

    const RackHasChildrenFactories: {
      [key in ZoneType]: {
        childrenFactory: RackHasChildrenFactory;
        rectFactory: RackHasRectFactory;
        coordinate: Coordinate;
      };
    } = {
      [ZoneType.WORKSTATION]: {
        childrenFactory: VerticalFactory,
        rectFactory: DefaultRackHasRect({}),
        coordinate: yCoord,
      },
      [ZoneType.SCADA]: { childrenFactory: VerticalFactory, rectFactory: DefaultRackHasRect({}), coordinate: yCoord },
      [ZoneType.MIDDLE]: { childrenFactory: VerticalFactory, rectFactory: DefaultRackHasRect({}), coordinate: yCoord },
      [ZoneType.LOW_NETWORK]: {
        childrenFactory: HorizontalFactory({}),
        rectFactory: DefaultRackHasRect({}),
        coordinate: xCoord,
      },
      [ZoneType.LOW_GENERAL]: {
        childrenFactory: VerticalFactory,
        rectFactory: DefaultRackHasRect({}),
        coordinate: yCoord,
      },
      [ZoneType.RPA_PROTECTION]: {
        childrenFactory: VerticalFactory,
        rectFactory: DefaultRackHasRect({}),
        coordinate: yCoord,
      },
      [ZoneType.RPA_PRIMARY_NETWORK_PROCESS]: {
        childrenFactory: HorizontalFactory({ gap }),
        rectFactory: DefaultRackHasRect({ startOffsetX }),
        coordinate: xCoord,
      },
      [ZoneType.RPA_PRIMARY_NETWORK_STATION]: {
        childrenFactory: HorizontalFactory({ gap, offset }),
        rectFactory: DefaultRackHasRect({ startOffsetX }),
        coordinate: xCoord,
      },
      [ZoneType.RPA_BACKUP_NETWORK_PROCESS]: {
        childrenFactory: HorizontalFactory({ gap, offset }),
        rectFactory: DefaultRackHasRect({ startOffsetX }),
        coordinate: xCoord,
      },
      [ZoneType.RPA_BACKUP_NETWORK_STATION]: {
        childrenFactory: HorizontalFactory({ gap }),
        rectFactory: DefaultRackHasRect({ startOffsetX }),
        coordinate: xCoord,
      },
    };

    return RackHasChildrenFactories[type];
  }
}

const childrenGap = 80;
const VerticalFactory: RackHasChildrenFactory = ({ hasPosition, eventDelegate }) =>
  new RackHasChildren(eventDelegate, new DefaultHasChildren(eventDelegate as any), (children) => {
    const startPosition = new AddedPoint(hasPosition.getPosition(), new BasePoint(RackPadding, TopRackPadding));
    let currentPositionY = startPosition.y;
    children.forEach((child) => {
      child.setPosition(startPosition.x, currentPositionY);
      currentPositionY += child.getSize().y + childrenGap;
    });
  });

const HorizontalFactory: Factory<{ gap?: number; offset?: number }, RackHasChildrenFactory> = ({ gap, offset }) => ({
  hasPosition,
  eventDelegate,
}) =>
  new RackHasChildren(eventDelegate, new DefaultHasChildren(eventDelegate as any), (children) => {
    const startPosition = new AddedPoint(
      hasPosition.getPosition(),
      new BasePoint(RackPadding + (offset || 0), TopRackPadding)
    );

    let currentPositionX = startPosition.x;

    children.forEach((child) => {
      child.setPosition(currentPositionX, startPosition.y);
      currentPositionX += gap !== undefined ? 2 * gap : childrenGap + child.getSize().x;
    });
  });
