import { ModelCommand } from '../../model/command/ModelCommand';
import { ConnectingSmartLinkModel } from '../../link/smart/ConnectingSmartLinkModel';
import { ConnectablePortModel } from '../../generics/ConnectablePortModel';
import { LanModel } from '../LanModel';
import { LanLinkModel } from '../link/LanLinkModel';

export class LanLinkConnectionCommand implements ModelCommand {
  private model: LanModel;
  private readonly origin: ConnectingSmartLinkModel;
  private readonly targetPort: ConnectablePortModel;

  constructor(model: LanModel, connectingLink: ConnectingSmartLinkModel, targetPort: ConnectablePortModel) {
    this.model = model;
    this.origin = connectingLink;
    this.targetPort = targetPort;
  }

  execute() {
    if (!this.origin.canConnectTarget(this.targetPort)) {
      this.origin!.remove();
      return;
    }

    this.origin!.setTargetPort(this.targetPort);
    const resultLink = this.getResultLink();
    const sourcePort = this.origin.getSourcePort();
    sourcePort.removeLink(this.origin);
    this.targetPort.removeLink(this.origin);
    this.model.removeLink(this.origin);
    this.model.addLink(resultLink);
    this.targetPort.notifyLinks();
    this.targetPort.reportPosition();
  }

  private getResultLink() {
    const sourcePort = this.origin.getSourcePort();
    const targetPort = this.targetPort;
    return new LanLinkModel(sourcePort, targetPort);
  }
}
