export const FrameLeftRu = () => {
  return (
    <g>
      <g style={{ fill: 'none', stroke: '#000000', strokeWidth: 0.5 }}>
        <rect y="65" x="8" height="85" width="12" />
        <path d="M 13,65 V 150" />
        <path d="M 8,90 H 20" />
        <path d="M 8,125 H 20" />
        <rect y="0" x="5.5" height="65" width="14.5" />
        <path d="M 10,10 H 20" />
        <path d="M 10,25 H 20" />
        <path d="M 10,45 H 20" />
        <path style={{ strokeWidth: 0.2 }} d="M 15,0 V 65" />
        <path d="M 10,0 V 65" />
        <path style={{ strokeWidth: 0.2 }} d="M 5.5,10 H 10" />
        <path d="M 5.5,25 H 10" />
      </g>
      <g
        style={{ fontSize: '4.23333311px', fontFamily: 'GOSTRUS', letterSpacing: '-0.26458332px', fill: '#000000' }}
        transform="rotate(-90)"
      >
        <text x="-137.5" y="12" textAnchor="middle">
          Инв. № подл.
        </text>
        <text x="-107.5" y="12" textAnchor="middle">
          Подп. и дата
        </text>
        <text x="-77.5" y="12" textAnchor="middle">
          Взам. инв. №
        </text>
        <text x="-64.5" y="9">
          Согласовано
        </text>
      </g>
    </g>
  );
};
