import { useCallback, useEffect, useMemo, useState } from 'react';
import { StageBaseDto, StageDto, StageType, StageValidationDto } from '../../api/nggrace-back';
import { Api } from '../../api/Api';
import { isEqual } from 'lodash';

export const useStage = (projectId: string, stageType: StageType) => {
  // TODO
  // extract json and do not save in the state as it's already stored in the engine
  // it required to sync both model that cause useless renders and errors
  const [stage, setStage] = useState<StageDto>();

  const loadStage = useCallback(() => {
    if (projectId && stageType) {
      Api.getStage({ projectId: Number(projectId), stageType }).then((result) => setStage(result.data));
    }
  }, [projectId, stageType]);

  useEffect(() => {
    loadStage();
  }, [loadStage]);

  const validateStage = useCallback(async (): Promise<StageValidationDto> => {
    if (stage?.baseInfo.id) {
      const { data } = await Api.validateStage(stage.baseInfo.id);
      setStage(data.stage);
      return data;
    }

    return Promise.reject();
  }, [stage?.baseInfo.id]);

  const finishStage = useCallback(async (): Promise<StageDto> => {
    if (stage?.baseInfo.id) {
      const { data } = await Api.finishStage(stage.baseInfo.id);
      setStage((stage) => ({
        ...stage!,
        baseInfo: data.baseInfo,
      }));

      return data;
    }

    return Promise.reject();
  }, [stage?.baseInfo.id]);

  const updateStage = useCallback(
    async (diagram: string): Promise<StageBaseDto> => {
      if (stage?.baseInfo.id) {
        const { data } = await Api.updateStage(stage.baseInfo.id, { value: diagram });

        setStage((stage) => {
          // avoid re-render if base info isn't changed
          if (stage && !isEqual(data, stage.baseInfo)) {
            return { ...stage!, baseInfo: data };
          }

          return stage;
        });

        return data;
      }

      return Promise.reject();
    },
    [stage?.baseInfo.id]
  );

  const rollbackStage = useCallback(async (): Promise<StageDto> => {
    if (stage?.baseInfo.id) {
      const { data } = await Api.rollbackToStage(stage.baseInfo.id);
      setStage(data);
      return data;
    }

    return Promise.reject();
  }, [stage?.baseInfo.id]);

  return useMemo(() => {
    return { finishStage, rollbackStage, stage, updateStage, reloadStage: loadStage, validateStage };
  }, [finishStage, loadStage, rollbackStage, stage, updateStage, validateStage]);
};
