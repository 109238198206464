import { DiagramModel, DiagramModelGenerics, LinkModel, NodeModel, PortModel } from '@projectstorm/react-diagrams';
import { SmartLinkModel } from './link/smart/SmartLinkModel';
import { BusNodeModel } from './bus/BusNodeModel';
import { PayloadNodeModel } from './generics/PayloadNodeModel';
import { DirectoryNodeModel } from './directory/DirectoryNodeModel';
import { RotatableNodeModel } from './generics/RotatableNodeModel';
import { NgGraceEngine } from './NgGraceEngine';
import { NgGraceLinkModel } from './link/NgGraceLinkModel';
import { ConnectivityNodeModel } from './connectivity/ConnectivityNodeModel';
import { DeserializeEvent, Toolkit } from '@projectstorm/react-canvas-core';
import { LanNodeModel } from './lan/node/LanNodeModel';
import { BusPortModel } from './bus/BusPortModel';
import { ConnectivityNodeDeletionCommand } from './model/command/ConnectivityNodeDeletionCommand';
import { NetworkNodeModel } from './lan/node/network/NetworkNodeModel';

const pasteOffset = 20;

const getValueOrDefault = (value: number | undefined, defaultValue: number): number => {
  return value !== undefined ? value : defaultValue;
};

export class NgGraceModel extends DiagramModel {
  constructor(options?: DiagramModelGenerics['OPTIONS']) {
    super({ ...options, gridSize: 1, zoom: 200 });
  }

  // used to increase offset on multiple sequential pastes
  private sequentialPasteNumber = 1;

  async deserializeModel(data: ReturnType<this['serialize']>, engine: NgGraceEngine) {
    super.deserializeModel(this.fillDefaultsData(data), engine);

    // deserialization is async, so lets wait
    await Promise.resolve();
  }

  fillDefaultsData = (data: ReturnType<this['serialize']>): ReturnType<this['serialize']> => {
    return {
      ...data,
      zoom: getValueOrDefault(data.zoom, 100),
      gridSize: getValueOrDefault(data.gridSize, 1),
      offsetX: getValueOrDefault(data.offsetX, 0),
      offsetY: getValueOrDefault(data.offsetY, 0),
    };
  };

  copy(): NgGraceModel {
    const model = this.generateModel();
    const selectedEntities = this.getSelectedEntities();
    selectedEntities.filter(isNodeModel).forEach((node) => model.addNode(node));
    selectedEntities
      .filter(isLinkModel)
      .filter((link) => link.getSourcePort().isSelected() && link.getTargetPort().isSelected())
      .forEach((link) => model.addLink(link));

    // reset sequential paste number
    this.sequentialPasteNumber = 1;

    return model;
  }

  paste(pasted: NgGraceModel): void {
    this.clearSelection();

    const lookUpTable = {};
    const models = pasted.getModels();
    const cloned = models.map((item) => item.clone(lookUpTable));
    const offset = this.sequentialPasteNumber * pasteOffset;

    cloned.filter(isNodeModel).forEach((node) => {
      node.setPosition(node.getX() + offset, node.getY() + offset);
      node.setSelected(true);
      this.addNode(node);
    });

    cloned.filter(isLinkModel).forEach((link) => {
      link.getPoints().forEach((p) => p.setPosition(p.getX() + offset, p.getY() + offset));
      link.setSelected(true);
      this.addLink(link);
    });

    this.removeRedundantConnectivityNodes();
    // increment sequential paste number
    this.sequentialPasteNumber++;
  }

  getZoomedValue(notZoomedValue: number) {
    return (notZoomedValue / 100) * this.getZoomLevel();
  }

  generateModel() {
    return new NgGraceModel();
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.getOptions().id = Toolkit.UID();
  }

  private removeRedundantConnectivityNodes() {
    const minConnectivityLinksNumber = 3;
    this.getNodes()
      .filter(
        (node): node is ConnectivityNodeModel =>
          isConnectivityNodeModel(node) &&
          Object.values(node.getPorts()).flatMap((port) => Object.values(port.getLinks())).length <
            minConnectivityLinksNumber
      )
      .forEach((node) => new ConnectivityNodeDeletionCommand(this, node).execute());
  }
}

export const isNodeModel = (item: unknown): item is NodeModel => item instanceof NodeModel;

export const isLanNodeModel = (item: unknown): item is LanNodeModel => item instanceof LanNodeModel;

export const isNetworkNodeModel = (item: unknown): item is NetworkNodeModel => item instanceof NetworkNodeModel;

export const isBusNodeModel = (item: unknown): item is BusNodeModel => item instanceof BusNodeModel;

export const isPayloadNodeModel = (item: unknown): item is PayloadNodeModel => item instanceof PayloadNodeModel;

export const isDirectoryNodeModel = (item: unknown): item is DirectoryNodeModel => item instanceof DirectoryNodeModel;

export const isRotatableNodeModel = (item: unknown): item is RotatableNodeModel => item instanceof RotatableNodeModel;

export const isLinkModel = (item: unknown): item is LinkModel => item instanceof LinkModel;

export const isConnectivityNodeModel = (item: unknown): item is ConnectivityNodeModel =>
  item instanceof ConnectivityNodeModel;

export const isNgGraceLinkModel = (item: unknown): item is NgGraceLinkModel => item instanceof NgGraceLinkModel;

export const isSmartLinkModel = (item: unknown): item is SmartLinkModel => item instanceof SmartLinkModel;

export const isPortModel = (item: unknown): item is PortModel => item instanceof PortModel;

export const isBusPortModel = (item: unknown): item is BusPortModel => item instanceof BusPortModel;
