import React, { useState } from 'react';
import { Toast, ToastType } from './Toast';
import styled from 'styled-components';
import { Icon } from './Icon';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/translucent.css';
import { Placement } from 'tippy.js';

interface TooltipProps {
  text: React.ReactNode | string;
  placement: Placement;
  offset?: [number, number];
}

export const Hint = styled(Icon)<{ active?: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.colors.turquoise : props.theme.colors.darkgrey)};
`;

const TippyContent = styled(Tippy)`
  background-color: transparent;
`;

export const Tooltip: React.FC<TooltipProps> = ({ text, placement, offset }) => {
  const [open, setOpen] = useState(false);

  return (
    <TippyContent
      content={<Toast title={'HINT'} text={text} type={ToastType.HINT} />}
      trigger="click"
      arrow={false}
      placement={placement}
      onShow={() => setOpen(true)}
      onHide={() => setOpen(false)}
      offset={offset}
    >
      <Hint name={'question'} active={open} />
    </TippyContent>
  );
};
