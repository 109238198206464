import React, { useCallback, useState } from 'react';
import { Page } from '../../Page';
import { Overlay } from '../../widgets/Overlay';
import { useParams } from 'react-router-dom';
import { ProjectSidebar } from '../project/ProjectSidebar';
import { MessageModal } from '../../widgets/MessageModal';
import { EngineProvider } from '../../editor/EngineContext';
import { SldDiagramEditor } from './SldDiagramEditor';
import { SldEngine } from '../../editor/sld/SldEngine';
import { useStage } from '../../hooks/useStage';
import { useDirectory } from '../../hooks/useDirectory';
import { ProjectStyleSelection } from '../../../api/nggrace-back';
import { SsdSettings } from '../ssd/SsdSettings';
import { BusTransformersLayoutGraph } from '../../editor/auto-layout/BusTransformersLayoutGraph';
import { NgGraceModel } from '../../editor/NgGraceModel';
import { NotificationProvider } from '../../context/NotificationContext';
import { DiagramModelProvider } from '../../editor/DiagramModelContext';
import { useNextStage } from '../../hooks/useNextStage';

export const SldStagePage: React.FC = () => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [validation, setValidation] = useState<string>();
  const { projectId } = useParams<{ projectId?: string }>();
  const { stage, updateStage, validateStage, finishStage, rollbackStage } = useStage(projectId!, 'SLD');
  const { getNodeDirectory, voltageLevelDirectory } = useDirectory();
  const { nextStage, showNextStageModal, closeNextStageModal } = useNextStage(stage);

  const handleValidateStage = useCallback(async () => {
    setOverlayVisible(true);
    const result = await validateStage().finally(() => setOverlayVisible(false));

    if (!result.success) {
      setValidation(result.message?.en || 'Unknown validation error');
      return false;
    }

    return true;
  }, [validateStage]);

  const handleValidationClose = useCallback(() => {
    setValidation(undefined);
  }, []);

  const handleEngineFactory = useCallback(
    (styleSelection: ProjectStyleSelection) => {
      return new SldEngine(getNodeDirectory(styleSelection));
    },
    [getNodeDirectory]
  );

  const handleInitStage = useCallback(
    (model: NgGraceModel) => {
      const layout = new BusTransformersLayoutGraph(model, voltageLevelDirectory);
      layout.dispose();
      return Promise.resolve();
    },
    [voltageLevelDirectory]
  );

  return (
    <Page hasSidebar loading={!stage}>
      {stage && (
        <>
          <ProjectSidebar project={stage.project} current={stage.baseInfo.type} />
          <NotificationProvider>
            <EngineProvider
              key={stage.baseInfo.id} // important here, we need to re-init engine on new stage
              stage={stage}
              engineFactory={handleEngineFactory}
              onInitStage={handleInitStage}
              onUpdateStage={updateStage}
            >
              <DiagramModelProvider>
                <SldDiagramEditor
                  stage={stage}
                  onNextStage={nextStage}
                  onValidateStage={handleValidateStage}
                  onFinishStage={finishStage}
                  onRollbackStage={rollbackStage}
                />
              </DiagramModelProvider>
            </EngineProvider>
          </NotificationProvider>
          {overlayVisible && <Overlay />}
          <MessageModal error header="Validation failed" isOpen={!!validation} onRequestClose={handleValidationClose}>
            {validation}
          </MessageModal>
          {showNextStageModal && <SsdSettings stage={stage} onClose={closeNextStageModal} />}
        </>
      )}
    </Page>
  );
};
