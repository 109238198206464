import React from 'react';
import { Styled as S } from './AuthFeedback.styled';
import { Icon } from '../widgets/Icon';
import { useRoutes } from '../../routes/hooks/useRoutes';
import { AnonymUserPage } from '../AnonymUserPage';

export const LogoutPage: React.FC = () => {
  const redirectToLoginPage = useRoutes().redirectToLoginPage;

  return (
    <AnonymUserPage>
      <S.Form>
        <S.Main>
          <S.CheckMark>
            <Icon name={'checkmark'} />
          </S.CheckMark>
          <S.Title>See you later!</S.Title>
          <S.Info>You are logged out. To return, please log in.</S.Info>
          <S.Button onClick={redirectToLoginPage}>Return to login</S.Button>
        </S.Main>
      </S.Form>
    </AnonymUserPage>
  );
};
