import { SmartLinkModel } from '../../SmartLinkModel';
import { ConnectablePortModel } from '../../../../generics/ConnectablePortModel';
import { SmartLinkPointModel } from '../../../../point/SmartLinkPointModel';
import {
  DefaultConnectedPathLength,
  DefaultPortDependantPointsCount,
  ExtendedPortDependantPointsCount,
  PortDependentPath,
} from '../Path';

export class UpdatingDependenciesPortDependentPath implements PortDependentPath {
  private readonly link: SmartLinkModel;
  private readonly origin: PortDependentPath;

  constructor(origin: PortDependentPath, link: SmartLinkModel) {
    this.origin = origin;
    this.link = link;
  }

  getPoints(changed: ConnectablePortModel): SmartLinkPointModel[] {
    const link = this.link;
    const lengthBefore = link.getPoints().length;
    const sourceDependentCountBefore = link.getPoints().filter((point) => point.isSourceDependent()).length;
    const targetDependentCountBefore = link.getPoints().filter((point) => point.isTargetDependent()).length;

    const result = this.origin.getPoints(changed);

    const lengthAfter = result.length;

    const sourcePort = link.getSourcePort();
    result.forEach((point) => {
      point.setSourceDependent(false);
      point.setTargetDependent(false);
    });

    let sourceDependentCount = sourceDependentCountBefore;
    let targetDependentCount = targetDependentCountBefore;

    if (lengthAfter === DefaultConnectedPathLength) {
      sourceDependentCount = DefaultPortDependantPointsCount;
      targetDependentCount = DefaultPortDependantPointsCount;
    } else if (lengthBefore < lengthAfter) {
      if (changed.getID() === sourcePort.getID()) {
        sourceDependentCount = ExtendedPortDependantPointsCount;
      }

      const targetPort = link.getTargetPort();
      if (changed.getID() === targetPort.getID()) {
        targetDependentCount = ExtendedPortDependantPointsCount;
      }
    } else if (lengthBefore > lengthAfter) {
      if (changed.getID() === sourcePort.getID()) {
        sourceDependentCount = DefaultPortDependantPointsCount;
      }

      const targetPort = link.getTargetPort();
      if (changed.getID() === targetPort.getID()) {
        targetDependentCount = DefaultPortDependantPointsCount;
      }
    }

    result.slice(0, sourceDependentCount).forEach((point) => point.setSourceDependent(true));

    result.slice(-targetDependentCount).forEach((point) => point.setTargetDependent(true));

    return result;
  }
}
