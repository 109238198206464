import styled, { css } from 'styled-components';
import { Icon } from '../Icon.styled';
import SimpleBar from 'simplebar-react';

const indent = 16;
const rowHeight = 36;

const Tree = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
`;

const TreeNode = styled.div<{ indentLevel: number }>`
  position: relative;
`;

const Header = styled.div<{
  highlight: boolean;
  canHighlight?: boolean;
  hasParent: boolean;
  last: boolean;
  indentLevel: number;
}>`
  width: 100%;
  height: ${rowHeight}px;

  padding-left: 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ highlight, theme }) => (highlight ? theme.colors.white : theme.colors.black)};
  background-color: ${({ highlight, theme }) => highlight && theme.colors.blue};

  ${({ highlight, theme }) =>
    !highlight &&
    css`
      :hover {
        background-color: ${theme.colors.solitude};
      }
    `}

  ${(props) =>
    !props.canHighlight &&
    css`
      cursor: default;
    `}

  ${({ hasParent, last, indentLevel, theme }) =>
    hasParent &&
    css`
      :before {
        top: 4px;
        left: ${indent * indentLevel + 8}px;
        pointer-events: none;

        height: ${!last ? 'calc(100% - 2px)' : '14px'};
        width: 1px;
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        border-left: dashed 1px ${theme.colors.darkgrey};
      }
    `}
`;

const TreeToggle = styled.div<{ hasParent: boolean; indentLevel: number }>`
  z-index: 1;

  :after {
    top: 26px;
    left: ${({ indentLevel }) => 24 + indent * indentLevel}px;

    height: 12px;
    width: 1px;

    z-index: 0;
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    border-left: dashed 1px ${({ theme }) => theme.colors.darkgrey};
  }

  ${({ hasParent, indentLevel }) =>
    hasParent &&
    css`
      :before {
        top: ${18}px;
        left: ${8 + indent * indentLevel}px;

        height: 1px;
        width: 8px;

        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        border-top: dashed 1px ${({ theme }) => theme.colors.darkgrey};
      }
    `}
`;

const Toggle = styled(TreeToggle)<{ open: boolean; highlight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme, highlight }) => `${highlight ? theme.colors.white : theme.colors.black}`};
  flex-shrink: 0;
  cursor: pointer;

  ${Icon} {
    width: 8px;
    height: 4px;
    transform: ${(p) => (p.open ? 'rotate(180deg)' : '')};
  }

  ${({ open }) =>
    !open &&
    css`
      :after {
        display: none;
      }
    `}
`;

const EmptyToggle = styled(TreeToggle)<{ highlight: boolean }>`
  margin-left: 4px;
  margin-right: 4px;
  padding: 4px;
  height: 8px;
  width: 8px;
  flex-shrink: 0;
  background: ${({ theme, highlight }) => (highlight ? theme.colors.white : theme.colors.black)};

  :before {
    width: 12px;
  }

  :after {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 12px;
  width: 100%;
`;

const SubTree = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
`;

const Indent = styled.div<{ level: number }>`
  width: ${({ level }) => indent * level}px;
  flex-shrink: 0;
`;

export const Styled = {
  Tree,
  TreeNode,
  Header,
  TreeToggle,
  Toggle,
  EmptyToggle,
  Title,
  SubTree,
  Indent,
};
