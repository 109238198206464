import { Node, NodeType } from './Node';
import {
  CompositeRotationHour,
  PortAlignmentRotationHour,
  SmartRotationHour,
  ZeroingRotationHour,
} from '../../geometry/RotationHour';
import { Rectangle } from '@projectstorm/geometry';
import { NgGraceModel } from '../../NgGraceModel';
import { RotatableNodeModel } from '../../generics/RotatableNodeModel';

export class PortOrderDependentNode implements Node {
  private node: Node;
  private previousNode: Node;
  private model: NgGraceModel;

  constructor(node: Node, previousNode: Node, model: NgGraceModel) {
    this.node = node;
    this.previousNode = previousNode;
    this.model = model;
  }

  getHour(): SmartRotationHour {
    const nodeModel = this.model.getNode(this.node.getID());
    const portToPrevious = Object.values(nodeModel.getPorts()).find((port) =>
      Object.values(port.getLinks()).find(
        (link) =>
          link.getSourcePort().getParent().getID() === this.previousNode.getID() ||
          link.getTargetPort().getParent().getID() === this.previousNode.getID()
      )
    );

    return new CompositeRotationHour(
      new ZeroingRotationHour(
        new CompositeRotationHour(
          new PortAlignmentRotationHour(portToPrevious!),
          (nodeModel as RotatableNodeModel).getRotation()
        )
      ),
      this.node.getHour()
    );
  }

  getID(): string {
    return this.node.getID();
  }

  getRect(): Rectangle {
    return this.node.getRect();
  }

  getType(): NodeType {
    return this.node.getType();
  }
}
