import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import * as React from 'react';
import { BusNodeModel } from './BusNodeModel';
import { BusNodeWidget } from './BusNodeWidget';
import { RawBusNodeModel } from './RawBusNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export class BusNodeFactory extends AbstractReactFactory<BusNodeModel, DiagramEngine> {
  constructor() {
    super('bus');
  }

  generateReactWidget(event: { model: BusNodeModel }): JSX.Element {
    return <BusNodeWidget engine={this.engine} node={event.model} />;
  }

  generateModel(event: {}) {
    return new RawBusNodeModel();
  }
}
