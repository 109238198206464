import { DefaultGraph, Graph } from './graph/Graph';
import { DefaultNode, Node } from './node/Node';
import { NgGraceModel } from '../NgGraceModel';
import { RotatableNodeModel } from '../generics/RotatableNodeModel';
import { DefaultLink } from './Link';
import { NgGraceLinkModel } from '../link/NgGraceLinkModel';
import { DefaultBusses } from './graph/domain-dependent/Busses';
import { Transformers } from './graph/domain-dependent/Transformers';
import { WholeSchema } from './graph/domain-dependent/WholeSchema';
import { BusNeighbourhood } from './graph/domain-dependent/BusNeighbourhood';
import { BusBranchWithSideNodes } from './graph/domain-dependent/BusBranchWithSideNodes';
import { PathGraphFactory, SimpleNodesPathGraph } from './graph/PathGraph';
import { BusWithLocalsAndTrsAndNeighbourhood } from './graph/domain-dependent/BusWithLocalsAndTrsAndNeighbourhood';
import { TreeGraph } from './graph/TreeGraph';
import { CompositeGraph } from './graph/CompositeGraph';
import { BusLocals } from './graph/domain-dependent/BusLocals';
import { ForestGraph } from './graph/ForestGraph';
import { NodeNeighbourhoodGraph } from './graph/NodeNeighbourhoodGraph';
import { PortOrderDependentNode } from './node/PortOrderDependentNode';
import { NormalizedNode } from './node/NormalizedNode';
import { ExcludingGraph } from './graph/ExcludingGraph';
import { SmartLinkModel } from '../link/smart/SmartLinkModel';
import { ConnectedSmartLinkModel } from '../link/smart/ConnectedSmartLinkModel';
import { BusPortUpdatingGraph } from './graph/domain-dependent/BusPortUpdatingGraph';
import { CachingGraph } from './graph/CachingGraph';
import { DefaultMapping, Mapping, PathMapping } from './mapping/Mapping';
import { BusTransformersMapping } from './mapping/BusTransformersMapping';
import { OrderedBussesRows } from './graph/domain-dependent/OrderedBussesRows';
import { PortAlignedNode } from './node/PortAlignedNode';
import { PropertiesDirectory, VoltageLevelDirectoryEntry } from '../directory/PropertiesDirectory';
import { CachingRectNode } from './node/CachingRectNode';
import { BusLocalsOrderGraph } from './graph/domain-dependent/BusLocalsOrderGraph';

export class BusTransformersLayoutGraph {
  layout: Graph;
  model: NgGraceModel;
  voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>;

  constructor(model: NgGraceModel, voltageLevelDirectory: PropertiesDirectory<VoltageLevelDirectoryEntry>) {
    this.model = model;
    this.voltageLevelDirectory = voltageLevelDirectory;
    const cacheHandle = { date: Date.now() };
    const environment = new CachingGraph(
      cacheHandle,
      new DefaultGraph(
        model.getNodes().map((node) => new NormalizedNode(new DefaultNode(node as RotatableNodeModel))),
        model.getLinks().map((link) => new DefaultLink(link as NgGraceLinkModel))
      )
    );
    const transformers = new CachingGraph(cacheHandle, new Transformers(environment));
    const defaultOrderedNodeMapping = new DefaultMapping(
      (parent: Node) =>
        new DefaultMapping(
          ({ node, previousNode = parent }: { node: Node; previousNode: Node }) =>
            new PortOrderDependentNode(node, previousNode, model)
        )
    );
    const defaultAlignedNodeMapping = new DefaultMapping((nodes: Node[]) => {
      if (!nodes.length) {
        return [];
      }
      const result = [nodes[0]];
      nodes
        .slice(1)
        .forEach((node, index) => result.push(new CachingRectNode(new PortAlignedNode(node, result[index], model))));
      return result;
    });
    const localsTrunkMapping = new DefaultMapping((paths: Node[][]) => {
      const maxLength = Math.max(...paths.map((path) => path.length));
      return paths.find((path) => path.length === maxLength)!;
    });

    const wholeGraphPathFactory = PathGraphFactory(environment);
    const smartSimplePathMapping: PathMapping = {
      map: ({ start, end }) => new SimpleNodesPathGraph(start, end, environment, wholeGraphPathFactory),
    };

    const busNeighbourhoodMapping = new DefaultMapping(
      (bus: Node) =>
        new CachingGraph(
          cacheHandle,
          new CompositeGraph(
            [
              transformers,
              new DefaultGraph(bus),
              new CachingGraph(
                cacheHandle,
                new BusNeighbourhood(
                  bus,
                  transformers,
                  new DefaultMapping(
                    (transformer: Node): Graph =>
                      new CachingGraph(
                        cacheHandle,
                        new BusBranchWithSideNodes(
                          smartSimplePathMapping.map({ start: bus, end: transformer }),
                          bus,
                          transformer,
                          new DefaultMapping(
                            (nodeToFindNeighboursFor) =>
                              new CachingGraph(
                                cacheHandle,
                                new NodeNeighbourhoodGraph(nodeToFindNeighboursFor, environment)
                              )
                          )
                        )
                      )
                  )
                )
              ),
            ],
            environment
          )
        )
    );

    const busWithTrsAndNeighbourhoodMapping = new DefaultMapping((bus: Node) => {
      const busNeighbourhood = busNeighbourhoodMapping.map(bus);

      const treeEnvironment = new CachingGraph(
        cacheHandle,
        new ExcludingGraph(busNeighbourhood, new DefaultGraph(bus))
      );

      const treePathFactory = PathGraphFactory(treeEnvironment);

      return new CachingGraph(
        cacheHandle,
        new ExcludingGraph(
          new CachingGraph(
            cacheHandle,
            new ForestGraph(
              new NodeNeighbourhoodGraph(bus, busNeighbourhood),
              busNeighbourhood,
              new DefaultMapping(
                ({ root }) =>
                  new CachingGraph(
                    cacheHandle,
                    new TreeGraph(
                      root,
                      treeEnvironment,
                      defaultOrderedNodeMapping.map(bus),
                      trTrunkMapping,
                      defaultAlignedNodeMapping,
                      treePathFactory
                    )
                  )
              )
            )
          ),
          new ExcludingGraph(transformers, busTransformersMapping.map(bus))
        )
      );
    });

    const busLocalsMapping: Mapping<Node, Graph> = new DefaultMapping<Node, Graph>(
      (bus: Node) =>
        new CachingGraph(
          cacheHandle,
          new CompositeGraph(
            [
              new BusLocals(bus, busWithTrsAndNeighbourhoodMapping.map(bus), environment, wholeGraphPathFactory),
              new DefaultGraph(bus),
            ],
            environment
          )
        )
    );

    const disorderedBusses = new DefaultBusses(environment);

    const busses = new OrderedBussesRows(
      disorderedBusses,
      new BusTransformersMapping(disorderedBusses, transformers, smartSimplePathMapping),
      model,
      voltageLevelDirectory,
      smartSimplePathMapping
    );

    const busTransformersMapping = new BusTransformersMapping(busses, transformers, smartSimplePathMapping);

    const trTrunkMapping = new DefaultMapping(
      (paths: Node[][]) =>
        transformers
          .getNodes()
          .map((tr) => paths.find((path) => path[path.length - 1].getID() === tr.getID()))
          .find((path) => path)!
    );

    const busLocalsMap = busses
      .getBusRows()
      .flat()
      .map((bus) => ({ busLocals: busLocalsMapping.map(bus), bus }))
      .reduce((map, entry) => map.set(entry.bus, entry.busLocals), new Map<Node, Graph>());

    this.layout = new CachingGraph(
      cacheHandle,
      new WholeSchema(
        busses,
        new DefaultMapping((bus) => {
          const treeNeighbourhoodEnvironment = new ExcludingGraph(busLocalsMapping.map(bus), new DefaultGraph(bus));
          const treeNeighbourhoodPathFactory = PathGraphFactory(treeNeighbourhoodEnvironment);

          return new CachingGraph(
            cacheHandle,
            new BusPortUpdatingGraph(
              bus,
              busses,
              model,
              new CachingGraph(
                cacheHandle,
                new BusWithLocalsAndTrsAndNeighbourhood(
                  bus,
                  new ExcludingGraph(busWithTrsAndNeighbourhoodMapping.map(bus), new DefaultGraph(bus)),
                  new CachingGraph(
                    cacheHandle,
                    new ExcludingGraph(
                      new CachingGraph(
                        cacheHandle,
                        new ForestGraph(
                          new BusLocalsOrderGraph(bus, busLocalsMap, busses),
                          busLocalsMap.get(bus)!,
                          new DefaultMapping(
                            ({ root }) =>
                              new CachingGraph(
                                cacheHandle,
                                new TreeGraph(
                                  root,
                                  treeNeighbourhoodEnvironment,
                                  defaultOrderedNodeMapping.map(bus),
                                  localsTrunkMapping,
                                  defaultAlignedNodeMapping,
                                  treeNeighbourhoodPathFactory
                                )
                              )
                          )
                        )
                      ),
                      new DefaultGraph(bus)
                    )
                  )
                )
              )
            )
          );
        })
      )
    );
  }

  dispose() {
    const model = this.model;
    const nodes = this.layout.getNodes();
    nodes.forEach((layoutNode) => {
      const node = model.getNode(layoutNode.getID()) as RotatableNodeModel;
      const center = layoutNode.getRect().getOrigin();
      const size = node.getSize();
      node.setPosition(center.x - size.x / 2, center.y - size.y / 2);
      node.setRotation(layoutNode.getHour());
    });

    const newLinks = (model.getLinks() as SmartLinkModel[]).map(
      (link) => new ConnectedSmartLinkModel(link.getSourcePort(), link.getTargetPort())
    );

    model.getLinks().forEach((oldLink) => oldLink.remove());
    model.addAll(...newLinks);
  }
}
