import { Link } from '../../Link';
import { Node, NodeType } from '../../node/Node';
import { Graph } from '../Graph';
import { Polygon, Rectangle } from '@projectstorm/geometry';

export interface Busses extends Graph {
  getBusRows(): Node[][];
}

export class DefaultBusses implements Busses {
  private environment: Graph;

  constructor(environment: Graph) {
    this.environment = environment;
  }

  getLinks(): Link[] {
    return [];
  }

  getNodes(): Node[] {
    return this.environment.getNodes().filter((node) => node.getType() === NodeType.BUS);
  }

  getBusRows(): Node[][] {
    const busses = this.getNodes();
    const bussesPerRow = Math.ceil(busses.length / 2);
    return [busses.slice(0, bussesPerRow), busses.slice(bussesPerRow)];
  }

  getRect(): Rectangle {
    return Polygon.boundingBoxFromPolygons(this.getNodes().map((node) => node.getRect()));
  }
}
