import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { OverlayProvider } from './components/context/OverlayContext';
import WebFont from 'webfontloader';
import { UserProvider } from './components/pages/login/UserContext';
import { NotificationProvider } from './components/context/NotificationContext';

function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      loading: () => {
        setFontsLoaded(true);
      },
      custom: {
        families: ['Mulish', 'Open Sans', 'Nunito', 'GostRus'],
        urls: ['/fonts.css'],
      },
    });
  }, []);

  return (
    <>
      {fontsLoaded && (
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <BrowserRouter>
              <OverlayProvider>
                <UserProvider>
                  <AppRoutes />
                </UserProvider>
              </OverlayProvider>
            </BrowserRouter>
          </NotificationProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
