import {
  AbstractDisplacementState,
  AbstractDisplacementStateEvent,
  Action,
  ActionEvent,
  InputType,
  State,
} from '@projectstorm/react-canvas-core';
import { KeyboardEvent, MouseEvent, WheelEvent } from 'react';
import { NodeResizerClassName, NodeResizerIdAttributeName } from '../NodeResizer';
import { ResizableNodeModel, ResizeEdge } from '../generics/ResizableNodeModel';
import { DiagramEngine } from '../insides/engine/DiagramEngine';

export class ResizeNodeState extends AbstractDisplacementState<DiagramEngine> {
  node?: ResizableNodeModel;
  resizerId?: string;

  constructor() {
    super({ name: 'resize-node-state' });

    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent | KeyboardEvent | WheelEvent>) => {
          const model = this.engine.getMouseElement(event.event as MouseEvent);
          if (!(model instanceof ResizableNodeModel)) {
            return this.eject();
          }
          this.node = model;
          this.resizerId = this.getResizerId(event.event.target as Element);
          this.engine.getModel().clearSelection();
        },
      })
    );
  }

  fireMouseMoved(event: AbstractDisplacementStateEvent) {
    if (!this.node || !this.resizerId) {
      throw new Error(
        'mouse down has not set node or resizerId, probably state transition executed without passing event'
      );
    }
    const mousePoint = this.engine.getRelativeMousePoint(event.event);
    const affectedEdges = this.node.resize(this.resizerId, mousePoint);
    this.engine.getCanvas().style.cursor = this.getResizeCursor(affectedEdges);
    this.engine.repaintCanvas();
  }

  getResizerId(element: Element) {
    return element.closest(`.${NodeResizerClassName}`)!.getAttribute(NodeResizerIdAttributeName)!;
  }

  deactivated(next: State) {
    this.engine.getCanvas().style.removeProperty('cursor');

    super.deactivated(next);
  }

  private getResizeCursor = (edges: ResizeEdge[]): string => {
    const horizontal = edges.includes(ResizeEdge.LEFT) || edges.includes(ResizeEdge.RIGHT);
    const vertical = edges.includes(ResizeEdge.TOP) || edges.includes(ResizeEdge.BOTTOM);

    return horizontal && vertical ? 'move' : horizontal ? 'ew-resize' : vertical ? 'ns-resize' : 'move';
  };
}
