import React from 'react';
import { useDrag } from 'react-dnd';
import { DraggableProps } from '../../editor/dnd/Draggable';
import { TreeDraggablePayload } from './state/TreeState';

export const TreeDraggable: React.FC<DraggableProps<TreeDraggablePayload>> = ({ formatId, payload, children }) => {
  const [, ref] = useDrag(
    () => ({
      type: formatId,
      item: payload,
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }),
    [payload]
  );

  return <div ref={ref}>{children}</div>;
};
